import type { Web3Blockchain } from "../model/pet/BlockchainId";
import { Blockchain } from "../model/pet/BlockchainId";
import type BlockchainReader from "./BlockchainReader";
import { EosReader } from "./eos/EosReader";
import Web3Reader from "./matic/Web3Reader";
import { NeoReader } from "./neo/NeoReader";
import { TronReader } from "./tron/TronReader";
import { ConfigInstance } from "@/cuties/engine/Config";
import TronHelper from "@/cuties/blockchain/tron/TronHelper";

export default class BlockchainReaderService {
    private static whenMaticReader: Promise<Web3Reader> | null = null;
    private static whenEthereumReader: Promise<Web3Reader> | null = null;
    private static whenNeoReader: Promise<NeoReader> | null = null;
    private static whenEosReader: Promise<EosReader> | null = null;
    private static whenTronReader: Promise<TronReader> | null = null;
    private static whenHecoReader: Promise<Web3Reader> | null = null;
    private static whenEmeraldReader: Promise<Web3Reader> | null = null;
    private static whenCscReader: Promise<Web3Reader> | null = null;
    private static whenOkcReader: Promise<Web3Reader> | null = null;
    private static whenBscReader: Promise<Web3Reader> | null = null;

    static maticReader: () => Promise<Web3Reader> = BlockchainReaderService.getMaticReader;
    static ethereumReader: () => Promise<Web3Reader> = BlockchainReaderService.getEthereumReader;
    static tronReader: () => Promise<TronReader> = BlockchainReaderService.getTronReader;
    static eosReader: () => Promise<EosReader> = BlockchainReaderService.getEosReader;
    static neoReader: () => Promise<NeoReader> = BlockchainReaderService.getNeoReader;
    static hecoReader: () => Promise<Web3Reader> = BlockchainReaderService.getHecoReader;
    static emeraldReader: () => Promise<Web3Reader> = BlockchainReaderService.getEmeraldReader;
    static cscReader: () => Promise<Web3Reader> = BlockchainReaderService.getCscReader;
    static okcReader: () => Promise<Web3Reader> = BlockchainReaderService.getOkcReader;
    static bscReader: () => Promise<Web3Reader> = BlockchainReaderService.getBscReader;

    static blockchainReaderImpls: Record<string, () => Promise<BlockchainReader>> = {
        [Blockchain.Ethereum]: () => BlockchainReaderService.getEthereumReader(),
        [Blockchain.Matic]: () => BlockchainReaderService.getMaticReader(),
        [Blockchain.Tron]: () => BlockchainReaderService.getTronReader(),
        [Blockchain.Eos]: () => BlockchainReaderService.getEosReader(),
        [Blockchain.Neo]: () => BlockchainReaderService.getNeoReader(),
        [Blockchain.Neo3]: () => BlockchainReaderService.getNeoReader(),
        [Blockchain.Heco]: () => BlockchainReaderService.getHecoReader(),
        [Blockchain.Emerald]: () => BlockchainReaderService.getEmeraldReader(),
        [Blockchain.Csc]: () => BlockchainReaderService.getCscReader(),
        [Blockchain.Okc]: () => BlockchainReaderService.getOkcReader(),
        [Blockchain.Bsc]: () => BlockchainReaderService.getBscReader(),
    };

    public static async getBlockchainReader(blockchain: Blockchain): Promise<BlockchainReader> {
        return Promise.resolve(BlockchainReaderService.blockchainReaderImpls[blockchain]());
    }

    public static async getWeb3Reader(blockchain: Web3Blockchain): Promise<Web3Reader> {
        return BlockchainReaderService.blockchainReaderImpls[blockchain]().then((reader) => reader as Web3Reader);
    }

    private static async getMaticReader(): Promise<Web3Reader> {
        if (!BlockchainReaderService.whenMaticReader) {
            if (!ConfigInstance.ethereums[Blockchain.Matic]) {
                throw new Error("Config for Web3-compatible blockchain: " + Blockchain.Matic + " was not supplied");
            }

            BlockchainReaderService.whenMaticReader = Promise.resolve(
                new Web3Reader(Blockchain.Matic, ConfigInstance.ethereums[Blockchain.Matic])
            );
        }
        return BlockchainReaderService.whenMaticReader;
    }

    private static async getHecoReader(): Promise<Web3Reader> {
        if (!BlockchainReaderService.whenHecoReader) {
            if (!ConfigInstance.ethereums[Blockchain.Heco]) {
                throw new Error("Config for Web3-compatible blockchain: " + Blockchain.Heco + " was not supplied");
            }

            BlockchainReaderService.whenHecoReader = Promise.resolve(
                new Web3Reader(Blockchain.Heco, ConfigInstance.ethereums[Blockchain.Heco])
            );
        }
        return BlockchainReaderService.whenHecoReader;
    }

    private static async getEmeraldReader(): Promise<Web3Reader> {
        if (!BlockchainReaderService.whenEmeraldReader) {
            if (!ConfigInstance.ethereums[Blockchain.Emerald]) {
                throw new Error("Config for Web3-compatible blockchain: " + Blockchain.Emerald + " was not supplied");
            }

            BlockchainReaderService.whenEmeraldReader = Promise.resolve(
                new Web3Reader(Blockchain.Emerald, ConfigInstance.ethereums[Blockchain.Emerald])
            );
        }
        return BlockchainReaderService.whenEmeraldReader;
    }

    private static async getCscReader(): Promise<Web3Reader> {
        if (!BlockchainReaderService.whenCscReader) {
            if (!ConfigInstance.ethereums[Blockchain.Csc]) {
                throw new Error("Config for Web3-compatible blockchain: " + Blockchain.Csc + " was not supplied");
            }

            BlockchainReaderService.whenCscReader = Promise.resolve(
                new Web3Reader(Blockchain.Csc, ConfigInstance.ethereums[Blockchain.Csc])
            );
        }
        return BlockchainReaderService.whenCscReader;
    }

    private static async getOkcReader(): Promise<Web3Reader> {
        if (!BlockchainReaderService.whenOkcReader) {
            if (!ConfigInstance.ethereums[Blockchain.Okc]) {
                throw new Error("Config for Web3-compatible blockchain: " + Blockchain.Okc + " was not supplied");
            }

            BlockchainReaderService.whenOkcReader = Promise.resolve(
                new Web3Reader(Blockchain.Okc, ConfigInstance.ethereums[Blockchain.Okc])
            );
        }
        return BlockchainReaderService.whenOkcReader;
    }

    private static async getBscReader(): Promise<Web3Reader> {
        if (!BlockchainReaderService.whenBscReader) {
            if (!ConfigInstance.ethereums[Blockchain.Bsc]) {
                throw new Error("Config for Web3-compatible blockchain: " + Blockchain.Bsc + " was not supplied");
            }

            BlockchainReaderService.whenBscReader = Promise.resolve(
                new Web3Reader(Blockchain.Bsc, ConfigInstance.ethereums[Blockchain.Bsc])
            );
        }
        return BlockchainReaderService.whenBscReader;
    }

    private static async getEthereumReader(): Promise<Web3Reader> {
        if (!BlockchainReaderService.whenEthereumReader) {
            if (!ConfigInstance.ethereums[Blockchain.Ethereum]) {
                throw new Error("Config for Web3-compatible blockchain: " + Blockchain.Ethereum + " was not supplied");
            }

            BlockchainReaderService.whenEthereumReader = Promise.resolve(
                new Web3Reader(Blockchain.Ethereum, ConfigInstance.ethereums[Blockchain.Ethereum])
            );
        }
        return BlockchainReaderService.whenEthereumReader;
    }

    private static getTronReader(): Promise<TronReader> {
        if (!BlockchainReaderService.whenTronReader) {
            BlockchainReaderService.whenTronReader = TronHelper.initClient(ConfigInstance.tron)
                .then(tronWeb => new TronReader(ConfigInstance.tron, tronWeb));
        }
        return BlockchainReaderService.whenTronReader;
    }

    public static async getNeoReader(): Promise<NeoReader> {
        if (!BlockchainReaderService.whenNeoReader) {
            BlockchainReaderService.whenNeoReader = Promise.resolve(new NeoReader(ConfigInstance.neo));
        }
        return BlockchainReaderService.whenNeoReader;
    }

    private static async getEosReader(): Promise<EosReader> {
        if (!BlockchainReaderService.whenEosReader) {
            BlockchainReaderService.whenEosReader = Promise.resolve(new EosReader());
        }
        return BlockchainReaderService.whenEosReader;
    }
}
