
    import VueUtils from "@/cuties/VueUtils";
    import { Component, Emit, Prop, Vue } from "vue-property-decorator";
    import { Getter } from "vuex-class";
    import HeaderMenuMobile from "@/components/HeaderMenuMobile.vue";
    import HeaderLangs from "@/app/components/header/HeaderLangs.vue";
    import type { HeaderProfileMenuEntry } from "@/app/cuties/header/HeaderProfileMenu";
    import UserProfileIcon from "@/app/components/player/UserProfileIcon.vue";
    import FeaturedCutieSelectPopup from "@/app/components/cutie/list/FeaturedCutieSelectPopup.vue";
    import type { HeaderMenuEntry } from "cuties-client-components/types/api/Config";

    @Component({
        components: { FeaturedCutieSelectPopup, UserProfileIcon, HeaderMenuMobile, HeaderLangs },
    })
    export default class HeaderMobilePart extends Vue {
        @Getter("getIsLogined") isLogined: boolean | undefined;
        @Prop({ required: true }) headerMenu!: HeaderMenuEntry[];
        @Prop({ required: true }) lang!: string;
        @Prop({ required: true }) langLong!: string;
        @Prop({ required: true }) playerId!: number;
        @Prop({ required: true }) showMobileMenu!: boolean;
        @Prop({ required: true }) name!: string;
        @Prop({ required: true }) pawCoins!: number;
        @Prop({ required: true }) profileMenu!: HeaderProfileMenuEntry[];

        private openedMenus = [];
        featuredCutieSelectPopupVisible = false;
        profileImageUpdateVersion = 0;
        profileOpened = false;
        langsOpened = false;

        readonly goToLink = VueUtils.goToLink;

        mobileGoToLink(event: MouseEvent): void {
            this.close();
        }

        @Emit()
        logout(): void {
            this.close();
        }

        closeMobileMenu(): void {
            this.openedMenus = [];
            this.close();
        }

        @Emit()
        close() {}

        @Emit()
        auth() {}

        @Emit()
        donate() {}
    }
