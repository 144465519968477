

    import { Component, Vue } from "vue-property-decorator";
    import { Getter } from "vuex-class";
    import type { LoginAdvertisement } from "@/app/cuties/engine/ConfigApiService";
    import ConfigApiService from "@/app/cuties/engine/ConfigApiService";
    import VueUtils from "@/cuties/VueUtils";
    import type { Player } from "@/cuties/model/player/Player";
    import { AppStoreGetters } from "@/store/AppStore";

    function hasTextInAd(advertisement: LoginAdvertisement): boolean {
        return !!advertisement.headingText
            || !!advertisement.descriptionText;
    }

    function isEmptyAdvertisement(advertisement: LoginAdvertisement): boolean {
        return advertisement.bannerImageType === "NO_IMAGE"
            && !hasTextInAd(advertisement)
            && !advertisement.descriptionText;
    }

    const LAST_READ_LOGIN_ADVERTISEMENT_ID = "LAST_READ_LOGIN_ADVERTISEMENT_ID";
    const LAST_READ_PATCHNOTES_VERSION = "LAST_READ_PATCHNOTES_VERSION";

    @Component({
    })
    export default class NewsPopup extends Vue {
        loginAdvertisement: LoginAdvertisement | null = null;

        popupClosed = false;

        lastReadLoginAdvertisementId: number | null = null;
        lastReadPatchnotesVersion: string | null = null;

        @Getter("getIsLogined") isLogined: boolean | undefined;
        @Getter("getPlayer") user!: Player;
        @Getter(AppStoreGetters.GET_TRANSLATIONS_LOADED) translationsLoaded!: boolean;

        created() {
            const lastAdId = localStorage.getItem(LAST_READ_LOGIN_ADVERTISEMENT_ID);
            this.lastReadLoginAdvertisementId = lastAdId ? +lastAdId : null;
            this.lastReadPatchnotesVersion = localStorage.getItem(LAST_READ_PATCHNOTES_VERSION);
        }

        mounted() {
            if (this.user.getRegisteredForMs() > 60 * 60 * 1000) {
                this.onLoggedIn();
            }
        }

        get version(): string {
            return this.$t("news_update_header") + "";
        }

        private async onLoggedIn() {
            this.loginAdvertisement = await ConfigApiService.getCurrentLoginAdvertisement();
        }

        get hasUnreadPatchnotes() {
            return this.lastReadPatchnotesVersion !== this.version;
        }

        get hasUnreadAdvertisement() {
            return this.loginAdvertisement
                && !isEmptyAdvertisement(this.loginAdvertisement)
                && (!this.lastReadLoginAdvertisementId || +this.lastReadLoginAdvertisementId !== this.loginAdvertisement.id);
        }

        isMobile(): boolean {
            return window.innerWidth < 800;
        }

        closePopup() {
            this.popupClosed = true;
        }

        hasTextInAd(advertisement: LoginAdvertisement) {
            return hasTextInAd(advertisement);
        }

        get isOpen() {
            if (this.user && this.user.getRegisteredForMs() < 60 * 60 * 1000) {
                return false; // don't complicate things for new users who just registered
            }
            const hasUnreadContent = this.hasUnreadAdvertisement || this.hasUnreadPatchnotes;
            return !this.popupClosed && this.isLogined && hasUnreadContent;
        }

        onClose() {
            if (this.loginAdvertisement) {
                this.lastReadLoginAdvertisementId = this.loginAdvertisement.id;
                localStorage.setItem(LAST_READ_LOGIN_ADVERTISEMENT_ID, this.loginAdvertisement.id + "");
            }
        }

        goToLink(event: MouseEvent) {
            const anchor = event.currentTarget as HTMLAnchorElement;
            const link = anchor.getAttribute("href");
            const asLocalLink = VueUtils.asLocalLink(link);
            if (asLocalLink) {
                event.preventDefault();
                this.$router.push({ path: asLocalLink });
            } else {
                // let browser handle non-game links normally
            }
            this.closePopup();
        }

        doNotShow() {
            localStorage.setItem(LAST_READ_PATCHNOTES_VERSION, this.version);
            this.lastReadPatchnotesVersion = this.version;
            this.closePopup();
        }
    }
