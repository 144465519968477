import RequestForm from "@/cuties/request/form/RequestForm";
import utils from "@/cuties/utils";


export default class RequestFormEmailSubscribe extends RequestForm {



    constructor(form_selector:HTMLElement|string) {
        super(form_selector, "/email_subscribe");
    }


    collect_post_data(): object {
        this.form_data = this.form_data || {};
        this.form_data["referrer"] = utils.get_referrer();
        return this.form_data;
    }


}
