import { bcuGetApi, getStateless, makeHttp } from "@/http";
import type DynamicConfiguration from "@/cuties/engine/DynamicConfiguration";
import type { ConfigModel } from "@/cuties/engine/ConfigModel";
import type { DropGroupView } from "@/cuties/model/adventure/AdventureModel";
import type { StatValue } from "@/cuties/BattleHpService";

const http = makeHttp({ baseUrl: "/rest" });

let whenWallpaperBackgrounds: null | Promise<Record<string, WallpaperBackground>> = null;
let whenWallpaperPatterns: null | Promise<Record<string, WallpaperPattern>> = null;
let whenDropRollDefinitionList: null | Promise<DropRollDefinitionListResponse> = null;
let whenItemTagModifierList: null | Promise<EnablableItemTagModifier[]> = null;
let whenCurrentLoginAdvertisement: null | Promise<LoginAdvertisement> = null;

export default {
    ...bcuGetApi.config,

    getCurrentLoginAdvertisement(): Promise<LoginAdvertisement> {
        if (whenCurrentLoginAdvertisement === null) {
            whenCurrentLoginAdvertisement = getStateless(
                "/public/login/advertisement/current"
            ).then(rs => rs.data);
        }
        return whenCurrentLoginAdvertisement;
    },

    /** @return - ISO datetime string */
    getTimeNow(): Promise<string> {
        return getStateless("/public/config/time/now").then(rs => rs.data);
    },

    getConstConfigWebData(): Promise<ConfigModel> {
        return getStateless("/public/config/const/data").then(rs => rs.data);
    },

    getDynamicConfigWebData(): Promise<DynamicConfiguration> {
        return getStateless("/public/config/dynamic/data").then(rs => rs.data);
    },

    getWallpaperBackgrounds(): Promise<Record<string, WallpaperBackground>> {
        if (!whenWallpaperBackgrounds) {
            whenWallpaperBackgrounds = getStateless("/public/config/wallpaper/backgrounds").then(rs => rs.data);
        }
        return whenWallpaperBackgrounds;
    },

    getWallpaperPatterns(): Promise<Record<string, WallpaperPattern>> {
        if (!whenWallpaperPatterns) {
            whenWallpaperPatterns = getStateless("/public/config/wallpaper/patterns").then(rs => rs.data);
        }
        return whenWallpaperPatterns;
    },

    dropRollDefinitionList(): Promise<DropRollDefinitionListResponse> {
        if (whenDropRollDefinitionList === null) {
            whenDropRollDefinitionList = getStateless("/public/drop/roll/definition/list").then(rs => rs.data);
        }
        return whenDropRollDefinitionList;
    },

    itemTagModifierList(): Promise<EnablableItemTagModifier[]> {
        if (whenItemTagModifierList === null) {
            whenItemTagModifierList = getStateless("/public/item/tag/modifier/definition/list").then(rs => rs.data);
        }
        return whenItemTagModifierList;
    },
};

/** may possibly add something like mp4 in future */
export type BannerImageType = "PNG" | "JPG" | "GIF" | "NO_IMAGE";

/** @see LoginAdvertisement.java */
export interface LoginAdvertisement {
    id: number;
    headingText?: string;
    descriptionText?: string;
    navigationUrl?: string;
    showInBanners?: boolean;
    openUrlInNewTab?: boolean;
    bannerImageType: BannerImageType;
}

export interface WallpaperBackground {
    /** "bg_winterwolf" */
    id: string;
    /** #000 */
    color?: string;
    /** ["linear-gradient(90deg, rgba(0, 0, 0, 0.3), transparent 15%, transparent 85%, rgba(0, 0, 0, 0.3))"] */
    gradients: string[];
}

export interface WallpaperPattern {
    /** "pt_victorvonstradovich" */
    id: string;
    /** "/static/bg/png/pt_victorvonstradovich.png" */
    imagePath: string;
}

export type DropRollView = DropGroupView & {
    id: string;
};

interface DropRollDefinitionListResponse {
    /** ISO, the time that was used as base for pools replenishment state calculation */
    time: string;
    dropRolls: DropRollView[];
}

export interface EnablableItemTagModifier {
    tag: string;
    stats: StatValue[];
}
