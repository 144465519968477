
    import { Component, Prop, Vue } from "vue-property-decorator";
    import type { AnyItemSupplySource } from "../../cuties/items/ItemSupplySource";
    import ItemSupplySourcesLayer from "@/app/cuties/items/ItemSupplySourcesLayer";
    import { anyCaseToHuman } from "cuties-client-components/src/TextUtils";

    /** renders nicely a structured link to a place in game where item in question can be obtained */
    @Component({})
    export default class ItemSupplySourceLine extends Vue {
        @Prop({ required: true }) itemKind: string;
        @Prop({ required: true }) supplySource: AnyItemSupplySource;

        getTypeSpecificDataStr(): string {
            const { type, status, ...typeSpecificData } = this.supplySource;
            return Object.keys(typeSpecificData).length === 0
                ? "" : JSON.stringify(typeSpecificData);
        }

        get link(): string | undefined {
            const link = ItemSupplySourcesLayer.getLink(this.supplySource);
            return link ? "/" + this.$i18n.locale + link : undefined;
        }

        translateQuestName(questName: string) {
            return this.$te("Quest_" + questName + "_title")
                ? this.$t("Quest_" + questName + "_title")
                : anyCaseToHuman(questName);
        }

        async goThere(event: MouseEvent) {
            event.preventDefault();
            this.$emit("close");
            if (this.link) {
                // VueUtils.goToLink(event);
                this.$router.push({
                    path: this.link,
                });
            } else {
                ItemSupplySourcesLayer.goToNoLinkSource(this.itemKind, this.supplySource);
            }
        }
    }
