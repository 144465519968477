import SaleEntryRewardModel from "@/cuties/model/sale/SaleEntryRewardModel";
import type SaleEntryPriceModel from "@/cuties/model/sale/SaleEntryPriceModel";
import type { Blockchain } from "../pet/BlockchainId";
import type { SaleEntryModel as SaleEntryModelData } from "@/app/cuties/presale/PresaleCutie";

export default class SaleEntryModel {
    public readonly data: SaleEntryModelData;
    public readonly lotId: number;
    public readonly active: boolean;
    public readonly started: boolean;
    public readonly prices: SaleEntryPriceModel[] = [];
    public readonly rewards: SaleEntryRewardModel[] = [];
    public readonly custody: Blockchain[] | null;

    constructor(data: SaleEntryModelData) {
        this.data = data;
        this.lotId = data.lotId;
        this.active = data.active;
        this.started = data.started;
        this.custody = data.custody;

        for (let index = 0; index < data.rewards.length; index++) {
            const rewardData = data.rewards[index];
            if (rewardData.type == "PET") {
                const saleEntryRewardModel = new SaleEntryRewardModel(rewardData);
                this.rewards.push(saleEntryRewardModel);
            }
        }

        for (let index = 0; index < data.prices.length; index++) {
            this.prices.push(data.prices[index]);
        }
    }
}
