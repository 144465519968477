import { PLAYER_SINGLETON } from "@/cuties/model/player/Player";
import I18nHelpers from "@/cuties/engine/I18nHelpers";
import DataHelpers from "@/cuties/engine/DataHelpers";
import PlayerModel from "../player/PlayerModel";
import PetModel from "../pet/PetModel";
import { Blockchain } from "../pet/BlockchainId";
import type CurrencyModel from "@/cuties/model/sale/CurrencyModel";
import { ActivityType } from "@/cuties/model/activity/ActivityType";
import type { ActivityModelData } from "@/cuties/model/query/ActivityModelData";

export default class ActivityModel {

    private type:ActivityType;
    private type_source: keyof typeof ActivityType | string;
    private time:number;

    private user1:PlayerModel = null;
    private user2:PlayerModel = null;

    private pet1:PetModel = null;
    private pet2:PetModel = null;
    private pet3:PetModel = null;

    private transactionError: string = null;
    private value: number = null;

    private confirmationCount:number;
    private needConfirmationCount:number;

    private blockchain:Blockchain;
    private eosAccountName:string;

    private currency : CurrencyModel;

    constructor(data: ActivityModelData & {
        petId?: never; // not passed anymore
    }) {
        this.currency = data.currency;

        this.user1 = data.user1 ? new PlayerModel(data.user1) : null;
        this.user2 = data.user2 ? new PlayerModel(data.user2) : null;

        this.pet1 = data.pet1 ? new PetModel(data.pet1) : null;
        this.pet2 = data.pet2 ? new PetModel(data.pet2) : null;
        this.pet3 = data.pet3 ? new PetModel(data.pet3) : null;

        this.blockchain = DataHelpers.to_enum(Blockchain, data.blockchain, Blockchain.Offchain);
        this.eosAccountName = data.eosAccountName;

        this.transactionError = data.transactionError;

        this.value = data.value;

        this.time = DataHelpers.toInt(data.time);

        this.confirmationCount = DataHelpers.toInt(data.confirmationCount);
        this.needConfirmationCount = DataHelpers.toInt(data.needConfirmationCount);

        this.type = DataHelpers.to_enum_value(ActivityType, data.type, ActivityType.None);
        this.type_source = data.type;
    }

    public getPet1Name(): string {
        return this.pet1 ? this.pet1.get_name() : null;
    }

    public getPet2Name(): string {
        return this.pet2 ? this.pet2.get_name() : null;
    }

    public getPet3Name(): string {
        return this.pet3 ? this.pet3.get_name() : null;
    }

    get_description(): string {
        let type_name = DataHelpers.to_enum_key(ActivityType, this.type);
        if (this.type == ActivityType.None)
            type_name = this.type_source;

        let str = I18nHelpers.translate("activity" + type_name);

        str = DataHelpers.replace_all(str, "%Type%", type_name);
        str = DataHelpers.replace_all(str, "%iconPawcoin%", "<span class='act-pawcoin'></span>");
        str = DataHelpers.replace_all(str, "%iconCutecoin%", "<span class='act-cutecoin'></span>");
        str = DataHelpers.replace_all(str, "%Time%", DataHelpers.get_time_string(this.time));
        str = DataHelpers.replace_all(str, "%EosAccountName%", this.eosAccountName);

        if(this.type == ActivityType.ExternalContract) {
            str = DataHelpers.replace_all(str, "%currency%", this.currency.type);
        }

        str = DataHelpers.replace_all(str, "%User1%",
            this.user1 == null ? I18nHelpers.translate("player") :
            this.user1.get_id() == PLAYER_SINGLETON.get_id() ? this.type == ActivityType.AdventureBattleWin || this.type == ActivityType.AdventureBattleFail || this.type == ActivityType.AdventureBattleDraw ? I18nHelpers.translate("your") : I18nHelpers.translate("you") :
            this.user1.name);


        str = DataHelpers.replace_all(str, "%User2%",
            this.user2 == null ? I18nHelpers.translate("player") :
            this.user2.get_id() == PLAYER_SINGLETON.get_id() ? I18nHelpers.translate("you") :
            this.user2.name);

        str = DataHelpers.replace_all(str, "%Pet1%", this.getPet1Name() ? this.getPet1Name() : I18nHelpers.translate("pet"));
        str = DataHelpers.replace_all(str, "%Pet2%", this.getPet2Name() ? this.getPet2Name() : I18nHelpers.translate("pet"));
        str = DataHelpers.replace_all(str, "%Pet3%", this.getPet3Name() ? this.getPet3Name() : I18nHelpers.translate("pet"));

        if (this.value != null && this.value > 0 &&
            (
                this.type == ActivityType.PetBuy ||
                this.type == ActivityType.AuctionSiringBid
            )) {
            let c = I18nHelpers.translate("activityPrice");
            c = DataHelpers.replace_all(c, "%price%", Math.ceil(Math.round(this.value * 10000)) / 10000);
            c = DataHelpers.replace_all(c, "%currency%", this.currency.name);
            str += " " + c;

        }

        if (this.needConfirmationCount > 0 && this.blockchain == Blockchain.Ethereum) {
            let c = I18nHelpers.translate("confirmations");
            c = DataHelpers.replace_all(c, "%confirmationCount%", this.confirmationCount);
            c = DataHelpers.replace_all(c, "%needConfirmationCount%", this.needConfirmationCount);
            str += " " + c;
        }

        if (this.transactionError != null) {
            str += ` (${this.transactionError})`;
        }

        return str;
    }

    get_user1() {
        return this.user1;
    }

    getType(): ActivityType {
        return this.type;
    }
}
