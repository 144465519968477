import I18nHelpers from "@/cuties/engine/I18nHelpers";
import DataHelpers from "@/cuties/engine/DataHelpers";
import mad from "./mad";

export type OnResponseFunc = (response: any, request: RequestBase, template_data?: any) => void;


export default abstract class RequestBase<TResponse = any> {

    protected request_id: string = null;

    /**
     * any data, used to save data in response
     */
    public data: any = {};

    /**
     * user-defined additional callback function on response
     */
    private on_response_user_func: OnResponseFunc = null;

    constructor() {
        const ctor: any = (<Object> this).constructor;
        if (ctor && DataHelpers.isString(ctor.name))
            this.request_id = ctor.name;
        else
            this.request_id = "";
    }

    /**
     * Request's URL without base part (base part will be appended by RequestManager)
     * Return value can be constructed with this.build_url_friendly(url, get_parameters_json_object) or this.build_url(url, get_parameters_json_object).
     * Method must be overridden.
     */
    abstract get_url(): string;

    get_url_json_base(): string {
        return mad.request.get_server_base_url_json();
    }

    /**
     * If this function returns JSON object, POST method will be used.
     * If this function returns null, GET method will be used.
     * Method can be overridden.
     */
    collect_post_data(): object {
        return null;
    }

    /**
     * Request-specific processing of ANY response, no matter if it contains error or not, if server was even accessed or not.
     * Method for overriding.
     */
    /* global JQueryXHR */
    on_response(response: object, textStatus: string, jqXHR: JQueryXHR): void {
    }

    /**
     * Request-specific processing of successful and valid response.
     * Method for overriding.
     */
    on_success(response: object): void {
    }

    /**
     * Request-specific processing of unsuccessful or invalid response (response.error is present).
     * Method for overriding.
     */
    on_error(response: object): void {
    }

    /**
    /* Other methods are not designed for overriding (at least in most cases)
     */
    get_id() {
        return this.request_id;
    }

    /**
     * if this request on begin/end requires some visual UI indicators or other handling,
     * this method returns prefix for such pair of handlers (xxx+"begin", xxx+"end"),
     * otherwise null if no handlers are required (transparent request).
     */
    get_handler_prefix(): string {
        return "on_request_";
    }

    /**
     * Send this request to the server.
     * @param {OnResponseFunc} on_response_user_func - (optional) user-defined callback function
     */
    public send = (on_response_user_func?: OnResponseFunc): Promise<any> => new Promise((resolve) => {
        this.on_response_user_func = (response, request, template_data?) => {
            resolve(response);
            if (on_response_user_func && DataHelpers.isFunction(on_response_user_func)) {
                on_response_user_func(response, request, template_data);
            }
        };

        const url = this.get_url();
        if (!url) {
            throw new Error("Request '" + this.request_id + "': no url");
        }

        const server_base_url_json = this.get_url_json_base();

        const post_data = this.collect_post_data();
        if (DataHelpers.isObject(post_data)) {
            // post
            mad.request.ajax_post(server_base_url_json + url, post_data, this.on_response_inner, this.on_response_error_inner, this, this.get_handler_prefix());
        } else {
            // get
            mad.request.ajax_get(server_base_url_json + url, this.on_response_inner, this.on_response_error_inner, this, this.get_handler_prefix());
        }
    });

    /**
     * @deprecated - please, use axios instead, this method has incorrect response type, the
     *     structure varies depending on whether collect_post_data() was overridden or not
     * @return - ajax fake promise on get requests or normal promise on post
     */
    send_async(): Promise<{ response: TResponse; textStatus: string; error?: string; jqXHR?: any }> {

        const url = this.get_url();
        const self = this;

        if (!url) {
            return Promise.reject("Request '" + this.request_id + "': no url");
        }

        const server_base_url_json = this.get_url_json_base();

        const post_data = this.collect_post_data();
        if (DataHelpers.isObject(post_data)) {
            // post
            return mad.request.ajax_post_async(server_base_url_json + url, post_data, this.on_response_inner, this.on_response_error_inner, self, self.get_handler_prefix());
        } else {
            // get
            return mad.request.ajax_get_async(server_base_url_json + url, post_data, this.on_response_inner, this.on_response_error_inner, self, self.get_handler_prefix());
        }
    }

    is_response_valid(response: any) {
        if (!DataHelpers.isObject(response))
            response = { data: response };

        response.error = this.process_error(response);

        return response.error === null;
    }

    process_error(response: any) {
        if (!response)
            return null;

        if (!response.error)
            return null;

        const error = response.error;
        if (DataHelpers.isString(error))
            return I18nHelpers.translate(error);

        if (DataHelpers.isObject(error) && error.message)
            return I18nHelpers.translate(error.message);

        if (Array.isArray(error)) {
            for (const i in error) {
                if (DataHelpers.isObject(error[i]) && error[i].message)
                    return I18nHelpers.translate(error[i].message);
            }
        }

        DataHelpers.error("Request '" + this.request_id + "': unsupported error format", error);

        return null;
    }

    /**
     * Top-level processing of the successful response, that was returned by the server.
     * If server returned response, this response still must be checked, since it can contain request-specific "error".
     * Do not override this method.
     * @param url - relative URL of the request
     * @param data - POST parameters or null
     * @param response - JSON object
     * @param textStatus
     * @param jqXHR
     */
    on_response_inner(url, data, response, textStatus: string, jqXHR: JQueryXHR) {
        try {
            this.on_response(response, textStatus, jqXHR);
        } catch (e) {
            DataHelpers.error("Request '" + this.request_id + "'.on_response_inner: on_response: ", e);
        }

        let is_valid = true;
        try {
            // standard response handlers (this.on_response, this.on_success, this.on_error)
            is_valid = this.is_response_valid(response);
        } catch (e) {
            DataHelpers.error("Request '" + this.request_id + "'.on_response_inner: is_response_valid: ", e);
        }

        if (is_valid) {
            try {
                this.on_success(response);
            } catch (e) {
                DataHelpers.error("Request '" + this.request_id + "'.on_response_inner: on_success: ", e);
            }
        } else {
            DataHelpers.error("Request '" + this.request_id + "'.on_response_inner: " + response.error, response);

            try {
                this.on_error(response);
            } catch (e) {
                DataHelpers.error("Request '" + this.request_id + "'.on_response_inner: on_error: ", e);
            }
        }

        try {
            // additional custom response handler (from user-defined callback function)

            if (this.on_response_user_func)
                this.on_response_user_func(response, this);
        } catch (e) {
            DataHelpers.error("Request '" + this.request_id + "'.on_response_inner:on_response_user_func: error in custom response handler: ", e);
        }
    }

    /**
     * Server didn't return response, something went wrong.
     * Do not override this method.
     * @param url - relative URL of the request
     * @param data - POST parameters or null
     * @param response - JSON object
     */
    on_response_error_inner(url, data, response, textStatus: string, jqXHR: JQueryXHR) {
        try {
            this.on_response(response, textStatus, jqXHR);
        } catch (e) {
            DataHelpers.error("Request '" + this.request_id + "'.on_response_error_inner: on_response: ", e);
        }

        try {
            this.on_error(response);
        } catch (e) {
            DataHelpers.error("Request '" + this.request_id + "'.on_response_error_inner: on_error: ", e);
        }

        try {
            // additional custom response handler (from user-defined callback function)

            if (this.on_response_user_func)
                this.on_response_user_func(response, this);
        } catch (e) {
            DataHelpers.error("Request '" + this.request_id + "'.on_response_inner:on_response_user_func: error in custom response handler: ", e);
        }
    }

    protected error(msg: string): void {
        DataHelpers.error("Request '" + this.request_id + "': " + msg, this);
    }

}
