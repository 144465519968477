
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";
    import type { ConsumableApplication } from "@/app/cuties/dungeons/DungeonsApiService";
    import { anyCaseToHuman } from "cuties-client-components/src/TextUtils";
    import type { EligibilityRule } from "@/app/cuties/dungeons/DungeonsApiService";

    @Component
    export default class ItemTooltipDungeonApplication extends Vue {
        @Prop({ required: true }) dungeonApplication!: ConsumableApplication;

        translateEligibility(eligibility: EligibilityRule) {
            if (this.$te("dungeon_consumable_application_eligibility_" + eligibility)) {
                return this.$t("dungeon_consumable_application_eligibility_" + eligibility);
            } else {
                return anyCaseToHuman(eligibility);
            }
        }

        anyCaseToNuman(input: string) {
            return anyCaseToHuman(input);
        }
    }
