import { PLAYER_SINGLETON } from "@/cuties/model/player/Player";
import I18nHelpers from "@/cuties/engine/I18nHelpers";
import socket from "@/socket";
import mad from "@/cuties/engine/mad";
import type { OnMessageFunction, OnRawMessageFunction, SubscriptionFunction } from "@/subscription/WebSocketSubscription";
import WebsocketSubscription from "@/subscription/WebSocketSubscription";
import { ActivityType } from "@/cuties/model/activity/ActivityType";
import ActivityModel from "@/cuties/model/activity/ActivityModel";
import utils from "@/cuties/utils";
import type { ActivityForAdventureModel } from "@/cuties/model/activity/ActivityForAdventureModel";
import store from "@/store";
import { AppStoreMutations } from "@/store/AppStore";
import type { ActivityModelData } from "@/cuties/model/query/ActivityModelData";
import type { UserTemplate } from "@/cuties/model/player/UserTemplate";

interface ImportantActivityMessage {
    importantUnseenActivity: ActivityModelData;
    user: UserTemplate;
    activityForAdventure: ActivityForAdventureModel;
}

const subscribe: SubscriptionFunction = function (handler: OnRawMessageFunction) {
    return socket.subscribeUserOnly("/important_activity", handler);
};

const onMessage: OnMessageFunction<ImportantActivityMessage> = function (message: ImportantActivityMessage) {
    if (message.activityForAdventure) {
        showAdventureNotification(message.activityForAdventure);
    } else if (message.importantUnseenActivity) {
        const activity: ActivityModel = new ActivityModel(message.importantUnseenActivity);
        const descriptionText = activity.get_description();

        switch (activity.getType()) {
            case ActivityType.TokenErc20Gain: {
                if (message.importantUnseenActivity.contextKind !== "Gift") {
                    console.log(message.importantUnseenActivity);
                    mad.popup.show("raidboss_popup", {
                        victory: message.importantUnseenActivity.contextKind === "RaidVictory",
                        boss: !message.importantUnseenActivity.boss ? undefined : {
                            name: message.importantUnseenActivity.boss.name,
                            image: message.importantUnseenActivity.boss.image,
                        },
                        tokenErc20: message.importantUnseenActivity.tokenErc20 ?? 0,
                    });
                }
                break;
            }
            // probably should just show Activity.vue component
            case ActivityType.PetBuy:
            case ActivityType.AuctionSiringBid: {
                if (activity.get_user1().get_id() != PLAYER_SINGLETON.get_id()) {
                    utils.show_notification(descriptionText);
                }
                break;
            }
            default: {
                utils.show_notification(descriptionText);
            }
        }
    }

    if (message.user) {
        PLAYER_SINGLETON.update(message.user);
    }
};

export function showAdventureNotification(activity_model: ActivityForAdventureModel): void {
    let res_text = "";

    const pet_name = I18nHelpers.translate("your_cutie");

    // user1 is the one that triggered the fight, while
    // user2 is the one that was waiting for matchmaking
    const isUser1 = activity_model.pet1.ownerId == PLAYER_SINGLETON.get_id();
    const isWin = activity_model.type === "AdventureBattleWin";
    const pet1_win = isUser1 === isWin;

    if (isWin) {
        res_text = pet_name + " " + I18nHelpers.translate("ping_pet_adventure_win");
    } else {
        res_text = pet_name + " " + I18nHelpers.translate("ping_pet_adventure_fail");
    }

    let participants = [
        { isWinner: pet1_win, model: activity_model.pet1 },
        { isWinner: !pet1_win, model: activity_model.pet2 },
    ];
    if (!isUser1) {
        // always show player's own cutie on the left, even when he is user2
        participants = participants.reverse();
    }

    if (!localStorage.getItem("disableBattleNotifications")) {
        store.commit(AppStoreMutations.ADD_NOTIFICATION, {
            text: res_text,
            participants: participants,
            item: activity_model.item,
            ticketCount: activity_model.ticketCount,
            type: "notificationBattle",
        });
    }
}

export default new WebsocketSubscription({
    subscribe,
    onMessage,
});
