
    import { Component, Emit, Prop, Vue } from "vue-property-decorator";
    import type { TranslateResult } from "vue-i18n";

    @Component({
        components: {},
    })
    export default class WalletButton extends Vue {
        @Prop() buttonClass: string;
        @Prop() buttonIconClass: string;
        @Prop() buttonText: string | TranslateResult;
        @Prop() isNewButton: boolean;

        getButtonClass(): string {
            return this.buttonClass ? "wallet-button--" + this.buttonClass : "wallet-button--default";
        }

        @Emit("click")
        handleClick() {}
    }
