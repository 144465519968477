import DataHelpers from "@/cuties/engine/DataHelpers";
import Request from "../Request";
import utils from "@/cuties/utils";

export default class RequestAdminDeletePet extends Request {

    private uid_pet_id: string;

    constructor(uid_pet_id: string) {
        super();

        this.uid_pet_id = uid_pet_id;
    }

    get_url(): string {
        return "/delete_pet?petId=" + this.uid_pet_id;
    }

    on_success(response: any): void {
        super.on_success(response);
        utils.show_notification(response);
    }

    on_error(response: any): void {
        try {
            const status = response.errorDetails.jqXHR.status;
            if (status == 401) {
                utils.propose_auth();
            } else{
                utils.show_notification("error status = " + status);
            }
        } catch (e) {
            DataHelpers.error("Request.on_error", e);
            utils.show_notification(response.error);
        }
    }

    get_handler_prefix(): string {
        return null;
    }
}
