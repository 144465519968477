import { PAGE_MANAGER_SINGLETON } from "@/cuties/engine/PageManager";
import utils from "@/cuties/utils";
import buildData from "@/../public/static/zzz_BUILD_UUID.json";
import { makeHttp } from "@/http";
import ConfigApiService from "@/app/cuties/engine/ConfigApiService";
import store from "@/store";
import { AppStoreMutations } from "@/store/AppStore";
import Cookies from "js-cookie";

const http = makeHttp({ baseUrl: "/" });

export default class PageInit {
    /**
     * add this to Date.now() to get approximate clock value on the server: system
     * time on user's device is often few seconds off relative to world time
     */
    private static serverClockOffsetMs = 0;

    static init_page(): void {
        this.loadWallpaperStyles().catch(error => {
            utils.show_notification("Failed to load wallpaper styles - " + error);
        });

        // wait few seconds before making measurements, to lead other requests finish executing
        setTimeout(async () => {
            this.estimateServerClockOffsetMs().then(result => this.serverClockOffsetMs = result);
        }, 7200);

        setInterval(PageInit.checkForAppUpdate, 300 * 1000);

        setInterval(() => {
            store.commit(AppStoreMutations.SET_EVERY_SECOND_TICK_TIME_MS, Date.now());
        }, 1000);

        setInterval(() => {
            store.commit(AppStoreMutations.SET_200_MS_TICK, Date.now());
        }, 200);

        store.commit(AppStoreMutations.SET_IS_MOBILE, window.innerWidth < 800);
        window.addEventListener("resize", event => {
            store.commit(AppStoreMutations.SET_IS_MOBILE, window.innerWidth < 800);
        });
    }

    public static async checkForAppUpdate() {
        if (!PAGE_MANAGER_SINGLETON.get_current_page_id() ||
            PAGE_MANAGER_SINGLETON.get_current_page_id() === "admin_batch_lottery" ||
            PAGE_MANAGER_SINGLETON.get_current_page_id() === "interactive-fights"
        ) {
            return; // don't reload page on focus sensitive pages
        }
        let response;
        try {
            response = await http.get("/static/zzz_BUILD_UUID.json", {
                headers: { "Cache-Control": "no-cache" },
            });
        } catch (exc) {
            utils.show_notification("Failed to check for updates: " + exc);
            return;
        }
        const latestBuild: typeof buildData = response.data;
        // the time check is required to avoid situations
        // when some clusters still return the old value
        if (buildData.buildUuid !== latestBuild.buildUuid &&
            new Date(buildData.buildTime).getTime() <= new Date(latestBuild.buildTime).getTime()
        ) {
            const msg = "An update was rolled out! Reloading window to update " +
                "your application version from " + buildData.buildUuid + " to " + latestBuild.buildUuid + ". " +
                "You may want to reset site data cache by doing a hard-refresh.";
            utils.show_notification(msg);
            setTimeout(() => location.reload(), 5000);
        }
    }

    private static async loadWallpaperStyles() {
        const [backgrounds, patterns] = await Promise.all([
            ConfigApiService.getWallpaperBackgrounds(),
            ConfigApiService.getWallpaperPatterns(),
        ]);
        const style = document.createElement("style") as HTMLStyleElement;
        style.setAttribute("data-type", "Wallpapers from server injected using CSSOM");
        document.head.appendChild(style);
        const styleSheet = style.sheet!;

        for (const background of Object.values(backgrounds)) {
            const selector = ".bg_block." + background.id;
            const ruleIndex = styleSheet.insertRule(selector + " {}", styleSheet.cssRules.length);
            const rule = styleSheet.cssRules.item(ruleIndex)! as CSSStyleRule;
            if (background.color) {
                rule.style.backgroundColor = background.color;
            }
            if (background.gradients.length > 0) {
                rule.style.backgroundImage = background.gradients.join(", ");
            }
        }

        for (const pattern of Object.values(patterns)) {
            const selector = ".pattern_block." + pattern.id;
            const ruleIndex = styleSheet.insertRule(selector + " {}", styleSheet.cssRules.length);
            const rule = styleSheet.cssRules.item(ruleIndex)! as CSSStyleRule;
            rule.style.backgroundImage = "url(" + pattern.imagePath + ")";
        }
    }

    private static async probeServerClockOffsetMs(): Promise<number> {
        const requestSentMs = Date.now();
        const requestReceivedTime = await ConfigApiService.getTimeNow();
        const responseReceivedMs = Date.now();
        const roundTripLatencyMs = responseReceivedMs - requestSentMs;
        // assuming that reply delivery time is same as request delivery time
        const expectedServerMs = requestSentMs + roundTripLatencyMs / 2;
        const realServerMs = new Date(requestReceivedTime).getTime();
        return realServerMs - expectedServerMs;
    }

    private static async estimateServerClockOffsetMs(): Promise<number> {
        const probes: number[] = [];
        // probe 3 times to eliminate error caused by random network delays
        for (let i = 0; i < 7; ++i) {
            const probe = await this.probeServerClockOffsetMs();
            probes.push(probe);
            await new Promise(resolve => setTimeout(resolve, 900));
        }
        probes.sort((a,b) => a - b);
        const median = probes[Math.floor(probes.length / 2)];
        console.debug("Server clock offset probes:", probes, median);
        return median;
    }

    /**
     * returns approximate ms time on server which often differs
     * few seconds from local time due to clock desync
     */
    public static getDateNowServer() {
        return Date.now() + this.serverClockOffsetMs;
    }

    public static getSeverTimeDiff() {
        return this.serverClockOffsetMs;
    }
    public static setLanguage(lang: string) {
        Cookies.set("lang", lang, { expires: 30 });
        location.href.replace("lang=", "");
        location.reload();
    }
}
