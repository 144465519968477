import { getStateless, makeHttp } from "@/http";
import type { CutieAssetsResponse } from "cuties-client-components/types/api/Cutie";

const http = makeHttp({ baseUrl: "/rest" });

/** a mapping to some SvgController.java API routes */
export default class SvgApiService {
    public static getCutieAssets(params: { cutieDbId: number }): Promise<CutieAssetsResponse> {
        return getStateless("/public/asset/list/by/cutie", { params }).then(rs => rs.data);
    }

    public static getAssetSvg(assetId: string, params: { animated: boolean }): Promise<string> {
        return getStateless(`/public/asset/${assetId}.svg`, { params }).then(rs => rs.data);
    }

    public static getAnimatedCutieFancySvg(fancyName: string): Promise<string> {
        return getStateless(`/public/cutie/fancy/${fancyName}.svg`).then(rs => rs.data);
    }

    public static getStaticCutieFancySvg(fancyName: string): Promise<string> {
        return getStateless(`/public/cutie/fancy/static/${fancyName}.svg`).then(rs => rs.data);
    }

    public static reportLongCutieFromAssetsRender(params: ReportLongCutieFromAssetsRenderRequest) {
        return http.post("/public/asset/report/long/cutie/render", params).then(rs => rs.data);
    }
}

interface ReportLongCutieFromAssetsRenderRequest {
    cutieId?: number;
    assetsLoadTime: number;
    compileTime: number;
    finalSvgLoadTime: number;
    assetsCacheError?: string;
}
