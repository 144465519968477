
    import { defineComponent } from "vue";
    import { AppStoreSingleton } from "@/store/AppStore";

    export default defineComponent({
        computed: {
            tick200ms: () => AppStoreSingleton.state.tick200ms,
            dotsInMessage() {
                return Math.floor(this.tick200ms / 400) % 4;
            },
            message() {
                const baseMessage = this.$t("please_wait_loading_data");
                const dots = ".".repeat(this.dotsInMessage);
                return (baseMessage + "").replace(/\.+$/, "") + dots;
            },
        },
    });
