
    import Vue from "vue";
    import Component from "vue-class-component";
    import type { InteractiveFighterTurnEffectView } from "@/app/cuties/interactive/InteractiveFightsApiService";
    import { Prop } from "vue-property-decorator";

    @Component
    export default class InteractiveFighterTurnEffect extends Vue {
        @Prop({ required: true }) effect!: InteractiveFighterTurnEffectView;

        signed(number: number) {
            return number > 0 ? "+" + number : "" + number;
        }
    }
