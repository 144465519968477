import { PLAYER_SINGLETON } from "@/cuties/model/player/Player";
import http, { getStateless, makeHttp } from "@/http";
import type { ResponseInventory } from "@/cuties/model/inventory/ResponseInventory";
import type { ResponseItemUse } from "@/cuties/model/inventory/ResponseItemUse";
import type { RequestInventory } from "@/cuties/model/inventory/RequestInventory";
import type { ResponseInventoryCutieUsable } from "@/cuties/model/inventory/ResponseInventoryCutieUsable";
import type {
    RequestInventoryCutieUsable,
    RequestInventorySingleCutieUsable,
} from "@/cuties/model/inventory/RequestInventorySingleCutieUsable";
import I18nHelpers from "@/cuties/engine/I18nHelpers";
import IntroductionSystem from "@/cuties/player/introduction/IntroductionSystem";
import type ItemStackModel from "../model/item/ItemStackModel";
import type { AnyItemSupplySource } from "@/cuties/items/ItemSupplySource";
import type { PetModelData } from "../model/pet/PetModelData";
import ItemsFullDataIndex from "@/cuties/items/ItemsFullDataIndex";
import ItemApiService from "@/app/cuties/items/ItemApiService";
import utils from "../utils";

export interface ItemGiftParams {
    itemKind: string;
    itemLevel: number;
    toUserId: string;
    quantity: number;
}

/** a mapping to some ItemController.java routes */
export default class ItemService {
    private static whenFullDataIndex: Promise<ItemsFullDataIndex> | null = null;

    public static async getInventoryItems(payload: RequestInventory): Promise<ResponseInventory> {
        return http.post<ResponseInventory>("/inventory", payload).then((response) => response.data);
    }

    public static async getInventoryCutieUsableItems(payload: RequestInventorySingleCutieUsable): Promise<ResponseInventoryCutieUsable> {
        return http.post<ResponseInventoryCutieUsable>("/inventory/cutie/usable", payload).then((response) => response.data);
    }

    public static async getInventoryGroupCutieUsableItems(payload: RequestInventoryCutieUsable): Promise<ResponseInventoryCutieUsable> {
        return makeHttp({ baseUrl: "/rest" })
            .post("/inventory/group/cutie/usable", payload)
            .then((response) => response.data);
    }

    public static async itemUse(petId: number, itemKind: string, level: number, itemId: number | null = null): Promise<ResponseItemUse> {
        return http
            .post<ResponseItemUse>("/item_use", {
                itemKind,
                level,
                petId,
                itemId,
            })
            .then((response) => {
                IntroductionSystem.completeStep("EQUIP_AN_ITEM");
                if (itemKind === "ElixirAdventure") {
                    IntroductionSystem.completeStep("USE_ADVENTURE_POTION");
                }
                return response.data;
            });
    }

    public static async itemUseOnUser(itemKind: string, level: number): Promise<ResponseItemUse> {
        return http.post<ResponseItemUse>("/item/use/user", {
            itemKind: itemKind,
            level: level
        }).then(response => {
            return response.data;
        });
    }

    public static async itemForUse(itemKind: string, level: number): Promise<ResponseItemForUse> {
        return http
            .post<ResponseItemForUse>("/item/use", {
                itemKind: itemKind,
                level: level,
            })
            .then((response) => {
                return response.data;
            });
    }

    /** fetch item instance model, as well as a list of cuties that have this item equipped */
    public static async itemByKind( itemKind: string, level: number = 0, size: number = 16, page: number = 0): Promise<ResponseItemByKind> {
        return http.post<ResponseItemByKind>("/itemsByKind", {
            itemKind: itemKind,
            level: level,
            page: page,
            size: size
        }).then(response => response.data);
    }

    public static async hasQuickItemRemover(): Promise<boolean> {
        return http.get<UserHaveQuickRemoversResponse>("/item/quickremover", { baseURL: "/rest" })
            .then(response => response.data.have);
    }

    public static showItemUseErrorNotification(message: string) {
        utils.show_notification(I18nHelpers.translate(message), { remove_link: true });
    }

    public static myMarket(payload): Promise<MyMarketResponse> {
        payload["player_id"] = PLAYER_SINGLETON.get_id();
        return http.post("/items_market", payload).then((response) => response.data);
    }

    /** note, returns personal and sellable item sources as separate entries */
    public static async getSupplySourcesMapping(): Promise<Record<string, AnyItemSupplySource[]>> {
        return getStateless("/public/item/supply/sources/mapping").then((response) => response.data);
    }

    public static async openLoobBox(itemKind: string): Promise<OpenLooboxResponse> {
        return http.post("/item/lootcrate/open", { itemKind: itemKind }).then((response) => response.data);
    }

    public static async itemGift(params: ItemGiftParams): Promise<any> {
        return http.get("/item_gift", { params }).then((response) => response.data);
    }

    public static async getFullDataIndex(): Promise<ItemsFullDataIndex> {
        if (!this.whenFullDataIndex) {
            this.whenFullDataIndex = Promise.all([
                ItemService.getSupplySourcesMapping(),
                ItemApiService.itemDefinitionMap(),
            ]).then(([sources, defs]) => new ItemsFullDataIndex(sources, defs));
        }
        return this.whenFullDataIndex;
    }
}

export interface OpenLooboxResponse {
    usableItemCount: number;
    usableItemId: number;
    rewards: LootboxOpenRewardModel[];
}

/** @see RewardModel.java */
export interface LootboxOpenRewardModel {
    type: "Group" | "Item" | "Tickets" | "Pet" | "CuteCoin" | "Energy";
    item: string;
    /** the sellable name of the item if any */
    itemBaseName: string;
    amount: number;
}
export interface MyMarketResponse {
    items: MarketItem[];
    count: number;
    sortBy: string;
    sortOrder: string;
}

export interface MarketItem {
    petItemId: number;
    // itemKind: number,
    itemName: string;
    status: string;
    level: number;
    playerId: number;
    auctionId: number;
    auctionType: string;
    priceStart: string;
    priceEnd: string;
    timeStart: number;
    timeEnd: number;
    groupKind: number;
    rarityKind: string;
    blockchain: string;
    quantity: number;
    pricePerUnit: number;
    item: ItemStackModel;
    hasSign: boolean;
    featured: boolean;
    partOfSet: boolean;
}

export interface UserHaveQuickRemoversResponse {
    have: boolean;
}

export interface ResponseItemByKind {
    isGiftable: boolean;
    isOpenable: boolean;
    isSellable: boolean;
    isUsableOnPet: boolean;
    isUsableOnUser: boolean;
    isUsableOnUserNow: boolean;
    isViewableInMarket: boolean;
    item: ItemStackModel;
    pets: PetModelData[];
    petsCount: number;
}

export interface ResponseItemForUse {
    id: number; // item stack id
    item: ItemStackModel;
}
