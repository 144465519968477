
    import { Vue } from "vue-property-decorator";
    import Component from "vue-class-component";
    import { toBigNumber } from "@/common/utils/toBigNumber";
    import formErrorMessages from "@/common/formErrorMessages";
    import { isNumberValid } from "@/common/formValidator";
    import type { RuleItem } from "async-validator/dist-types/interface";

    @Component
    export default class BaseForm extends Vue {
        get translatedErrors() {
            const translatedErrors = { ...formErrorMessages };
            Object.entries(formErrorMessages).forEach(([key, value]) => {
                translatedErrors[key] = this.$t(value) + "";
            });
            return translatedErrors;
        }

        isNumberValidator(...args: Parameters<RuleItem["validator"]>): void {
            const [rule, value, callback] = args;
            if (!isNumberValid(value)) {
                return callback(this.translatedErrors.INVALID_NUMBER);
            }

            return callback();
        };

        zeroValidator(...args: Parameters<RuleItem["validator"]>): void {
            let [rule, value, callback] = args;
            value = value.trim();
            if (toBigNumber(value).isZero()) {
                return callback(this.translatedErrors.ZERO);
            }

            return callback();
        };
    }
