import { getStateless, makeHttp } from "@/http";
import type ItemInstanceModel from "@/cuties/model/item/ItemInstanceModel";
import type RangeModel from "@/cuties/model/misc/RangeModel";
import { PLAYER_SINGLETON } from "@/cuties/model/player/Player";
import type {
    CutieFancyUnit,
    CutieGeneralUnit, PossessionIdentity,
    Token1155Unit,
    TokenNftUnit,
    UndefinedUnit, UnitIdentity,
    CuteCoinUnit, ItemUnit, PawCoinUnit,
    AnyOfUnit, ChallengeQuestGlobalProgressUnit,
    ClearedDungeonStageUnit, DungeonWorldCountableUnit,
    NotUnit,
    OffchainCurrencyUnit, SubscriptionMinutesUnit,
} from "cuties-client-components/types/general/UnitIdentity";

const http = makeHttp({ baseUrl: "/rest" });

/** a mapping to some CraftingController.java API routes */
export default class CraftingApiService {
    public static async craftTimedTableList(location: "FORGE"): Promise<CraftTimedTableListResponse> {
        return http.get(`/craft/timed/table/list/${location}`).then((response) => response.data);
    }

    /**
     * you need to call it before each craftTimedRecipeList() - this request handles
     * the cleanup of recipes that were wiped, it's an alternative to a scheduler
     *
     * if you load page without /reset/pending/counters, you will get
     * into a state that backend does not expect and will yield errors
     */
    public static async craftTimedResetPendingCounters() {
        return http.post("/craft/timed/reset/pending/counters").then((response) => response.data);
    }

    public static async craftTimedRecipeList(craftingTableId: string): Promise<CraftTimedRecipeListResponse> {
        if (PLAYER_SINGLETON.is_logined()) {
            await this.craftTimedResetPendingCounters();
        }
        return http.get(`/craft/timed/recipe/list/${craftingTableId}`)
            .then((response) => response.data);
    }

    public static async craftTimedProcessList(): Promise<CraftTimedProcessListResponse> {
        return http.get("/craft/timed/process/list").then((response) => response.data);
    }

    public static async craftTimedStart(params: CraftTimedStartRequest): Promise<CraftTimedStartResponse> {
        return http.post("/craft/timed/start", params).then((response) => response.data);
    }

    public static async craftTimedEffectiveIngredients(params: CraftTimedStartRequest): Promise<CraftTimedEffectiveIngredientsResponse> {
        return http.get("/craft/timed/effective/ingredients", { params }).then((response) => response.data);
    }

    public static async craftTimedCancel(params: CraftTimedProcessWithConsumeOptionRequest) {
        return http.post("/craft/timed/cancel", params).then((response) => response.data);
    }

    public static async craftTimedClaim(params: CraftTimedProcessRequest): Promise<CraftTimedClaimResponse> {
        return http.post("/craft/timed/claim", params).then((response) => response.data);
    }

    public static async craftTimedFinishForce(params: CraftTimedProcessWithConsumeOptionRequest) {
        return http.post("/craft/timed/force/finish", params).then((response) => response.data);
    }

    public static async quantityLeftByIdentity(params: UnitIdentity): Promise<number> {
        return http
            .post("/quantity/left/by/identity", { ...params })
            .then((response) => response.data.quantityLeft);
    }

    public static async quantityLeftByIdentities<T extends UnitIdentity>(params: T[]): Promise<(PossessionIdentityBase & T)[]> {
        if (params.length === 0) {
            return [];
        }
        return http
            .post("/quantity/left/by/identities", params)
            .then((response) => response.data);
    }

    public static async guessableCrafting(): Promise<GuessableCraftResponse> {
        return getStateless("/public/craft/guessable/categories/list/active").then((response) => response.data);
    }
}

export interface GuessableCraftResponse {
    categories: GuessableCategory[];
}

export interface GuessableCategory {
    id: string;
}

export type ItemKind = string;

export interface PossessionIdentityBase {
    type: string;
    count: string;
}

export type PossessionIdentityItem = PossessionIdentityBase & ItemUnit;

export type PossessionIdentityPawCoin = PossessionIdentityBase & PawCoinUnit;

export type PossessionIdentityCuteCoin = PossessionIdentityBase & CuteCoinUnit;

export type PossessionIdentityToken1155 = PossessionIdentityBase & Token1155Unit;

export type PossessionIdentityTokenNft = PossessionIdentityBase & TokenNftUnit;

export type PossessionIdentityCutieFancy = PossessionIdentityBase & CutieFancyUnit;

export type PossessionIdentityCutieGeneral = PossessionIdentityBase & CutieGeneralUnit;

export type PossessionIdentityOffchainCurrency = PossessionIdentityBase & OffchainCurrencyUnit;

export type  PossessionIdentityUndefined = PossessionIdentityBase & UndefinedUnit;

export type PossessionIdentityAnyOf = PossessionIdentityBase & AnyOfUnit;

export type PossessionIdentityNot = PossessionIdentityBase & NotUnit;

export type ClearedDungeonStage = PossessionIdentityBase & ClearedDungeonStageUnit;

export type PossessionIdentityChallengeQuestGlobalProgress = PossessionIdentityBase & ChallengeQuestGlobalProgressUnit;

export type SubscriptionMinutes = PossessionIdentityBase & SubscriptionMinutesUnit;

export type DungeonWorldCountable = PossessionIdentityBase & DungeonWorldCountableUnit;

export interface AnyOfRequirement {
    allOfRequirements: Array<PossessionIdentity>;
}

export type VisibilityKind = "VISIBLE" | "MASKED" | "HIDDEN";

export interface BasicRewardOption {
    id?: string | null;
    identity: PossessionIdentity;
    weight: number;
    /** see TimedCraftingRecipeConfig.java */
    initialVisibility: VisibilityKind;
}

export interface RangedRewardOption extends BasicRewardOption {
    min: number;
    max: number;
}

/** returned if reward is configured to be masked until revealed */
export interface MaskedRewardOption {
    /** ID excluded from masked options for now, as current configuration exposes contents in the ID string */
    id: null;
    identity: null;
    weight: number;
    initialVisibility: "MASKED";
}

export type AnyRewardOption = RangedRewardOption | MaskedRewardOption;

export interface AllOfReward {
    anyOfRewards: AnyRewardOption[];
}

export interface SlotModel {
    index: number;
    speed: number;
    isBusy: boolean;
    requirementsMet: boolean;
    anyOfRequirements: Array<AnyOfRequirement>;
}

export interface TimedRecipeModel {
    id: string;
    groupId: string | null;
    slotRequired: boolean;
    isMasked: boolean;
    craftedCount: number;
    timeEnd: number;
    craftLimitPerPlayer: number | null;
    requirements: Array<PossessionIdentity>;
    consumes: Array<PossessionIdentity>;
    /** will be empty when isMasked=true */
    allOfRewards: Array<AllOfReward>;
    durationMs: number;
    rarity: string;
}

export interface CategoryModel {
    id: string;
    recipes: Array<TimedRecipeModel>;
}

export interface CraftingTableModel {
    id: string;
    location: string;
    maxQuantityPerSlot: number;
    slots: Array<SlotModel>;
}

export interface AnyOfConsumePerUnit {
    allOfConsumes: Array<PossessionIdentity>;
    /** example: '1h 30m' */
    timeUnit: string;
    perSeconds: number;
}

export interface AnyOfConsumeFixed {
    allOfConsumes: Array<PossessionIdentity>;
}

interface CraftTimedStartRequest {
    recipeId: string;
    quantity: number;
    allowEnchantedIngredients?: boolean;
}

interface CraftTimedProcessWithConsumeOptionRequest {
    processId: number;
    consumeOptionIndex: number;
}

interface CraftTimedProcessRequest {
    processId: number;
}

export interface CraftTimedRecipeListResponse {
    slots: Array<SlotModel>;
    categories: Array<CategoryModel>;
}

interface CraftTimedStartResponse {
    processId: number;
}

export interface CraftTimedClaimResponse {
    calculatedRewardOptions: Array<BasicRewardOption>;
}

export interface CraftTimedTableListResponse {
    craftingTables: Array<CraftingTableModel>;
}

export interface TimedCraftingProcessModel {
    processId: number;
    craftingTableId: string;
    recipeId: string;
    slotIndex: number;
    timeStart: string; // '2020-07-22T21:49:22.214Z'
    timeEnd: string;
    quantity: number;
    allOfRewards: Array<AllOfReward>;
    anyOfCancelConsumesTotal: Array<AnyOfConsumeFixed>;
    anyOfFinishNowConsumesPerTimeUnit: Array<AnyOfConsumePerUnit>;
    anyOfFinishNowConsumesTotal: Array<AnyOfConsumeFixed>;
    consumes: Array<{
        instance: ItemInstanceModel;
        quantity: RangeModel;
    }>;
}

interface CraftTimedProcessListResponse {
    processes: Array<TimedCraftingProcessModel>;
}

interface CraftTimedEffectiveIngredientsResponse {
    effectiveIngredients: PossessionIdentity[];
}
