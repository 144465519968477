

/**
 * an attempt to fight the fact that Set is not
 * reactive in Vue without copying it every time
 *
 * mutating operations return a new instance of the class,
 * but the Set under the hood stays the same object
 */
export default class ReactiveSet<T> implements Iterable<T> {
    private readonly source: Set<T>;
    // discard mutated instance to make sure there won't be side effects in case of misuse
    private discarded = false;

    constructor(source: Set<T> = new Set()) {
        this.source = source;
    }

    add(item: T): ReactiveSet<T> {
        this.checkDiscarded();
        this.source.add(item);
        const result = new ReactiveSet(this.source);
        this.discarded = true;
        return result;
    }

    delete(item: T): ReactiveSet<T> {
        this.checkDiscarded();
        this.source.delete(item);
        const result = new ReactiveSet(this.source);
        // discard this instance to make sure there won't be side effects in case of misuse
        this.discarded = true;
        return result;
    }

    get size(): number {
        this.checkDiscarded();
        return this.source.size;
    }

    has(item: T): boolean {
        this.checkDiscarded();
        return this.source.has(item);
    }

    [Symbol.iterator]() {
        this.checkDiscarded();
        return this.source[Symbol.iterator]();
    }

    private checkDiscarded() {
        if (this.discarded) {
            throw new Error("Attempted to reuse a discarded ReactiveSet, you most likely forgot to reassign after add()/delete(): " + JSON.stringify([...this.source]));
        }
    }
}
