import type { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import type { RootState } from "@/store/RootState";
import type { DailyQuestDto } from "@/cuties/quest/QuestService";
import QuestService from "@/cuties/quest/QuestService";
import type { IntroStep } from "@/cuties/player/introduction/IntroductionSystem";
import QuestsApiService from "@/cuties/quest/QuestsApiService";

export interface QuestsState {
    quests: DailyQuestDto[];
    questsNotification: boolean;
    questTimerHandler: number;
    questUserData: DailyQuestDto;
    activeIntroStep: IntroStep | null;
    dailyQuestsPopup: boolean;
}

export const state: QuestsState = {
    quests: [],
    questsNotification: false,
    questTimerHandler: 0,
    questUserData: null,
    activeIntroStep: null,
    dailyQuestsPopup: false,
};

export enum QuestStoreGetters {
    GET_DAILY_QUESTS_POPUP = "GET_DAILY_QUESTS_POPUP",
}

export const getters: GetterTree<QuestsState, RootState> = {
    getQuests: state => state.quests,
    getQuestUserData: state => state.questUserData,
    getNotification: state => state.questsNotification,
    getQuestTimerHandler: state => state.questTimerHandler,
    getActiveIntroStep: state => state.activeIntroStep,
    [QuestStoreGetters.GET_DAILY_QUESTS_POPUP]: (state) => state.dailyQuestsPopup,
};

export enum QuestStoreMutations {
    OPEN_DAILY_QUESTS_POPUP = "OPEN_DAILY_QUESTS_POPUP",
    CLOSE_DAILY_QUESTS_POPUP = "CLOSE_DAILY_QUESTS_POPUP",
}

export const mutations: MutationTree<QuestsState> = {
    setQuests: (state, payload) => {
        state.quests = payload;
    },
    setQuestUserData: (state, payload) => state.questUserData = payload,
    setQuestsNotification: (state, payload) => state.questsNotification = payload,
    setQuestTimerHandler: (state, payload) => state.questTimerHandler = payload,
    setActiveIntroStep: (state, payload) => state.activeIntroStep = payload,
    [QuestStoreMutations.OPEN_DAILY_QUESTS_POPUP]: (state) => (state.dailyQuestsPopup = true),
    [QuestStoreMutations.CLOSE_DAILY_QUESTS_POPUP]: (state) => (state.dailyQuestsPopup = false),
};

export enum QuestStoreActions {
    OPEN_DAILY_QUESTS_POPUP = "OPEN_DAILY_QUESTS_POPUP",
    CLOSE_DAILY_QUESTS_POPUP = "CLOSE_DAILY_QUESTS_POPUP",
}

export const actions: ActionTree<QuestsState, RootState> = {
    loadQuests: async ({ commit }) => {
        if (state.questTimerHandler) {
            clearTimeout(state.questTimerHandler);
            commit("setQuestTimerHandler", 0);
        }

        const response = await QuestsApiService.getQuests();
        commit("setQuests", response.quests);
        commit("setQuestUserData", response.questUserData);

        const timerHandler = QuestService.setTimer(response.questUserData, response.quests);
        if (timerHandler) {
            commit("setQuestTimerHandler", timerHandler);
        }

        if (QuestService.checkForNew(response.quests)) {
            commit("setQuestsNotification", true);
        }

        return true;
    },
    setActiveIntroStep: async ({ commit }, step) => {
        commit("setActiveIntroStep", step);
        return true;
    },
    [QuestStoreActions.OPEN_DAILY_QUESTS_POPUP]: async ({ commit }) => {
        commit(QuestStoreMutations.OPEN_DAILY_QUESTS_POPUP);
        return true;
    },

    [QuestStoreActions.CLOSE_DAILY_QUESTS_POPUP]: async ({ commit }) => {
        commit(QuestStoreMutations.CLOSE_DAILY_QUESTS_POPUP);
        return true;
    },
};

class QuestsStore implements Module<QuestsState, RootState> {
    public namespaced = false;
    public state: QuestsState = state;
    public getters: GetterTree<QuestsState, RootState> = getters;
    public mutations: MutationTree<QuestsState> = mutations;
    public actions: ActionTree<QuestsState, RootState> = actions;
}

// return singleton
export const QuestsStoreSingleton = new QuestsStore();
