import type { DailyQuestDto, QuestsDetails } from "@/cuties/quest/QuestService";
import { makeHttp } from "@/http";

const http = makeHttp({ baseUrl: "/rest" });

export default class QuestsApiService {
    public static async getQuests(): Promise<QuestsDetails> {
        return http.get("/quests")
            .then(response => response.data);
    }

    public static async questStart(id: number): Promise<DailyQuestDto> {

        return http.post("/quests/" + id.toString() + "/start")
            .then(response => response.data);
    }

    public static async questFinish(id: number): Promise<DailyQuestDto> {
        return http.post("/quests/" + id.toString() + "/finish")
            .then(response => response.data);
    }

    public static async questReset(id: number): Promise<DailyQuestDto> {
        return http.post("/quests/" + id.toString() + "/reset")
            .then(response => response.data);
    }

    /**
     * Sets quests as seen + quests notification has seen.
     */
    public static async setSeen(): Promise<boolean> {
        return http.get("/quests/seen")
            .then(response => {
                return true; // server do not returns any data
            });
    }
}
