import { PLAYER_SINGLETON } from "@/cuties/model/player/Player";
import { getStateless, makeHttp } from "@/http";
import type { LotTemplateData, PresaleBidParams } from "@/cuties/presale/PresaleLotModel";
import PresaleLotModel from "@/cuties/presale/PresaleLotModel";
import { Blockchain } from "@/cuties/model/pet/BlockchainId";
import utils from "../utils";
import LoginManager from "../LoginManager";
import type { PresaleCutiesResponse } from "@/app/cuties/presale/PresaleCutie";
import { ConfigInstance } from "@/cuties/engine/Config";
import CutiesApiFaucet from "@/app/cuties/blockchain/CutiesApiFaucet";
import PopupManager from "@/cuties/engine/PopupManager";

const http = makeHttp({ baseUrl: "/rest/1/" });

export default class PresaleService {
    public static async getPresaleLotList() {
        return http.get("/presale/lot/all").then((response) => {
            return PresaleLotModel.init_lot_models(response.data.lots);
        });
    }

    public static async purchaseWeb3(lot: PresaleBidParams): Promise<string> {
        const service = await LoginManager.unlock(lot.blockchain);
        return lot.lotKind === "NATIVE"
            ? service.bid_native(lot.lotId, lot.price)
            : service.presale_bid(lot.lotId, lot.rate, lot.signExpireTime, lot.v, lot.r, lot.s, lot.price);
    }

    public static async purchaseTron(lot: PresaleBidParams): Promise<string> {
        const service = await LoginManager.unlock(lot.blockchain);
        return lot.lotKind == "NATIVE"
            ? service.bid_native(lot.lotId, lot.price)
            : service.presale_bid(lot.lotId, lot.rate, lot.signExpireTime, lot.v, lot.r, lot.s, lot.price);
    }

    public static async bidPresaleLot(lot: PresaleLotModel | LotTemplateData, blockchain: Blockchain = Blockchain.Ethereum): Promise<void> {
        const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        const data = {
            mobile: mobile,
        };

        const callback = (hash) => {
            if (hash == "0x0000000000000000000000000000000000000000000000000000000000000000") {
                new PopupManager().hide();
                new PopupManager().show("presale_thanks_purchase_popup", data);
            } else if (hash) {
                const data = {};
                if (hash) data["hash"] = hash;
                new PopupManager().show("presale_thanks_purchase_popup", data);
            }
        };

        let hash: string;

        // use sign for blockchain
        switch (lot.blockchain) {
            case Blockchain.Matic:
            case Blockchain.Heco:
            case Blockchain.Emerald:
            case Blockchain.Csc:
            case Blockchain.Okc:
                hash = await PresaleService.purchaseWeb3(lot);
                break;
            case Blockchain.Ethereum: {
                if (lot.lotKind === "NATIVE" && lot.currency.name !== "ETH") {
                    hash = await this.ethereumTokenPurchase(lot, blockchain);
                } else {
                    hash = await PresaleService.purchaseWeb3(lot);
                }
                break;
            }
            case Blockchain.Tron: {
                hash = await PresaleService.purchaseTron(lot);
                break;
            }
            case Blockchain.Eos: {
                if (PLAYER_SINGLETON.is_logined()) {
                    const wallet = await LoginManager.obtainUnlockedProvider(lot.blockchain);
                    hash = await wallet.presale_bid(lot.lotId, lot.rate, lot.signExpireTime, lot.v, lot.r, lot.s, lot.price);
                } else {
                    utils.show_notification("No EOS provider");
                    console.error("Select unlock, old code was - popup select_eos_provider");
                    return;
                }
                break;
            }
            case Blockchain.Neo: {
                throw new Error("Unsupported purchase on NEO blockchain");
            }
            default:
                alert("Presale Service Support Not Implemented for " + lot.blockchain);
                return;
        }
        await callback(hash);
    }

    public static async getPresaleConfig(): Promise<ResponsePresaleConfig> {
        return getStateless("/public/config/presale").then((response) => {
            return response.data.config;
        });
    }

    private static async ethereumTokenPurchase(lot: PresaleLotModel | LotTemplateData, blockchain: Blockchain = Blockchain.Ethereum) {
        const tokenInfo = ConfigInstance.getErc20TokenInfo("ETHEREUM", lot.currency.name);
        if (!tokenInfo) {
            throw new Error("Missing token info for currency " + lot.currency.name);
        }
        const api = await CutiesApiFaucet.getEthereum();
        // todo: @nikita cover simple token standard with allowance check and real contract call
        switch (tokenInfo.standard) {
            case "ApproveAndCall":
                return api.presaleTokenApproveAndCall(lot.lotId, lot.price, lot.currency.name, blockchain);
            default:
                throw new Error("Unsupported token standard");
        }
    }

    public static async presaleCuties(type: string): Promise<PresaleCutiesResponse> {
        return http.get("/sale/" + type).then((response) => {
            return response.data;
        });
    }
}
export interface ResponsePresaleConfig {
    presalePageTimerEndTime: number;
}
