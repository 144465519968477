import BigNumber from "bignumber.js";
import type { Blockchain } from "@/cuties/model/pet/BlockchainId";
import type { EthereumConfig } from "@/cuties/blockchain/ethereum/EthereumContractAddresses";
import type BlockchainReader from "../BlockchainReader";
import type Web3 from "web3";
import Web3ContractRegistry from "@/cuties/blockchain/web3/Web3ContractRegistry";
import CutiesApiFaucet from "@/app/cuties/blockchain/CutiesApiFaucet";

/** a generic wallet implementation class for ETH-based blockchains */
export default class Web3Reader implements BlockchainReader {
    private readonly whenWeb3: Promise<Web3>;
    private registry: Web3ContractRegistry | null = null;

    public constructor(private readonly blockchain: Blockchain, private readonly config: EthereumConfig) {
        this.whenWeb3 = Web3Reader.getWeb3(config);
    }

    /**
     * Creates Web3 instance with particular blockchain.
     * Blockchain is identified by provided configuration node.
     * Used anywhere we need a http web3 instance.
     */
    public static async getWeb3(config: EthereumConfig): Promise<Web3> {
        const Web3 = await CutiesApiFaucet.getWeb3();
        const httpProvider = new Web3.providers.HttpProvider(config.nodeUrl);
        return new Web3(httpProvider);
    }

    /**
     * @return - human-readable amount of balance (in ether)
     */
    public async getBalance(walletAddress: string): Promise<BigNumber> {
        const web3 = await this.whenWeb3;
        const balance = await web3.eth.getBalance(walletAddress);
        return new BigNumber(web3.utils.fromWei(balance));
    }

    /**
     * Get breeding fee not in blockchain base units
     * @return - amount of breeding fee to pay for breeding
     */
    public async getBreedingFee(matronId: number, sireId: number): Promise<BigNumber> {
        const web3 = await this.whenWeb3;

        if (!this.registry) {
            this.registry = new Web3ContractRegistry(web3, this.blockchain);
        }

        const method = await this.registry.getMethodObj({
            contractName: "core",
            methodName: "getBreedingFee",
            methodArguments: [matronId, sireId],
        });
        const amount = await method.call();

        return new BigNumber(web3.utils.fromWei(amount, "ether"));
    }

    /**
     * Get ERC20 token user balance in decimal scale
     * @param walletAddress blockchain address
     */
    public async getTokenBalance(walletAddress: string, tokenAddress: string): Promise<string> {
        // token may not exist on blockchain thus no address
        if (!tokenAddress) {
            return "0";
        }

        const web3 = await this.whenWeb3;
        if (!this.registry) {
            this.registry = new Web3ContractRegistry(web3, this.blockchain);
        }

        const method = await this.registry.getMethodObj({
            contract: "erc20",
            address: tokenAddress,
            methodName: "balanceOf",
            methodArguments: [walletAddress],
        });
        const amount = await method.call();

        return web3.utils.fromWei(amount, "ether");
    }
}
