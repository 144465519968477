import type { ErrorType } from "@/cuties/VueUtils";

export default class TranslatedClientError extends Error {
    translationKey: ErrorType;
    tplVars: string[];

    /**
     * @param translationKey - see BCU_lang_error
     * https://docs.google.com/spreadsheets/d/1rU3co4b2Ua20D9zJb0KnnP5_l7enXEmqvPLLrb4mY3s/edit#gid=0
     */
    constructor(translationKey: string, tplVars: string[] = []) {
        super([translationKey, ...tplVars].join(" "));
        this.translationKey = translationKey;
        this.tplVars = tplVars;
    }
}
