import I18nHelpers from "@/cuties/engine/I18nHelpers";
import LocationPath from "./engine/LocationPath";
import type { LocaleMessages } from "vue-i18n";
import i18n from "@/i18n";
import { PAGE_MANAGER_SINGLETON } from "@/cuties/engine/PageManager";
import { TEMPLATE_MANAGER_SINGLETON } from "@/cuties/engine/TemplateManager";
import Cookies from "js-cookie";
import store from "@/store";
import { AppStoreMutations } from "@/store/AppStore";
import { BigNumber } from "bignumber.js";
import type { Route } from "vue-router/types/router";
import { getMetaInfoSync } from "cuties-client-components/src/MetaInfoUtils";

const PROMO_LINE_KEY = "promo_line_disable_season7";

/**
 * utils contains (project-specific) helper functions, statically available.
 */
export default class utils {

    static clean_referrer(): void {
        const prev_referrer:string = Cookies.get("referrer");
        if (!prev_referrer)
            return;

        const current_location = LocationPath.create(window.location);
        const current_base = current_location.get_base();
        if (!current_base)
            return;

        const referrers:string[] = prev_referrer.split(",");
        const result:string[] = [];

        for (let i = 0; i < referrers.length; ++i) {
            if (referrers[i].indexOf(current_base) == 0)
                continue;
            if (result.length >= 10)
                break;
            result.push(referrers[i]);
        }

        const resultStr = result.join(",");

        // referrer is "URL1,URL2,URL3,..."
        Cookies.set("referrer", resultStr, { expires: 14 });
    }

    static init_referrer(): void {
        let referrer:string = document.referrer;

        if (referrer) {
            referrer = referrer.trim();
        }

        const current_location = LocationPath.create(window.location);
        const current_base = current_location.get_base();

        if (referrer && referrer.indexOf(current_base) != 0) {
            const prev_referrer: string | undefined = Cookies.get("referrer");
            if (prev_referrer) {
                //mad.warning("utils.init_referrer: rewriting referrer. previous: " + prev_referrer + ". new: " + referrer);
                const referrers:string[] = prev_referrer.split(",");
                if (referrers.indexOf(referrer) == -1 && referrers.length < 10) {
                    referrers.push(referrer);
                }

                referrer = referrers.join(",");
            }

            // referrer is "URL1,URL2,URL3,..."
            Cookies.set("referrer", referrer, { expires: 14 });
        }

        utils.clean_referrer();
    }

    static get_referrer(): string {
        let referrer = Cookies.get("referrer");
        if (!referrer)
            referrer = document.referrer;
        return referrer;
    }

    /**
     * init inviter id (ethereum wallet)
     * @param user_inviter_id - user wallet id
     */
    static init_inviter_id(user_inviter_id:string): void {
        if (user_inviter_id) {
            const prev_user_inviter_id = utils.get_inviter_id();
            if (prev_user_inviter_id && prev_user_inviter_id != prev_user_inviter_id) {
                //mad.warning("utils.init_inviter: rewriting inviter. previous: " + prev_user_inviter_id + ". new: " + user_inviter_id);
            }

            Cookies.set("inviter", user_inviter_id, { expires: 7 });
        }
    }

    static get_inviter_id(): string {
        return Cookies.get("inviter");
    }

    static homePage(): void {
        window.location.href = "/";
    }

    static propose_auth(): void {
        this.homePage();
    }

    static addAdminNotification(text:string, remove_link = true) {
        utils.show_notification("" + text, { remove_link });
    }

    static show_notification(text:string | LocaleMessages, params: { is_error_message?: boolean; remove_link?: boolean } = {}) {
        store.commit(AppStoreMutations.ADD_NOTIFICATION, {
            text: text,
            removeLink: params.remove_link ?? true,
            isError: params.is_error_message,
            type: "notificationText",
        });
    }

    static page_error(title_key:string, msg_key:string): boolean {
        if (title_key == msg_key)
            title_key = "error";

        const title = I18nHelpers.translate(title_key);
        const msg = I18nHelpers.translate(msg_key);

        return utils.page_error_plain(title, msg);
    }

    static page_error_plain(title:string, msg:string): boolean {
        PAGE_MANAGER_SINGLETON.set_body_class("error");

        TEMPLATE_MANAGER_SINGLETON.show("error", {
            title: title,
            msg: msg,
            admin_info: null
        },"#page", true).then(function () {
            $(".loading_ani").fadeOut(300);
        });

        return false;
    }

    static page_error_no_config(): void {
        PAGE_MANAGER_SINGLETON.set_body_class("error");
        const errorPanel = document.createElement("div");
        errorPanel.classList.add("server-config-not-available-error-panel");
        errorPanel.textContent = i18n.te("ERR_SERVER_CONFIG_NOT_AVAILABLE")
            ? i18n.t("ERR_SERVER_CONFIG_NOT_AVAILABLE") + ""
            : "Dear visitor, the server is being restarted now. Please wait a few minutes, then try refreshing the page.";
        document.getElementById("page")!.appendChild(errorPanel);

        $("nav.header-navigation a").remove();
        $("#footer_copyright_").remove();
    }

    static timestamp_to_date(timestamp : number, seconds = false, breakLine = false): string {
        let b = ", ";
        if(breakLine) {
            b = "<br/>";
        }
        const a = new Date(timestamp * 1000);
        const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        const year = a.getFullYear();
        const month = months[a.getMonth()];
        const date = a.getDate();
        const hour = a.getHours();
        const min = this.zeroPad(a.getMinutes(), 2);
        const sec = this.zeroPad(a.getSeconds(), 2);
        //var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
        let time = date + " " + month + ", " + year + b + hour + ":" + min;
        if(seconds) {
            time += ":" + sec;
        }
        return time;
    }

    static zeroPad(num, places): string {
        const zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join("0") + num;
    }

    static clamp_interval(num:number, min:number, max:number): number {
        if(num < min) return min;
        if(num > max) return max;
        return num;
    }

    static percentage(cur: number, min: number, max: number) {
        return (cur - min) / (max - min);
    }

    static getTypedArray<T>(data: any): T[] {
        const length: number = data.length;
        const array : T[] = [];

        for (let i = 0; i < length; i++) {
            const d: any = data[i];
            const obj: T =  <T>{};
            //todo
            obj.constructor(data);
            array.push(obj);
        }

        return array;
    }

    static parse_location(href:string) {
        const l = document.createElement("a");
        l.href = href;
        return l;
    };

    static isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    static isWinter(): boolean {
        return [11, 0, 1].includes(new Date().getMonth());
    }

    static seasonThemeClass(): string {
        if (this.isWinter()) {
            return "winter-theme";
        } else {
            return "";
        }
    }

    static getPromoValue(): string {
        return localStorage.getItem(PROMO_LINE_KEY) ?? "";
    }

    static setPromoValue(value: string): void {
        localStorage.setItem(PROMO_LINE_KEY, value);
    }

    static setPageTitle(route: Route) {
        let path: string, lang: string;
        if (route.params.lang) {
            path = route.path.replace("/" + route.params.lang, "");
            lang = route.params.lang;
        } else {
            path = route.path;
            lang = i18n.locale ?? "en";
        }
        let search = route.fullPath.split("?")[1] ?? "";
        search = search.split("#")[0];
        const segments = path.split("/").slice(1);
        const tr = (key: string) => i18n.t(key) + "";
        const te = (key: string) => i18n.te(key);
        const metaInfo = getMetaInfoSync(segments, search, lang, tr, te);
        if ("title" in metaInfo && metaInfo.title) {
            document.title = metaInfo.title;
        }
    }

    static setPageFavicon(faviconPath: string) {
        document.querySelector("link[type=\"image/x-icon\"]")?.setAttribute("href", faviconPath);
    }

    static formatMeaningfulDecimalValue(price: string, meaningfulDecimals = 4): string {
        let decimals = 0;
        const pos = price.toString().replace(".", "").toString().search(/[^0.]/);
        const pointPosition = price.toString().search(/[.]/);
        if (pointPosition !== -1 && pointPosition < pos + meaningfulDecimals) {
            decimals = pos - pointPosition + meaningfulDecimals;
        }

        return new BigNumber(price).toFixed(decimals);
    }
}
