import type { ContractInvoke, NeoProvider } from "@/cuties/blockchain/neo/NeoProvider";
import type { NeoConfig } from "@/cuties/blockchain/neo/NeoConfig";
import type o3dapi from "o3-dapi-core";
import O3dapiNeo3 from "o3-dapi-neo3";
import type { PersonalMessageSignature, TransactionHash, WalletProviderKind } from "@/components/LoginManager/TypeDefs";
import { BlockchainNetworkError } from "@/cuties/blockchain/BlockchainService";
import { ArgumentDataType } from "o3-dapi-neo3/src/constants";
import { throws } from "@/app/cuties/utils/utils";
import CutiesApiFaucet from "@/app/cuties/blockchain/CutiesApiFaucet";
import type { sc } from "@cityofzion/neon-core";

const INIT_TIMEOUT = 3000; // in millis

declare global {
    interface Window {
        NEOLine: { Init: new () => any };
        NEOLineN3: { Init: new () => any };
    }
}

enum ExtendedType {
    ANY = "Any",
}

type ExtendedArgumentType = ArgumentDataType | ExtendedType;

let whenO3DapiCore: Promise<typeof o3dapi> | null = null;

function getO3DapiCore() {
    if (!whenO3DapiCore) {
        whenO3DapiCore = import(/* webpackChunkName: "o3-dapi-core-chunk" */ "o3-dapi-core")
            .then((o3DapiCore) => o3DapiCore.default);
    }
    return whenO3DapiCore;
}

async function getContractParamMapping(): Promise<Partial<Record<sc.ContractParamType, ExtendedArgumentType>>> {
    const { sc, u } = await CutiesApiFaucet.getNeonCore();
    return {
        [sc.ContractParamType.String]: ArgumentDataType.STRING,
        [sc.ContractParamType.Boolean]: ArgumentDataType.BOOLEAN,
        [sc.ContractParamType.Hash160]: ArgumentDataType.HASH160,
        [sc.ContractParamType.Hash256]: ArgumentDataType.HASH256,
        [sc.ContractParamType.Integer]: ArgumentDataType.INTEGER,
        [sc.ContractParamType.ByteArray]: ArgumentDataType.BYTEARRAY,
        [sc.ContractParamType.Array]: ArgumentDataType.ARRAY,
        [sc.ContractParamType.Any]: ExtendedType.ANY,
    };
}

/**
 * Provider for Neo Wallet - O3 wallet and NeoLine
 * They are almost compatible with each other, hence some unsafe access are present
 * see https://neoline.io/dapi/N3.html
 */
export default class NeoO3Provider implements NeoProvider {
    private constructor(private readonly config: NeoConfig, private readonly dapi: O3dapiNeo3, private readonly kind: WalletProviderKind) {}

    public static async create(config: NeoConfig, kind: WalletProviderKind): Promise<NeoO3Provider> {
        let dapi = null;

        if (kind === "o3_wallet") {
            dapi = await NeoO3Provider.setupO3();
        } else if (kind === "neoline") {
            dapi = await NeoO3Provider.setupNeoLine();
        } else {
            throw new Error("Unsupported NEO wallet");
        }

        return new NeoO3Provider(config, dapi, kind);
    }

    public async getWalletAddress(): Promise<string> {
        const account = await this.dapi.getAccount();
        return account.address;
    }

    /**
     * Sign terms of usage with O3 compatible wallet.
     * O3 wallet adds salt for signature for security reasons, but our API expects only string, that's why
     * our signature is combined with salt and signature divided by non-hex char 'x'.
     * NeoLine wallet cannot sign our terms of usage in plain text therefore hexed value is used.
     */
    public async signPersonalMessage(termsText: string): Promise<PersonalMessageSignature> {
        if (this.kind === "o3_wallet") {
            const sign = await this.dapi.signMessage({ message: termsText });
            return sign.salt + "x" + sign.data;
        } else if (this.kind === "neoline") {
            const { u } = await CutiesApiFaucet.getNeonCore();
            const hexed = u.str2hexstring(termsText);
            const sign = await this.dapi.signMessage({ message: hexed });
            return sign.salt + "xy" + sign.data;
        } else {
            throw new Error("Unsupported NEO wallet!");
        }
    }

    public async checkReadyForTransaction(): Promise<void> {
        const { defaultNetwork } = await this.dapi.getNetworks();

        if (defaultNetwork !== this.config.network) {
            throw new BlockchainNetworkError(defaultNetwork, this.config.network);
        }
    }

    public async invoke(params: ContractInvoke): Promise<TransactionHash> {
        const CONTRACT_PARAM_MAPPING = await getContractParamMapping();
        const args = params.args.map((arg) => {
            return {
                type: CONTRACT_PARAM_MAPPING[arg.type] ?? throws(`Unsupported O3 wallet type [arg=${arg}]`),
                value: arg.toJson().value,
            };
        });

        const response = await this.dapi.invoke({
            scriptHash: params.scriptHash,
            operation: params.operation,
            args: args,
            signers: params.signers,
        } as any);
        return response.txid;
    }

    private static async setupO3(): Promise<O3dapiNeo3> {
        // Init the NEO plugin into the core dapi provider package
        const o3dapi = await getO3DapiCore();
        o3dapi.initPlugins([O3dapiNeo3]);

        // Subscribe to O3 ready event, but add timeout for initialization
        return new Promise<O3dapiNeo3>((resolve, reject) => {
            o3dapi.NEO3.addEventListener("READY", () => resolve(o3dapi.NEO3));

            setTimeout(() => {
                o3dapi.NEO3.removeEventListener("READY");
                reject(new Error("Cannot connect to O3 wallet"));
            }, INIT_TIMEOUT);
        });
    }

    private static async setupNeoLine(): Promise<O3dapiNeo3> {
        // Subscribe to NeoLine ready event, but add timeout for initialization
        const awaitDapi = new Promise<O3dapiNeo3>((resolve, reject) => {
            if (window.NEOLine) {
                resolve(new window.NEOLine.Init());
            }

            window.addEventListener("NEOLine.NEO.EVENT.READY", () => {
                resolve(new window.NEOLine.Init());
            });

            setTimeout(() => {
                window.removeEventListener("NEOLine.NEO.EVENT.READY", () => {});
                reject(new Error("Cannot connect to NeoLine wallet"));
            }, INIT_TIMEOUT);
        });

        const awaitNeo3dapi = new Promise<O3dapiNeo3>((resolve, reject) => {
            if (window.NEOLineN3) {
                resolve(new window.NEOLineN3.Init());
            }

            window.addEventListener("NEOLine.N3.EVENT.READY", () => {
                resolve(new window.NEOLineN3.Init());
            });

            setTimeout(() => {
                window.removeEventListener("NEOLine.N3.EVENT.READY", () => {});
                reject(new Error("Cannot connect to NeoLine wallet"));
            }, INIT_TIMEOUT);
        });

        const [dapi, neo3dapi] = await Promise.all([awaitDapi, awaitNeo3dapi]);

        return {
            getAccount: neo3dapi.getAccount,
            invoke: neo3dapi.invoke,
            signMessage: neo3dapi.signMessage,
            getNetworks: dapi.getNetworks,
        } as O3dapiNeo3;
    }
}
