import I18nHelpers from "@/cuties/engine/I18nHelpers";
import DataHelpers from "@/cuties/engine/DataHelpers";
import mad from "../engine/mad";
import AuctionUpdater from "../AuctionUpdater";
import RequestAdminSetCanGiftItems from "../request/admin/RequestAdminSetCanGiftItems";
import RequestEmailSubscribe from "@/cuties/request/RequestEmailSubscribe";
import $ from "jquery";
import RequestEmailSubscribePlayer from "@/cuties/request/RequestEmailSubscribePlayer";
import LoginManager from "../LoginManager";
import { EosProviderType } from "@/cuties/blockchain/eos/EosWallet";
import { BigNumber } from "bignumber.js";
import { HANDLER_MANAGER_SINGLETON } from "@/cuties/engine/HandlerManager";
import PopupManager from "@/cuties/engine/PopupManager";
import router from "@/router";

{
    // configure special handlers

    // additional preparations for all templates
    // every template will receive these data
    HANDLER_MANAGER_SINGLETON.register("on_template_prepare", function(data) {
        if (Array.isArray(data))
            return data;

        if (!DataHelpers.isObject(data))
            data = {};

        return data;
    });

    // additional preparations for all templates
    HANDLER_MANAGER_SINGLETON.register("on_template_show", function(selector_write_to, template_id) {
        const obj = $(this);
        AuctionUpdater.init(obj);
    });
}

    HANDLER_MANAGER_SINGLETON.register("eos_scatter_callback", function() {
        const callback = mad.storage.get("scatter_callback");
        console.log($("#eos_form"));
        console.log("call eos scatter");
        console.log(callback);
        callback(EosProviderType.WOMBAT_WALLET);
    });

    HANDLER_MANAGER_SINGLETON.register("eos_lynx_callback", function() {
        const callback = mad.storage.get("lynx_callback");
        console.log("call eos lynx");
        console.log(callback);
        callback(EosProviderType.LYNX_WALLET);
    });

    // auth_meta_mask
    HANDLER_MANAGER_SINGLETON.register("auth_meta_mask", function() {
        const login_manager = new LoginManager(null,null, null, true);
        login_manager.run();
    });

    HANDLER_MANAGER_SINGLETON.register("popup_close", function () {
        new PopupManager().hide();
    });

    HANDLER_MANAGER_SINGLETON.register("accordion_head", function() {
        const obj = $(this);
        $("." + obj.attr("id") + "-content").slideToggle("fast");
        if(obj.children("span").html() == "+") {
            obj.children("span").html("-");
        } else {
            obj.children("span").html("+");
        }
    });

    // hide popup
    HANDLER_MANAGER_SINGLETON.register("popup_hide", function() {
        // $(this).html('');
        new PopupManager().hide();
        if ($("body").hasClass("bodyOverflow")) {
            $("body, html").removeClass("bodyOverflow");
        }

        return false;
    });

    // hide popup, when click on background
    HANDLER_MANAGER_SINGLETON.register("popup_hide_bg", function() {
        if ($(".popup-panel:hover").length != 0)
            return;

        new PopupManager().hide();
    });

    HANDLER_MANAGER_SINGLETON.register("popup_gift_item", function() {
        const data = {
            itemLevel: $(this).attr("data-itemLevel"),
            itemKind: $(this).attr("data-itemKind")
        };

        new PopupManager().show("_popup_gift_item", data, true, function() {
            $("#giftWallet").focus();
        });
    });

HANDLER_MANAGER_SINGLETON.register("donate", function() {
    const value = new BigNumber($("#donateAmount").val() as string);
    if(!value.isNaN()) {
        // we may possibly want to bring donate functionality back with support
        // for all blockchains and with some symbolic reward to motivate the donor
        alert("You are the first person ever to click this button. Thanks, we appreciate the thought.");
        HANDLER_MANAGER_SINGLETON.run("popup_hide");
    }
});

HANDLER_MANAGER_SINGLETON.register("donate_popup", function() {
    new PopupManager().show("_donate_popup", [], true);
});

HANDLER_MANAGER_SINGLETON.register("createEthPopupCopy", function() {
    const obj = $(this);
    const $temp = $("<input>");
    $("body").append($temp);
    const $val = obj.closest(".wallet-input-wrap").find("textarea").val();
    console.log($val);
    $temp.val($val).select();
    document.execCommand("copy");
    $temp.remove();
    obj.html(I18nHelpers.translate("copied"));
});

HANDLER_MANAGER_SINGLETON.register("change_language", function() {
    const obj = $(this);
    const lang = obj.data("lang");
    return;

    // PLAYER_SINGLETON.set_language(lang);
    // const newLoc = location.href.replace("lang=", "");

    // location.reload();
});


HANDLER_MANAGER_SINGLETON.register("toggleLang", function() {
    if ($(".dropdown").length) {
        $(".dropdown-menu").toggleClass("show");
    }
});

HANDLER_MANAGER_SINGLETON.register("admin_enable_gifting", function() {
    const id = DataHelpers.toInt($(this).data("user_id"));
    const val = DataHelpers.toBoolean($(this).data("cangift"));

    const request = new RequestAdminSetCanGiftItems(id, !val);
    request.send(function() {
        router.push({ path: location.pathname });
    });
});

HANDLER_MANAGER_SINGLETON.register("show_hide_pwd", function() {
    const type = $(this).prev().attr("type");
    if( type == "text" ) {
        $(this).prev().attr("type", "password");
        $(this).attr("src", "/static/wallet-popup/baseline-visibility_off-24px.png");
    } else {
        $(this).prev().attr("type", "text");
        $(this).attr("src", "/static/wallet-popup/baseline-visibility-24px.png");
    }
    console.log($(this));
});

HANDLER_MANAGER_SINGLETON.register("landing_subscribe", function() {
    const request = new RequestEmailSubscribe($("#subscribe_email").val() as string);
    $(".landingSubscribeBox").addClass("active");

    $(".storeSubsBtnWrap").empty();
    $(".storeSubsBtnWrap").html("<p>" + I18nHelpers.translate("presale_email_sent") + "</p>");

    request.set_handler_prefix(null);
    const self = $(this);
    request.send(function(response) {
        if (!response.eroor) {
            setTimeout(function () {
                if(self.data("action") != null) {
                    new PopupManager().show("presale_thanks_popup", {}, false);
                } else {
                    $(".landingSubscribeBoxBtn").val("Success");
                    $(".landingSubscribeDesc").html(I18nHelpers.translate("landing_subscribed")).parents(".landingSubscribe").find(".landingSubscribeBox").addClass("done").find(".landingSubscribeBoxBtn").val("Success");

                }

                },500);

        }
    });
});

HANDLER_MANAGER_SINGLETON.register("landing_subscribe_logged", function() {
    if($(this).data("disabled") != null) return;
    $(this).data("disabled", "true");

    const self = $(this);

    const request = new RequestEmailSubscribePlayer();
    request.send(function() {
        if(self.data("action") != null) {
            new PopupManager().show("presale_thanks_popup", {}, false);
        } else {
            self.val(I18nHelpers.translate("presale_sub_thanks"));
            self.attr("disabled", "true");
        }

    });
});

HANDLER_MANAGER_SINGLETON.register("kote_subscribe", function() {
    const request = new RequestEmailSubscribe($("#subscribe_email").val() as string);
    $(".landingSubscribeBox").addClass("active");

    request.send(function(response) {
        if (!response.eroor) {
            setTimeout(function () {
               $(".landingSubscribeBoxBtn").val(I18nHelpers.translate("mascot_subscribed"));
            },500);

        }
    });
});

HANDLER_MANAGER_SINGLETON.register("landsButton", function() {
    location.href = "/presalestore", "_self";
});

HANDLER_MANAGER_SINGLETON.register("landing_mob", function () {
    $(".landingMobHeader").toggleClass("fa-times");
    $(".landingMobOpen").toggleClass("landingMobClose");
    $(".landingHeader").toggleClass("landingHeaderMob");

    if ($(this).attr("data-noroute")) {
        const href = $(this).attr("href");
        location.href = "/presalestore" + href, "_self";
    }
});
HANDLER_MANAGER_SINGLETON.register("presale_thanks_popup_open", function() {
    new PopupManager().show("presale_thanks_popup", function() {
    });
});

HANDLER_MANAGER_SINGLETON.register("buy_presale_lot", function () {
    throw new Error("Fiat payments are not available at this time");
});
