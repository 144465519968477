import socket from "@/socket";
import type { OnMessageFunction, OnRawMessageFunction, SubscriptionFunction } from "@/subscription/WebSocketSubscription";
import WebsocketSubscription from "@/subscription/WebSocketSubscription";
import type DynamicConfiguration from "@/cuties/engine/DynamicConfiguration";
import { ConfigInstance } from "@/cuties/engine/Config";

const subscribe: SubscriptionFunction = function (handler: OnRawMessageFunction) {
    return socket.subscribe("/config", handler);
};

const onMessage: OnMessageFunction<DynamicConfiguration> = function (message: DynamicConfiguration) {
    const config = ConfigInstance;

    // initial configuration on subscription
    if (config.dynamic == null) {
        config.dynamic = message;
        return;
    }

    // update field only on changes (incoming message field not null)
    config.dynamic.usdPrices = message.usdPrices ?? config.dynamic.usdPrices;
    config.dynamic.usdFiatPrices = message.usdFiatPrices ?? config.dynamic.usdFiatPrices;
    config.dynamic.neoGasPrice = message.neoGasPrice ?? config.dynamic.neoGasPrice;

    if (message.ethereums) {
        Object.keys(message.ethereums).forEach((blockchain) => {
            config.dynamic.ethereums[blockchain] = message.ethereums[blockchain];
        });
    }
};

export default new WebsocketSubscription({
    subscribe,
    onMessage,
});
