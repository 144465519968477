
    import Vue from "vue";
    import Component from "vue-class-component";
    import type { InteractiveFightsStateResponse, Action, InteractiveFightTurnView } from "@/app/cuties/interactive/InteractiveFightsApiService";
    import InteractiveFightsApiService from "@/app/cuties/interactive/InteractiveFightsApiService";
    import { Emit, Prop, Watch } from "vue-property-decorator";
    import InteractiveFighterTurnEffect from "./InteractiveFighterTurnEffect.vue";
    import VueUtils from "@/cuties/VueUtils";

    const ACTION_OPTIONS: { action: Action; icon: string }[] = [
        { action: "ATTACK", icon: "🗡️" },
        { action: "REFLECT", icon: "🛡️" },
        { action: "SCHEME", icon: "📜" },
    ];

    type Selection = {
        action: Action;
    };

    @Component({
        components: { InteractiveFighterTurnEffect },
    })
    export default class InteractiveFightActionsPanel extends Vue {
        @Prop({ required: true }) fighterId!: string;
        @Prop({ required: true }) fightState!: InteractiveFightsStateResponse;
        @Prop({ required: true }) turnsHistory!: InteractiveFightTurnView[];

        selection: Selection | null = null;

        get actionOptions() {
            return ACTION_OPTIONS;
        }

        async chooseNextAction(action: Action) {
            this.selection = { action };
            try {
                await InteractiveFightsApiService
                    .chooseNextAction({ fighterId: this.fighterId, action: action });
                this.actionChosen(action);
            } catch (error) {
                this.selection = null;
                VueUtils.handleError(error);
            }
        }

        get selectedAction(): Action | undefined | null {
            if (this.selection) {
                return this.selection.action;
            } else {
                return this.fightState.fighters.find(f => f.isRequester && f.nextAction)?.nextAction;
            }
        }

        @Watch("fightState")
        onFightStateChange() {
            this.selection = null;
        }

        @Emit("actionChosen")
        actionChosen(action: Action) {
            return action;
        }
    }
