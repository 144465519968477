import type { Filter } from "@/Filter";
import type { ItemGroupKind } from "@/cuties/model/item/ItemGroupKind";

export type ItemSetFilterKind = "PART_OF_SET" | "NOT_PART_OF_SET";

/** describes possible values, comes from server */
export interface InventoryItemsFilters {
    kind: Filter<string>;
    group: Filter<keyof typeof ItemGroupKind>;
    itemSetFilter: Filter<ItemSetFilterKind>;
    rarity: Filter<string>;
    equip: Filter<string>;
}

/** describes values selected by user */
export class InventoryItemsFilter {

    public kind: string | null;
    public equip: string[];
    public group: string[];
    public itemSetFilter: string[];
    public rarity: string[];
    constructor(data: Partial<InventoryItemsFilter>) {
        this.kind = data.kind ?? null;
        this.equip = this.defaultArray(data.equip);
        this.group = this.defaultArray(data.group);
        this.itemSetFilter = this.defaultArray(data.itemSetFilter);
        this.rarity = this.defaultArray(data.rarity);
    }

    private defaultArray(value: any[] | null | undefined): string[] {
        return value == null ? [] : value as string[];
    }
}

export type InventoryItemsFilterNullable = {
    [k in keyof InventoryItemsFilter]: InventoryItemsFilter[k] | null
};
