
    import Vue from "vue";
    import type { CutieFilterState, PetFilter, PetFilterOptions } from "@/app/cuties/list/CutieListApiService";
    import { Emit, Prop } from "vue-property-decorator";
    import Component from "vue-class-component";
    import VueUtils, { translateBlockchain } from "@/cuties/VueUtils";
    import type { ElementKind } from "@/cuties/model/pet/PetModelData";
    import CutieAttributesAutocomplete from "@/app/components/cutie/list/CutieAttributesAutocomplete.vue";
    import type { PetKind, PetNobility, PetRarityKind } from "@/cuties/model/pet/PetModel";
    import type { Blockchain } from "@/cuties/model/pet/BlockchainId";
    import PossibleCutieAttributesPopup from "@/app/components/cutie/PossibleCutieAttributesPopup.vue";
    import IntroductionSystem from "@/cuties/player/introduction/IntroductionSystem";
    import CutieRaceSelect from "@/app/components/cutie/list/CutieRaceSelect.vue";
    import type { AttributeModelData } from "cuties-client-components/types/api/Cutie";

    /** don't fret, it's just a list of keys whose value is a string */
    type OptionFilterName = {
        [k in keyof PetFilter]: CutieFilterState<string | number>[k] extends string ? k : never
    }[keyof PetFilter];

    function flattenNull<T>(value: T | null | undefined): T[] {
        return value === null || value === undefined ? [] : [value];
    }

    /**
     * this is a newer implementation intended to become a part of PetFiltersBlock.vue
     * this component one is more modular, limited to only the state of PetFilter, without sorting and stuff
     */
    @Component({
        components: { CutieRaceSelect, CutieAttributesAutocomplete, PossibleCutieAttributesPopup }
    })
    export default class CutieListFiltersCriteria extends Vue {
        @Prop({ required: true }) filterOptions!: PetFilterOptions;
        @Prop({ default: {} }) filterState!: CutieFilterState<string | number>;
        @Prop({ default: false }) hideBlockchainFilter!: boolean;
        @Prop({ default: false }) disableAttributeFilter?: boolean;

        private showAttributesPopup = false;

        @Emit("update:filtersState")
        updateFiltersState(newState: CutieFilterState<string | number>) {
            return newState;
        }

        translateBlockchain(blockchain: Blockchain): string {
            return translateBlockchain(blockchain);
        }

        private applyFilter<TName extends keyof PetFilter>(name: TName, newValue: PetFilter[TName] | null) {
            this.updateFiltersState({ ...this.filterState, [name]: newValue });
        }

        private applyOptionFilter<TName extends OptionFilterName>(name: TName, val: PetFilter[TName][]) {
            const newValue = VueUtils.toggleFilterString(val);
            this.applyFilter(name, newValue);
        }

        /**
         * apparently, el-input-number buttons do not work
         * if you supply it a numeric string as a value
         */
        private getElementValue(element: ElementKind): number | undefined {
            const strValue = (this.filterState.element || {})[element] ?? undefined;
            return strValue === undefined || strValue === "" ? undefined : +strValue;
        }

        private applyFilterByElement(element: ElementKind, newValue: number): void {
            this.updateFiltersState({
                ...this.filterState,
                element: {
                    ...(this.filterState.element ?? {}),
                    [element]: newValue,
                }
            });
        }

        private removeElementFilter(element: ElementKind): void {
            const newState = { ...this.filterState };
            if (newState.element && typeof newState.element[element] === "number") {
                newState.element = {
                    ...newState.element,
                    [element]: undefined,
                };
                this.updateFiltersState(newState);
            }
        }

        private selectAttribute(attribute: AttributeModelData) {
            const oldList = this.filterState.attributes ?? [];
            const newList = [...oldList, attribute.srcName];
            this.updateFiltersState({
                ...this.filterState,
                attributes: [...new Set(newList)],
            });
            this.showAttributesPopup = false;
        }

        get blockchains() { return flattenNull(this.filterState.blockchain); }
        set blockchains(value: Blockchain[]) { this.applyOptionFilter("blockchain", value); }

        get kinds() { return flattenNull(this.filterState.kind); }
        set kinds(value: (keyof typeof PetKind)[]) { this.applyOptionFilter("kind", value); }

        get nobilities() { return flattenNull(this.filterState.nobility); }
        set nobilities(value: (keyof typeof PetNobility)[]) { this.applyOptionFilter("nobility", value); }

        get rarities() { return flattenNull(this.filterState.rarity); }
        set rarities(value: (keyof typeof PetRarityKind)[]) { this.applyOptionFilter("rarity", value); }

        get levelBounds(): [number, number] {
            const { min, max } = this.filterState.level ?? {};
            return [
                +(min ?? this.filterOptions.level.min),
                +(max ?? this.filterOptions.level.max),
            ];
        }

        set levelBounds(value: [number, number]) {
            const [newMin, newMax] = value;
            const [oldMin, oldMax] = this.levelBounds;
            if (newMin !== oldMin || newMax !== oldMax) {
                this.applyFilter("level", { min: newMin, max: newMax });
            }
        }

        private isBuyCutiesIntroStep(): boolean {
            const activeStep = IntroductionSystem.getActiveStep();
            return !!activeStep && activeStep.id === "ACQUIRE_CUTIES_FOR_BREEDING";
        }

        private shouldHintBlockchainFilterForIntro(value: Blockchain): boolean {
            return value === IntroductionSystem.RECOMMENDED_BLOCKCHAIN
                && this.isBuyCutiesIntroStep();
        }
    }
