
    import { Component, Emit, Prop, Vue } from "vue-property-decorator";
    import type { LocaleMessages } from "vue-i18n";
    import UserApiService from "@/cuties/player/UserApiService";
    import utils from "@/cuties/utils";
    import type { UserTemplate } from "@/cuties/model/player/UserTemplate";
    import VueUtils from "@/cuties/VueUtils";
    import analytics from "@/cuties/analytics";
    import { ConfigInstance } from "@/cuties/engine/Config";

    /** @see https://developers.google.com/recaptcha/docs/display#js_api */
    interface Recaptcha {
        /** returns widgetId */
        render: (target: string | HTMLElement, params: {
            sitekey: string;
        }) => string;
        getResponse: (widgetId?: string) => string;
        reset: (widgetId?: string) => void;
    }

    /**
     * represents the form where you enter login/password and
     * possibly captcha and receive the xsrf token in return
     */
    @Component({
        components: {},
    })
    export default class extends Vue {
        @Prop({ required: true }) okCallback: (user: UserTemplate) => void;

        password = "";
        username = "";
        error: string | LocaleMessages = "";
        showResendConfirmCode = false;
        getCaptchaResponse: () => string | null = () => null;
        /** should set true if server reply says so */
        captchaRequired = false;
        submittingCredentials = false;

        loginDisabled(): boolean {
            return this.password.length === 0
                || this.username.length === 0
                || this.submittingCredentials;
        }

        cleanError() {
            this.error = "";
            this.showResendConfirmCode = false;
        }

        resendConfirmationCode() {
            this.cleanError();
            this.showResendConfirmCode = true;

            const email = this.username;
            return UserApiService.resendEmailConfirmationCode({
                email: email,
            })
                .then((res) => {
                    this.cleanError();
                    utils.show_notification(
                        this.$t("email_signup_done_desc_1") + " " + email + ". " +
                            this.$t("email_signup_done_desc_2")
                    );
                })
                .catch((e) => {
                    console.error(e);
                    const { data, status } = (e || {}).response || {};
                    this.error = this.$t(data);
                });
        }

        requireCaptcha() {
            if (!this.captchaRequired) {
                this.captchaRequired = true;
                window["whenGrecaptchaReady"].then((recaptcha: Recaptcha) => {
                    const widgetId = recaptcha.render("email-login-captcha-target", {
                        "sitekey": ConfigInstance.get("passwordLoginCaptchaSiteKey"),
                    });
                    this.getCaptchaResponse = () => {
                        const captchaResponse = recaptcha.getResponse(widgetId);
                        if (captchaResponse) {
                            recaptcha.reset(widgetId); // a CAPTCHA solution can be used only once
                        }
                        return captchaResponse;
                    };
                });
            }
        }

        @Emit()
        forgotPassword() {}

        submitEmailLoginForm(evt: Event) {
            evt.preventDefault();

            if (!this.password) {
                this.error = this.$t("EthereumPrivateKey_MissingPassword");
                analytics.gtag_log_event("log in", "email", this.error);
                return false;
            }

            if (!this.username) {
                this.error = this.$t("email_is_empty_error");
                analytics.gtag_log_event("log in", "email", this.error);
                return false;
            }

            let captchaResponse = null;
            if (this.captchaRequired) {
                captchaResponse = this.getCaptchaResponse();
                if (!captchaResponse) {
                    this.error = this.$t("ERR_CAPTCHA_NOT_SOLVED");
                    analytics.gtag_log_event("log in", "email", this.error);
                    return false;
                }
            }

            this.submittingCredentials = true;
            return UserApiService.loginUsingPassword({
                username: this.username,
                password: this.password,
                captchaResponse: captchaResponse,
            })
                .then((response) => {
                    this.cleanError();
                    this.okCallback(response.user);
                    analytics.gtag_log_event("log in", "email", "success");
                })
                .catch((e) => {
                    console.error(e);
                    const { error, tplVars } = VueUtils.extractErrorData(e) ?? {};
                    if (error !== "ERR_PASSWORD_LOGIN_BLOCKED" &&
                        error !== "ERR_PASSWORD_MUST_BE_CHANGED" &&
                        error !== "errorUnconfirmedMail"
                    ) {
                        // after first unsuccessful attempt server will require captcha,
                        // unless the cause is one of the reasons mentioned above
                        this.requireCaptcha();
                    }

                    this.showResendConfirmCode = error === "errorUnconfirmedMail";
                    if (!error) {
                        this.error = String(e);
                    } else {
                        this.error = this.$t(error, tplVars);
                    }
                    analytics.gtag_log_event("log in", "email", this.error);
                })
                .finally(() => this.submittingCredentials = false);
        }
    }
