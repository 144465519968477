import DataHelpers from "@/cuties/engine/DataHelpers";
import utils from "../utils";
import RequestBase from "../engine/RequestBase";

/**
 * Base class for all project-specific requests.
 * If they share some logic, you can add it here.
 */
export default abstract class Request<TResponse = any> extends RequestBase<TResponse> {

    abstract get_url(): string;

    collect_post_data(): object {
        return null;
    }

    /* global JQueryXHR */
    on_response(response: any, textStatus: string, jqXHR: JQueryXHR): void {
    }

    on_error(response: any): void {
        if(response.error == "errorRaidNoCurrent") return;

        try {
            const status = response.errorDetails.jqXHR.status;
            if (status == 401) {
                utils.propose_auth();
            }
        } catch (e) {
            DataHelpers.error("Request.on_error", e);
        }
        // note, response.error is already translated!
        utils.page_error(response.error, response.error);
    }
}

