import { PLAYER_SINGLETON } from "@/cuties/model/player/Player";
import mad from "@/cuties/engine/mad";
import { assertRealBlockchain, Blockchain, getAllBlockchains } from "@/cuties/model/pet/BlockchainId";
import Vue from "vue";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import type BlockchainService from "@/cuties/blockchain/BlockchainService";
import utils from "@/cuties/utils";
import BlockchainFeatures, { Feature } from "@/cuties/blockchain/BlockchainFeatures";
import { AppStoreActions } from "@/store/AppStore";
import VueUtils from "@/cuties/VueUtils";
import AttachAccountManager from "@/cuties/AttachAccountManager";
import type { AuthProvider } from "@/cuties/blockchain/types";
import TranslatedClientError from "@/app/cuties/utils/TranslatedClientError";
import CutiesApiFaucet from "@/app/cuties/blockchain/CutiesApiFaucet";
import type { WalletProviderKind } from "@/components/LoginManager/TypeDefs";

export type OnAuthorizeSuccess = (wallet: BlockchainService) => void | Promise<void>;
export type OnAuthorizeCancel = () => void | Promise<void>;
export type OnProviderReadiness = (blockchain: Blockchain, provider: AuthProvider, walletProviderKind: WalletProviderKind) => Promise<void>;

const LoginManagerVue = () => import(/* webpackChunkName: "LoginManagerVue" */ "@/views/LoginManagerVue.vue");

export default class LoginManager {
    private readonly onLogin: OnAuthorizeSuccess;
    private readonly onClose: OnAuthorizeCancel;
    private readonly allowedOnlyBlockchains: Blockchain[];
    private allowRegister: boolean;

    public showCloseButton: boolean = true;

    // is null - allow all

    public constructor(
        onLogin: OnAuthorizeSuccess,
        onClose: OnAuthorizeCancel | null,
        allowedOnlyBlockchains: Blockchain[] | null,
        allowRegister: boolean
    ) {
        this.onLogin = onLogin;
        this.onClose = onClose;
        this.allowedOnlyBlockchains = allowedOnlyBlockchains;
        this.allowRegister = allowRegister;

        if (!this.allowedOnlyBlockchains) this.allowedOnlyBlockchains = getAllBlockchains();

        if (window["tesseract"]) this.allowedOnlyBlockchains = [Blockchain.Offchain];

        this.allowedOnlyBlockchains = this.allowedOnlyBlockchains.filter((bc) => BlockchainFeatures.is_enabled(Feature.Login, bc));

        if (!this.onClose) {
            this.onClose = () => {
                this.closePopup();
            };
        }
    }

    public closePopup() {
        // a bit sad that we are still using a legacy templater here that
        // relies on a single global DOM element that holds all popups ;c
        mad.popup.hide();
        if ($("body").hasClass("bodyOverflow")) {
            $("body, html").removeClass("bodyOverflow");
        }
    }

    public run() {
        this.runLogIn();
    }

    public static runAsync(params: { allowedOnlyBlockchains: Blockchain[]; allowRegister: boolean }): Promise<BlockchainService> {
        return new Promise((resolve, reject) => {
            new LoginManager(
                resolve,
                () => reject(new Error("Login prompt cancelled")),
                params.allowedOnlyBlockchains,
                params.allowRegister
            ).run();
        });
    }

    public attach() {
        if (!this.isLoggedIn()) {
            this.runLogIn();
            return;
        } else {
            if (this.onLogin) {
                // todo: @nikita decide what to do
                Promise.resolve()
                    .then(() => this.onLogin(null))
                    .catch(error => VueUtils.handleError(error));
            }
        }
    }

    public runLogIn(): void {
        const self = this;

        mad.popup.show("popup_login_manager_vue", null, false, () => {
            let unlock = false;
            if (self.isLoggedIn()) {
                unlock = true;
            }

            new Vue({
                i18n,
                router,
                store,
                render: (h) => h(
                    LoginManagerVue, {
                        props: {
                            callback: self.onLogin,
                            onClose: self.onClose,
                            bus: new Vue(),
                            allowedBlockchains: self.allowedOnlyBlockchains,
                            unlock: unlock,
                            showClose: self.showCloseButton,
                        },
                    }),
            }).$mount("#login_manager_vue");
        });
    }

    public runEmailChangePassword(token: string) {
        const self = this;

        mad.popup.show("popup_login_manager_vue", null, false, () => {

            let unlock = false;
            if (self.isLoggedIn() ) {
                unlock = true;
            }

            new Vue({
                i18n,
                router,
                store,
                render: (h) => h(
                    LoginManagerVue, {
                    props: {
                        callback: self.onLogin,
                        bus: new Vue(),
                        allowedBlockchains: [Blockchain.Offchain],
                        unlock: unlock,
                        showClose: self.showCloseButton,
                        token: token,
                    }
                }),
            }).$mount("#login_manager_vue");
        });
    }

    private isLoggedIn() : boolean {
        return PLAYER_SINGLETON.is_logined();
    }

    public static async obtainUnlockedProvider(blockchain: Blockchain): Promise<BlockchainService> {
        const wallet = await CutiesApiFaucet.getApi(blockchain);
        const provider = await wallet.get_provider();
        if (provider) {
            return wallet;
        } else {
            const hasAttachedAccount = PLAYER_SINGLETON.get_blockchains().has(blockchain);
            if (!hasAttachedAccount && PLAYER_SINGLETON.is_logined()) {
                AttachAccountManager.attach(assertRealBlockchain(blockchain));
                throw new TranslatedClientError("wallet_unlock_failed_need_attach", [blockchain]);
            }
            return new Promise((resolve, reject) => {
                const onLogin = (blockchainWallet: BlockchainService) => {
                    resolve(blockchainWallet);
                    store.dispatch(AppStoreActions.REFRESH_BLOCKCHAIN, blockchain);
                    loginManager.closePopup();
                };
                const onClose = () => reject(new TranslatedClientError("ERR_LOGIN_METHOD_SELECT_CANCELLED"));
                const loginManager = new LoginManager(onLogin, onClose, [blockchain], false);
                loginManager.run();
            });
        }
    }

    /**
     * supposed to handle errors itself and not return the promise
     * though would be nice to use the Promise.then() instead of callback argument eventually
     */
    static run_unlock(blockchain: Blockchain, callback: OnAuthorizeSuccess): void {
        const handleUnlockError = (e: Error | string | any) => {
            const msg = VueUtils.extractError(e);
            const prefix = i18n.t("wallet_unlock_failed").toString();
            utils.show_notification(prefix + " - " + msg);
            console.error(prefix, e);
        };
        this.obtainUnlockedProvider(blockchain)
            .then(callback, handleUnlockError)
            .catch(exc => VueUtils.handleError(exc));
    }

    public static unlock(blockchain: Blockchain): Promise<BlockchainService> {
        return this.obtainUnlockedProvider(blockchain);
    }

    static showLogIn(): Promise<void> {
        if (!PLAYER_SINGLETON.is_logined()) {
            return new Promise((resolve, reject) => {
                const manager = new LoginManager(() => resolve(), reject, null, true);
                manager.showCloseButton = false;
                manager.runLogIn();
            });
        } else {
            return Promise.resolve();
        }
    }
}
