import Vue from "vue";
import Vuex from "vuex";
import { PlayerStoreSingleton } from "@/store/PlayerStore";
import { InventoryStoreSingleton } from "@/store/InventoryStore";
import { AdminEquipmentStoreSingleton } from "@/store/AdminEquipmentStore";
import { PresaleStoreSingleton } from "./store/PresaleStore";
import { HeaderMenuStoreSingleton } from "./store/HeaderMenuStore";
import { QuestsStoreSingleton } from "./store/QuestsStore";
import { AppStoreSingleton } from "./store/AppStore";
import { GlobalChatStoreSingleton } from "@/app/store/GlobalChatStore";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        playerName: "",
    },
    getters: {
        // playerSettings: state => PlayerSettingsService.getCurrentPlayerSettings(),
    },
    mutations: {
        // setPlayerName: (state, payload) => state.playerName = payload
    },
    actions: {},

    modules: {
        player: PlayerStoreSingleton,
        inventory: InventoryStoreSingleton,
        admin: AdminEquipmentStoreSingleton,
        presale: PresaleStoreSingleton,
        header: HeaderMenuStoreSingleton,
        quests: QuestsStoreSingleton,
        globalChat: GlobalChatStoreSingleton,
        app: AppStoreSingleton,
    },
});
