
    import { defineComponent } from "vue";
    import { RouterLink } from "vue-router";

    export default defineComponent({
        components: { RouterLink },
        props: {
            to: {
                required: true,
            },
        },
        computed: {
            effectiveAttrs() {
                let to;
                if (typeof this.to === "string") {
                    if (this.to === "/") {
                        // trailing sjash on root page affects base url and breaks style links
                        to = "/" + this.$i18n.locale;
                    } else {
                        to = "/" + this.$i18n.locale + this.to;
                    }
                } else {
                    to = {
                        ...this.to,
                        name: !this.to.name ? undefined : this.to.name + "-localized",
                        params: {
                            ...(this.to.params ?? {}),
                            lang: this.$i18n.locale,
                        },
                    };
                }
                return {
                    ...this.$attrs,
                    to: to,
                };
            },
        },
    });
