import * as Handler from "../engine/HandlerManager";
import * as Request from "../engine/RequestManager";

import { ConfigInstance } from "../engine/Config";
import type Storage from "../engine/Storage";
import { STORAGE_SINGLETON } from "../engine/Storage";

import PopupManager from "../engine/PopupManager";
import { Blockchain } from "../model/pet/BlockchainId";
import type { BlockchainServiceCompanion } from "../blockchain/BlockchainService";
import $ from "jquery";
import NeoWalletCompanion from "@/cuties/blockchain/neo/NeoWalletCompanion";
import TronWalletCompanion from "@/cuties/blockchain/tron/TronWalletCompanion";
import EosWalletCompanion from "@/cuties/blockchain/eos/EosWalletCompanion";
import EthereumWalletCompanion from "@/cuties/blockchain/ethereum/EthereumWalletCompanion";
import Money from "@/cuties/engine/Money";
import MaticWalletCompanion from "@/cuties/blockchain/matic/MaticWalletCompanion";
import HecoWalletCompanion from "@/cuties/blockchain/heco/HecoWalletCompanion";
import DataHelpers from "@/cuties/engine/DataHelpers";
import EmeraldWalletCompanion from "@/cuties/blockchain/emerald/EmeraldWalletCompanion";
import CscWalletCompanion from "@/cuties/blockchain/csc/CscWalletCompanion";
import OkcWalletCompanion from "@/cuties/blockchain/okc/OkcWalletCompanion";
import BscWalletCompanion from "@/cuties/blockchain/bsc/BscWalletCompanion";
import { weiToGwei } from "@/app/cuties/utils/utils";

// type: { stringKey1:valueOfAnyType1, stringKey2:valueOfAnyType2, ... }
export type AnyMap = { [key: string]: any };

/**
 * @deprecated - using mad is bad and if you do, you should feel bad
 * it's a graveyard of bad design decisions that lead to circular dependencies
 */
export default class mad {
    /** @deprecated - use HANDLER_MANAGER_SINGLETON directly to avoid circular dependencies caused by mad.ts */
    static handler: Handler.Interface = Handler.HANDLER_MANAGER_SINGLETON;
    /** @deprecated - should drop all usages, axios is what we should use for HTTP requests */
    static request:Request.Interface = new Request.RequestManager();
    static popup:PopupManager = new PopupManager();

    /** @deprecated - use singleton directly to avoid circular references caused by mad.ts */
    static storage:Storage = STORAGE_SINGLETON;

    private static walletCompanion = {
        [Blockchain.Ethereum]: new EthereumWalletCompanion(),
        [Blockchain.Matic]: new MaticWalletCompanion(),
        [Blockchain.Eos]: new EosWalletCompanion(),
        [Blockchain.Tron]: new TronWalletCompanion(),
        [Blockchain.Neo]: new NeoWalletCompanion(),
        [Blockchain.Neo3]: new NeoWalletCompanion(),
        [Blockchain.Heco]: new HecoWalletCompanion(),
        [Blockchain.Emerald]: new EmeraldWalletCompanion(),
        [Blockchain.Csc]: new CscWalletCompanion(),
        [Blockchain.Okc]: new OkcWalletCompanion(),
        [Blockchain.Bsc]: new BscWalletCompanion(),
    };

    static warning(...args: any[]) {
        console.warn(args.length == 1 ? args[0] : Array.prototype.slice.call(args));
    }

    /**
     * collect values from form's fields
     */
    static collect_form_values(form_selector:HTMLElement|string, field_key_attribute?:string|undefined): { [key:string]:string } {
        field_key_attribute = field_key_attribute || "data-id";

        // $(form_selector).serializeArray()
        // todo multiple select

        const json = {};
        // will no search input type="hidden"
        $(form_selector as any).find("input, textarea, select").each(function() {
            const obj = $(this);
            const key = obj.attr(field_key_attribute as string);

            if(obj.is("[type=submit]")) {
                // nothing
            } else if (obj.is("[type=checkbox]")) {
                const value = obj.is(":checked") ? true : false;
                json[key] = value;
            } else {
                const value = obj.val();
                json[key] = value;
            }
        });

        return json;
    }

    static format(x: number):string {
        const number = Math.ceil(x / 1000000);
        return number + "000000";
    }

    public static getWalletCompanion(blockchain: Blockchain): BlockchainServiceCompanion {
        return mad.walletCompanion[blockchain];
    }

    public static get_supported_fiat_currencies(price = null, chain = null, fiat = null, usdPrice = null) {
        if(!usdPrice && price) {
            const fiatUSDRate = ConfigInstance.dynamic.usdPrices[chain];
            usdPrice = fiatUSDRate * DataHelpers.toFloat(price);
        }

        const fiatRates = ConfigInstance.dynamic.usdFiatPrices;
        const supported : string[] = ConfigInstance.supportedFiatCurrencies;
        const data = [];
        for(const i in supported) {
            let rate = parseFloat((usdPrice / fiatRates[supported[i]]).toString()).toFixed(2);
            if(rate == "0.00") rate = "0.01";
            const result = {
                curr : supported[i],
                val : price || (usdPrice && !price) ? rate : null
            };
            if(fiat) {
                if(result.curr == fiat) return result;
            }
            data.push(result);
        }
        return data;
    }

    public static get_gas_eth_value() : number {
        const gas = ConfigInstance.dynamic.ethereums[Blockchain.Ethereum].gasPrices.Fast1min;
        const gweisBigInt = weiToGwei(gas);
        const gwei = new Money(DataHelpers.toInt(gweisBigInt));
        const gasPrice = gwei.divide(1000000000).multiply(ConfigInstance.ethereums[Blockchain.Ethereum].maxGasPerTx);
        return DataHelpers.toFloat(gasPrice.getAmountFixed());
    }

    public static is_fiat(currency : string) : boolean {
        const supported : string[] = ConfigInstance.supportedFiatCurrencies;
        return supported.includes(currency);
    }

    public static apply_fiat_commision(price : number | string) {
        price = DataHelpers.toFloat(price);
        const priceNew = price + price * (DataHelpers.toFloat(ConfigInstance.get("fiatInterestPercents")) / 100);
        return DataHelpers.toFloat(priceNew);
    }
}
