import Request from "./Request";


export default class RequestEmailSubscribePlayer extends Request {



    private handler_prefix:string = null;

    constructor() {
        super();
    }

    get_url(): string {
        return "/email_subscribe_presale_player";
    }

    collect_post_data(): object {
        return {

        };
    }

    on_success(response) {

    }


    set_handler_prefix(handler_prefix: string): void {
        this.handler_prefix = handler_prefix;
    }

}
