import Request from "./Request";


export default class RequestEmailSubscribe extends Request {

    private email:string;

    private handler_prefix:string = null;

    constructor(email:string) {
        super();

        this.email = email;
    }

    get_url(): string {
        return "/email_subscribe_presale";
    }

    collect_post_data(): object {
        return {
            email: this.email,
        };
    }

    on_success(response) {

    }

    get_handler_prefix(): string {
        return this.handler_prefix;
    }

    set_handler_prefix(handler_prefix: string): void {
        this.handler_prefix = handler_prefix;
    }

}
