import http, { getStateless, makeHttp } from "@/http";
import type { Blockchain } from "@/cuties/model/pet/BlockchainId";
import LoginManager from "../LoginManager";
import IntroductionSystem from "@/cuties/player/introduction/IntroductionSystem";
import { PLAYER_SINGLETON } from "@/cuties/model/player/Player";
import store from "@/store";
import { AppStoreActions } from "@/store/AppStore";
import type { PossessionIdentityOffchainCurrency } from "@/cuties/request/crafting/CraftingApiService";
import type { OffchainCurrency } from "@/cuties/offchaincurrency/OffchainCurrency";
import { fromFixedPointBigNumber, toBlockchain } from "@/cuties/offchaincurrency/OffchainCurrency";
import BigNumber from "bignumber.js";
import type { LotteryTemplate } from "@/cuties/model/lottery/LotteryModel";
import type { PossessionIdentity } from "cuties-client-components/types/general/UnitIdentity";
import router from "@/router";
import i18n from "@/i18n";

export default class LotteryService {

    public static async getDefinitions(): Promise<RegularLottery[]> {
        return getStateless("/public/lottery/definitions")
            .then(response => response.data);
    }

    public static async getLottery(params: { definitionId: string }): Promise<CurrentLotteryResponse> {
        return http.get("/lottery", { params })
            .then(response => response.data);
    }

    /** public for admin page */
    public static recordDepositForLotteryParticipation(params: LotteryParticipationViaDepositRequest): Promise<void> {
        return makeHttp({ baseUrl: "/rest" }).post("/lottery/participate/record/deposit", params).then(rs => {
            IntroductionSystem.completeStep("PARTICIPATE_IN_LOTTERY");
        });
    }

    public static async signInLottery(lotteryId: number, fee: PossessionIdentityOffchainCurrency): Promise<void> {
        const priceMajor = fromFixedPointBigNumber(fee.count, fee.offchainCurrency);

        const blockchain = toBlockchain(fee.offchainCurrency);
        const wallet = await LoginManager.unlock(blockchain);
        const hash = await wallet.offchainCurrencyDeposit(new BigNumber(priceMajor));
        await this.recordDepositForLotteryParticipation({
            depositBlockchain: blockchain,
            initialDepositTransactionHash: hash,
            currency: fee.offchainCurrency,
            depositAmount: String(fee.count),
            lotteryId: lotteryId,
        });
        router.push({ path: "/" + i18n.locale + "/activity" });
    }

    /**
     * we want to motivate people to do transactions so that we got rated higher in
     * dapradar, so we show popup on each refresh till person participates in the lottery
     */
    public static async onTokenAuth() {
        // but showing this popup on every single refresh is nagging, so we do that only one of 20 times
        if (Math.random() >= 0.05) {
            return;
        }
        if (window.location.pathname === "/promo" ||
            window.location.pathname === "/mascot"
        ) {
            return;
        }
        if (!PLAYER_SINGLETON.get_email() &&
            PLAYER_SINGLETON.get_blockchains().size === 0
        ) {
            // user just registered, locked on settings form, let's not bother him
            return;
        }
        const definitions = await this.getDefinitions();
        if (definitions.length === 0) {
            return;
        }
        const definition = definitions[0];
        const state = await this.getLottery({ definitionId: definition.id });
        const definitionBlockchains = definitions[0].entryFeeOptions
            .map(fee => toBlockchain(fee.offchainCurrency));
        if (!state.lottery.allowed.some(blockchain => definitionBlockchains.includes(blockchain))) {
            // user already participated on all possible blockchains
            return;
        }

        store.dispatch(AppStoreActions.LOTTERY_POPUP_OPEN);
    }
}

interface FixedRewardDistribution {
    unitsDistributed: number;
    unitIdentity: PossessionIdentity;
}

export interface RegularLottery {
    id: string;
    entryFeeOptions: PossessionIdentityOffchainCurrency[];
    /** rewards that user receives after a successful transaction */
    participationRewards: PossessionIdentity[];
    fixedRewardsDistributions: FixedRewardDistribution[];
}

/** @see CurrentLotteryResponse.java */
export interface CurrentLotteryResponse {
    lottery: LotteryTemplate;
    archive: LotteryTemplate[];
}

interface LotteryParticipationViaDepositRequest {
    depositBlockchain: Blockchain;
    initialDepositTransactionHash: string;
    currency: OffchainCurrency;
    depositAmount: string;
    lotteryId: number;
}
