
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";
    import type { PossessionIdentity } from "cuties-client-components/types/general/UnitIdentity";
    import ItemTooltipLazyLoad from "@/components/items/ItemTooltipLazyLoad.vue";
    import type { ItemDefinitionModel } from "@/app/cuties/items/ItemApiService";
    import ItemApiService from "@/app/cuties/items/ItemApiService";
    import type {
        PossessionIdentityItem,
        PossessionIdentityOffchainCurrency
    } from "@/cuties/request/crafting/CraftingApiService";
    import PossessionsLayer from "@/app/components/crafting/PossessionsLayer";
    import I18nHelpers from "@/cuties/engine/I18nHelpers";
    import type { ItemInstanceModelBase } from "@/cuties/model/item/ItemInstanceModel";
    import VueUtils from "@/cuties/VueUtils";
    import { fromFixedPointBigNumber } from "@/cuties/offchaincurrency/OffchainCurrency";
    import type { StageDefinition } from "@/app/cuties/dungeons/DungeonsApiService";
    import DungeonsApiService from "@/app/cuties/dungeons/DungeonsApiService";

    @Component({
        components: { ItemTooltipLazyLoad }
    })
    export default class PossessionIdentityDescription extends Vue {
        @Prop({ required: true }) identity!: PossessionIdentity;

        itemDefinitionMap: null | Map<string, ItemDefinitionModel> = null;

        readonly goToLink = VueUtils.goToLink;
        stageDefenition: StageDefinition | null = null;

        async created() {
            this.itemDefinitionMap = await ItemApiService.itemDefinitionMap();
            if (this.identity.type === "clearedDungeonStage") {
                this.stageDefenition = await DungeonsApiService.getStageDefinition(this.identity.stageId);
            }
        }

        get dungeonWorldName(): string {
            return this.stageDefenition ? this.$t("dungeon_world_name_" + this.stageDefenition.worldId).toString() : "...";
        }

        getItemInstanceModel(identity: PossessionIdentityItem): ItemInstanceModelBase | null {
            const definition = this.itemDefinitionMap?.get(identity.item);
            if (!definition) {
                return null;
            }
            return PossessionsLayer.toItemInstanceModel(identity, definition);
        }

        minutesToFormattedDuration(minutes: string) {
            return I18nHelpers.get_time_duration_string(+minutes * 60);
        }

        toDecimalScale(identity: PossessionIdentityOffchainCurrency) {
            return fromFixedPointBigNumber(identity.count, identity.offchainCurrency);
        }

        get asItem(): PossessionIdentityItem | null {
            return PossessionsLayer.asItem(this.identity);
        }
    }
