
    import Vue from "vue";
    import Component from "vue-class-component";
    import type { PopupProps } from "@/cuties/VueUtils";
    import { Prop } from "vue-property-decorator";
    import type { ConfirmPopupData } from "@/cuties/blockchain/eos/EosPrivateKey";
    import VueUtils from "@/cuties/VueUtils";
    import UserProfileIcon from "@/app/components/player/UserProfileIcon.vue";
    import { Getter } from "vuex-class";
    import type { Player } from "@/cuties/model/player/Player";
    @Component({
        components: { UserProfileIcon }
    })
    export default class EosConfirmTransaction extends Vue implements PopupProps<void> {
        @Prop({ required: true }) okCallback!: () => void | Promise<void>;
        @Prop({ required: true }) cancelCallback!: (exc?: Error | unknown) => void | Promise<void>;
        @Prop({ required: true }) data!: ConfirmPopupData;
        @Getter("getPlayer") user!: Player;

        visible = true;

        readonly isMobile = VueUtils.isMobile();

        reject(): void {
            this.visible = false;
            this.cancelCallback(new Error("Transaction declined"));
        }

        confirm(): void {
            this.okCallback();
        }
    }
