import Request from "../Request";


export default class RequestAdminSetCanGiftItems extends Request {

    private userId:number;
    private canGiftItems:boolean;

    constructor(userId:number, canGiftItems:boolean) {
        super();

        this.userId = userId;
        this.canGiftItems = canGiftItems;
    }

    get_url(): string {
        return "/set_can_gift_items";
    }

    collect_post_data(): object {
        const data = {
            canGiftItems: this.canGiftItems,
            userId: this.userId
        };

        return data;
    }

}
