
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";

    @Component
    export default class FormatStat extends Vue {
        @Prop({ required: true }) value!: string;

        get statClass(): string {
            if (this.value.length) {
                if (this.value.length > 1) {
                    if (this.value.charAt(0) === "-") {
                        return "stat-negative";
                    } else if (this.value.charAt(0) === "+" && this.value.charAt(1) === "0") {
                        return "stat-zero";
                    } else if (this.value.charAt(0) === "0") {
                        return "stat-zero";
                    }
                    return "stat-positive";
                } else if (
                    this.value.length === 1 &&
                    this.value.charAt(0) !== "+" &&
                    this.value.charAt(0) !== "-" &&
                    this.value.charAt(0) !== "0"
                ) {
                    return "stat-positive";
                }
            }

            return "stat-zero";
        }
    }
