
    import { Component, Prop, Vue } from "vue-property-decorator";
    import type { ItemInstanceModelBase } from "@/cuties/model/item/ItemInstanceModel";
    import type ItemInstanceModel from "@/cuties/model/item/ItemInstanceModel";
    import VueUtils from "../cuties/VueUtils";
    import ItemCardIconList from "@/components/items/ItemCardIconList.vue";
    import PossessionsLayer from "@/app/components/crafting/PossessionsLayer";

    /**
     * used on activity page, item market, paw shop and few others places
     * usually decently smaller than the InventoryItem.vue
     */
    @Component({
        components: { ItemCardIconList },
    })
    export default class ItemBlock extends Vue {
        @Prop({ required: true }) item: ItemInstanceModel | ItemInstanceModelBase;
        @Prop({ default: "" }) imgClass: string;
        @Prop({ default: null }) quantity: number;
        @Prop({ default: null }) quantityLeft: string;
        @Prop({ default: "" }) wrapperClass: string;
        @Prop({ default: "" }) countClass: string;
        @Prop({ default: true }) showTooltip: boolean;
        @Prop({ default: null }) quantityMin: number;
        @Prop({ default: null }) quantityMax: number;
        @Prop({ default: "" }) minMaxClass: string;
        @Prop({ default: false }) showCountOne: boolean;

        get baseName(): string {
            return PossessionsLayer.getModelBaseName(this.item);
        }

        close(): void {
            this.$emit("close");
        }

        itemWrapperClass(): string {
            let itemWrapperClass = "";
            if (this.wrapperClass) {
                itemWrapperClass = this.wrapperClass + " ";
            }

            itemWrapperClass += VueUtils.itemRarityClass(this.item.schema.rarity);

            return itemWrapperClass;
        }

        get quanitiyValue(): string {
            return "x" + this.quantity + (this.quantityLeft ? " / " + this.quantityLeft : "");
        }
    }
