
    import { Component, Prop, Vue } from "vue-property-decorator";
    import type { PopupProps } from "@/cuties/VueUtils";
    import VueUtils from "@/cuties/VueUtils";

    @Component({
        components: {}
    })
    export default class AccountMergePopup extends Vue implements PopupProps<void> {
        @Prop({ required: true }) okCallback!: () => Promise<void>;
        @Prop({ required: true }) cancelCallback!: () => void;

        readonly isMobile = VueUtils.isMobile;

        private error = "";

        private async submit(evt: Event) {
            evt.preventDefault();
            this.error = "";
            try {
                await this.okCallback();
            } catch (exc) {
                this.error = this.$t("errorGameAccountMergeError") + " - " + exc;
            }
        }
    }
