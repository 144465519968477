// css global vars
import "cuties-client-components/styles/global-variables.css";
import "./css/global-variables-bsc.css";
// css libs
import "./../public/css/bids/normalize.css";
import "./../public/css/bids/webflow.css";
import "./../public/css/font-awesome.css";
import "./../public/css/bids/blockchain-cuties-gradients.webflow.css";
import "./../public/css/animate.min.css";
import "./../public/css/slick.css";
import "./../public/css/slick-theme.css";
// our css files
import "./../public/css/style.css";
import "./../public/css/raidBoss.css";
import "./../public/css/petBackgrounds.css";
import "./../public/css/mascot.css";
import "./../public/css/lands.css";
import "./../public/css/storePage.css";
import "./../public/css/tutorial.css";
import "./../public/css/seasonpass.css";
import "./css/animations.css";

import Vue from "vue";
import App from "./App.vue";

import store from "./store";
import i18n from "./i18n";
import "@/cuties/main";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "@/scss/petFilters.scss";
import "@/scss/vueGlobal.scss";
import "../public/css/sass/item.scss";
import "../public/css/sass/christmasSeason.scss";
import "../public/css/sass/market.scss";
import "../public/css/sass/wallet.scss";
import "@/scss/item.scss";
import "@/scss/leaderboard.scss";
import "@/scss/pet.scss";
import "@/scss/adventureBackgrounds.scss";
import "@/scss/icons.scss";
import "../public/css/sass/offchainCurrencyReception.scss";

import "@/scss/components/components.scss";

import Clipboard from "v-clipboard";
import router from "@/router";

import directives from "./directives/index";
import Router from "vue-router";
import { routes } from "@/routes";
import AppLangRouterLink from "@/components/app-components/AppLangRouterLink.vue";

Vue.use(ElementUI);
Vue.use(Clipboard);
Vue.use(directives);
Vue.use(Router);

for (const route of routes) {
    // fallback for old links without explicit /en/
    router.addRoute(route);
}
for (const route of routes) {
    const localizedRoute = {
        ...route,
        name: !route.name ? undefined : route.name + "-localized",
        path: "/:lang" + route.path,
    };
    router.addRoute(localizedRoute);
}

Vue.component("router-link", AppLangRouterLink);

Vue.config.productionTip = false;

const app = new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");

// need for unity to call funtion of component - will call it by ref
(window as any)["vue"] = app;
