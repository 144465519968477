import type { CallParams, TransactionObject, TronWeb } from "@/cuties/blockchain/tron/TronProvider";
import type { TronConfig, TronContracts } from "@/cuties/blockchain/tron/TronConfig";
import type { WalletAddress } from "@/components/LoginManager/TypeDefs";
import { throws } from "@/app/cuties/utils/utils";
import { ConfigInstance } from "@/cuties/engine/Config";

export default class TronContractRegistry {
    private readonly cache: Map<WalletAddress, any> = new Map<WalletAddress, any>();

    constructor(private readonly tronWeb: TronWeb) {}

    /**
     * TronWeb can read contract abi from the blockchain.
     * To avoid extra calls to the network contracts are cached.
     */
    public async getMethodObj(call: CallParams & { overrideContractAddress?: string }): Promise<TransactionObject> {
        const address = call.overrideContractAddress ?? this.getContractAddress(call.contractName);
        const contract = await this.getContactObj(address);
        const method = contract[call.methodName] ?? throws(`Contract '${call.contractName}' doesn't have method ${call.methodName}`);
        return method(...(call.methodArguments ?? []));
    }

    public async getContactObj(address: string) {
        return this.cache.get(address) ?? (await this.fetchContract(address));
    }

    public getContractAddress(name: keyof TronContracts): WalletAddress {
        const contracts = this.getConfig().contracts || null;
        if (!contracts || Object.keys(contracts).length === 0) {
            throw new Error("Contract addresses were not supplied in config");
        }
        const contractAddress = contracts[name] || null;
        if (!contractAddress) {
            throw new Error(`Contract address for ${name} was not supplied in config`);
        } else {
            return contractAddress;
        }
    }

    private async fetchContract(address: string) {
        const contract = await this.tronWeb.contract().at(address);
        this.cache.set(address, contract);
        return contract;
    }

    private getConfig(): TronConfig {
        const config = ConfigInstance.tron || null;
        if (!config || Object.keys(config).length === 0) {
            throw new Error("TRON config is not available");
        } else {
            return config;
        }
    }
}
