
    import { Component, Emit, Prop, Vue } from "vue-property-decorator";
    import type { HeaderMenuEntry } from "cuties-client-components/types/api/Config";
    const ICONS_DIR_PATH = "/static/header";

    @Component({
        name: "HeaderMenuMobile",
        components: { HeaderMenuMobile },
    })
    export default class HeaderMenuMobile extends Vue {
        @Prop({ required: true }) headerMenu!: HeaderMenuEntry[];
        @Prop() nested: boolean;
        @Prop() depth: number;
        @Prop({ required: true }) openedSubmenusInitial: string[];
        @Prop({ default: undefined }) parent!: HeaderMenuEntry;

        openedSubmenus: string[] = [];

        mounted() {
            this.openedSubmenus = this.openedSubmenusInitial;
        }

        toggleSubmenu(submenuId: string): void {
            if (!this.openedSubmenus.includes(submenuId)) {
                this.openedSubmenus.push(submenuId);
            } else {
                this.openedSubmenus = this.openedSubmenus.filter((el) => el !== submenuId);
            }
        }

        getTranslation(menuNode: HeaderMenuEntry, parentName = ""): string {
            if (parentName === "raidBoss") {
                return this.$t(menuNode.name).toString();
            }
            return this.$t("header_menu_" + menuNode.name).toString();
        }

        isMenuActive(submenuId: string): boolean {
            return this.openedSubmenus.includes(submenuId);
        }

        goToLink(event: MouseEvent) {
            this.closeMobileMenu();
            this.openedSubmenus = [];
        }

        closeMobileMenu(): void {
            this.close();
        }

        getIconPath(menuNode: HeaderMenuEntry): string | undefined {
            const fileName = "icon_header_" + menuNode.name + ".png";
            const path = ICONS_DIR_PATH + "/" + fileName;
            return path;
        }

        @Emit()
        close() {}
    }
