
    import Vue from "vue";
    import ItemTooltipLazyLoad from "@/components/items/ItemTooltipLazyLoad.vue";
    import { Component, Prop } from "vue-property-decorator";
    import type { ItemInstanceModelBase } from "@/cuties/model/item/ItemInstanceModel";
    import type ItemInstanceModel from "@/cuties/model/item/ItemInstanceModel";
    import PlayerEnvironment from "@/cuties/player/PlayerEnvironment";
    import VueUtils from "@/cuties/VueUtils";

    @Component({
        components: { ItemTooltipLazyLoad },
    })
    export default class ItemCardIconList extends Vue {

        @Prop({ required: true }) itemInstanceModel: ItemInstanceModel | ItemInstanceModelBase;
        @Prop({ default: false }) popupDisable;

        private tooltipVisible = false;

        openDelay = VueUtils.tooltipOpenDelay;
        closeDelay = VueUtils.tooltipCloseDelay;

        close(): void {
            this.$emit("close");
            const pop = this.$refs[`popover-${this.itemInstanceModel.schema.info.type}`] as any;
            if (pop && typeof pop.doClose === "function") {
                pop.doClose();
            }
        }

        get statusIcon() {
            let icon = "";
            if (this.itemInstanceModel.schema.unconfirmedUse) {
                icon = "/static/icons/status_unconfirmeduse.png";
            }

            if (!this.itemInstanceModel.schema.sellable) {
                icon = "/static/icons/personal.png";
            }

            if (this.itemInstanceModel.schema.broken) {
                icon = "/static/icons/broken.png";
            }

            return icon;
        }

        private getEnv(): typeof PlayerEnvironment {
            return PlayerEnvironment;
        }
    }
