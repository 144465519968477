import LoginManager from "./LoginManager";
import type BlockchainAccountManager from "@/cuties/BlockchainAttachManager";
import type { RealBlockchain } from "@/cuties/model/pet/BlockchainId";
import AttachEosAccountManager from "@/cuties/AttachEosAccountManager";
import AttachNeoAccountManager from "@/cuties/AttachNeoAccountManager";
import AttachTronAccountManager from "@/cuties/AttachTronAccountManager";
import AttachWeb3AccountManager from "@/cuties/AttachWeb3AccountManager";

const BLOCKCHAIN_TO_ACCOUNT_MANAGER: Record<RealBlockchain, BlockchainAccountManager> = {
    EOS: new AttachEosAccountManager(),
    TRON: new AttachTronAccountManager(),
    NEO3: new AttachNeoAccountManager(),
    ETHEREUM: new AttachWeb3AccountManager("ETHEREUM"),
    MATIC: new AttachWeb3AccountManager("MATIC"),
    HECO: new AttachWeb3AccountManager("HECO"),
    EMERALD: new AttachWeb3AccountManager("EMERALD"),
    CSC: new AttachWeb3AccountManager("CSC"),
    OKC: new AttachWeb3AccountManager("OKC"),
    BSC: new AttachWeb3AccountManager("BSC"),
};

export default class AttachAccountManager {
    public static attach(blockchain: RealBlockchain): void {
        const manager = BLOCKCHAIN_TO_ACCOUNT_MANAGER[blockchain];
        new LoginManager(
            manager.OpenPopupAttachAccount.bind(manager),
            null,
            null,
            true
        ).attach();
    }
}
