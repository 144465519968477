
    import VueUtils from "@/cuties/VueUtils";
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Emit, Prop } from "vue-property-decorator";
    import CutieListApiService from "@/app/cuties/list/CutieListApiService";
    import { DefaultCatch } from "catch-decorator-ts";
    import { defaultErrorHandler } from "@/common/utils/defaultErrorHandler";
    import type { AttributeModelData } from "cuties-client-components/types/api/Cutie";
    import CutieAttributesList from "cuties-client-components/components/CutieAttributesList.vue";

    @Component({
        components: {
            CutieAttributesList,
        },
    })
    export default class PossibleCutieAttributesPopup extends Vue {
        @Prop({ required: true }) visible!: boolean;

        loading = true;
        attributes: AttributeModelData[] = [];

        readonly isMobile = VueUtils.isMobile;

        @DefaultCatch(defaultErrorHandler)
        async created() {
            try {
                this.attributes = await CutieListApiService.cutieAttributeList();
            } finally {
                this.loading = false;
            }
        }

        get syncedVisible() {
            return this.visible;
        }

        /** @PropSync not available in 7.0.0 T_T */
        set syncedVisible(value: boolean) {
            this.$emit("update:visible", value);
        }

        @Emit()
        select(tag: AttributeModelData) {
            return tag;
        }

        /** all possible cutie attributes grouped by icon type */
        private get_pet_attributes_grouped(): Record<string, AttributeModelData[]> {
            const result: Record<string, AttributeModelData[]> = {};
            for (const attr of this.attributes) {
                if (!result[attr.type]) {
                    result[attr.type] = [];
                }
                result[attr.type].push(attr);
            }

            return result;
        }

        get attributesGroupList(): { name: string; list: AttributeModelData[] }[] {
            const groups = this.get_pet_attributes_grouped();
            const { "unique": uniqueGroup, "rarity-fancy": fancyGroup, ...rest } = groups;
            const records = Object.entries(rest).map(([type, list]) => ({ type, list }));
            // put lengthy unique/tribute attributes in the end
            records.push({ type: "unique", list: uniqueGroup });
            records.push({ type: "rarity-fancy", list: fancyGroup });

            const result: { name: string; list: AttributeModelData[] }[] = [];
            for (const { type, list } of records) {
                const name = this.$t("attribute_type_name_" + type) + "";
                result.push({ name, list });
            }

            return result;
        }
    }
