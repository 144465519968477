import type {
    InteractiveFightsStateResponse,
    InteractiveFightTurnView
} from "@/app/cuties/interactive/InteractiveFightsApiService";
import { getSoundLayerAudioPlayer } from "../utils/SoundLayer";

const SOUND_EVENTS = [
    "NEW_TURN_STRONGER", "NEW_TURN_WEAKER", "NEW_TURN_EVEN",
    "OPPONENT_ACTED", "OPPONENT_JOINED", "VICTORY", "DEFEAT",
    "MEOW", "MOVEMENT", "DESTRUCTIBLE_HIT", "NPC_HIT",
];

export type SoundEvent = (typeof SOUND_EVENTS)[number];

export function shouldPlaySound(
    oldState: InteractiveFightsStateResponse | null,
    newState: InteractiveFightsStateResponse,
    turnsHistory: InteractiveFightTurnView[]
): SoundEvent | null {
    if (!oldState || newState.fighters.length < 2) {
        return null;
    }
    if (oldState.fighters.length !== newState.fighters.length) {
        return "OPPONENT_JOINED";
    }
    if (oldState.gameOverState !== newState.gameOverState) {
        if (newState.gameOverState === "REQUESTER_WON") {
            return "VICTORY";
        }
        if (newState.gameOverState === "OPPONENT_WON") {
            return "DEFEAT";
        }
    }
    const requesterIndex = oldState.fighters.findIndex(f => f.isRequester);
    if (oldState.turnNumber !== newState.turnNumber) {
        const lastTurn = turnsHistory.find(t => t.turnNumber === oldState.turnNumber);
        if (lastTurn && lastTurn.effects[requesterIndex].countering === "STRONGER") {
            return "NEW_TURN_STRONGER";
        } else if (lastTurn && lastTurn.effects[requesterIndex].countering === "WEAKER") {
            return "NEW_TURN_WEAKER";
        } else {
            return "NEW_TURN_EVEN";
        }
    }
    const oldOpponent = oldState.fighters.find(f => !f.isRequester)!;
    const newOpponent = newState.fighters.find(f => !f.isRequester)!;
    if (oldOpponent.hasNextAction !== newOpponent.hasNextAction) {
        return "OPPONENT_ACTED";
    }
    return null;
}

export function getAudioPlayer() {
    return getSoundLayerAudioPlayer("/static/interactive/sound_events/", SOUND_EVENTS);
}
