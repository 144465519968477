import http from "@/http";
import type { Blockchain } from "../model/pet/BlockchainId";
import type ResponseAdminEquipment from "@/cuties/admin/ResponseAdminEquipment";


export default class AdminEqupmentService {

    public static async getItems(level: number, status: string): Promise<ResponseAdminEquipment> {
        return http.get<any>("/admin/page/equipment", { params: { level, status } })
            .then(response => response.data);
    }

    public static async createItem(type: string, count: number, chain: boolean, level:number, blockchain: Blockchain ) {
        return http.get("/admin/create/item", { params: { type, count, chain, level, blockchain } })
            .then(response => response.data)
            .catch(e =>{
                return this.process_error(e);
            });
    }

    public static async createSet(type: string, count: number) {
        return http.get("/admin/create/item/set", { params :{ type, count } })
            .then(response => response.data)
            .catch(e =>{
                return this.process_error(e);
            });
    }


    public static process_error(e) {

        if(e && e.response.data.error ) return Promise.reject(e.response.data.error);
        else{
            return e;
        }
    }
}
