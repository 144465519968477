import { PLAYER_SINGLETON } from "@/cuties/model/player/Player";
import mad from "./engine/mad";
import LocationPath from "./engine/LocationPath";
import utils from "./utils";

// import all handlers, so they will be added to the js-build
import "./handler/common";
import "./handler/forms";
import "./handler/ethereum";
import "./handler/blockchains";
import "./handler/tron";
import "./handler/tooltip";
import "./handler/admin";

import analytics from "./analytics";
import $ from "jquery";
import DynamicConfigurationSubscription from "@/subscription/DynamicConfigurationSubscription";
import store from "@/store";
import BigNumber from "bignumber.js";
import Vue from "vue";
import VueUtils, { globalJsErrorHandler, globalUnhandledRejectionHandler, globalVueErrorHandler } from "./VueUtils";
import UserApiService from "./player/UserApiService";
import { HANDLER_MANAGER_SINGLETON } from "@/cuties/engine/HandlerManager";
import { TEMPLATE_MANAGER_SINGLETON } from "@/cuties/engine/TemplateManager";
import { ConfigInstance } from "@/cuties/engine/Config";
import PageInit from "@/cuties/PageInit";
import { AppStoreMutations } from "@/store/AppStore";
import ConfigApiService from "@/app/cuties/engine/ConfigApiService";
import LotteryService from "@/cuties/lottery/LotteryService";
import { currentNavigation } from "@/router";
import type { Route } from "vue-router/types/router";
import LoginService from "@/cuties/model/player/LoginService";

/**
 * Almost never return exponential notation for BigNumber:
 * For example when using .toString()
 * https://mikemcl.github.io/bignumber.js/#exponential-at
 */
BigNumber.config({ EXPONENTIAL_AT: 1e+9 });

Vue.config.errorHandler = globalVueErrorHandler;
window.addEventListener("error", globalJsErrorHandler);
window.addEventListener("unhandledrejection", globalUnhandledRejectionHandler);

function* getWithParents(element: HTMLElement): Iterable<HTMLElement> {
    yield element;
    if (element.parentElement) {
        yield * getWithParents(element.parentElement);
    }
}

function isVueRouterLink(element: HTMLElement) {
    return element.tagName === "A"
        && element.getAttribute("href")?.startsWith("/");
}

function reloadsOnItsOwn(to: Route, from: Route) {
    return to.name !== from.name
        || !to.name && !from.name
        && to.path !== from.path;
}

function isForceRouteReloadNeeded() {
    return !currentNavigation
        || !reloadsOnItsOwn(currentNavigation.to, currentNavigation.from);
}

window.addEventListener("click", (event: MouseEvent) => {
    if (event.altKey || event.shiftKey || event.metaKey || event.ctrlKey) {
        return; // do not reload current page on ctrl+click
    }

    if (!isForceRouteReloadNeeded()) {
        // do not conflict with vue router: if there is an active
        // navigation process already, that means vue reacted on it's own
        return;
    }

    for (const element of getWithParents(event.target as HTMLElement)) {
        if (isVueRouterLink(element)) {
            store.commit(AppStoreMutations.INCREMENT_NAVIGATION_ITERATION);
        }
    }
});

addEventListener("popstate", (event) => {
    // setTimeout needed to let vue comprehend the route update first
    // before force reload is initiated, as otherwise this.$route.query
    setTimeout(() => {
        // trigger full component re-creation on navigation using browser back/forward buttons, as
        // otherwise when you change filters and hit back, address changes, but content stays same
        if (isForceRouteReloadNeeded()) {
            store.commit(AppStoreMutations.INCREMENT_NAVIGATION_ITERATION);
        }
    }, 0);
});

const whenAuthToken = UserApiService.authorizeTokenSession().catch(exc => {
    const errorData = VueUtils.extractErrorData(exc);
    if (!errorData || errorData.error !== "errorGameSessionUnknown") {
        VueUtils.handleError(exc);
    }
    return null;
});

const whenConstConfig = ConfigApiService.getConstConfigWebData();
const whenDynamicConfig = ConfigApiService.getDynamicConfigWebData();
const whenPackSource = TEMPLATE_MANAGER_SINGLETON.fetch_pack_source("main");

function trim_special(str:string, characters:string) {
    const c_array = characters.split("");
    let result  = "";

    for (let i = 0; i < characters.length; i++)
        result += "\\" + c_array[i];

    return str.replace(new RegExp("^[" + result + "]+|[" + result + "]+$", "g"), "");
}

$(document).ready(async function() {
    // configure RequestManager and link/form handlers
    utils.init_referrer();

    const current_location = LocationPath.create(window.location);
    const current_url = trim_special(current_location.get_base(), "/");

    mad.request.set_server_base_url(current_url + "/rest/1", current_url);
    mad.request.init();

    let configs_are_valid: boolean;
    let constConfig;
    let dynamicConfig;
    try {
        constConfig = await whenConstConfig;
        dynamicConfig = await whenDynamicConfig;
        configs_are_valid = true;
    } catch (error) {
        if (error.message) {
            error.message = "Failed to initialize configuration - " + error.message;
        }
        VueUtils.handleError(error);
        constConfig = null;
        dynamicConfig = null;
        configs_are_valid = false;
    }

    if (configs_are_valid) {
        // init config and translations
        ConfigInstance.init(constConfig!, dynamicConfig!);

        DynamicConfigurationSubscription.subscribe();
    }

    // register templates
    // templates removed from index
    TEMPLATE_MANAGER_SINGLETON.register_templates_by_selector("#templates > script, #partials > script", true);

    // TEMPLATE_MANAGER_SINGLETON.show() will add template to this selector (by default)
    TEMPLATE_MANAGER_SINGLETON.set_page_selector("#page");

    $("body").keyup(function(e) {
        if(e.which == 27) {
            if ($("#popup").find(".popupCloseCross, .close-popup-times").length ) {
                e.preventDefault();
                HANDLER_MANAGER_SINGLETON.run("popup_hide");
            }
        }
    });

    if (!configs_are_valid) {
        console.error("Wrong client configuration!");
        $(".loading_ani").fadeToggle(400);
        utils.page_error_no_config();
        return;
    }

    const packSource = await whenPackSource;
    TEMPLATE_MANAGER_SINGLETON.set_fetched_pack("main", packSource);

    $(".loading_ani").fadeToggle(400);
    const authData = await whenAuthToken;

    if (authData) {
        LoginService.initializeAuthorizedUser(authData.user);
        LotteryService.onTokenAuth();
    }
    store.commit(AppStoreMutations.SET_CONFIG_LOADED, true);
    // route currently opened URL

    // some additional logic
    analytics.gtag_log_page_navigation(PLAYER_SINGLETON);
    PageInit.init_page();
});
