import type { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import type { RootState } from "@/store/RootState";
import TokensService from "@/cuties/items/TokensService";
import token1155 from "@/cuties/model/token1155";
import ItemService from "@/cuties/items/ItemService";
import type ItemStackModel from "@/cuties/model/item/ItemStackModel";
import type { ResponseInventory } from "@/cuties/model/inventory/ResponseInventory";
import type { RequestInventory } from "@/cuties/model/inventory/RequestInventory";
import type { InventoryItemsFilters } from "@/cuties/model/inventory/InventoryItemsFilters";
import { InventoryItemsFilter } from "@/cuties/model/inventory/InventoryItemsFilters";

export interface InventoryState {
    tokens: token1155[];
    items: ItemStackModel[];
    itemCount: number;
    filters: InventoryItemsFilters;
    filter: InventoryItemsFilter;
    search: string;
}

export const state: InventoryState = {
    tokens: undefined,
    items: undefined,
    itemCount: 0,
    filters: null,
    filter: null,
    search: "",
};

export const getters: GetterTree<InventoryState, RootState> = {
    getTokens: state => state.tokens,
    getInventoryItems: state => state.items,
    getInventoryItemsCount: state => state.itemCount,
    getInventorySearch: state => state.search,
};

export const mutations: MutationTree<InventoryState> = {
    setTokens: (state, payload) => state.tokens = payload,
    setInventoryItems: (state, payload) => state.items = payload,
    setInventoryItemsCount: (state, payload) => state.itemCount = payload,
    setFilters: (state, payload) => state.filters = payload,
    setInvetorySearch: (state, payload) => state.search = payload,
    setFilter: (state, payload) => state.filter = new InventoryItemsFilter(payload),
};

export const actions: ActionTree<InventoryState, RootState> = {
    loadToken1155Inventory: async ({ commit }) => {
        const response = await TokensService.getTokens();

        const tokens = [];
        for (let index = 0; index < response.tokens.length; index++) {
            tokens.push(new token1155(response.tokens[index]));

        }

        commit("setTokens", tokens);
        return true;
    },
    loadInventoryItems: async ({ commit }, payload: RequestInventory) => {
        const response: ResponseInventory = await ItemService.getInventoryItems(payload);

        const items: ItemStackModel[] = [];
        for (let index = 0; index < response.items.length; index++) {
            items.push(response.items[index]);
        }

        commit("setInventoryItems", items);
        commit("setInventoryItemsCount", response.itemCount);
        commit("setFilters", response.filters);
        commit("setFilter", response.filter);
        return true;
    }
};

class InventoryStore implements Module<InventoryState, RootState> {
    public namespaced: boolean = false;
    public state: InventoryState = state;
    public getters: GetterTree<InventoryState, RootState> = getters;
    public mutations: MutationTree<InventoryState> = mutations;
    public actions: ActionTree<InventoryState, RootState> = actions;
}

// return singleton
export const InventoryStoreSingleton = new InventoryStore();
