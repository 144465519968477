import SaleEntryModel from "@/cuties/model/sale/SaleEntryModel";
import type { SaleEntryGroupModel as SaleEntryGroupModelData } from "@/app/cuties/presale/PresaleCutie";

export default class SaleEntryGroupModel2 {

    private readonly _title : string;
    private readonly _entries : SaleEntryModel[] = [];

    constructor(data: SaleEntryGroupModelData) {
        this._title = data.title;

        for (const entry of data.entries || []) {
            this._entries.push(new SaleEntryModel(entry));
        }
    }

    get title(): string {
        return this._title;
    }

    get entries(): SaleEntryModel[] {
        return this._entries;
    }
}
