import type { DirectiveBinding } from "vue/types/options";

// examples:
// <a v-button >Link text</a>
// <a v-button="{ type: 'outlined' }" >Link text</a>
// <a v-button="{ type: 'outlined', size: 'extra-small' }" >Link text</a>
export const button = {
    bind(el: HTMLElement, { value }: DirectiveBinding = null): void {
        el.classList.add("glb-button");

        if (!value || !value?.type) {
            el.classList.add("glb-button--solid");
        }

        if (value?.type) {
            el.classList.add(`glb-button--${value.type}`);
        }

        if (value?.size) {
            el.classList.add(`glb-button--${value.size}`);
        }

        if (typeof value === "string") {
            el.classList.add(`glb-button--${value}`);
        }
    },
};
