import { TEMPLATE_MANAGER_SINGLETON } from "@/cuties/engine/TemplateManager";
import type Template from "@/cuties/engine/Template";

export default class PopupManager {

    async show(templateId:string, data:any, toTop:boolean = false, onShow: Function | null = null): Promise<Template> {
        const template = await TEMPLATE_MANAGER_SINGLETON.show(templateId, data, "#popup", toTop);

            $("body").addClass("body-popup-open");

            if( toTop ) {
                $("body").addClass("body-popup-open-top");
            }

            if (onShow != null)
                onShow();

            $("#popup").fadeIn("fast");

        return template;
    };

    hide() {
        $("#popup").fadeOut("fast", function() {
            $("body").removeClass("body-popup-open");
            $("body").removeClass("body-popup-open-top");
        });
    }

}
