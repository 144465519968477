export enum FiatCurrency {
    USD = "USD",
    EUR = "EUR",
    RUB = "RUB",
    CNY = "CNY",
}

export const Blockchain = {
    Offchain: "OFFCHAIN",
    Ethereum: "ETHEREUM",
    Eos: "EOS",
    Tron: "TRON",
    Neo: "NEO",
    Neo3: "NEO3",
    Matic: "MATIC",
    Heco: "HECO",
    Emerald: "EMERALD",
    Csc: "CSC",
    Okc: "OKC",
    Bsc: "BSC",
} as const;

export type Blockchain = (typeof Blockchain)[keyof typeof Blockchain];

export type RealBlockchain = Exclude<(typeof Blockchain)[keyof typeof Blockchain], "OFFCHAIN" | "NEO">;

export type AltCurrency = "CUTE_COIN" | "PAW_COIN" | "BCUG";

export type Web3Blockchain = "ETHEREUM" | "MATIC" | "HECO" | "EMERALD" | "CSC" | "OKC" | "BSC";

/** @throws {Error} */
export function assertRealBlockchain(realOrFakeBlockchain: Blockchain): RealBlockchain {
    if (realOrFakeBlockchain === "OFFCHAIN" || realOrFakeBlockchain === "NEO") {
        throw new Error("Tried to use " + realOrFakeBlockchain + " as a live blockchain");
    } else {
        return realOrFakeBlockchain;
    }
}

export function getAllBlockchains(): Blockchain[] {
    return Object.values(Blockchain);
}

export const blockchainCurrencyMap: Map<Blockchain, string> = new Map([
    [Blockchain.Ethereum, "ETH"],
    [Blockchain.Eos, "EOS"],
    [Blockchain.Tron, "TRX"],
    [Blockchain.Neo3, "NEO3GAS"],
    [Blockchain.Matic, "MATIC"],
    [Blockchain.Heco, "HT"],
    [Blockchain.Emerald, "ROSE"],
    [Blockchain.Csc, "CET"],
    [Blockchain.Okc, "OKT"],
    [Blockchain.Bsc, "BNB"],
]);

export const currencyToBlockchainMap: Map<string, Blockchain> = new Map([
    ["ETH", Blockchain.Ethereum],
    ["EOS", Blockchain.Eos],
    ["TRX", Blockchain.Tron],
    ["GAS (NEO)", Blockchain.Neo3],
    ["GAS", Blockchain.Neo3],
    ["NEO3GAS", Blockchain.Neo3],
    ["NEO", Blockchain.Neo3],
    ["MATIC", Blockchain.Matic],
    ["HT", Blockchain.Heco],
    ["ROSE", Blockchain.Emerald],
    ["CET", Blockchain.Csc],
    ["OKT", Blockchain.Okc],
    ["BNB", Blockchain.Bsc],
]);

export function getBlockchainCurrency(blockchain: Blockchain): string {
    return blockchainCurrencyMap.get(blockchain);
}

export const NATIVE_CHAIN = 0;

export function getChainId(blockchain: Blockchain): number {
    switch (blockchain) {
        case Blockchain.Ethereum:
            return 1;
        case Blockchain.Eos:
            return 2;
        case Blockchain.Tron:
            return 3;
        case Blockchain.Neo:
            return 4;
        case Blockchain.Matic:
            return 5;
        case Blockchain.Heco:
            return 6;
        case Blockchain.Neo3:
            return 7;
        case Blockchain.Emerald:
            return 8;
        case Blockchain.Csc:
            return 9;
        case Blockchain.Okc:
            return 10;
        case Blockchain.Bsc:
            return 11;
        default:
            throw new Error("Unsupported blockchain");
    }
}

export default class BlockchainId {
    static readonly DELIMITER = ":";
    public blockchain: Blockchain;
    private token_id: number;

    constructor(data?: any) {
        // data may be absent, if the pet is, actually a "bot" - temporarily generated image of a pet.
        if (data) {
            this.token_id = data.tokenId;
            this.blockchain = data.blockchain;
        }
    }

    public get_currency(): string {
        return blockchainCurrencyMap.get(this.blockchain);
    }

    get_blockchain(): Blockchain {
        return this.blockchain;
    }

    get_token_id(): number {
        return this.token_id;
    }

    public toString = (): string => {
        return `${this.blockchain}${BlockchainId.DELIMITER}${this.token_id}`;
    };
}
