import type { Blockchain } from "@/cuties/model/pet/BlockchainId";
import http from "@/http";
import type { EthereumConfig, EthereumContractAddresses, EthereumContractsAbi } from "@/cuties/blockchain/ethereum/EthereumContractAddresses";
import type { CallAbiContract, CallContract, CallParams, TransactionObject } from "@/cuties/blockchain/matic/Web3Provider";
import type Web3 from "web3";
import type { WalletAddress } from "@/components/LoginManager/TypeDefs";
import { ConfigInstance } from "@/cuties/engine/Config";

export default class Web3ContractRegistry {
    private readonly web3: Web3;
    private readonly blockchain: Blockchain;
    private static whenAbi: Promise<EthereumContractsAbi> | null = null;

    constructor(web3: Web3, blockchain: Blockchain) {
        this.web3 = web3;
        this.blockchain = blockchain;
    }

    private async getConfig(): Promise<EthereumConfig> {
        const config = (ConfigInstance.ethereums || {})[this.blockchain] || null;
        if (!config || Object.keys(config).length === 0) {
            throw new Error(this.blockchain + " config is not available");
        } else {
            return config;
        }
    }

    private static getAbi(): Promise<EthereumContractsAbi> {
        if (this.whenAbi === null) {
            const url = `static/abi/ethereum-abi.json?ver=${process.env.VUE_APP_VERSION}`;
            this.whenAbi = http.get<EthereumContractsAbi>(url, { baseURL: "/" }).then((rs) => rs.data);
        }
        return this.whenAbi;
    }

    private async getContractAddress(name: keyof EthereumContractAddresses): Promise<WalletAddress> {
        const config = await this.getConfig();
        const contracts = config.contracts || null;
        if (!contracts || Object.keys(contracts).length === 0) {
            throw new Error("Contract addresses were not supplied in config");
        }
        const contractAddress = contracts[name] || null;
        if (!contractAddress) {
            throw new Error(`Contract address for ${name} was not supplied in config`);
        } else {
            return contractAddress;
        }
    }

    private async getMethodCommonObj({
        contractName,
        methodName,
        methodArguments = [],
        whenContractAddress,
    }: {
        contractName: keyof EthereumContractsAbi;
        methodName: string;
        methodArguments?: unknown[];
        whenContractAddress: Promise<string>;
    }) {
        const [contractAddress, abi] = await Promise.all([
            whenContractAddress, Web3ContractRegistry.getAbi(),
        ]);
        const contract = new this.web3.eth.Contract(abi[contractName], contractAddress);
        return contract.methods[methodName](...methodArguments);
    }

    private async getMethodPredefinedObj(call: CallContract & {
        overrideContractAddress?: string;
    }): Promise<TransactionObject> {
        return this.getMethodCommonObj({
            ...call,
            whenContractAddress: call.overrideContractAddress
                ? Promise.resolve(call.overrideContractAddress)
                : this.getContractAddress(call.contractName),
        });
    }

    /**
     * `contract` and `address` is same thing as `contractName` and `overrideContractAddress`,
     * I think we want to use single params structure at some point, need to refactor usages
     */
    private async getMethodAbiObj(call: CallAbiContract): Promise<TransactionObject> {
        return this.getMethodCommonObj({
            ...call,
            contractName: call.contract,
            whenContractAddress: Promise.resolve(call.address),
        });
    }

    public async getMethodObj(callParams: CallParams): Promise<TransactionObject> {
        // todo temporary workaround to call different contract addresses with known abi
        // eslint-disable-next-line prettier/prettier
        return "contractName" in callParams
            ? await this.getMethodPredefinedObj(callParams)
            : await this.getMethodAbiObj(callParams);
    }
}
