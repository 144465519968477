import { bcuGetApi, getStateless, makeHttp } from "@/http";
import qs from "qs";
import type {
    AdventureTemplate,
    LocationCooldownTemplate,
    PetModelData,
    ElementKind
} from "@/cuties/model/pet/PetModelData";
import type { CutieListFilterRequestBase, FilteredCutiesListResponse, AuctionType } from "@/app/cuties/tournament/TournamentService";
import type { AuctionInterface } from "@/cuties/pet/PetService";
import type { OccupationModel } from "@/cuties/model/pet/OccupationModel";
import type { Page } from "@/cuties/model/misc/Page";
import type { PetKind, PetNobility, PetRarityKind } from "@/cuties/model/pet/PetModel";
import type { Filter } from "@/Filter";
import type { Blockchain } from "@/cuties/model/pet/BlockchainId";
import type { AttributeModelData, CutieListEntryMinimalView } from "cuties-client-components/types/api/Cutie";

const http = makeHttp({ baseUrl: "/rest" });

let whenCutieAttributeList: Promise<AttributeModelData[]> | null = null;
let whenCutieAttributeMap: Promise<Record<string, AttributeModelData>> | null = null;

/** a mapping to CutieListController.java */
export default {
    ...bcuGetApi.cutie.list,

    async listAllAsc(params: { minId: number; filterState?: CutieFilterState<string | number> | null }): Promise<CutieListAllResponse> {
        return http.post("/public/cutie/list/all/asc", params).then(response => response.data);
    },
    async listAllDesc(params: { maxId: number | null | undefined; filterState: CutieFilterState<string | number> | null }): Promise<CutieListAllResponse> {
        return http.post("/public/cutie/list/all/desc", params).then(response => response.data);
    },
    async listMy(params: CutieListMyRequest): Promise<FilteredCutiesListResponse<CutieListEntryMinimalView>> {
        return http.post("/cutie/list/my", params).then(rs => rs.data);
    },
    async getCutiePageStates(ids: number[]): Promise<CutieStates[]> {
        if (ids.length === 0) {
            return [];
        }
        return http.get("/public/cutie/page/states", {
            params: { ids },
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        }).then(rs => rs.data);
    },
    async listMyCount(params: CutieListMyCriteria): Promise<{ count: number }> {
        return http.post("/cutie/list/my/count", params).then(rs => rs.data);
    },
    async listRaidV2Applicable(params: CutieListRaidV2ApplicableRequest): Promise<FilteredCutiesListResponse<CutieListEntryMinimalView>> {
        return http.post("/cutie/list/raid/v2/applicable", params).then(rs => rs.data);
    },
    async listAdventureApplicable(params: { page: number; name: string }): Promise<Page<PetModelData>> {
        return http.get("/cutie/list/adventure/applicable", { params }).then(rs => rs.data);
    },
    async cutieAttributeList(): Promise<AttributeModelData[]> {
        if (!whenCutieAttributeList) {
            whenCutieAttributeList = getStateless("/public/cutie/attribute/list")
                .then(response => response.data);
        }
        return whenCutieAttributeList;
    },
    async cutieAttributeMap(): Promise<Record<string, AttributeModelData>> {
        if (!whenCutieAttributeMap) {
            whenCutieAttributeMap = this.cutieAttributeList()
                .then(records => Object.fromEntries(
                    records.map(r => [r.srcName, r] as const)
                ));
        }
        return whenCutieAttributeMap;
    }
};

interface CutieListAllResponse {
    /**
     * when it's greater than the number of entries
     * returned, that means we reached the end of list
     */
    pageSize: number;
    filter: PetFilter;
    filterOptions: PetFilterOptions;
    cuties: CutieListAllEntry[];
}

export interface CutieListAllEntry extends CutieListEntryMinimalView {
    /**
     * not returned by server, but rather added manually on client for entries from previous
     * page that were added to fill remainder space when last page returns less than 16 cuties
     */
    remainderIndicator?: boolean;
}

export type Pagination = {
    direction: "ASC";
    minId: number;
} | {
    direction: "DESC";
    maxId?: number | null;
};

export type IdPagedQueryData = CutieFilterState<string | number> & {
    direction: Pagination["direction"];
    minId?: number | string;
    maxId?: number | string | null;
};

export interface CutieListRaidV2ApplicableRequest extends CutieListFilterRequestBase {
    raidHashId: string;
    page: number;
}

export interface CutieListMyCriteria {
    filter?: CutieFilterState<string | number>;
    ignoreGroups?: boolean;
    groupId?: number | null;
    auctionType?: AuctionType[] | null;
}

export interface CutieListMyRequest extends CutieListFilterRequestBase, CutieListMyCriteria {
    page: number;
}


export interface CutieStates {
    id: number;
    auction?: AuctionInterface;
    occupation: OccupationModel;
    adventure?: AdventureTemplate;
    locationCooldowns: LocationCooldownTemplate[];
}

/** @see PetFilterOptions.java */
export interface PetFilterOptions {
    blockchain: Filter<Blockchain>;
    kind: Filter<keyof typeof PetKind>;
    nobility: Filter<keyof typeof PetNobility>;
    rarity: Filter<keyof typeof PetRarityKind>;
    element: Filter<ElementKind>;
    generation: number;
    level: { min: number; max: number };
}

/**
 * pass <number> for reliable data coming for server or
 * <number | string> for data coming from querystring
 */
export interface CutieFilterState<TNumber extends number | string> {
    blockchain?: Blockchain;
    nameCondition?: "DEFINED" | "ANY";
    kind?: keyof typeof PetKind;
    nobility?: keyof typeof PetNobility;
    rarity?: keyof typeof PetRarityKind;
    level?: {
        min: TNumber;
        max: TNumber;
    };
    generation?: TNumber;
    element?: { [k in ElementKind]?: TNumber };
    /** note, case-sensitive names expected here */
    attributes?: string[];
}

/** @see PetFilter.java */
export type PetFilter = CutieFilterState<number>;

export interface Range {
    min?: number;
    max?: number;
}
