
    import { Component, Vue } from "vue-property-decorator";
    import Cookies from "js-cookie";

    @Component({})
    export default class GdprNotice extends Vue {
        gdprNoticeDisble: boolean = false;
        cookieName: string = "GdprNoticeDisable";

        mounted() {
            this.gdprNoticeDisble = !!Cookies.get(this.cookieName);
        }

        disableWarning() {
            this.gdprNoticeDisble = true;
            Cookies.set(this.cookieName, "agree", { expires: 365 * 10 });
        }
    }
