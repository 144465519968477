import DataHelpers from "@/cuties/engine/DataHelpers";
import Money from "../../engine/Money";
import type { Blockchain } from "./BlockchainId";
import BigNumber from "bignumber.js";
import type { AuctionInterface } from "@/cuties/pet/PetService";

export enum AuctionKind {
    None = 0,
    Sell = 1,
    Breed = 2,
    NoAuction = 3,
    Item = 4,
}

export class AuctionModel {

    private auction_id:number;
    private auction_kind:AuctionKind;

    private blockchain: Blockchain;
    public readonly price_start:Money;
    public readonly price_end:Money;
    private featured:boolean;

    /**
     * (timestamp) time start (in seconds)
     */
    private time_start:number;

    /**
     * duration (in seconds)
     */
    public readonly duration:number;
    private contract:string;
    private allowedTokens: string[] | null;

    constructor(data: AuctionInterface) {
        this.auction_id = DataHelpers.toInt(data.id);
        this.auction_kind = DataHelpers.to_enum_value(AuctionKind, data.type, AuctionKind.Sell);

        //d("AuctionKind: " + data.type + " => " + this.auction_kind);

        this.blockchain = data.blockchain;
        this.price_start = new Money(data.priceStart);
        this.price_end    = new Money(data.priceEnd);

        this.time_start    = DataHelpers.toInt(data.timeStart);
        this.duration    = DataHelpers.toInt(data.duration);
        this.featured    = DataHelpers.toBoolean(data.featured);
        this.contract    = data.contract;
        this.allowedTokens    = data.allowedTokens;

        // _cooldown: I18nHelpers.translate('breeding_cooldown'+this.breeding_cooldown),
    }

    get_time_start(): number {
        return this.time_start;
    }

    get_time_end(): number {
        return this.time_start + this.duration;
    }

    is_actual(): boolean {
        const now = DataHelpers.get_time_seconds();
        if (this.auction_kind == AuctionKind.Breed || this.auction_kind == AuctionKind.Sell) {
            return true;
        } else {
            return /* now >= this.time_start && */ now <= this.time_start + this.duration;
        }
    }

    get_price_now(): Money {
        if (!this.is_actual())
            return new Money(0);

        const time_start = this.get_time_start();
        const time_end = this.get_time_end();

        let time_now = DataHelpers.get_time_seconds();

        if (this.auction_kind == AuctionKind.Breed || this.auction_kind == AuctionKind.Sell) {
            if (time_now >= time_end)     // Pet's action continues
                return new Money(this.price_end);
        }

        if( this.price_end.getAmountRaw().isGreaterThan(this.price_start.getAmountRaw()) ) {
            time_now += 300; // if price goes up - make 5 min price save amount
        }

        return AuctionModel.interpolate_price(
            time_start, time_end,
            new Money(this.price_start), new Money(this.price_end),
            time_now
        );
    }

    // price is always in base of blockchain - WEI, SUN and etc - it is must be without decimals
    static interpolate_price(time_start: number, time_end: number, price_start: Money, price_end: Money, timeNow: number): Money {
        /*
            (price - price0) / (time - time0) = (price1 - price0) / (time1 - time0)
            c = (price1 - price0) / (time1 - time0)
            price - price0 = c * (time - time0)
            price = price0 + c * (time - time0)
        */

        if (timeNow >= time_end) {
            return new Money(price_end);
        }

        const time_diff = time_end - time_start;
        if (time_diff == 0) {
            DataHelpers.error("AuctionModel.interpolate_price: time_diff is 0");
            return new Money(price_end);
        }

        const c = new BigNumber(price_end.getAmountRaw().minus(price_start.getAmountRaw())).div(time_diff).times(timeNow - time_start);
        price_start.addBigNumber(c);
        // interpolation is in wei - so it is always will be number without decimals
        return new Money(price_start.getAmountRaw().toFixed(0, BigNumber.ROUND_UP));
    }
}
