
    import type { Player } from "@/cuties/model/player/Player";

    import { Component, Prop, Vue } from "vue-property-decorator";
    import { Blockchain } from "@/cuties/model/pet/BlockchainId";
    import type Web3CutiesApi from "@/cuties/blockchain/matic/Web3CutiesApi";
    import type { OnAuthorizeCancel, OnAuthorizeSuccess } from "@/cuties/LoginManager";
    import PopupManager from "../cuties/engine/PopupManager";
    import LoginService from "@/cuties/model/player/LoginService";
    import utils from "@/cuties/utils";
    import type { WalletProviderKind } from "@/components/LoginManager/TypeDefs";
    import type { Web3Provider } from "@/cuties/blockchain/matic/Web3Provider";
    import type BlockchainService from "@/cuties/blockchain/BlockchainService";
    import type { AuthProvider } from "@/cuties/blockchain/types";
    import type { NeoProvider } from "@/cuties/blockchain/neo/NeoProvider";
    import CutiesApiFaucet from "@/app/cuties/blockchain/CutiesApiFaucet";
    import { Getter } from "vuex-class";
    import LoginManagerEmail from "@/components/LoginManager/LoginManagerEmail.vue";
    import { defineAsyncComponent } from "vue";
    import PleaseWaitLoadingData from "@/app/components/app-components/PleaseWaitLoadingData.vue";

    const LoginManagerEth = defineAsyncComponent({
        loader: () => import(/* webpackChunkName: "LoginManagerEth" */ "@/components/LoginManager/LoginManagerEth.vue"),
        loadingComponent: PleaseWaitLoadingData,
    });
    const LoginManagerTron = defineAsyncComponent({
        loader: () => import(/* webpackChunkName: "LoginManagerTron" */ "@/components/LoginManager/LoginManagerTron.vue"),
        loadingComponent: PleaseWaitLoadingData,
    });
    const LoginManagerEos = defineAsyncComponent({
        loader: () => import(/* webpackChunkName: "LoginManagerEos" */ "@/components/LoginManager/LoginManagerEos.vue"),
        loadingComponent: PleaseWaitLoadingData,
    });
    const LoginManagerNeo = defineAsyncComponent({
        loader: () => import(/* webpackChunkName: "LoginManagerNeo" */ "@/components/LoginManager/LoginManagerNeo.vue"),
        loadingComponent: PleaseWaitLoadingData,
    });
    const LoginManagerWeb3 = defineAsyncComponent({
        loader: () => import(/* webpackChunkName: "LoginManagerWeb3" */ "@/components/LoginManager/LoginManagerWeb3.vue"),
        loadingComponent: PleaseWaitLoadingData,
    });

    type State = "LoginManagerEmail" | "LoginManagerTron" | "LoginManagerEos" | "LoginManagerNeo" | Blockchain;

    @Component({
        components: {
            LoginManagerEmail,
            LoginManagerEth,
            LoginManagerTron,
            LoginManagerEos,
            LoginManagerNeo,
            LoginManagerWeb3,
        },
    })
    export default class LoginManagerVue extends Vue {
        @Prop({ type: Boolean, default: true }) readonly showClose: boolean;
        @Prop() callback: OnAuthorizeSuccess;
        @Prop() onClose: OnAuthorizeCancel;
        @Prop() bus;
        @Prop() allowedBlockchains: Blockchain[];
        @Prop() unlock: boolean;
        @Prop() token: string;
        @Getter("getPlayer") user!: Player;

        dataLoaded = false;
        back_handler = "";

        state: State | null = null;
        tab = "";

        created(): void {
            switch (this.allowedBlockchains[0]) {
                case Blockchain.Offchain:
                    this.switch_network_email();
                    break;
                case Blockchain.Eos:
                    this.switch_network_eos();
                    break;
                case Blockchain.Tron:
                    this.switch_network_tron();
                    break;
                case Blockchain.Neo3:
                    this.switch_network_neo();
                    break;
                case Blockchain.Ethereum:
                case Blockchain.Matic:
                case Blockchain.Heco:
                case Blockchain.Emerald:
                case Blockchain.Csc:
                case Blockchain.Okc:
                case Blockchain.Bsc:
                    this.switchNetworkTab(this.allowedBlockchains[0]);
                    break;
                default:
                    this.switch_network_email();
                    break;
            }
            this.dataLoaded = true;
            // this.$forceUpdate();
        }

        private isActiveState(state: State) {
            return state === this.state;
        }

        is_active(state: State): string {
            if (this.isActiveState(state)) {
                return "active";
            } else {
                return "";
            }
        }

        is_neo_allowed(): boolean {
            return this.allowedBlockchains.includes(Blockchain.Neo3);
        }

        is_eos_allowed(): boolean {
            return this.allowedBlockchains.includes(Blockchain.Eos);
        }

        is_tron_allowed(): boolean {
            return this.allowedBlockchains.includes(Blockchain.Tron);
        }

        isAllowed(blockchain: Blockchain): boolean {
            return this.allowedBlockchains.includes(blockchain);
        }

        is_mail_allowed(): boolean {
            return this.allowedBlockchains.includes(Blockchain.Offchain);
        }

        popup_hide(successfully = false): void {
            new PopupManager().hide();
            if ($("body").hasClass("bodyOverflow")) {
                $("body, html").removeClass("bodyOverflow");
            }
            if (!successfully && this.onClose) this.onClose();
        }

        /**
         * older blockchains performed redirect right inside the child component - I tried
         * to move such global stuff a level higher to be able to reuse login components without
         * side effects and generalize code general for all blockchains here
         *
         * maybe it could be moved even higher - to LoginManager.ts eventually...
         *
         * @param walletConfirmed - shall be called once provider is confirmed to belong to the player: either
         *  by signing a personal message or by matching wallet address to the one of currently logged user.
         *  This callback should set the provider as _current_ in the implementation singleton
         */
        private async callbackAuthProvider({ provider, kind, blockchain, walletConfirmed }: {
            provider: AuthProvider;
            kind: WalletProviderKind;
            blockchain: Blockchain;
            walletConfirmed: () => Promise<BlockchainService>;
        }) {
            const authorized = async () => {
                const wallet = await walletConfirmed();
                if (this.callback) {
                    try {
                        this.popup_hide(true);
                        await this.callback(wallet);
                    } catch (exc) {
                        utils.show_notification("Login callback failed - " + exc);
                        throw exc;
                    }
                }
            };
            const walletAddress = await provider.getWalletAddress();
            if (this.unlock) {
                const playerAddress = this.user.addressByBlockchain(blockchain);
                if (playerAddress && playerAddress.toLowerCase() !== walletAddress.toLowerCase()) {
                    const msg = this.$t("errorUnlockWalletAddressMismatch", {
                        0: playerAddress,
                        1: walletAddress,
                    }).toString();
                    throw new Error(msg);
                }
                await authorized();
            } else {
                const user = await LoginService.authorizeBySigningTerms({
                    blockchain: blockchain,
                    walletProvider: kind,
                    provider: provider,
                });
                LoginService.initializeAuthorizedUser(user);
                await authorized();
            }
        }

        async initializeProvider(blockchain: Blockchain, provider: AuthProvider, walletProviderKind: WalletProviderKind): Promise<void> {
            const walletConfirmed = async () => {
                const wallet = await CutiesApiFaucet.getApi(blockchain);

                if (!wallet["setWalletProvider"]) throw new Error("Unsupported blockchain implementation");

                (wallet as any).setWalletProvider(provider);
                return wallet;
            };
            return this.callbackAuthProvider({
                provider,
                kind: walletProviderKind,
                blockchain,
                walletConfirmed,
            });
        }

        async callbackNeoProvider(provider: NeoProvider, kind: WalletProviderKind) {
            const blockchain = Blockchain.Neo3;
            const walletConfirmed = async () => {
                const wallet = await CutiesApiFaucet.getNeo();
                wallet.setWalletProvider(provider);
                return wallet;
            };
            return this.callbackAuthProvider({
                provider,
                kind,
                blockchain,
                walletConfirmed,
            });
        }

        async callbackWeb3Provider(provider: Web3Provider, blockchain: Blockchain, kind: WalletProviderKind): Promise<void> {
            const walletConfirmed = async () => {
                const wallet = await CutiesApiFaucet.getApi(blockchain);
                (wallet as Web3CutiesApi).setWalletProvider(provider);
                return wallet;
            };
            return this.callbackAuthProvider({
                provider,
                kind,
                blockchain,
                walletConfirmed,
            });
        }

        switch_network_email(): void {
            this.bus.$emit("email_tab_login_popup_show");
            this.state = "LoginManagerEmail";
        }

        switch_network_eos(): void {
            this.state = "LoginManagerEos";
            this.bus.$emit("eos_start_state");
        }

        switch_network_tron(): void {
            this.state = "LoginManagerTron";
            this.bus.$emit("tron_start_state");
        }

        switch_network_neo(): void {
            this.state = "LoginManagerNeo";
            this.bus.$emit("neo_start_state");
        }

        switchNetworkTab(blockchain: Blockchain): void {
            this.state = blockchain;
            this.bus.$emit("start_state");
        }
    }
