import socket from "@/socket";
import type { OnMessageFunction, OnRawMessageFunction, SubscriptionFunction } from "@/subscription/WebSocketSubscription";
import WebsocketSubscription from "@/subscription/WebSocketSubscription";
import type { DailyQuestDto } from "@/cuties/quest/QuestService";
import store from "@/store";

interface QuestMessage {
    quests: DailyQuestDto[];
    notification: boolean;
}

const subscribe: SubscriptionFunction = function (handler: OnRawMessageFunction) {
    return socket.subscribeUserOnly("/questNotifications", handler);
};

const onMessage: OnMessageFunction<QuestMessage> = function (message: QuestMessage) {
    store.commit("setQuestsNotification", message.notification ?? false);
    store.commit("setQuests", message.quests);
};

export default new WebsocketSubscription({
    subscribe,
    onMessage,
});
