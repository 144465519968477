
    import Vue from "vue";
    import Component from "vue-class-component";
    import type {
        ConversationState
    } from "@/app/components/interactive/login/InteractiveNewUserReception.vue";
    import InteractiveNewUserReception from "@/app/components/interactive/login/InteractiveNewUserReception.vue";
    import { getAudioPlayer } from "@/app/cuties/interactive/InteractiveFightsSoundLayer";
    import InteractiveFight from "@/app/components/interactive/InteractiveFight.vue";
    import InteractiveFightsFooter from "@/app/components/interactive/InteractiveFightsFooter.vue";
    import InteractiveFightsApiService from "@/app/cuties/interactive/InteractiveFightsApiService";
    import { getAllBlockchains } from "@/cuties/model/pet/BlockchainId";
    import BlockchainFeatures, { Feature } from "@/cuties/blockchain/BlockchainFeatures";
    import VueUtils from "@/cuties/VueUtils";
    import { Getter } from "vuex-class";
    import { AppStoreGetters } from "@/store/AppStore";
    import { Watch } from "vue-property-decorator";
    import utils from "@/cuties/utils";
    import LoginManagerVue from "@/views/LoginManagerVue.vue";

    let whenLoginManagerVue = null;
    function getLoginManagerVue() {
        if (whenLoginManagerVue === null) {
            whenLoginManagerVue = import(/* webpackChunkName: "LoginManagerVue" */ "@/views/LoginManagerVue.vue");
        }
        return whenLoginManagerVue;
    }
    const LoginManagerVueLazy = () => getLoginManagerVue();

    @Component({
        components: { LoginManagerVue, InteractiveFightsFooter, InteractiveFight, InteractiveNewUserReception }
    })
    export default class LoginPage extends Vue {
        @Getter("getIsLogined") isLogined: boolean | undefined;
        @Getter(AppStoreGetters.GET_EVERY_SECOND_TICK_TIME_MS) everySecondTickTimeMs: number;

        audioPlayer: null | ReturnType<typeof getAudioPlayer> = null;
        receptionState: ConversationState = "START";
        fighterId: null | string = null;
        fightMatchOverTime: number | null = null;
        loginFormVisible = false;

        loginManagerMounted!: (value?: void) => void;
        whenLoginManagerMounted!: Promise<void>;

        readonly isMobile = VueUtils.isMobile;

        created() {
            this.audioPlayer = getAudioPlayer();
            if (window.localStorage.getItem("LAST_READ_LOGIN_ADVERTISEMENT_ID")) {
                this.onNewState("IT_IS_GOOD_TO_BE_BACK");
            }
            this.whenLoginManagerMounted = new Promise(resolve => {
                this.loginManagerMounted = resolve;
            });
            // preload login form while user is talking with interactive reception character
            // getLoginManagerVue();
        }

        async onNewState(newState: ConversationState) {
            this.receptionState = newState;
            if (newState === "IT_IS_GOOD_TO_BE_BACK" ||
                newState === "SEE_YA"
            ) {
                if (this.isLogined) {
                    this.loggedIn();
                } else {
                    this.loginFormVisible = true;
                }
            } else if (newState === "LET_US_GO_TO_FIGHT_CLUB") {
                const hostedFight = await InteractiveFightsApiService.hostIntroductionFight();
                this.fighterId = hostedFight.fighterId;
            } else if (newState === "SURE") {
                utils.show_notification(this.$t("login_conversation_achievement_you_are_now_friends"));
            } else if (newState === "ABSOLUTELY_SURE") {
                utils.show_notification("❤ " + this.$t("login_conversation_achievement_you_are_now_friends"));
                await this.audioPlayer!.playSoundEvent("MEOW");
            }
            if (newState === "SEE_YA") {
                this.whenLoginManagerMounted.then(() => {
                    this.$emit("email_tab_signup_form_show");
                });
            }
        }

        async loggedIn() {
            await this.$router.replace({ path: "/" + this.$i18n.locale + "/pets_my" }).catch(() => {});
        }

        @Watch("everySecondTickTimeMs")
        async onSecondTick(now: number, old: number) {
            if (this.fightMatchOverTime !== null &&
                this.receptionState === "LET_US_GO_TO_FIGHT_CLUB"
            ) {
                // match is over, but user did not quit from the interactive fight yet
                const timePassed = Date.now() - this.fightMatchOverTime;
                if (timePassed > 6000) {
                    // quit fight automatically: it may not be obvious for user that he has to click "Quit"
                    this.onFightEnded();
                }
            }
        }

        onFightMatchOver() {
            this.fightMatchOverTime = Date.now();
        }

        onFightEnded() {
            this.receptionState = "FIGHT_ENDED";
        }

        get allowedBlockchains() {
            return getAllBlockchains().filter((bc) => {
                return BlockchainFeatures.is_enabled(Feature.Login, bc);
            });
        }

        get bus() {
            return this;
        }
    }
