
    import { Component, Prop, Vue } from "vue-property-decorator";
    import type { SubscriptionMinutes } from "@/cuties/request/crafting/CraftingApiService";
    import type { SubscriptionDefinition } from "@/cuties/player/UserApiService";
    import UserApiService from "@/cuties/player/UserApiService";

    @Component({
        components: {},
    })
    export default class PossessionIdentityBlockSubscriptionMinutes extends Vue {
        @Prop({ required: true }) identity!: SubscriptionMinutes;

        subsMap: Map<string, SubscriptionDefinition> | null = null;

        mounted(): void {
            UserApiService.subscriptionDefinitionMap().then((subsMap) => (this.subsMap = subsMap));
        }

        get effectId() {
            if (this.subsMap) {
                return this.subsMap.get(this.identity.subscriptionId)?.effects[0]?.type ?? null;
            } else {
                return null;
            }
        }
    }
