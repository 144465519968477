import { getStateless, makeHttp } from "@/http";

const http = makeHttp({ baseUrl: "/rest" });

/** a mapping to GlobalChatController.java */
export default class GlobalChatApiService {
    public static listRooms(): Promise<GlobalChatRoomListResponse> {
        return getStateless("/public/global/chat/room/list").then(rs => rs.data);
    }

    public static sendMessage(params: GlobalChatMessageSendRequest): Promise<GlobalChatMessageSendResponse> {
        return http.post("/global/chat/message/send", params).then(rs => rs.data);
    }

    public static listMessages(params: GlobalChatMessageListRequest): Promise<GlobalChatMessageListResponse> {
        return http.get("/global/chat/message/list", { params }).then(rs => rs.data);
    }

    public static getExactMessage(params: MessageActionRequest): Promise<MessageView> {
        return http.get("/global/chat/message/exact", { params }).then(rs => rs.data);
    }

    public static announceMessage(params: MessageActionRequest): Promise<void> {
        return http.post("/admin/global/chat/message/announce", params).then(rs => rs.data);
    }

    public static banMessageAuthor(params: GlobalChatMessageBanAuthorRequest): Promise<void> {
        return http.post("/admin/global/chat/message/ban/author", params).then(rs => rs.data);
    }

    public static deleteMessage(params: MessageActionRequest): Promise<void> {
        return http.post("/global/chat/message/delete", params).then(rs => rs.data);
    }
}

export type SystemRoom =
    | "TRADING"
    | "GENERAL_ENGLISH"
    | "GENERAL_PERSIAN"
    | "GENERAL_SPANISH"
    | "GENERAL_RUSSIAN"
    | "GENERAL_CHINESE"
    | "GENERAL_OTHERS"
    | string;

interface MessageActionRequest {
    messageId: number;
}

interface GlobalChatMessageBanAuthorRequest {
    messageId: number;
    banChattingMinutes: number;
}

interface GlobalChatRoomListResponse {
    rooms: RoomView[];
}

export interface RoomView {
    id: SystemRoom;
    /**
     * a BCP 47 code
     * @see https://datatracker.ietf.org/doc/html/rfc5646
     */
    language: string | "";
}

interface GlobalChatMessageSendRequest {
    room: SystemRoom;
    messageBody: string;
    replyToMessageId?: number;
}

interface GlobalChatMessageSendResponse {
    messageId: number;
}

/** @see TelegramUser.java */
export interface TelegramUser {
    id: number;
    displayName: string;
    /** ISO */
    firstMessageTime: string;
    totalMessages: number;
}

export interface MessageView {
    id: number;
    userId: number;
    userName: string;
    userSuffix: "REGULAR_USER" | "ADMIN";
    messageBody: string;
    time: string;
    replyToMessageId?: number;
    imageLink?: string;
    telegramUser?: TelegramUser;
    consideredCredible: boolean;
}

interface GlobalChatMessageListRequest {
    room: SystemRoom;
    lastId?: number;
}

interface GlobalChatMessageListResponse {
    /**
     * the response may only return at most 50 messages, the API does not assume
     * historical lookup, so if you missed more than about 100 messages, you won't see earlier
     * ones: `truncated` will be `true` and that should be reflected in the UI, possibly
     * best just flush the state in such case
     */
    truncated: boolean;
    /** last few messages in descending order */
    messages: MessageView[];
}
