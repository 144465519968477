import type { Client, Message, SubscribeHeaders, Subscription } from "webstomp-client";
import type StompClient from "@/subscription/StompClient";

export class SubscriptionHolder {
    private subscription: Subscription;

    constructor(subscription: Subscription) {
        this.subscription = subscription;
    }

    public updateSubscription(subscription: Subscription): void {
        this.subscription = subscription;
    }

    public unsubscribe(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}

const USER_QUEUE_PREFIX = "/user/queue";
const TOPIC_PREFIX = "/topic";
const APP_PREFIX = "/app";

type SubscribeFunction = (client: Client) => Promise<Subscription>;

export default class WebSocketClient {
    private subscriptions: Array<[SubscriptionHolder, SubscribeFunction]> = [];

    constructor(private readonly stomp: StompClient) {}

    public async resubscribe(): Promise<void> {
        const client = await this.stomp.getClient();
        for (const subscription of this.subscriptions) {
            const holder = subscription[0];
            const subs = await subscription[1](client);
            holder.updateSubscription(subs);
        }
    }

    public async reconnect(delayMs: number): Promise<void> {
        await this.stomp.reconnect(delayMs);
    }

    public async userConnection(): Promise<void> {
        await this.stomp.getClient();
        if (!this.stomp.hasUserSession()) {
            await this.reconnect(500);
        }
    }

    public async disconnect(): Promise<void> {
        const client = await this.stomp.getClient();
        for (const subscription of this.subscriptions) {
            subscription[0].unsubscribe();
        }
        this.subscriptions = [];
        client.disconnect();
    }

    private async internalSubscribe(subscriptionFx: SubscribeFunction): Promise<SubscriptionHolder> {
        const client = await this.stomp.getClient();
        const subscription = await subscriptionFx(client);
        const subscriptionHolder = new SubscriptionHolder(subscription);
        this.subscriptions.push([subscriptionHolder, subscriptionFx]);
        return subscriptionHolder;
    }

    public async subscribe(
        destination: string,
        onMessage: (message: Message) => unknown,
        headers?: SubscribeHeaders
    ): Promise<SubscriptionHolder> {
        const subscriptionFx = async (stomp: Client) => stomp.subscribe(TOPIC_PREFIX + destination, onMessage, headers);
        return this.internalSubscribe(subscriptionFx);
    }

    public async subscribeUserOnly(
        destination: string,
        onMessage: (message: Message) => unknown,
        headers?: SubscribeHeaders
    ): Promise<SubscriptionHolder> {
        const subscriptionFx = async (stomp: Client) => stomp.subscribe(USER_QUEUE_PREFIX + destination, onMessage, headers);
        return this.internalSubscribe(subscriptionFx);
    }

    public async send(destination: string, payload: unknown, headers?: SubscribeHeaders): Promise<void> {
        const client = await this.stomp.getClient();
        client.send(APP_PREFIX + destination, JSON.stringify(payload), headers);
    }
}
