import DataHelpers from "@/cuties/engine/DataHelpers";
import Template from "./Template";
import { PAGE_MANAGER_SINGLETON } from "@/cuties/engine/PageManager";
import { HANDLER_MANAGER_SINGLETON } from "@/cuties/engine/HandlerManager";
import Storage from "@/cuties/engine/Storage";

const KV_STORAGE = new Storage();

export interface Interface
{

    /**
     * Set a selector for a DOM element, which will contain HTML of the instantiated template
     * @param {string} selector - selector for a DOM element
     */
    set_page_selector(selector:string);

    /**
     * register template
     * @param {string} template_id - template id
     * @param {string} html - template itself
     * @param {string} handler_id_prepare - (optional) handler id - for a function(data){return data} - preprocess data before template is instantiated with this data
     * @param {string} handler_id_show - (optional) handler id - for a function(data){} - postprocess page after template is instantiated and HTML added to the page
     */
    register_template(template_id:string, html:string, handler_id_prepare?:string|undefined, handler_id_show?:string|undefined);

    /**
     * find template definitions in the page using selector (for example, #my_templates > div) and register those templates
     * @param {string} selector - selector for template definitions in the page
     */
    register_templates_by_selector(selector:string, remove_container:boolean);

    on_show(template_id:string, handler_func:Function):Interface;

    /**
     * show a template
     * @param {string} template_id - template id
     * @param {object} data - JSON object for value replacements in the template, for example:  {key1: replacement1, key2: replacement2, ...}
     * @param {string} selector_write_to - (optional: string) custom container for instantiated HTML. if not set, default this.selector_write_to will be used
     * @param {boolean} toTop - if true, scroll page to top when template is shown
     * @param {Function} onShow - callback function
     */
    show(template_id:string, data?: { [key:string]:any } | null, selector_write_to?:string|any, toTop?:boolean | null, onShow?:Function);

    /**
     * load pack of templates dynamically
     * @param {string} pack_id - pack's id: /templates/[pack_id].htm
     * @param {Function} onLoad - callback function
     */
    load_pack(pack_id:string, onLoad?:Function);

    /**
     * @param {string} template_id - check if template is registered
     */
    is_registered(template_id: string): boolean;

    clear(selector?: string): void;

    /**
     * When languages changed, update translated templates
     */
    on_change_language(): void;

    /**
     * get array of DOM elements with attribute [data-time], registered on the previous TemplateManager.show() call
     */
    get_timers(): any[];

    /**
     * update timer - DOM element with attribute [data-time]
     */
    /* global JQuery */
    update_timer(dom_context:JQuery): void;

    get_log(): any;

}

/**
 * TemplateManager knows how to load and show html templates.
 * It supports:
 * 1. Multilanguage translations (via syntax: %Translation_key%)
 * 2. Value replacements (mustache is used for that: https://mustache.github.io/mustache.5.html )
 * 3. Linking handlers and requests to DOM elements.
 *
 * After template is shown, a handler can be called.
 *         data-show="handler_id" for inline templates => function(data) { 'this' is DOM element container where template was added }
 * Before template is shown, a handler can be called.
 *         data-prepare="handler_id" for inline templates => function(data) { (data is prepared here for the template) return data; }
 *
 * When template is shown, runs handler 'on_template_show'.
 *
 * @deprecated - use Vue router
 */
export class TemplateManager implements Interface {

    /**
     * @property {string} selector_write_to - selector of the DOM element that represents a container for the contents. By default, it's InnerHTML will receive html (processed templates).
     */
    private selector_write_to:string = "body";

    /**
     * @property {object} templates - map of all registered templates {template_id:html, ...}
     */
    private readonly templates_translated: Record<string, string>;
    private readonly templates_sources: Record<string, string>;

    /**
     * @property {object} handlers_prepare - map of all registered "prepare"-handlers for preprocessing templates {template_id:handler_id, ...}
     */
    private readonly handlers_prepare: Record<string, string>;

    /**
     * @property {object} handlers_show - map of all registered "show"-handlers for postprocessing templates {template_id:handler_id, ...}
     */
    private readonly handlers_show: Record<string, string>;

    /** @deprecated - unused it seems */
    private readonly handlers_func_show: Record<string, () => void>;

    /**
     * array of timers on the page
     */
    private timers:any[] = null;

    private packsLoaded: Record<string, unknown> = {};

    constructor() {
        this.templates_sources = {};
        this.templates_translated = {};
        this.handlers_prepare = {};
        this.handlers_show = {};
        this.handlers_func_show = {};
    }

    /**
     * Set a selector for a DOM element, which will contain HTML of the instantiated template
     * @param {string} selector - selector for a DOM element
     */
    set_page_selector(selector:string) {
        this.selector_write_to = selector;
    }

    /**
     * register template
     * @param {string} template_id - template id
     * @param {string} html - template itself
     * @param {string} handler_id_prepare - (optional) handler id - for a function(data){return data} - preprocess data before template is instantiated with this data
     * @param {string} handler_id_show - (optional) handler id - for a function(data){} - postprocess page after template is instantiated and HTML added to the page
     */
    register_template(template_id:string, html:string, handler_id_prepare?:string|undefined, handler_id_show?:string|undefined) {
        if (this.templates_sources[template_id])
            console.warn("TemplateManager: rewriting template '" + template_id + "'");

        //d('TemplateManager.register_template: ' + template_id);

        this.templates_sources[template_id] = html;
        this.templates_translated[template_id] = Template.apply_translations(html);

        if (handler_id_prepare)
            this.handlers_prepare[template_id] = handler_id_prepare;

        if (handler_id_show)
            this.handlers_show[template_id] = handler_id_show;
    }

    on_show(template_id:string, handler_func: () => void):Interface {
        if (DataHelpers.isFunction(handler_func))
            this.handlers_func_show[template_id] = handler_func;
        return this;
    }

    on_change_language(): void {
        for (const i in this.templates_sources) {
            let html = this.templates_sources[i];
            html = Template.apply_translations(html);
            this.templates_translated[i] = html;
        }
    }

    /**
     * find template definitions in the page using selector (for example, #my_templates > div) and register those templates
     * @param {string} selector - selector for template definitions in the page
     */
    register_templates_by_selector(selector:string, remove_container:boolean) {
        const obj = $(selector);
        const count = obj.length;
        const self = this;

        obj.each(function() {
            const template_id = $(this).attr("data-id");
            const html = $(this).html();

            if (!DataHelpers.isString(template_id) || !template_id) {
                console.warn("TemplateManager: selector '" + selector + "' contains element without data-id.", this);
                return;
            }

            const handler_id_prepare = $(this).attr("data-prepare");
            const handler_id_show = $(this).attr("data-show");

            self.register_template(template_id, html, handler_id_prepare, handler_id_show);
        });

        // remove source of the templates
        // so, if they contain element's id, they will not conflict with instantiated element's id.
        if (remove_container)
            obj.remove();
        else
            obj.html("");
    }

    /**
     * show a template
     * @param {string} template_id - template id
     * @param {object} data - JSON object for value replacements in the template, for example:  {key1: replacement1, key2: replacement2, ...}
     * @param {string} selector_write_to - (optional) custom container for instantiated HTML. if not set, default this.selector_write_to will be used
     * @param {boolean} toTop - go to top of page after drawing, default true
     */
    async show(template_id:string, data?: { [key:string]:any } | null, selector_write_to?: string | undefined | any, toTop: boolean | null = true): Promise<Template> {
        const self = this;
        if (self.is_registered(template_id)) {
            return self._show(template_id, data, selector_write_to, toTop);
        } else {
            console.log("template not registered: " + template_id);
            return self.load_pack_and_show_default_template(template_id, data, selector_write_to, toTop);
        }
    }

    /**
     * load dynamically pack of templates and show a template with id = pack_id
     * @param {string} pack_id - pack's id and template's id
     * @param {object} data - JSON object for value replacements in the template, for example:  {key1: replacement1, key2: replacement2, ...}
     * @param {string} selector_write_to - (optional: string) custom container for instantiated HTML. if not set, default this.selector_write_to will be used
     * @param {boolean} toTop - if true, scroll page to top when template is shown
     * @param {Function} onShow - callback function
     */
    private async load_pack_and_show_default_template(pack_id:string, data?: { [key:string]:any }, selector_write_to?:string|any, toTop?:boolean): Promise<Template> {
        const self = this;
        await this.load_pack(pack_id);

        const template_id = pack_id;

        if (self.is_registered(template_id)) {
            return self._show(template_id, data, selector_write_to, toTop);
        } else {
            throw new Error("TemplateManager.load_pack_and_show_default_template: unknown template '" + template_id + "'");
        }
    }

    fetch_pack_source(pack_id: string): Promise<string> {
        return new Promise((resolve, reject) => $.ajax({
            type: "GET",
            url: "/template/" + pack_id + ".htm",
            success(response, textStatus, jqXHR) {
                resolve(response);
            },
            error(jqXHR, textStatus, errorThrown) {
                DataHelpers.error("TemplateManager.load_pack", pack_id, "response:", textStatus, errorThrown, jqXHR);
                reject(errorThrown);
            }
        }));
    }

    async load_pack(pack_id:string, onLoad?:Function): Promise<void> {
        if (this.packsLoaded[pack_id]) {
            if (onLoad && DataHelpers.isFunction(onLoad))
                onLoad();
            return Promise.resolve();
        }

        const response = await this.fetch_pack_source(pack_id);
        this.set_fetched_pack(pack_id, response);

        if (onLoad && DataHelpers.isFunction(onLoad)) {
            onLoad();
        }
    }

    /** must be called after translations are initialized */
    set_fetched_pack(pack_id: string, response: string) {
        $("#templates_dynamic").html(response);

        TEMPLATE_MANAGER_SINGLETON.register_templates_by_selector("#templates_dynamic > script", false);

        this.packsLoaded[pack_id] = true;
    }

    private _show(template_id:string, data?: { [key:string]:any } | null, selector_write_to?:string|undefined, toTop: boolean | null = true): Template {
            const pushEventTemplateIds = ["_popup_ping_default", "_popup_ping_battle"];

            const html = this.templates_translated[template_id];
            if (!DataHelpers.isString(html)) {
                throw new Error("TemplateManager.show: unknown template '" + template_id + "'");
            }

            if (selector_write_to !== null) {
                selector_write_to = selector_write_to ? selector_write_to : this.selector_write_to;
            }

            const new_data = HANDLER_MANAGER_SINGLETON.run_if_exist("on_template_prepare", $(selector_write_to), [data]);
            if (new_data)
                data = new_data;

            const handler_id_prepare = this.handlers_prepare[template_id];
            if (handler_id_prepare) {
                // run handler 'data-prepare' => function(data) { ... return data; }
                if (!HANDLER_MANAGER_SINGLETON.exist(handler_id_prepare)) {
                    DataHelpers.error("Template '" + template_id + "': data-prepare contains unknown handler '" + handler_id_prepare + "' in DOM element");
                } else {
                    data = HANDLER_MANAGER_SINGLETON.run(handler_id_prepare, $(selector_write_to), [data]);
                }
            }

            const template = new Template(template_id, html);
            //template.apply_translations();
            template.apply_replacements(data, this.templates_translated);

            if (selector_write_to !== null) {

                template.write_to(selector_write_to);

                template.init_handlers(selector_write_to);

                const handler_id_show = this.handlers_show[template_id];
                if (handler_id_show) {
                    // run handler 'data-show' => function(data) { ... }
                    if (!HANDLER_MANAGER_SINGLETON.exist(handler_id_show)) {
                        DataHelpers.error("Template '" + template_id + "': data-show contains unknown handler '" + handler_id_show + "' in DOM element");
                    } else {
                        HANDLER_MANAGER_SINGLETON.run(handler_id_show, $(selector_write_to), [data]);
                    }
                }
            }

            this.collect_timers();
            this.init_timer_updater();

            HANDLER_MANAGER_SINGLETON.run_if_exist("on_template_show", $(selector_write_to), [selector_write_to, template_id]);

            const handler_func = this.handlers_func_show[template_id];
            if (DataHelpers.isFunction(handler_func)) {
                try {
                    handler_func();
                } catch (e) {
                    DataHelpers.error("Template '" + template_id + "': handler function 'on show': ", e);
                }
            }

            // show images on load
            $(selector_write_to).find("img").one("load", function() {
                // d('call ' + $(this).attr('src'));
                $(this).fadeIn(500);
            });

            // todo: move all next project-specific code to the handler 'on_template_show', that's the purpose of that handler
            if(toTop) window.scrollTo(0, 0);


            $(".header-dropdown").removeClass("show");
            $(".dropdown-menu").removeClass("show");

            return template;
    }

    is_registered(template_id: string): boolean {
        const html = this.templates_translated[template_id];
        return DataHelpers.isString(html);
    }

    clear(selector?: string): void {
        if (!selector)
            selector = this.selector_write_to;
        $(selector).html("");
    }

    get_log(): any {
        return {
            selector_write_to: this.selector_write_to,
            templates: this.templates_translated
        };
    }

    private collect_timers() {
        const self = this;
        self.timers = [];

        const time_now = DataHelpers.get_time_seconds();

        $("body").find("[data-time]").each(function() {
            const obj = $(this);
            self.timers.push(obj);
            TEMPLATE_MANAGER_SINGLETON.update_timer(obj);
        });
    }

    private init_timer_updater() {
        KV_STORAGE.set_interval_func("_template_time", function() {
            const timers = TEMPLATE_MANAGER_SINGLETON.get_timers();
            if (!timers)
                return;

            for (let i = timers.length - 1; i >= 0; --i) {
                TEMPLATE_MANAGER_SINGLETON.update_timer(timers[i]);
            }
        });
    }

    /**
     * get array of DOM elements with attribute [data-time], registered on the previous TemplateManager.show() call
     */
    get_timers(): any[] {
        return this.timers;
    }

    /**
     * update timers - DOM elements with attribute [data-time]
     */
    update_timer(obj): void {
        const useParts = obj.data("useparts");
        const time_now = DataHelpers.get_time_seconds();
        let time_end = DataHelpers.toInt(obj.attr("data-time"));
        const durationReady = obj.data("durationready");
        const includeDays = obj.data("includedays");
        const ascending = obj.data("ascending") != null;

        if (durationReady) {
            obj.attr("data-time", !ascending ? time_end -= 1 : time_end += 1);
            time_end = time_now + time_end;
        }

        let duration =  Math.ceil(time_end - time_now);

        if (duration < 0)
            duration = 0;

            if(duration <= 0 && PAGE_MANAGER_SINGLETON.get_current_page_id() == "pet" && obj.data("updated") == null && obj.data("reload") != null) {
                obj.data("updated", "true");
                return;
            }

            if(duration <= 0) {
                return;
            }
        let duration_str = DataHelpers.get_time_duration_digits(duration);
        const parts = duration_str.split(":");
        const txtD = "";
        const txtH = "";
        const txtM = "";
        const txtS = "";
        if(useParts) {

            obj.find(".number._00 > p").html(parts[0]);
            obj.find(".number._01 > p").html(parts[1]);
            obj.find(".number._02 > p").html(parts[2]);
            obj.find(".number._03 > p").html(parts[3]);
        } else {
            if(!includeDays) {
                const h = DataHelpers.toInt(parts[0]) * 24 + DataHelpers.toInt(parts[1]);
                let hour;
                if( h < 10 && h > 0 ) {
                    hour = "0" + h;
                }
                if( h > 9 ) hour = h;
                if(h == 0) hour = "00";
                duration_str = hour + ":" + parts[2] + ":" + parts[3];
            }
            if(duration <= 0) duration_str = "";
            obj.html(duration_str);
        }

    }
}

/** used to render our SPA pages, te legacy way */
export const TEMPLATE_MANAGER_SINGLETON = new TemplateManager();
