import I18nHelpers from "@/cuties/engine/I18nHelpers";
import DataHelpers from "@/cuties/engine/DataHelpers";
import Mustache from "mustache";
import { HANDLER_MANAGER_SINGLETON } from "@/cuties/engine/HandlerManager";

/**
 * Class Template knows how to bake initial template's html to a real html. It supports:
 * 1. Multilanguage translations (via syntax: %Translation_key%)
 * 2. Value replacements (mustache is used for that: https://mustache.github.io/mustache.5.html )
 * 3. Linking handlers and requests to DOM elements.
 *
 * Client code work with templates via TemplateManager class.
 */
export default class Template {

    private template_id: string;

    private html: string;

    /**
     * template's constructor
     * @param {string} template_id - template id
     * @param {string} html - html (initial definition of the template)
     */
    constructor(template_id:string, html:string) {
        this.template_id = template_id;
        this.html = Template.apply_translations(html);
    }

    /**
     * find all %translation_key% and replace them with a translations for current mad.text (TextManager).
     * if you need "%" in HTML, use entity code instead.
     */
    static apply_translations(html:string): string {
        if (!DataHelpers.isString(html)) {
            DataHelpers.error("Template.apply_translations: typeof html = " + (typeof html));
            return "";
        }

        html = DataHelpers.replace_all(html, "{{&gt;", "{{>");    // replace occurences: {{> template_id}}

        return html.replace(/\%([a-zA-Z0-9_]+)\%/g, function(full_pattern, translation_key, c) {
            return I18nHelpers.translate(translation_key);
        });
    }

    /**
     * provide mustache-specific replacements in the template HTML
     * @param {object} data - JSON object for value replacements in the template, for example:  {key1: replacement1, key2: replacement2, ...}
     * @param {object} partials - JSON object {template_id_string: template_html_string, ...}
     */
    apply_replacements(data:object, partials:object): void {
        if (DataHelpers.isObject(data)) {
            // data is JSON object
            this.html = Mustache.render(this.html, data, partials as any);
        } else if (data) {
            // unsupported data type
            DataHelpers.error("Template '" + this.template_id + "': bad data for replacements, JSON object expected", data);
        }
    }

    /**
     * associate event handlers with links, forms and any DOM elements with special attributes (data-request, data-click, etc.)
     * @param {string} selector - search DOM elements inside this selector
     */
    init_handlers(selector): void {
        const tpl = this;
        $(selector).find("a, form, [data-click]").each(function() {
            const self = this;
            const obj = $(this);

            // validate handler id for click event
            if (obj.is("[data-click]")) {
                const handler_id = obj.attr("data-click");
                if (!handler_id) {
                    DataHelpers.error("Template '" + tpl.template_id + "': data-click is not set for DOM element", self);
                } else if (!HANDLER_MANAGER_SINGLETON.exist(handler_id)) {
                    DataHelpers.error("Template '" + tpl.template_id + "': data-click contains unknown handler '" + handler_id + "' in DOM element", self);
                }
            }

            // if DOM element if form, listen to 'submit' event, otherwise listen to 'click' event
            const event_name = obj.is("form") ? "submit" : "click";

            (obj[event_name] as any)(function(e) {
                const attr_name = obj.is("form") ? "action" : "href";

                // perform handler

                if (obj.is("[data-click]")) {
                    const handler_id = obj.attr("data-click");
                    const handler = HANDLER_MANAGER_SINGLETON.get(handler_id);
                    if (handler != null) {
                        handler.call(self, e);
                        e.stopPropagation();
                    }
                    return false;
                }

                if (obj.is("a")) {
                    if(obj.is("[data-checkhover]")) {
                        if($(".cutie-checkbox:hover").length > 0) {
                            e.stopPropagation();
                            return true;
                        }
                    }

                    if (e.ctrlKey) {
                        obj.attr("target", "_blank").attr("data-blank-dynamic", 1);
                        return true;
                    } else if (obj.is("[data-blank-dynamic]")) {
                        $(obj).removeAttr("target").removeAttr("data-blank-dynamic");
                    }

                    if (obj.is("[data-noroute]") || obj.is("[name]") || obj.attr("target") == "_blank") {
                        // e.preventDefault();
                        e.stopPropagation();
                        return true;
                    }
                }

                // try to route by 'href' or 'action' attribute's value (auto-map URI to request class)
                const url = obj.attr(attr_name);
                if (url != undefined) {
                    // route found
                    window.location.href = url;
                    return false;
                }

                // perform default handler
                const link_handler = HANDLER_MANAGER_SINGLETON.get_default_handler();
                if (DataHelpers.isFunction(link_handler)) {
                    link_handler.call(self);
                    return false;
                }

                DataHelpers.error("Template '" + tpl.template_id + "': not routed", self);

                return false;
            });
        });


        /// ON(INPUT) when input/textarea element is changed, trigger event and make something in the associated handler
        $(selector).find("input[data-change], textarea[data-change], select[data-change]").each(function() {
            const self = this;
            const obj = $(this);

            const handler_id = obj.attr("data-change");

            if (!handler_id) {
                DataHelpers.error("Template '" + tpl.template_id + "': data-change is not set for DOM element", self);
                return;
            }

            const handler = HANDLER_MANAGER_SINGLETON.get(handler_id);
            if (!DataHelpers.isFunction(handler)) {
                DataHelpers.error("Template '" + tpl.template_id + "': data-change contains unknown handler '" + handler_id + "' in DOM element", self);
                return;
            }

            obj.on("input change", function() {
                if (handler != null)
                    handler.call(self);
                return false;
            });
        });
    }

    /**
     * baked well-formed HTML write to the specified DOM element
     * @param {string} selector - selector of the DOM element, where HTML must be written to
     */
    write_to(selector): void {
        $(selector).empty();

        $(selector).html(this.html);
    }
}
