import { PLAYER_SINGLETON } from "@/cuties/model/player/Player";
import I18nHelpers from "@/cuties/engine/I18nHelpers";
import { sha256 } from "js-sha256";
import aesjs from "aes-js";
import type { Signature } from "@/cuties/blockchain/BlockchainService";
import type { PrivateKeyImportSyncOrAsyncDelegate } from "@/components/LoginManager/TypeDefs";
import { throws } from "@/app/cuties/utils/utils";

export default abstract class BlockchainPrivateKey {
    // These parameters must be defined
    protected abstract provider_key: string; // change to unique example neojs, eosjs, web3js
    protected abstract session_pwd_key: string;
    protected abstract kind: string; // 'neo_private_key';
    protected account = null;
    protected address: string = "";

    protected error_attach_text = "tron_error_attach";
    protected error_no_account_text = "tron_account_do_not_exist";
    protected error_game_account_wrong_text = I18nHelpers.translate("tron_game_account_error_wrong");
    protected error_wrong_password_text = I18nHelpers.translate("wallet_ulock_failed_pwd"); // EthereumPrivateKey_UnlockFailed_InvalidPassword
    protected error_missing_pwd_text = I18nHelpers.translate("wallet_ulock_failed_missing_pwd"); // EthereumPrivateKey_UnlockFailed_MissingPassword

    // do not forget to define this
    public abstract sign(text: string): Promise<Signature>;
    protected abstract init(auth: any, callback?: Function, password?: string): Promise<any>;
    protected abstract is_private_key(pk: string): Promise<boolean>;
    protected abstract get_address_from_private_key(pk: string): Promise<string>;

    protected get_password_from_session(): string | null {
        return sessionStorage.getItem(this.session_pwd_key) ?? null;
    }

    protected sha256(string: string): string {
        return sha256(string);
    }

    public static makePasswordHash(password: string): string {
        return sha256(password);
    }

    public logout(): void {
        sessionStorage.removeItem(this.session_pwd_key);
    }

    protected get_wallet_storage_key(password_hash: string) {
        return BlockchainPrivateKey.getWalletStorageKeyStatic(this.provider_key, password_hash);
    }

    public static getWalletStorageKeyPrefix(providerKey: string): string {
        return providerKey + "_wallet_";
    }

    private static getWalletStorageKeyStatic(provider_key: string, password_hash: string): string {
        // password_hash is the encryption key for the private key text, so it must be a secret,
        // hash_new is used in localStorage key, so it's not a secret, hence the double-hashing
        const hash_new = sha256(password_hash);
        return this.getWalletStorageKeyPrefix(provider_key) + hash_new;
    }

    protected get_wallet_storage_key_old(password_hash: string) {
        return this.provider_key + "_wallet_" + password_hash;
        //  return "neojs_wallet_" + password_hash; //+ Web3.utils.sha3(password);
    }

    public async getAddress(): Promise<string> {
        return this.address;
    }

    public abstract get_provider_current_account_address(): string;

    public import: PrivateKeyImportSyncOrAsyncDelegate = (privateKey, password, addToStorage) => {
        if (addToStorage && !password) {
            return I18nHelpers.translate("wallet_import_failed_password_missing");
        }

        if (addToStorage) {
            const pwd_hash = BlockchainPrivateKey.makePasswordHash(password);
            sessionStorage.setItem(this.session_pwd_key, pwd_hash);
            this.storePrivateKey(privateKey, pwd_hash);
        }
    };

    public static hasStoredPrivateKeys(providerKey: string): boolean {
        const prefix = BlockchainPrivateKey.getWalletStorageKeyPrefix(providerKey);
        for (let i = 0; i < window.localStorage.length; ++i) {
            const key = window.localStorage.key(i) ?? "";
            if (key.startsWith(prefix)) {
                return true;
            }
        }
        return false;
    }

    protected getStoredPrivateKey(passwordHash: string): string | null {
        return BlockchainPrivateKey.getStoredPrivateKeyByPasswordHash(this.provider_key, passwordHash);
    }

    public static getStoredPrivateKeyByPasswordHash(provider_key: string, passwordHash: string): string | null {
        const hashKey = BlockchainPrivateKey.getWalletStorageKeyStatic(provider_key, passwordHash);
        const stored = localStorage.getItem(hashKey);

        if (!stored) return null;

        const keyBytes = aesjs.utils.utf8.toBytes(passwordHash);

        const key = new Array(16);
        for (let i = 0; i < 16; i++) {
            key[i] = keyBytes[i];
        }

        // When ready to decrypt the hex string, convert it back to bytes
        const encryptedBytes = aesjs.utils.hex.toBytes(stored);

        // The counter mode of operation maintains internal state, so to
        // decrypt a new instance must be instantiated.
        const aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
        const decryptedBytes = aesCtr.decrypt(encryptedBytes);

        // Convert our bytes back into text
        return aesjs.utils.utf8.fromBytes(decryptedBytes);
    }

    protected storePrivateKey(text: string, passwordHash: string) {
        return BlockchainPrivateKey.storePrivateKeyStatic(this.provider_key, text, passwordHash);
    }

    private static encryptText(text: string, passwordHash: string) {
        const keyBytes = aesjs.utils.utf8.toBytes(passwordHash);

        const key = new Array(16);
        for (let i = 0; i < 16; i++) {
            key[i] = keyBytes[i];
        }
        // var key = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 ];

        // Convert text to bytes
        const textBytes = aesjs.utils.utf8.toBytes(text);

        // The counter is optional, and if omitted will begin at 1
        const aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
        const encryptedBytes = aesCtr.encrypt(textBytes);

        // To print or store the binary data, you may convert it to hex
        return aesjs.utils.hex.fromBytes(encryptedBytes);
    }

    public static storePrivateKeyStatic(provider_key: string, text: string, passwordHash: string) {
        const encryptedHex = BlockchainPrivateKey.encryptText(text, passwordHash);

        // If you want to check - uncomment
        // // When ready to decrypt the hex string, convert it back to bytes
        // const encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex);

        // // The counter mode of operation maintains internal state, so to
        // // decrypt a new instance must be instantiated.
        // const aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
        // const decryptedBytes = aesCtr.decrypt(encryptedBytes);

        // // Convert our bytes back into text
        // const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
        // if( debug ) console.log('7.7.1. StorePrivateKey is pass same = ', (text == decryptedText));
        // // "Text may be any length you wish, no padding is required."

        localStorage.setItem(BlockchainPrivateKey.getWalletStorageKeyStatic(provider_key, passwordHash), encryptedHex);

        return true;
    }

    public migratePasswordHash(password: string): void {
        const hash = BlockchainPrivateKey.makePasswordHash(password);
        const old_key = this.get_wallet_storage_key_old(hash);
        const val = localStorage.getItem(old_key);
        const new_key = this.get_wallet_storage_key(hash);

        if (val) {
            localStorage.setItem(new_key, val);
            if (localStorage.getItem(new_key)) localStorage.removeItem(old_key);
            console.log("migrated = old, new key", old_key, new_key);
        }
    }

    public async private_key_init(on_login: string, password?: string, login?: any, callback?: Function): Promise<any> {
        if (!password) {
            throw new Error(this.error_missing_pwd_text);
        }

        const passwordHash = BlockchainPrivateKey.makePasswordHash(password);
        const storedKey = this.getStoredPrivateKey(passwordHash);

        if (!storedKey || !(await this.is_private_key(storedKey))) {
            throw new Error(this.error_wrong_password_text);
        }

        const address = (await this.get_address_from_private_key(storedKey)) ?? throws(this.error_wrong_password_text);

        let unlock = false;

        if (!PLAYER_SINGLETON.is_logined()) {
            unlock = true;
        } else {
            if (this.get_provider_current_account_address()) {
                if (address === this.get_provider_current_account_address()) {
                    unlock = true;
                } else {
                    throw new Error(this.error_game_account_wrong_text + this.get_provider_current_account_address());
                }
            } else if (!this.get_provider_current_account_address() && PLAYER_SINGLETON.get_ethereum_wallet_id()) {
                throw new Error(this.error_attach_text);
            } else {
                throw new Error(this.error_no_account_text);
            }
        }

        if (unlock) {
            sessionStorage.setItem(this.session_pwd_key, passwordHash);
            await this.init(on_login, callback, password);
        } else {
            throw new Error(this.error_no_account_text);
        }

        return address;
    }

    public export_private_key(password: string): string {
        if (!password) {
            throw Error(I18nHelpers.translate("wallet_ulock_failed_missing_pwd"));
        }

        if (!this.isPrivateKeyPasswordValid(password)) {
            throw Error(I18nHelpers.translate("wallet_ulock_failed_pwd"));
        }

        const passwordHash = BlockchainPrivateKey.makePasswordHash(password);
        return this.getStoredPrivateKey(passwordHash) ?? throws("Cannot export TRON private key");
    }

    isPrivateKeyPasswordValid(password: string): boolean {
        return BlockchainPrivateKey.makePasswordHash(password) === this.get_password_from_session();
    }

    public checkReadyForTransaction(): Promise<void> {
        // network url are provided by server, nothing to check
        return Promise.resolve();
    }
}
