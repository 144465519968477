const formErrorMessages = {
    FIELD_REQUIRED: "wallet_field_required",
    INSUFFICIENT_BALANCE: "wallet_balance_error",
    INVALID_NUMBER: "wallet_digits_validation",
    AT_LEAST_ONE_REQUIRED: "errorAtLeastOneFilled",
    TOO_MANY_DECIMALS: "errorTooManyDecimals",
    TOO_MANY_DECIMALS_SHORT: "errorTooManyDecimalsShort",
    ZERO: "offchain_page_err_zero",
    TRX_FREEZE_MIN_VALUE: "tron_freeze_amount_min_error",
};

export default formErrorMessages;
