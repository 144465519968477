
    import type { DungeonWorldCountable } from "@/cuties/request/crafting/CraftingApiService";
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {}
    })
    export default class PossessionIdentityBlockDungeonWorldCountable extends Vue {
        @Prop({ required: true }) identity!: DungeonWorldCountable;
    }
