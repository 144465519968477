import type { UnitIdentity } from "cuties-client-components/types/general/UnitIdentity";

export function toUnitId(possessionIdentity: UnitIdentity & { count?: number | string }): string {
    // would possibly make sense to also sort object keys and normalize null
    const { count, ...unitIdentity } = possessionIdentity;
    return JSON.stringify(unitIdentity);
}

/**
 * holds the mapping of possession identities to their quantity in user's inventory
 */
export default class PossessionsState {
    private readonly byUnitId: Map<string, bigint>;

    constructor(byReference: Map<UnitIdentity, bigint>) {
        this.byUnitId = new Map(
            [...byReference.entries()].map(([pi,q]) => {
                return [toUnitId(pi), q];
            })
        );
    }

    get(key: UnitIdentity): bigint | undefined {
        return this.byUnitId.get(toUnitId(key));
    }

    set(key: UnitIdentity, value: bigint) {
        return this.byUnitId.set(toUnitId(key), value);
    }
}
