import Request from "./Request";
import type { Blockchain } from "../model/pet/BlockchainId";

export default class RequestCutieEvents extends Request {

    private readonly blockchain: Blockchain;
    private readonly cutieId: number;
    private readonly failed: boolean;

    constructor(blockchain: Blockchain, cutieId?: number, failed?: boolean) {
        super();
        this.blockchain = blockchain;
        this.cutieId = cutieId;
        this.failed = failed;
    }

    get_url(): string {

        let url = `/${this.blockchain}/event/cutie`;

        const params: string[] = [];

        if (this.cutieId) {
            params.push(`cutieId=${this.cutieId}`);
        }

        if (this.failed) {
            params.push(`failed=${this.failed}`);
        }

        if (params.length > 0) {
            url += "?" + params.join("&");
        }

        return url;
    }

}
