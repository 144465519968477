

/**
 * Storage is a key-value storage. It stores temporary references to models, interval functions, etc. for the current page.
 * Client-code manually manages consistency of this data.
 */
export default class Storage {
    /**
     * map [function user-defined id] => [function system id]
     */
    private interval_funcs: { [key:string]: number } = {};

    private data: { [key:string]: any } = {};

    clear(): void {
        this.data = {};
    }

    remove(key:string): void {
        delete this.data[key];
    }

    add(key:string, value:any): Storage {
        this.data[key] = value;
        return this;
    }

    get(key:string): any {
        // todo add check if exists
        if( this.data[key] ) return this.data[key];
        else                 return null;
    }

    set_interval_func(user_defined_func_id:string, interval_func:Function, interval_period_ms:number = 1000): void {
        let system_func_id = this.interval_funcs[user_defined_func_id];

        if (system_func_id) {
            clearInterval(system_func_id);
            delete this.interval_funcs[user_defined_func_id];
        }

        if (interval_func) {
            system_func_id = setInterval(interval_func, interval_period_ms);
            this.interval_funcs[user_defined_func_id] = system_func_id;
        }
    }
}

export const STORAGE_SINGLETON = new Storage();
