
    import DataHelpers from "@/cuties/engine/DataHelpers";
    import utils from "@/cuties/utils";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {},
    })
    export default class HeaderPromoLine extends Vue {
        timePeriod = 14 * 24 * 60 * 60;
        showPromo = false;

        mounted() {
            const ts = utils.getPromoValue();
            const curTs = DataHelpers.get_time_seconds();
            let showPromo = false;
            if (ts && curTs > (+ts + this.timePeriod)) {
                showPromo = true;
            }

            if (!ts) {
                showPromo = true;
            }
            this.showPromo = showPromo;
        }

        hide(): void {
            this.showPromo = false;
            utils.setPromoValue(DataHelpers.get_time_seconds() + "");
        }
    }
