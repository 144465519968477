import DataHelpers from "@/cuties/engine/DataHelpers";
import Request from "../Request";
import utils from "../../utils";

export default class RequestPromoCodeCreate extends Request {

    // request
    public chars:string = null;
    public promoCodeCount:number = null;
    public promoCodeLength:number;
    public useTimes:number = 1;

    public timeAvailableFrom:number = null;
    public timeAvailableTill:number = null;

    public item = null;
    public pet = null;

    // result
    public promoCodes: string[];
    public errors: string[];

    public constructor(chars:string, promoCodeCount:number, promoCodeLength:number, useTimes:number) {
        super();
        if( chars ) this.chars = chars;
        if( promoCodeCount ) this.promoCodeCount = promoCodeCount;
        this.promoCodeLength = promoCodeLength;
        this.useTimes = useTimes;

        //timeAvailableFrom: this.timeAvailableFrom,
        //timeAvailableTill: this.timeAvailableTill,
    }

    set_item(item) {
        this.item = item;
    }

    set_pet(pet) {
        this.pet = pet;
    }

    get_url(): string {
        return "/promo_code_create";
    }

    collect_post_data(): object {
        return {
            chars: this.chars,
            promoCodeCount: this.promoCodeCount,
            promoCodeLength: this.promoCodeLength,
            useTimes: this.useTimes,

            timeAvailableFrom: this.timeAvailableFrom,
            timeAvailableTill: this.timeAvailableTill,

            item: this.item,
            pet: this.pet,
        };
    }


    on_success(response: any): void {
        if (Array.isArray(response.promoCodes))
            this.promoCodes = response.promoCodes;

        if (Array.isArray(response.errors))
            this.errors = response.errors;
    }

    get_handler_prefix(): string {
        return null;
    }

    on_error(response: any): void {
        try {
            const error = response.errorDetails.jqXHR.responseJSON;
            const status = response.errorDetails.jqXHR.status;
            // console.log("responseJSON", response, status, error);
            // d('error status = ' + status);
            if (status == 401) {
                utils.propose_auth();
            } else{
                utils.show_notification("error status = " + status + "<br>" + error.message);
            }
        } catch (e) {
            DataHelpers.error("Request.on_error", e);
            utils.show_notification(response.error);
        }
    }

}
