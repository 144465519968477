import mad from "@/cuties/engine/mad";
import CutiesApiFaucet from "@/app/cuties/blockchain/CutiesApiFaucet";

/** used in tron template -_- */
mad.handler.register("ethereum_private_key_import_pk_change", async function() {

    const Web3 = await CutiesApiFaucet.getWeb3();
    const field = $("form#ethereum_private_key_import [data-id=address]");
    try {
        const obj = mad.collect_form_values("#ethereum_private_key_import", "data-id");

        let privateKey = obj.private_key.toLowerCase();
        if (privateKey.substr(0, 2) != "0x") {
            privateKey = "0x" + privateKey;
        }

        if (/^(0x)?[a-fA-F0-9]{64}$/.test(privateKey)) {
            const web3tmp = new Web3();
            const account = web3tmp.eth.accounts.privateKeyToAccount(privateKey);
            field.text(account.address);
        } else {
            field.text("");
        }
    } catch (e) {
        field.text("");
    }
});

