import http from "@/http";
import type PlayerSettings from "./PlayerSettings";
import LocationPath from "@/cuties/engine/LocationPath";
import type { Player } from "@/cuties/model/player/Player";
import type { UserTemplate } from "@/cuties/model/player/UserTemplate";
import type { AxiosResponse } from "axios";

export default class PlayerSettingsService {

    public static getCurrentPlayerSettings(player: Player): PlayerSettings {
        const location = LocationPath.create(window.location);
        const authorizationData = player.authorizationData!;

        const haveEmail = !!authorizationData.email;

        return {
            playerName: !authorizationData.hasRealName ? "" : authorizationData.name,
            defaultName: !authorizationData.hasRealName ? authorizationData.name : "",
            blockchains: player.get_blockchains(),

            referral: location.get_base() + "?inviter=" + authorizationData.id,
            agreeEmails:
                authorizationData.agreeEmails ||    // player already decided to agree
                !authorizationData.email,           // player initially enters settings, so check "agree" by default
            haveEmail: haveEmail,
            email: authorizationData.email,
            confirmedEmail : authorizationData.confirmedEmail,
            hasPassword : authorizationData.hasPassword,
            changedEmail : authorizationData.changedEmail,
            unconfirmedEmail : authorizationData.unconfirmedEmail,
        };
    }

    public static async updatePlayerSettings(dt: SettingsFormData): Promise<AxiosResponse<InfoEditResponse>> {
        return this.playerInfoEdit({
            email: dt.settings.email,
            name: dt.settings.playerName || dt.settings.defaultName,
            agreeEmails: dt.settings.agreeEmails,
            oldPassword : dt.oldPass ? dt.oldPass : null,
            newPassword : dt.newPass ? dt.newPass : null
        });
    }

    /**
     * note, there are two error response formats that can be returned by this function:
     * `Promise.resolve({result: ResultType})` and `Promise.reject({error: ResultType})`
     * you have check for both of them
     */
    public static async playerInfoEdit(params: PlayerInfoEditRequest): Promise<AxiosResponse<InfoEditResponse>> {
        return http.post("/player_info_edit", params);
    }

    public static async sendConfirmationEmail() : Promise<any> {
        return http.get("/confirm_existing_email", {});
    }

}

export interface SettingsFormData {
    settings: PlayerSettings;
    oldPass: string | null;
    newPass: string | null;
}

interface PlayerInfoEditRequest {
    email?: string;
    name: string;
    agreeEmails?: boolean;
    oldPassword?: string;
    newPassword?: string;
}

export type InfoEditResponse = UserTemplate | {
    /** error ResultType */
    result: string;
};
