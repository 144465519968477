
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";
    import { anyCaseToHuman } from "cuties-client-components/src/TextUtils";
    import type { EnablableItemTagModifier } from "@/app/cuties/engine/ConfigApiService";
    import ConfigApiService from "@/app/cuties/engine/ConfigApiService";
    import { formatStat, getStatMapping } from "@/cuties/model/adventure/BonusUseInfo";
    import type { Stat } from "@/cuties/BattleHpService";
    import type { BonusKind } from "cuties-client-components/types/api/Cutie";

    @Component
    export default class ItemTagList extends Vue {
        @Prop({ required: true }) tags!: string[];

        private itemTagModifierList: EnablableItemTagModifier[] | null = null;

        created() {
            ConfigApiService.itemTagModifierList()
                .then(list => this.itemTagModifierList = list);
        }

        get occurrences(): Record<number, string> {
            return this.tags.reduce((cnt, cur) => (cnt[cur] = cnt[cur] + 1 || 1, cnt), {});
        }

        getTagName(tag: string): string {
            const key = "item_tag_name_" + tag;
            if (this.$te(key)) {
                return this.$t(key) + "";
            } else {
                return anyCaseToHuman(tag);
            }
        }

        hasMeaningfulDescription(tag: string) {
            const key = "item_tag_desc_" + tag;
            return this.$te(key) || this.itemTagModifierList?.some(mod => mod.tag === tag);
        }

        getTagStatsModifier(tag: string) {
            const modifier: EnablableItemTagModifier | undefined = this.itemTagModifierList?.find(m => m.tag === tag);
            return modifier?.stats;
        }

        formatStat(value: number, stat: Stat): string {
            return formatStat(value, stat, false);
        }

        toLegacyStat(key: Stat): BonusKind {
            const mapping = getStatMapping();

            return mapping[key];
        }
    }
