import type { AnyItemSupplySource } from "@/cuties/items/ItemSupplySource";
import type { ItemDefinitionModel } from "@/app/cuties/items/ItemApiService";
import type { ItemKind } from "@/cuties/model/item/ItemKind";
import type { PossessionIdentityItem } from "@/cuties/request/crafting/CraftingApiService";

export default class ItemsFullDataIndex {
    public readonly sellableToPersonalName: Map<string, string>;
    public readonly energyItemDefs: Map<string, ItemDefinitionModel[]>;

    constructor(
        private readonly sources: Record<string, AnyItemSupplySource[]>,
        public readonly defs: Map<string, ItemDefinitionModel>
    ) {
        const defsValues = [...defs.values()];
        this.sellableToPersonalName = new Map(
            defsValues
                .map((d: ItemDefinitionModel) => d)
                .filter(d => d.baseName)
                .map(d => [d.baseName!, d.name])
        );

        this.energyItemDefs = this.getEnergyNameToItemDefs(defsValues);
    }

    /** @param itemKind - may be a derived name with "Personal" suffix */
    public getSupplySources(itemKind: keyof typeof ItemKind | string): Set<AnyItemSupplySource> {
        const definition = this.defs.get(itemKind);
        if (!definition) {
            return new Set();
        }
        const baseName = definition.baseName ?? definition.name;
        const derivedName = this.sellableToPersonalName.get(itemKind) ?? itemKind;
        return new Set([
            ...this.sources[baseName] ?? [],
            ...this.sources[derivedName] ?? [],
        ]);
    }

    public getEnergyNameToItemDefs(defs: ItemDefinitionModel[]): Map<string, ItemDefinitionModel[]> {
        const map = new Map();

        for (const def of defs) {
            const applicationEffects = def.applicationEffects ?? [];
            for (const effect of applicationEffects) {
                if (effect.type === "UserItemEffectAddCurrentEnergy") {
                    if (!map.has(effect.energyName)) {
                        map.set(effect.energyName, []);
                    }
                    map.get(effect.energyName).push(def);
                }
            }
        }

        return map;
    }

    public getItemsByRestoredEnergyName(energyName: string): ItemDefinitionModel[] {
        return this.energyItemDefs.get(energyName) ?? [];
    }

    /** @param itemKind - may be a derived name with "Personal" suffix */
    public makeIdentityFromKind(itemKind: keyof typeof ItemKind | string): PossessionIdentityItem {
        const definition = this.defs.get(itemKind);
        if (!definition) { // definition not public yet
            return {
                type: "item",
                item: itemKind,
                personality: "FROM_KIND",
                count: "1",
            };
        }
        const baseName = definition.baseName ?? definition.name;
        return {
            type: "item",
            item: baseName,
            personality: definition.sellable ? "SELLABLE" : "PERSONAL",
            count: "1",
        };
    }
}
