import { PLAYER_SINGLETON } from "@/cuties/model/player/Player";
import I18nHelpers from "@/cuties/engine/I18nHelpers";
import DataHelpers from "@/cuties/engine/DataHelpers";
import type { TronConfig } from "./TronConfig";
import mad from "@/cuties/engine/mad";
import BlockchainPrivateKey from "./../BlockchainPrivateKey";
import { Blockchain } from "@/cuties/model/pet/BlockchainId";
import type TronWeb from "tronweb";
import type { Signature } from "@/cuties/blockchain/BlockchainService";
import { BigNumber } from "bignumber.js";
import type { ConfirmTransactionParams, TronProvider } from "@/cuties/blockchain/tron/TronProvider";
import type { PersonalMessageSignature } from "@/components/LoginManager/TypeDefs";
import { ConfigInstance } from "@/cuties/engine/Config";
import CutiesApiFaucet from "@/app/cuties/blockchain/CutiesApiFaucet";

export class TronPrivateKey extends BlockchainPrivateKey implements TronProvider {
    protected tronWeb: TronWeb | null = null;

    protected readonly provider_key = "tron"; // change to unique
    protected readonly session_pwd_key = "TronAccountPassword";
    public readonly kind = "tron_private_key";
    protected readonly mnemonic_path = "m/44'/195'/0'/0/";

    constructor(private readonly config: TronConfig) {
        if (!config) {
            // may happen in if instantiated as a static field initializer
            throw new Error("Tried to initialize TronPrivateKey with empty config");
        }
        super();
    }

    protected get_wallet_storage_key(password_hash: string) {
        // Migration for tron
        const old_key = "PrividerName_wallet_" + password_hash;
        const old_stored_value = localStorage.getItem(old_key);
        if (old_stored_value) {
            localStorage.setItem(this.provider_key + "_wallet_" + password_hash, old_stored_value);
            localStorage.removeItem(old_key);
        }

        return super.get_wallet_storage_key(password_hash);
    }

    public async init(auth: any = false, callback?: Function, password?: string): Promise<TronWeb> {
        const fullNode = this.config.nodeUrl; // https://api.trongrid.io';
        const solidityNode = this.config.nodeUrl; // 'https://api.trongrid.io';
        const eventServer = this.config.nodeUrl; // 'https://api.trongrid.io/';

        const TronWeb = await CutiesApiFaucet.getTronWeb();
        const tronWeb = new TronWeb(fullNode, solidityNode, eventServer);

        const sessionHash = this.get_password_from_session();

        if (sessionHash) {
            const key = this.getStoredPrivateKey(sessionHash);
            const address = tronWeb.address.fromPrivateKey(key);
            if (address) {
                this.address = address;
                tronWeb.setPrivateKey(key);
                tronWeb.setAddress(address);
            } else {
                throw new Error("Cannot obtain TRON address");
            }
        }

        return tronWeb;
    }

    public get_provider_current_account_address(): string {
        return PLAYER_SINGLETON.get_tron_address();
    }

    public unlock(password: string, login?: any, callback?: Function): Promise<any> {
        this.migratePasswordHash(password);
        return this.private_key_init("login", password, callback);
    }

    public async mnemonic_to_private_key(mnemonic: string, account_index = 0) {
        const { Wallet } = await import(/* webpackChunkName: "EthersprojectWallet" */ "@ethersproject/wallet");

        // Load the first account from a mnemonic
        const path = this.mnemonic_path + account_index;
        const wallet = Wallet.fromMnemonic(mnemonic, path);

        return wallet.privateKey;
    }

    public async validate_mnemonic(mnemonic: string): Promise<boolean> {
        const bip39 = await import(/* webpackChunkName: "bip39" */ "bip39");
        return bip39.validateMnemonic(mnemonic);
    }

    public async get_address_from_private_key(pk: string): Promise<string> {
        const TronWeb = await CutiesApiFaucet.getTronWeb();
        return TronWeb.address.fromPrivateKey(pk);
    }

    public static async isValidPrivateKey(key: string) {
        const reg = /^[0-9A-Fa-f]{64}$/g;
        const TronWeb = await CutiesApiFaucet.getTronWeb();
        return !!TronWeb.address.fromPrivateKey(key) && reg.test(key);
    }

    protected async is_private_key(key: string) {
        return TronPrivateKey.isValidPrivateKey(key);
    }

    public async import_from_mnemonic(mnemonic: string, password: string, account_index = 0): Promise<string | undefined> {
        if (!(await this.validate_mnemonic(mnemonic))) {
            return I18nHelpers.translate("wallet_import_error_wrong_mnemonic");
        }

        const path = this.mnemonic_path + account_index;
        const { Wallet } = await import(/* webpackChunkName: "EthersprojectWallet" */ "@ethersproject/wallet");
        const wallet = Wallet.fromMnemonic(mnemonic, path);

        return await this.import(wallet.privateKey.substring(2), password, true);
    }

    public async sign(sign: string): Promise<Signature> {
        return { code: await this.signPersonalMessage(sign) };
    }

    public async getTronWeb(): Promise<TronWeb> {
        if (!this.tronWeb) {
            this.tronWeb = await this.init();
        }
        return this.tronWeb;
    }

    public async getWalletAddress(): Promise<string> {
        const tronWeb = await this.getTronWeb();
        return String(tronWeb.defaultAddress.base58);
    }

    public async signPersonalMessage(termsText: string): Promise<PersonalMessageSignature> {
        try {
            const tronWeb = await this.getTronWeb();
            const hexValue = tronWeb.toHex(termsText);
            return await tronWeb.trx.sign(hexValue);
        } catch (e) {
            if (e === "User has not unlocked wallet") {
                throw new Error("ERR_TRONLINK_EXT_INACTIVE");
            }
            throw e;
        }
    }

    public async confirmTransaction({ sendParams }: ConfirmTransactionParams): Promise<void> {
        const tronWeb = await this.getTronWeb();

        const [balance, resources, bandwidth] = await Promise.all([
            tronWeb.trx.getBalance(PLAYER_SINGLETON.get_tron_address()).then((sun) => tronWeb.fromSun(sun)),
            tronWeb.trx.getAccountResources(PLAYER_SINGLETON.get_tron_address()),
            tronWeb.trx.getBandwidth(PLAYER_SINGLETON.get_tron_address()),
        ]);

        const trxPrice = ConfigInstance.dynamic.usdPrices[Blockchain.Tron];
        const balance_usd = new BigNumber(balance).times(trxPrice).toFixed(2);

        let cpu_max = 0;
        let cpu_cur = 0;
        let cpu_fill = 0;
        let net_max = 0;
        let net_cur = 0;
        let net_fill = 0;

        if (!resources.freeNetUsed) resources.freeNetUsed = 0;
        if (!resources.EnergyUsed) resources.EnergyUsed = 0;

        if (resources && resources.freeNetLimit) {
            net_max = resources.freeNetLimit;
            if (resources.NetLimit) {
                net_max = resources.NetLimit + resources.freeNetLimit;
            }
            net_cur = bandwidth;
            net_fill = Math.round((bandwidth / net_max) * 100);
        }

        if (resources && resources.EnergyLimit) {
            cpu_max = resources.EnergyLimit;
            cpu_cur = resources.EnergyLimit - resources.EnergyUsed;
            cpu_fill = Math.round((cpu_cur / resources.EnergyLimit) * 100);
        }

        const popupData = {
            address: PLAYER_SINGLETON.get_tron_address(),
            balance: DataHelpers.formatMoney(balance),
            ballance_usd: balance_usd,
            cpu_max: cpu_max,
            cpu_cur: cpu_cur,
            cpu_fill: cpu_fill,
            net: DataHelpers.formatMoney(bandwidth, 0),
            net_max: DataHelpers.formatMoney(net_max, 0),
            net_cur: DataHelpers.formatMoney(net_cur, 0),
            net_fill: net_fill,
            bandwith: DataHelpers.formatMoney(bandwidth, 0),
            price: tronWeb.fromSun(sendParams.callValue ?? 0),
            price_usd: (Number(tronWeb.fromSun(sendParams.callValue ?? 0)) * trxPrice).toFixed(2),
            toAddress: sendParams.overrideContractAddress ?? ConfigInstance.tron.contracts[sendParams.contractName],
        };

        return new Promise((resolve, reject) => {
            mad.popup.show("tron_confirm_popup", popupData, false, function () {
                $("#tron_confirm").data("confirm_callback", () => resolve());
                $("#tron_reject").data("reject_callback", () => reject());
            });
        });
    }
}
