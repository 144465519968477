import { PLAYER_SINGLETON } from "@/cuties/model/player/Player";
import type { TronWebCutiesApi } from "./TronWebCutiesApi";
import CutiesApiFaucet from "@/app/cuties/blockchain/CutiesApiFaucet";
import router from "@/router";

const BlockchainCutiesAndroidApi = window["BlockchainCutiesAndroidApi"] || null;

interface MsgEnv<Tdata> {
    messageType: string;
    messageData: Tdata;
    referenceId?: number;
    error?: null; // applicable for reply messages
}
interface MsgDataIn {}
interface MsgDataOut {}

interface MsgDataInStatus extends MsgDataIn {
    status: ReadyStatus;
    message: string;
    /** available since '2.0' */
    apiVersion?: string;
}
interface MsgDataInTransmitPushEvent extends MsgDataIn {
    eventType: "goToUrl" | string;
    /** JSON string */
    eventDataStr: string;
    triggerAppState: "FOREGROUND" | "BACKGROUND" | "CLOSED";
}
interface MsgDataOutGetTronAddresses extends MsgDataIn {
    addresses: string[];
}
interface MsgDataOutSignRawTx extends MsgDataOut {
    unsignedTxHex: string;
}
interface MsgDataInSignRawTx extends MsgDataIn {
    signedMessage: string;
}

type MsgResolve = (msgData: MsgDataOut) => void;
type MsgReject = (exc: Error) => void;
type CallbackTuple = [MsgResolve, MsgReject];

export enum ReadyStatus {
    /** @deprecated - use SCW_INSTANTIATED */
    READY_FOR_INCOMING_MESSAGES = "READY_FOR_INCOMING_MESSAGES",
    /** ready for incoming messages - Samsung blockChain Wallet available */
    SCW_INSTANTIATED = "SCW_INSTANTIATED",
    /** ready for incoming messages - Samsung blockChain Wallet not available (pre-2019 smartphone) */
    SCW_NOT_INSTANTIATED = "SCW_NOT_INSTANTIATED",
    /**
     * triggered when player presses Go Back button on the phone - expects js to respond
     * whether or not there are previous pages left in history - if js says "no" app
     * prompts user to close it, currently js always returns false due to Router issues
     */
    REQUEST_GO_BACK = "REQUEST_GO_BACK",
}

let readyStatus: ReadyStatus | null = null;
let resolveReady: (msgData: MsgDataInStatus) => void;
let tron: TronWebCutiesApi | null = null;
const whenReady = new Promise<MsgDataInStatus>((resolve, reject) => {
    if (BlockchainCutiesAndroidApi != null) {
        CutiesApiFaucet.getTron().then(tronArg => tron = tronArg);
        resolveReady = (statusData) => {
            readyStatus = statusData.status;
            resolve(statusData);
        };
    } else {
        const msg = "BlockchainCutiesAndroidApi not available. You sure this page is running from APK?";
        reject(new Error(msg));
    }
});
// prevent Uncaught (in promise) Error on desktop
// eslint-disable-next-line @typescript-eslint/no-empty-function
whenReady.catch((exc) => {});

let postLoginRedirects: string[] = [];

const referenceIdToCallback: { [id: number]: CallbackTuple } = {};
let lastReferenceId = 0;

/**
 * the Android APK protocol expects this method to be overridden
 * by us so that it could post us messages through it
 */
(BlockchainCutiesAndroidApi || {}).handleMessage = (record: MsgEnv<MsgDataOut>): { status: string } => {
    console.debug("AndrdoidApi.handleMessage", record);
    const tuple: CallbackTuple = referenceIdToCallback[record.referenceId];
    if (tuple) {
        const [resolve, reject] = tuple;
        delete referenceIdToCallback[record.referenceId];
        if (record.error && !record.messageData) {
            reject(new Error("Android call returned error - " + record.error));
        } else {
            resolve(record.messageData);
        }
    } else if (record.messageType === "status") {
        const data = <MsgDataInStatus>record.messageData;
        if ([ReadyStatus.SCW_INSTANTIATED, ReadyStatus.READY_FOR_INCOMING_MESSAGES].includes(data.status)) {
            resolveReady(data);
        } else if (data.status === ReadyStatus.SCW_NOT_INSTANTIATED) {
            resolveReady(data);
        } else if (data.status === ReadyStatus.REQUEST_GO_BACK) {
            const historyIndex = (<any>window.History).getState()?.data?.index || 0;
            // 0 - first push_state makes it instantly "1"
            // 1 - index_samsung_tron
            // 2 - pets_my
            const canGoBack = historyIndex > 2;
            if (canGoBack) {
                // could just window.history.goBack() ourselves instead...
                post("confirmCanGoBack", {});
            } else {
                post("requestShutDown", {});
            }
        }
    } else if (record.messageType === "transmitPushEvent") {
        const data = <MsgDataInTransmitPushEvent>record.messageData;
        const eventData = JSON.parse(data.eventDataStr);
        if (data.eventType === "goToUrl") {
            const { url } = eventData;
            let canRedirect = true;
            const loggedIn = PLAYER_SINGLETON.is_logined() && tron && tron.get_provider();
            if (loggedIn && data.triggerAppState === "FOREGROUND") {
                canRedirect = confirm(`Something new happened at ${url}. Should we go there now?`);
            }
            if (canRedirect) {
                if (loggedIn) {
                    router.push({ path: url });
                } else {
                    postLoginRedirects.push(url);
                }
            }
        } else {
            console.warn("Unsupported PUSH event type " + data.eventType, record);
        }
    } else {
        return { status: "UNKNOWN_MESSAGE" };
    }
    return { status: "HANDLED_SUCCESSFULLY" };
};

const post = <Tout extends MsgDataOut, Tin extends MsgDataIn>(messageType: string, messageData: Tout): Promise<Tin> =>
    whenReady.then(() => {
        return new Promise((resolve, reject) => {
            const referenceId = ++lastReferenceId;
            const msgEnv: MsgEnv<Tout> = {
                referenceId: referenceId,
                messageType: messageType,
                messageData: messageData,
            };
            console.debug("AndrdoidApi.postMessage", msgEnv);
            window["BlockchainCutiesAndroidApi"].postMessage(JSON.stringify(msgEnv));
            referenceIdToCallback[referenceId] = [resolve, reject];
        });
});

let whenGetTronAddresses: Promise<MsgDataOutGetTronAddresses> | null = null;

export default class AndroidApi {
    public static whenReady = whenReady;

    public static isAvailable(): boolean {
        return BlockchainCutiesAndroidApi != null;
    }

    public static getReadyStatus(): ReadyStatus {
        return readyStatus;
    }

    public static flushPostLoginRedirect() {
        const lastRedirect = postLoginRedirects.pop();
        postLoginRedirects = [];
        return lastRedirect;
    }

    public static getTronAddresses(): Promise<MsgDataOutGetTronAddresses> {
        if (whenGetTronAddresses === null) {
            whenGetTronAddresses = post("getTronAddresses", {});
        }
        return whenGetTronAddresses;
    }

    public static signTronTx(msgData: MsgDataOutSignRawTx): Promise<MsgDataInSignRawTx> {
        return post("signTronTx", msgData);
    }

    public static signTronPersonalMessage(msgData: MsgDataOutSignRawTx): Promise<MsgDataInSignRawTx> {
        return post("signTronPersonalMessage", msgData);
    }

    public static reportStatus(params: { status: string; message: string }): Promise<MsgDataIn> {
        return post("status", params);
    }

    public static reportSignedOut(): Promise<MsgDataIn> {
        return post("signedOut", {});
    }
}
