
    import type { Player } from "@/cuties/model/player/Player";
    import { Component, Emit, Prop, Vue } from "vue-property-decorator";
    import { Getter } from "vuex-class";
    import { ConfigInstance } from "@/cuties/engine/Config";
    import { RouterLink } from "vue-router";

    @Component({
        components: { RouterLink },
    })
    export default class HeaderLangs extends Vue {
        @Prop({ default: false }) mobile!: boolean;
        @Getter("getPlayer") user!: Player;

        get supportedLanguages() {
            return ConfigInstance.get_supported_languages();
        }

        getLink(lang: string): string {
            if (this.$route.params.lang) {
                return "/" + lang + this.$route.fullPath.replace(/^\/[^/]+/, "");
            } else {
                return "/" + lang + this.$route.fullPath;
            }
        }

        @Emit("changed")
        changed() {}
    }
