import type ItemStackModel from "../model/item/ItemStackModel";
import store from "@/store";
import type { ItemGroupKind } from "@/cuties/model/item/ItemGroupKind";
import type { ItemRarityKind } from "@/app/cuties/items/ItemApiService";
import type { PetKind } from "@/cuties/model/pet/PetModel";

export default class QuestService {
    public static setTimer(userState: DailyQuestUserState, quests: DailyQuestDto[]): number | null {

        const questClosestPoint = quests
            .filter(q => q.willBeAvailableSeconds)
            .map(q => q.willBeAvailableSeconds)
            .sort((a, b) => a - b)[0] || 0;

        let resetTimeoutSeconds = Number.MAX_SAFE_INTEGER;

        if (userState.resetNextTick) {
            const now = new Date();
            const resetTimeout = new Date(userState.resetNextTick);
            resetTimeoutSeconds = (resetTimeout.getTime() - now.getTime()) / 1000;
        }

        // get closest refresh event, in case when on event is 0 take future event
        const closestPoint = questClosestPoint > 0 && resetTimeoutSeconds > 0
            ? Math.min(questClosestPoint, resetTimeoutSeconds)
            : Math.max(questClosestPoint, resetTimeoutSeconds);

        if (closestPoint > 0) {
            return window.setTimeout(function () {
                store.dispatch("loadQuests");
            }, closestPoint * 1000);
        }

        return null;
    }

    public static checkForNew(quests: DailyQuestDto[]): boolean {
        return quests.some(q => !q.seen);
    }

}

/** @see QuestsDetails.java */
export interface QuestsDetails {
    quests: DailyQuestDto[];
    questUserData: DailyQuestUserState;

    newQuestsCount: number; // new quest count when open first time
    maxNewQuestsPerDayOnComplete: number;
    maxNewQuestsPerDayOnCompleteResetTime: number; // in seconds
}

/** @see DailyQuestDto.java */
export interface DailyQuestDto {
    id: number;
    quest: string; // suggest name or questName - translation key
    description: string; // translation key
    background: string;
    started: boolean;
    willBeAvailable: string | null; // timestamp if quest available after time then set
    willBeAvailableSeconds: number; // duration - till moment in seconds
    seen: boolean;
    progress: number;
    threshold: number;
    rewards: ItemStackModel[];
    objectives: DailyQuestModel[];
}

/** @see DailyQuestUserState.java */
export interface DailyQuestUserState {
    resetTimeout: string; // reset timeout from config in seconds
    resetNextTick: string; // timestamp when next tick
    completeNextTick: string; // timestamp when next tick
    resetCount: number; // max resets -  from config
    currentResetCount: number; // current user reset count
    completeCount: number; // max can complete - from config
    completed: number; // current completed
}

interface DailyQuestModelBase {
    type: string;
    count: number;
    /** any of criteria */
    criteria: unknown[];
}

export type DailyQuestModel = DailyQuestModelBase & ({
    type: "countAdventure" | "winAdventure" | "loseAdventure" | "clearDungeonStage";
    criteria: { location?: "*" | string | null }[];
} | {
    type: "getItem" | "likeACutieEquippedWithAnItem";
    criteria: {
        location?: "*" | string;
        item?: DailyQuestItemModel;
    }[];
} | {
    type: "sellItem" | "useItem";
    criteria: { item: DailyQuestItemModel }[];
} | {
    type: "countRaid";
    criteria: { boss: DailyQuestBossModel }[];
} | {
    type: "countBreedParents" | "countBreedChild";
    criteria: BreedCriteria[];
} | {
    type: "winInFightClub" | "enlistToATournament" | "craftAnItemInTimedCrafting" | "nameACutie" | "writeABioForACutie";
});

export interface BreedCriteria {
    mother?: BaseCriteriaModel;
    father?: BaseCriteriaModel;
    child?: BaseCriteriaModel;
}

export type DailyQuestSourceType =
    | "adventure"    // the item is received from adventure
    | "raid"         // the item is received from a raid boss
    | "achievement"  // the item is received from achievement
    | "market"       // the item was bought from the item market
    | "pawshop"      // the item was crafted in the paw shop crafting system or bought on Paw shop or bought on Cute coin shop
    | "forgeEnchant" // the item was enchanted in Forge
    | "forgeCollect" // the item was collected in Forge recycle
    | "cutie"        // the item was received from a cutie (unequipped)
    | "event"        // the item was received from an event rewarding source (collect a prize)
    | "admin";       // the item was generated by admin or gifted by any account with rights to gift items

export interface DailyQuestItemModel {
    source?: DailyQuestSourceType[];
    itemKind?: string;
    itemGroup?: keyof typeof ItemGroupKind;
    itemRarity?: ItemRarityKind;
    /** item enchant level before the action */
    itemLevel?: number;
    /** item enchant level after the action if applicable */
    itemEnchantmentLevel?: number;
}

interface DailyQuestBossModel {
    boss: "*" | string;   // real boss name
    name?: string;   // "A", "B" ...
    level?: number;  // current boss level
    /** I doubt it will ever be used */
    ver?: number;    // config version 1 or 2
}

interface BaseCriteriaModel {
    type?: keyof typeof PetKind;
    attribute?: string[];
}
