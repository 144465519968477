import type { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import type { RootState } from "@/store/RootState";
import PresaleService from "@/cuties/presale/PresaleService";
import PresaleRewardsService from "@/cuties/presale/PresaleRewardsService";
import type SaleModel from "@/cuties/model/sale/SaleModel";
import type { ShopCurrency } from "@/cuties/model/sale/CurrencyModel";
import type { BlockchainPriceModel } from "@/app/cuties/presale/PresaleCutie";

export interface PresaleState {
    lots: Record<number, Record<ShopCurrency, BlockchainPriceModel>>;
    saleModel: SaleModel;
}

export const state: PresaleState = {
    lots: undefined,
    saleModel: undefined,
};

export const getters: GetterTree<PresaleState, RootState> = {
    getLots: (state) => state.lots,
    getSaleModel: (state) => state.saleModel,
};

export const mutations: MutationTree<PresaleState> = {
    setLots: (state, payload) => (state.lots = payload),
    setSaleModel: (state, payload) => (state.saleModel = payload),
};

export const actions: ActionTree<PresaleState, RootState> = {
    loadLots: async ({ commit }) => {
        const response = await PresaleService.getPresaleLotList();
        commit("setLots", response);
        return true;
    },
    loadSaleModel: async ({ commit }, payload) => {
        const response = await PresaleRewardsService.getSaleModel(payload.path);
        commit("setSaleModel", response);
        return true;
    },
};

class PresaleStore implements Module<PresaleState, RootState> {
    public namespaced: boolean = false;
    public state: PresaleState = state;
    public getters: GetterTree<PresaleState, RootState> = getters;
    public mutations: MutationTree<PresaleState> = mutations;
    public actions: ActionTree<PresaleState, RootState> = actions;
}

// return singleton
export const PresaleStoreSingleton = new PresaleStore();
