

function reverse(text: string): string {
    return text.split("").reverse().join("");
}

export function getCommonPrefix(texts: string[]): string {
    const any = texts[0];
    if (!any) {
        return "";
    }
    const maxLength = Math.min(...texts.map(t => t.length));
    for (let offset = 0; offset < maxLength; ++offset) {
        const chars = new Set(texts.map(t => t[offset]));
        if (chars.size > 1) {
            return any.slice(0, offset);
        }
    }
    // one of strings completely consists of the common prefix
    return any.slice(0, maxLength);
}

export function getCommonPostfix(texts: string[]): string {
    return reverse(getCommonPrefix(texts.map(reverse)));
}

/** @see https://stackoverflow.com/a/7616484/2750743 */
export function hashCode(str: string) {
    let hash = 0, i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr   = str.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}
