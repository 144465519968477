
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Emit, PropSync } from "vue-property-decorator";
    import type { FilteredCutiesListResponse } from "@/app/cuties/tournament/TournamentService";
    import type { CutieListEntryMinimalView } from "cuties-client-components/types/api/Cutie";
    import type { CutieFilterState, PetFilter } from "@/app/cuties/list/CutieListApiService";
    import CutieListFiltersCriteria from "@/app/components/cutie/list/CutieListFiltersCriteria.vue";
    import type { CutieListMyRequest } from "@/app/cuties/list/CutieListApiService";
    import CutieListApiService from "@/app/cuties/list/CutieListApiService";
    import ActionsQueue from "@/app/cuties/utils/ActionsQueue";
    import CutieCardImageFromAssets from "@/app/components/cutie/cards/CutieCardImageFromAssets.vue";
    import UserApiService from "@/cuties/player/UserApiService";

    @Component({
        components: { CutieCardImageFromAssets, CutieListFiltersCriteria }
    })
    export default class FeaturedCutieSelectPopup extends Vue {
        @PropSync("visible", { required: true }) syncedVisible!: boolean;

        private lastResponse: FilteredCutiesListResponse<CutieListEntryMinimalView> | null = null;
        private filterState: CutieFilterState<string | number> = {};
        private loading = false;
        private pageRequestsQueue = ActionsQueue();

        created() {
            this.loadPage(0);
        }

        private async loadPage(page: number) {
            const request: CutieListMyRequest = {
                filter: this.filterState,
                ignoreGroups: true,
                groupId: null,
                page: page,
                sortBy: "Age",
                sortOrder: "Desc",
            };
            await this.pageRequestsQueue.enqueue(async () => {
                this.loading = true;
                try {
                    this.lastResponse = await CutieListApiService.listMy(request);
                } finally {
                    this.loading = false;
                }
            });
        }

        private async updateFiltersState(newState: PetFilter) {
            this.filterState = newState;
            await this.loadPage(0);
        }

        pageClick(pageNumber: number) {
            this.loadPage(pageNumber - 1);
        }

        /** for infinite pagination: to not linearly scan whole table */
        get assumedTotal(): number {
            if (!this.lastResponse) {
                return 0;
            } else {
                const passed = this.lastResponse.cutiesPage.number
                    * this.lastResponse.cutiesPage.size;
                if (this.lastResponse.cutiesPage.content.length === 0) {
                    // end of the list, show 1 extra page to be able to return
                    return passed + 1;
                } else if (this.lastResponse.cutiesPage.content.length < this.lastResponse.cutiesPage.size) {
                    return passed + this.lastResponse.cutiesPage.content.length;
                } else {
                    // show 1 extra page
                    return passed + this.lastResponse.cutiesPage.size + 1;
                }
            }
        }

        async selectCutie(cutie: CutieListEntryMinimalView) {
            await UserApiService.setFeaturedCutie({ cutieDbId: cutie.id });
            this.selected();
            this.syncedVisible = false;
        }

        @Emit("selected")
        selected() {}
    }
