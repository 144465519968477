import BigNumber from "bignumber.js";

export default class Money {
    /**
     * Amount (wei)
     */
    private amount: BigNumber;


    constructor(amount: any) {
        if (amount instanceof Money) {
            const m = amount as Money;
            this.amount = new BigNumber(m.getAmountRaw());
        } else {
            this.amount = new BigNumber(amount);
        }
    }

    get_eth_raw(): number {
        const value = this.amount.div(new BigNumber("1000000000000000000")); // shift dot 18 positions - wei to eth
        const res = value;
        return res.toNumber();
    }

    getAmountRaw(): BigNumber {
        return this.amount;
    }

    /**
     * returns number value of BigNumber - can be exponential
     * @returns {number}
     */
    getAmount(): number {
        return this.amount.toNumber();
    }

    /**
     * returns string value of BigNumber - can be exponential
     * @returns {string}
     */
    getAmountString(): string {
        return this.amount.toString();
    }

    /**
     * Returns string value of BigNumber "as is" - can't be exponential
     * @returns {string}
     */
    getAmountFixed(): string {
        return this.amount.toFixed(0, BigNumber.ROUND_CEIL);
    }

    add(a: number): Money {
        this.amount = this.amount.plus(a);
        return this;
    }

    addBigNumber(a: BigNumber): Money {
        this.amount = this.amount.plus(a);
        return this;
    }

    abs(): Money {
        this.amount = this.amount.abs();// = Math.abs(this.amount);
        return this;
    }

    divide(a: number): Money {
        this.amount = this.amount.div(a);
        return this;
    }

    multiply(a: number): Money {
        this.amount = this.amount.times(a);
        return this;
    }

    static plus(value1: Money, value2: Money): Money {
        return new Money(value1.getAmountRaw().plus(value2.getAmountRaw()));
    }

    static minus(value1: Money, value2: Money): Money {
        return new Money(value1.getAmountRaw().minus(value2.getAmountRaw()));
    }
}
