import i18n from "@/i18n";

/**
 * copied as is from mad.ts, there were no attempts to improve code quality
 * supposed to be zero dependency
 */
export default class I18nHelpers {
    static translate(key: string): string {
        return i18n.t(key).toString();
    }

    static translationExists(key: string) {
        return i18n.te(key);
    }

    static get_time_duration_string(seconds:number): string {
        seconds = Math.ceil(seconds);
        if (seconds <= 0) {
            return "0 " + this.translate("seconds");
        }

        if (seconds < 60) {
            //d(seconds);
            return seconds + " " + (seconds == 1 ? this.translate("second") : this.translate("seconds"));
        }

        const minutes = Math.ceil(seconds / 60);
        if (minutes < 60) {
            //d(seconds, minutes);
            return minutes + " " + (minutes == 1 ? this.translate("minute") : this.translate("minutes"));
        }

        const hours = Math.ceil(minutes / 60);
        if (hours < 24) {
            //d(seconds, minutes, hours);
            return hours + " " + (hours == 1 ? this.translate("hour") : this.translate("hours"));
        }

        const days = Math.ceil(hours / 24);
        //d(seconds, minutes, hours, days);
        return days + " " + (days == 1 ? this.translate("day") : this.translate("days"));
    }

    /** @return - "13d 5h 18m" */
    static get_time_duration_string_short(sec:number) {
        if (sec < 60) {
            return sec + this.translate("TimeSecondsSh");
        }

        const mins = Math.floor(sec / 60);
        if(mins < 60) {
            return mins + this.translate("TimeMinutesSh");
        }
        let hours = mins / 60;
        if(hours < 24) {
            if(mins % 60 == 0) {
                return hours + this.translate("TimeHoursSh");
            }
            const fullHours = Math.floor(hours);
            const leftMins = mins - (fullHours * 60);
            return fullHours + this.translate("TimeHoursSh") + " " + leftMins + this.translate("TimeMinutesSh");
        }

        hours = Math.floor(hours);
        const days = hours / 24;
        if(hours % 24 == 0) {
            return days + this.translate("TimeDaysSh");
        }
        const fullDays = Math.floor(days);
        const leftHours = Math.floor(hours - (fullDays * 24));
        return fullDays + this.translate("TimeDaysSh") + " " + leftHours + this.translate("TimeHoursSh");
    }
}
