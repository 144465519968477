import I18nHelpers from "@/cuties/engine/I18nHelpers";
import mad from "@/cuties/engine/mad";
import { Blockchain } from "@/cuties/model/pet/BlockchainId";
import { TronPrivateKey } from "./blockchain/tron/TronPrivateKey";
import type BlockchainAccountManager from "./BlockchainAttachManager";
import store from "@/store";
import { AppStoreActions } from "@/store/AppStore";
import { ConfigInstance } from "@/cuties/engine/Config";
import { ShowLoader } from "@/common/decorators/ShowLoader";
import CutiesApiFaucet from "@/app/cuties/blockchain/CutiesApiFaucet";
import type { WalletProviderKind } from "@/components/LoginManager/TypeDefs";
import i18n from "@/i18n";
import UserApiService from "@/cuties/player/UserApiService";
import IntroductionSystem from "@/cuties/player/introduction/IntroductionSystem";
import type { AxiosError } from "axios";
import VueUtils from "@/cuties/VueUtils";
import LoginService from "@/cuties/model/player/LoginService";

interface MergePopupData {
    title: string;
    description: string;
    walletProviderName: string;
    blockchain: Blockchain;
    back_handler?: string;
    remove_close?: boolean;
    /** see HandlerManager.handlers */
    action: string;
}

export default class AttachTronAccountManager implements BlockchainAccountManager {
    private get privateKeyProvider() {
        return new TronPrivateKey(ConfigInstance.tron);
    }

    attachFromTronLink_name = "attachFromTronLink";
    attachFromPrivateKey_name = "attachFromPrivateKey";
    attachFromSamsung_name = "attachFromSamsung";
    attachGenerateFromMnemonic_name = "attachGenerateFromMnemonic";
    attachFromPrivateKeyOpen_name = "attachFromPrivateKeyOpen";
    attachFromMnemonicOpen_name = "attachFromMnemonicOpen";
    attachFromMnemonic_name = "attachFromMnemonic";
    attachCreateOpen_name = "attachCreateOpen";
    attachPrivateKeyOptionsOpen_name = "attachPrivateKeyOptionsOpen";
    generateMnemonic_name = "generateMnemonic";
    attachCheckMnemonicOpen_name = "attachCheckMnemonicOpen";
    attachGoToStart_name = "attachGoToStart";
    protected icon = "newTRX-white";

    private readonly popup_data: Record<string, unknown>;

    public constructor() {
        this.popup_data = {
            remove_close: false,
            attachFromTronLink: this.attachFromTronLink_name,
            attachFromPrivateKey: this.attachFromPrivateKey_name,
            attachFromSamsung_name: this.attachFromSamsung_name,
            attachGenerateFromMnemonic: this.attachGenerateFromMnemonic_name,
            attachFromPrivateKeyOpen: this.attachFromPrivateKeyOpen_name,
            attachFromMnemonicOpen: this.attachFromMnemonicOpen_name,
            attachFromMnemonic: this.attachFromMnemonic_name,
            attachCreateOpen: this.attachCreateOpen_name,
            attachPrivateKeyOptionsOpen: this.attachPrivateKeyOptionsOpen_name,
            generateMnemonic: this.generateMnemonic_name,
            attachCheckMnemonicOpen: this.attachCheckMnemonicOpen_name,
            icon: this.icon,
        };
    }

    private get_popup_data() {
        return { ...this.popup_data };
    }

    protected getBlockchain() {
        return Blockchain.Tron;
    }

    public OpenPopupAttachAccount(): void {
        this.register_all_handlers();
        this.attachGoToStart();
    }

    public register_all_handlers() {
        mad.handler.register(this.attachFromTronLink_name, this.attachFromTronLink.bind(this));
        mad.handler.register(this.attachFromPrivateKey_name, this.attachFromPrivateKey.bind(this));
        mad.handler.register(this.attachFromSamsung_name, this.attachFromTronSamsung.bind(this));
        mad.handler.register(this.generateMnemonic_name, this.generateMnemonic.bind(this));
        mad.handler.register(this.attachGenerateFromMnemonic_name, this.attachGenerateFromMnemonic.bind(this));
        mad.handler.register(this.attachFromPrivateKeyOpen_name, this.attachFromPrivateKeyOpen.bind(this));
        mad.handler.register(this.attachFromMnemonicOpen_name, this.attachFromMnemonicOpen.bind(this));
        mad.handler.register(this.attachFromMnemonic_name, this.attachFromMnemonic.bind(this));
        mad.handler.register(this.attachCreateOpen_name, this.attachCreateOpen.bind(this));
        mad.handler.register(this.attachPrivateKeyOptionsOpen_name, this.attachPrivateKeyOptionsOpen.bind(this));
        mad.handler.register(this.attachCheckMnemonicOpen_name, this.attachCheckMnemonicOpen.bind(this));
        mad.handler.register(this.attachGoToStart_name, this.attachGoToStart.bind(this));
    }

    onAttachSuccess() {
        const popup = this.get_popup_data();
        popup["title"] = I18nHelpers.translate("tron_attach_account_done_title");
        popup["description"] = I18nHelpers.translate("tron_attach_account_done_description");
        popup["icon"] = "tron";

        mad.popup.show("account_manager_attach_done_popup", popup);
        store.dispatch(AppStoreActions.REFRESH_BLOCKCHAIN, this.getBlockchain());
    }

    public attachGoToStart() {
        const popup = this.get_popup_data();
        popup["back_handler"] = this.attachGoToStart_name;

        mad.popup.show("account_manager_tron_attach_popup", popup, false, function () {
            $(".start_back_hadler").hide();
        });
    }

    public attachCheckMnemonicOpen() {
        const mnemonic = <string>$("#mnemonic_field").val();

        const rnd1 = Math.floor(Math.random() * Math.floor(3) + 1);
        const rnd2 = rnd1 + 3;
        const rnd3 = rnd2 + 3;

        const popup = this.get_popup_data();
        popup["rnd1"] = rnd1;
        popup["rnd2"] = rnd2;
        popup["rnd3"] = rnd3;
        popup["mnemonic"] = mnemonic;
        popup["back_handler"] = this.attachGoToStart_name;
        popup["title"] = I18nHelpers.translate("almostThere");

        mad.popup.show("account_manager_create_account_mnemonic_check", popup, false, function () {});
    }

    public async attachCreateOpen() {
        const bip39 = await import(/* webpackChunkName: "bip39" */ "bip39");
        const mnemonic = bip39.generateMnemonic();

        const popup = this.get_popup_data();
        popup["mnemonic"] = mnemonic;
        popup["back_handler"] = this.attachGoToStart_name;
        popup["title"] = I18nHelpers.translate("tron_create_account_title");

        mad.popup.show("account_manager_create_pk_account", popup, false, function () {});
    }

    public attachFromMnemonicOpen() {
        const popup = this.get_popup_data();
        popup["back_handler"] = this.attachGoToStart_name;
        popup["title"] = I18nHelpers.translate("tron_wallet_import_pk_title");

        mad.popup.show("account_manager_import_attach_account_mnemonic_popup", popup, false, function () {});
    }

    public attachFromPrivateKeyOpen() {
        const popup = this.get_popup_data();
        popup["back_handler"] = this.attachGoToStart_name;
        popup["title"] = I18nHelpers.translate("tron_wallet_import_pk_title");
        popup["pk_title"] = I18nHelpers.translate("wallet_private_key");

        mad.popup.show("account_manager_import_attach_account_pk_popup", popup, false, function () {});
    }

    public async attachGenerateFromMnemonic() {
        const obj = mad.collect_form_values("#account_manager_account_create", "data-id");
        this.clear_errors();

        const mnemonic = <string>obj.mnemonic;
        const mneminic_splited = mnemonic.split(" ");

        if (!obj.password) {
            $(".error_div").html(I18nHelpers.translate("wallet_error_missing_pwd"));
            return false;
        }

        if (obj.password != obj.password_repeat) {
            $(".error_div").html(I18nHelpers.translate("wallet_error_pwd_dismatch"));
            return false;
        }

        if (
            obj.mnemonic_word_1.trim() != mneminic_splited[parseInt(obj.mnemonic_number_1) - 1] ||
            obj.mnemonic_word_2.trim() != mneminic_splited[parseInt(obj.mnemonic_number_2) - 1] ||
            obj.mnemonic_word_3.trim() != mneminic_splited[parseInt(obj.mnemonic_number_3) - 1]
        ) {
            $(".error_div").html(I18nHelpers.translate("wallet_mnemonic_missmatch"));
            return false;
        }

        const pk = (await this.privateKeyProvider.mnemonic_to_private_key(mnemonic)).substring(2); // remove 0x

        const errorMsg = await this.privateKeyProvider.import(pk, obj.password, true);
        if (errorMsg) {
            console.log(errorMsg);
            this.clear_errors();
            return false;
        } else {
            try {
                await this.attach_account("private_key");
                this.onAttachSuccess();
            } catch (e) {
                this.onAttachError(e, "private_key");
            }
        }
    }

    public attachPrivateKeyOptionsOpen() {
        this.clear_errors();
        $("#tron_create_select").hide();
        $("#tron_create_select_pk").show();
        $(".start_back_hadler").show();
    }

    public async generateMnemonic() {
        const bip39 = await import(/* webpackChunkName: "bip39" */ "bip39");
        const mnemonic = bip39.generateMnemonic();

        $("#mnemonic_field").val(mnemonic);
        $("#mnemonic_field").closest(".wallet-input-wrap").find(".copy-phrase-link").html(I18nHelpers.translate("copy"));
    }

    public async attachFromPrivateKey() {
        const obj = mad.collect_form_values("#tron_private_key_import", "data-id");

        if (!obj.password) {
            $(".error_div").html(I18nHelpers.translate("wallet_error_missing_pwd"));
            return false;
        }

        if (obj.password != obj.password_repeat) {
            $(".error_div").html(I18nHelpers.translate("wallet_error_pwd_dismatch"));
            return false;
        }

        const errorMsg = await this.privateKeyProvider.import(obj.private_key, obj.password, true);

        if (errorMsg) {
            $(".error_div").html(errorMsg);
        } else {
            try {
                await this.attach_account("private_key");
                this.onAttachSuccess();
            } catch (e) {
                this.onAttachError(e, "private_key");
            }
        }
    }

    public attachFromTronSamsung() {
        alert("attachFromTronSamsung");
    }

    public async attachFromMnemonic() {
        const obj = mad.collect_form_values("#tron_form", "data-id");
        const mnemonic = obj.mnemonic.trim();

        if (!obj.password) {
            $(".error_div").html(I18nHelpers.translate("wallet_error_missing_pwd"));
            return false;
        }

        if (obj.password != obj.password_repeat) {
            $(".error_div").html(I18nHelpers.translate("wallet_error_pwd_dismatch"));
            return false;
        }

        if (!(mnemonic.trim().split(/\s+/g).length >= 12) || !(await this.privateKeyProvider.validate_mnemonic(mnemonic))) {
            $(".error_div").html(I18nHelpers.translate("wallet_import_error_wrong_mnemonic"));
            return false;
        }

        const errorMsg = await this.privateKeyProvider.import_from_mnemonic(mnemonic, obj.password);

        if (errorMsg) {
            $(".error_div").html(errorMsg);
        } else {
            try {
                await this.attach_account("private_key");
                this.onAttachSuccess();
            } catch (e) {
                this.onAttachError(e, "private_key");
            }
        }
    }

    public async attachFromTronLink() {
        this.clear_errors();

        try {
            await this.attach_account("tronlink");
            this.onAttachSuccess();
        } catch (e) {
            this.onAttachError(e, "tronlink");
        }
    }

    private clear_errors() {
        $(".error_div").html("");
    }

    @ShowLoader
    private async initPrivateKeyProvider(): Promise<void> {
        await this.privateKeyProvider.getTronWeb();
    }

    private async attach_account(provider_name: string, data?: [string, string, string]): Promise<any> {
        const tron = await CutiesApiFaucet.getTron();
        try {
            let account, signature, salt;

            if (!data) {
                [account, signature, salt] = await tron.signTerms(provider_name);
            } else {
                [account, signature, salt] = data;
            }

            const { user } = await UserApiService.attachBlockchainAccountToUser({
                blockchain: this.getBlockchain(),
                account: account,
                code: signature,
                salt: salt,
            });
            IntroductionSystem.completeStep("ATTACH_BLOCKCHAIN_ACCOUNT");
            LoginService.setAddedBlockchainAddress(this.getBlockchain(), account);
            return { user };
        } catch (error) {
            tron.clean_provider();
            throw error;
        }
    }

    private show_popup_error(text:string) {
        $(".error_div").html(text);
    }

    private onAttachError(error: AxiosError | any, providerName: WalletProviderKind | string) {
        const errorData = VueUtils.extractErrorData(error);
        if (errorData && errorData.error === "errorGameAccountAlreadyExist") {
            const popup_data = this.get_popup_data();

            popup_data.title = I18nHelpers.translate("game_account_merge_title");
            popup_data.description = I18nHelpers.translate("game_account_merge_description");
            popup_data.back_handler = this.attachGoToStart_name;
            popup_data.walletProviderName = providerName;
            popup_data.blockchain = this.getBlockchain();

            const action = "mergeBlockchainAccounts";
            if (!mad.handler.exist(action)) {
                const msg = i18n.t("ERR_MISSING_MERGE_SUPPORT_FOR_PROVIDER", [providerName]).toString();
                this.show_popup_error(msg);
                return;
            }
            popup_data.action = action;

            mad.popup.show("account_merge_popup", popup_data);
        } else {
            this.show_popup_error(error);
        }
    };
}
