/**
 * this class holds the logic of what behaviour should
 * app have depending on player's environment
 *
 * for example, in Samsung apk you can only use TRX,
 * so you should not be suggested to buy ETH cuties
 */
import { Blockchain } from "@/cuties/model/pet/BlockchainId";
import AndroidApi from "@/cuties/blockchain/tron/AndroidApi";
import type { WalletProviderKind } from "@/components/LoginManager/TypeDefs";
import type EthereumProvider from "@walletconnect/ethereum-provider/dist/types/EthereumProvider";

class PlayerEnvironment {
    public static getDefaultFilterBlockchains(): Blockchain[] {
        if (AndroidApi.isAvailable()) {
            // per Tron's request, we limited the Samsung apk usage to only TRX,
            // though technically we could also use ETH in some distant future...
            return [Blockchain.Tron];
        } else {
            return [];
        }
    }

    public static isImtokenWalletAvailable() {
        return (<any>window).imToken;
    }

    public static isCutiesWalletAvailable() {
        const web3 = (<any>window).web3;
        return web3 && web3.currentProvider && web3.currentProvider.isCutiesWallet;
    }

    public static isGoWalletAvailable() {
        const web3 = (<any>window).web3;
        return web3 && web3.currentProvider && web3.currentProvider.isGoWallet;
    }

    public static isWalletAvailable(kind: WalletProviderKind): boolean {
        const web3 = (<any>window).web3;
        switch (kind) {
            case "metamask":
                return web3?.currentProvider?.isMetaMask;
            case "im_token":
                return !!(<any>window).imToken;
            case "cuties_wallet":
                return web3?.currentProvider?.isCutiesWallet;
            case "go_wallet":
                return web3?.currentProvider?.isGoWallet;
            case "bitkeep":
                return web3?.currentProvider?.isBitKeep;
            case "wombat":
                return web3?.currentProvider?.isWombat;
            case "software":
                const windowObj: {} = window;
                const ethAwareWindow: { ethereum?: EthereumProvider } = windowObj;
                return !!web3 || !!ethAwareWindow.ethereum;
            default:
                return false;
        }
    }

    public static isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
}

export default PlayerEnvironment;
