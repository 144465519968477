import DataHelpers from "@/cuties/engine/DataHelpers";
import mad from "../engine/mad";
import { Blockchain } from "@/cuties/model/pet/BlockchainId";
import type CurrencyModel from "@/cuties/model/sale/CurrencyModel";
import Money from "@/cuties/engine/Money";
import type { BlockchainPriceModel } from "@/app/cuties/presale/PresaleCutie";
import type { ShopCurrency } from "@/cuties/model/sale/CurrencyModel";

export type PresaleLotModelInput = BlockchainPriceModel & { leftCount: number } | {
    blockchain: Blockchain;
    lotId: number;
    leftCount: 0;
};

export default class PresaleLotModel implements PresaleBidParams {
    private _rate: string;
    private _signExpireTime: number;
    private _v: string;
    private _r: string;
    private _s: string;
    private _price: string;
    private _lotExpireTime: number;
    private _leftCount: number;
    private _lotId: number;
    private _blockchain: Blockchain;
    private _priceShow: string;
    private _pricesFiat;
    private _lotKind;
    private _currency: CurrencyModel;

    constructor(lot_id: number, data: PresaleLotModelInput) {
        this._lotId = lot_id;
        this._leftCount = DataHelpers.toInt(data.leftCount);
        this._blockchain = DataHelpers.to_enum(Blockchain, data.blockchain, Blockchain.Offchain);
        if (!("price" in data)) {
            return;
        }
        this._v = data.v;
        this._r = data.r;
        this._s = data.s;
        this._rate = data.rate;
        this._price = data.price;
        this._signExpireTime = DataHelpers.toInt(data.signExpireTime);
        this._lotExpireTime = DataHelpers.toInt(data.lotExpireTime);
        this._lotKind = data.lotKind;
        if (data.currency) {
            this._currency = data.currency;
        }

        if (data.leftCount > 0) {
            this._priceShow = mad.getWalletCompanion(data.blockchain).toBaseCurrencyString(new Money(data.price));

            let priceForFiat: number = DataHelpers.toFloat(this._priceShow);
            if (this.blockchain == "ETHEREUM") {
                priceForFiat += mad.get_gas_eth_value();
            }

            priceForFiat = mad.apply_fiat_commision(priceForFiat);
            this._pricesFiat = mad.get_supported_fiat_currencies(priceForFiat, this.blockchain);
        }
    }

    get rate(): string {
        return this._rate;
    }

    get priceShow(): string {
        return this._priceShow;
    }

    get signExpireTime(): number {
        return this._signExpireTime;
    }

    get v(): string {
        return this._v;
    }

    get r(): string {
        return this._r;
    }

    get s(): string {
        return this._s;
    }

    get price(): string {
        return this._price;
    }

    get leftCount(): number {
        return this._leftCount;
    }

    get lotId(): number {
        return this._lotId;
    }

    get blockchain(): Blockchain {
        return this._blockchain;
    }

    get lotKind(): string {
        return this._lotKind;
    }

    get currency(): CurrencyModel {
        return this._currency;
    }

    static init_lot_models(data: Record<number, Record<ShopCurrency, BlockchainPriceModel & { leftCount: number }>>) {
        const list: Record<number, Record<ShopCurrency, PresaleLotModel>> = {};
        for (let i = 1; i <= 50; i++) {
            if (!data[i]) continue;

            const lots: Record<ShopCurrency, PresaleLotModel> = {};
            for (const n in data[i]) {
                lots[n] = new PresaleLotModel(i, data[i][n]);
            }
            list[i] = lots;
        }

        return list;
    }
}

export interface PresaleBidParams {
    lotId: number;
    blockchain: Blockchain;
    price: string;
    signExpireTime: number;
    r: string;
    s: string;
    v: string;
    lotKind: string;
    rate: string;
}

export interface LotTemplateData extends PresaleBidParams {
    lotExpireTime: number;
    priceShow: string;
    pricesFiat: { curr: string; val: string | null }[] | { curr: string; val: string | null };
    currency: CurrencyModel;
}
