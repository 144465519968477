
    import Vue from "vue";
    import Component from "vue-class-component";
    import WalletButton from "@/components/LoginManager/misc/WalletButton.vue";
    import { Emit, Prop, PropSync } from "vue-property-decorator";
    import analytics from "@/cuties/analytics";
    import type { EmailRegisterResponse } from "@/cuties/player/UserApiService";
    import UserApiService from "@/cuties/player/UserApiService";
    import utils from "@/cuties/utils";
    import { Getter } from "vuex-class";
    import type { Player } from "@/cuties/model/player/Player";
    import { ShowLoader } from "@/common/decorators/ShowLoader";
    import VueUtils from "@/cuties/VueUtils";

    @Component({
        components: { WalletButton }
    })
    export default class EmailSignupForm extends Vue {
        @PropSync("email", { required: true }) syncedEmail!: string;
        @Prop({ required: true }) mobile!: boolean;
        @Getter("getPlayer") user!: Player;

        signUpInProgress = false;
        password = "";
        password_repeat = "";
        serverError = "";

        $refs!: {
            signupEmailInput: HTMLInputElement;
        };

        mounted() {
            this.$refs.signupEmailInput.focus();
        }

        signUpDisabled() {
            return this.signUpInProgress;
        }

        /** make images more opaque in response to user's input */
        getIllustrationStyle(step: number) {
            let progress;
            if (step === 0) {
                progress = this.syncedEmail.match(/^\S+@\S+\.\S{2,}$/)
                    ? 1 : this.syncedEmail.length / 16;
            } else if (step === 1) {
                progress = this.password.length / 8;
            } else if (step === 2) {
                progress = this.password_repeat.length / Math.max(this.password.length, 8);
            } else {
                progress = 0;
            }
            progress = Math.min(1, progress);
            return {
                "opacity": 0.2 + 0.8 * progress,
                "filter": "brightness(" + (0.5 + 0.5 * progress) + ") saturate(" + (0.2 + 0.8 * progress) + ")",
            };
        }

        onFormInput() {
            this.serverError = "";
        }

        get validationError() {
            let error = "";
            if (this.password) {
                // see UserService.java#isStrongPassword()
                if (this.password.length < 8) {
                    error = this.$tc("loginManagerErrorPasswordTooShort");
                } else if (this.password.toLowerCase() === this.password) {
                    error = this.$tc("loginManagerErrorPasswordMustHaveAtLeastOneCapitalLetter");
                } else if (this.password.toUpperCase() === this.password) {
                    error = this.$tc("loginManagerErrorPasswordMustHaveAtLeastOneSmallLetter");
                } else if (!this.password.match(/\d/)) {
                    error = this.$tc("loginManagerErrorPasswordMustHaveAtLeastOneDigit");
                }
            }
            if (!error && this.password_repeat && this.password != this.password_repeat) {
                error = this.$tc("loginManagerErrorPasswordNotMatch");
            }
            return error;

        }

        cleanError() {
            this.serverError = "";
        }

        @ShowLoader
        async email_sign_up(event: Event) {
            event.preventDefault();

            if (this.validationError) {
                analytics.gtag_log_event("sign up email check", "registration", this.validationError);
                return false;
            }

            this.cleanError();

            const registering = UserApiService.registerOffchainAccount({
                email: this.syncedEmail,
                password: this.password,
                language: this.$i18n.locale,
                referrer: utils.get_referrer(),
                inviterId: utils.get_inviter_id(),
                campaignUrlParams: UserApiService.getCampaignUrlParams(),
            });
            this.signUpInProgress = true;
            let response: EmailRegisterResponse;
            try {
                response = await registering;
            } catch (e) {
                this.serverError = VueUtils.extractError(e);
                analytics.gtag_log_event("sign up email check", "registration", this.serverError);
                return;
            } finally {
                this.signUpInProgress = false;
            }
            analytics.gtag_log_event("sign up email check", "registration", "success");
            this.done();
        }

        @Emit("done")
        done() {}
    }
