import type {
    AllOfReward,
    PossessionIdentityItem, TimedRecipeModel } from "@/cuties/request/crafting/CraftingApiService";
import CraftingApiService from "@/cuties/request/crafting/CraftingApiService";
import type VueI18n from "vue-i18n";
import type { ItemDefinitionModel } from "@/app/cuties/items/ItemApiService";
import ItemApiService from "@/app/cuties/items/ItemApiService";
import type { ItemInstanceModelBase } from "@/cuties/model/item/ItemInstanceModel";
import { anyCaseToHuman } from "cuties-client-components/src/TextUtils";
import { getCommonPostfix, getCommonPrefix } from "@/app/cuties/utils/TextUtils";
import PossessionsState from "@/app/components/crafting/PossessionsState";
import { BigNumber } from "bignumber.js";
import { fromFixedPointBigNumber } from "@/cuties/offchaincurrency/OffchainCurrency";
import EthereumWalletCompanion from "@/cuties/blockchain/ethereum/EthereumWalletCompanion";
import I18nHelpers from "@/cuties/engine/I18nHelpers";
import { PLAYER_SINGLETON } from "@/cuties/model/player/Player";
import type { PossessionIdentity, UnitIdentity } from "cuties-client-components/types/general/UnitIdentity";

function formatDecimal(number: number) {
    const fixed = number.toFixed(3);
    if (fixed === "0.000" && number > 0) {
        return new BigNumber(number).toPrecision(2).toString();
    } else if (fixed === Math.round(number) + ".000") {
        return Math.round(number) + "";
    } else {
        return fixed;
    }
}

function getItemName(itemKind: string, i18n: VueI18n) {
    const withoutSuffix = itemKind.replace(/Personal$/, "");
    if (!i18n.te("item_name_" + itemKind) &&
        i18n.te("item_name_" + withoutSuffix)
    ) {
        return i18n.t("item_name_" + withoutSuffix);
    } else {
        return i18n.t("item_name_" + itemKind);
    }
}

/** the class provides general-purpose functions related to Possession Identity */
export default class PossessionsLayer {
    public static getTitleText(identity: PossessionIdentity, i18n: VueI18n): string | null {
        if (identity.type === "challengeQuestGlobalProgress") {
            const key = `${identity.type}_${identity.challengeId}_${identity.questId}_${identity.count}`;
            return i18n.t(key).toString();
        } else if (identity.type === "item") {
            const suffix = !identity.personality ? "" : {
                "ANY": " 💰/🔗",
                "PERSONAL": " 🔗",
                "SELLABLE": "",
                "FROM_KIND": "",
            }[identity.personality];
            return getItemName(identity.item, i18n) + suffix;
        } else if (identity.type === "pawCoin") {
            return i18n.t("paw_coins_header") + "";
        } else if (identity.type === "cuteCoin") {
            return "$CUTE";
        } else if (identity.type === "subscriptionMinutes") {
            const key = "subscription_name_" + identity.subscriptionId;
            let name;
            if (i18n.te(key)) {
                name = i18n.t(key);
            } else {
                name = anyCaseToHuman(identity.subscriptionId);
            }
            return name + " " + i18n.t("subscription_suffix");
        } else if (identity.type === "token1155") {
            return identity.token1155;
        } else if (identity.type === "cutieFancy") {
            return [
                ...identity.isNoble ? ["noble"] : [],
                ...typeof identity.generation === "number" ? ["GEN" + identity.generation] : [],
                i18n.t("fancy_name_" + identity.cutieFancy).toString(),
            ].join(" ");
        } else if (identity.type === "cutieGeneral") {
            const parts = [
                ...identity.isNoble ? ["noble"] : [],
                ...typeof identity.generation === "number" ? ["GEN" + identity.generation] : [],
                ...identity.blockchain ? [identity.blockchain] : [],
                identity.race ?? "Cutie",
            ];
            return parts.join(" ");
        } else if (identity.type === "clearedDungeonStage") {
            return "Clear " + i18n.t("dungeon_stage_name_" + identity.stageId);
        } else if (identity.type === "dungeonWorldCountable") {
            return anyCaseToHuman(identity.name) + " in world " + anyCaseToHuman(identity.worldId);
        } else if (identity.type === "anyOf") {
            return identity.options.map(o => this.getTitleText(o, i18n) + " x" + o.count).join(" OR ");
        } else if (identity.type === "allOf") {
            return identity.options.map(o => this.getTitleText(o, i18n) + " x" + o.count).join(" AND ");
        } else if (identity.type === "not") {
            return "NOT " + this.getTitleText(identity.not, i18n);
        } else if (identity.type === "offchainCurrency") {
            return identity.offchainCurrency + " " + i18n.t("possession_identity_offchain_currency_title");
        } else {
            // unsupported type
            return null;
        }
    }

    public static formatDisplayAmount(identity: PossessionIdentity): string {
        if (identity.type === "offchainCurrency") {
            return fromFixedPointBigNumber(identity.count, identity.offchainCurrency);
        } else if (identity.type === "cuteCoin") {
            return new EthereumWalletCompanion().fromFixedPoint(new BigNumber(identity.count)).toString();
        } else if (identity.type === "subscriptionMinutes") {
            return I18nHelpers.get_time_duration_string_short(Number(identity.count) * 60);
        } else {
            return identity.count + "";
        }
    }

    public static asItem(identity: PossessionIdentity): PossessionIdentityItem | null {
        if (identity.type === "item") {
            return identity;
        } else if (identity.type === "not" && identity.not.type === "item") {
            return identity.not;
        } else if (identity.type === "pawCoin") {
            // Paw Coins have a fake item definition with description, image, etc...
            return {
                item: "PawCoin",
                type: "item",
                count: identity.count,
                personality: "PERSONAL",
            };
        } else if (identity.type === "cuteCoin") {
            // Paw Coins have a fake item definition with description, image, etc...
            return {
                item: "CuteCoin",
                type: "item",
                count: identity.count,
                personality: "PERSONAL",
            };
        } else {
            return null;
        }
    }

    public static toItemInstanceModel(identity: PossessionIdentityItem, definition: ItemDefinitionModel): ItemInstanceModelBase {
        const baseName = definition.baseName ?? definition.name;
        const personality = identity.personality ?? "FROM_KIND";
        return {
            level: identity.enchantLevel ?? 0,
            schema: {
                info: {
                    type: identity.item,
                },
                rarity: definition.rarity,
                tags: definition.tags ?? [],
                maxEnchantLevel: definition.maxEnchantLevel,
                reqPetLevel: definition.reqLevel,
                sellable: personality === "FROM_KIND"
                    ? definition.sellable
                    : personality !== "PERSONAL",
                baseName: definition.baseName,
                icon: "/static/items/png/" + baseName + ".png",
            },
        };
    }

    public static getModelBaseName(model: ItemInstanceModelBase) {
        return model.schema.baseName
            ?? model.schema.info.type;
    }

    public static async itemBaseModelByIdentity(identity: PossessionIdentityItem): Promise<ItemInstanceModelBase> {
        return ItemApiService.itemDefinitionMap()
            .then(map => {
                const definition = map.get(identity.item);
                if (!definition) {
                    throw new Error("Item " + identity.item + " is not defined on server");
                } else {
                    return definition;
                }
            })
            .then(definition => this.toItemInstanceModel(identity, definition));
    }

    public static getRecipeTranslationKey(id: string): string {
        return "timed_crafting_recipe_name_" + id;
    }

    public static getGroupOptionTranslationKey(id: string): string {
        return "timed_crafting_collapse_group_option_name_" + id;
    }

    public static getRecipeDirectName(id: string, i18n: VueI18n): string | null {
        const recipeKey = this.getRecipeTranslationKey(id);
        return i18n.te(recipeKey) ? i18n.t(recipeKey) + "" : null;
    }

    public static getRecipeName(recipe: RecipeLike, i18n: VueI18n, multiplier: number): string {
        const recipePrefix = multiplier !== null && multiplier > 1 ? multiplier + " x " : "";
        const directName = this.getRecipeDirectName(recipe.id, i18n);
        if (directName) {
            return recipePrefix + directName;
        }
        const firstReward = recipe.allOfRewards[0]?.anyOfRewards[0] || null;
        if (firstReward &&
            firstReward.initialVisibility === "VISIBLE"
        ) {
            if (firstReward.identity.type === "item") {
                let prefix = "";
                if (multiplier !== null) {
                    const min = firstReward.min * +firstReward.identity.count * multiplier;
                    const max = firstReward.max * +firstReward.identity.count * multiplier;
                    const rangeStr = min === max ? min + "" : min + "-" + max;
                    if (rangeStr !== "1") {
                        prefix = rangeStr + " x ";
                    }
                }
                return prefix + i18n.t("item_name_" + firstReward.identity.item) + "";
            } else if (firstReward.identity.type === "cutieGeneral"
                    || firstReward.identity.type === "cutieFancy"
            ) {
                return PossessionsLayer.getTitleText(firstReward.identity, i18n)!;
            }
        }
        // no own name defined for the recipe, nor item info available
        return recipePrefix + this.getRecipeTranslationKey(recipe.id);
    }

    public static getFullOptionName(recipe: TimedRecipeModel, i18n: VueI18n): string {
        const optionKey = this.getGroupOptionTranslationKey(recipe.id);
        const optionName = i18n.te(optionKey) ? i18n.t(optionKey) + "" : null;
        return optionName
            ?? PossessionsLayer.getRecipeDirectName(recipe.id, i18n)
            ?? PossessionsLayer.getRecipeTranslationKey(recipe.id);
    }

    public static getShortOptionName(recipe: TimedRecipeModel, recipeGroup: TimedRecipeModel[], i18n: VueI18n): string {
        const optionKey = this.getGroupOptionTranslationKey(recipe.id);
        if (i18n.te(optionKey)) {
            return i18n.t(optionKey) + "";
        }
        const fullName = this.getFullOptionName(recipe, i18n);
        const fullNames = recipeGroup.map(r => this.getFullOptionName(r, i18n));
        const commonPrefix = getCommonPrefix(fullNames);
        const commonPostfix = getCommonPostfix(fullNames.map((n) => n.slice(commonPrefix.length)));
        const end = commonPostfix.length > 0 ? -commonPostfix.length : fullName.length;
        const shortened = fullName.slice(commonPrefix.length, end);

        let result = shortened.replace(/_/g, " ").trim() || "Default";
        if (result.match(/^\d+$/)) {
            result = i18n.t("timed_crafting_collapse_group_option_numeric_prefix") + result;
        }
        return result;
    }

    public static async countOwnedIdentities(
        requiredIdentities: UnitIdentity[]
    ): Promise<PossessionsState> {
        const flattened = requiredIdentities.flatMap(flattenIdentity);
        const ownedIdentities = await CraftingApiService
            .quantityLeftByIdentities(flattened);
        const requirementsState = new Map<UnitIdentity, bigint>();
        for (let i = 0; i < flattened.length; ++i) {
            const ownedCount = BigInt(ownedIdentities[i].count);
            requirementsState.set(flattened[i], ownedCount);
        }
        return new PossessionsState(requirementsState);
    }

    public static async countOwnedIdentitiesIfLoggedIn(
        requiredIdentities: UnitIdentity[]
    ): Promise<PossessionsState> {
        return PLAYER_SINGLETON.is_logined()
            ? await PossessionsLayer.countOwnedIdentities(requiredIdentities)
            : new PossessionsState(new Map(
                requiredIdentities.map(pi => [pi, BigInt(0)])
            ));
    }

    public static areRequirementsMet(
        requirements: PossessionIdentity[],
        ownedIdentities: PossessionsState
    ): boolean {
        return requirements.every(r => {
            const owned = ownedIdentities.get(r) ?? BigInt(0);
            return owned >= BigInt(r.count);
        });
    }

    public static calculateWeightPercents(option: { weight: number }, options: { weight: number }[]) {
        const totalWeight = options.reduce((sum, item) => sum + item.weight, 0);
        const chancePercents = (100 * option.weight) / totalWeight;
        return formatDecimal(chancePercents);
    }
}

function flattenIdentity(identity: UnitIdentity): UnitIdentity[] {
    let extra: UnitIdentity[] = [];
    if (identity.type === "anyOf") {
        extra = identity.options;
    } else if (identity.type === "allOf") {
        extra = identity.options;
    } else if (identity.type === "not") {
        extra = [identity.not];
    }
    return [identity, ...extra.flatMap(flattenIdentity)];
}

interface RecipeLike {
    id: string;
    allOfRewards: AllOfReward[];
}
