import Request from "../Request";

export default class RequestAdminTranferItems extends Request {

    private fromId:number;
    private toId:number;

    constructor(fromId:number, toId:number) {
        super();

        this.fromId = fromId;
        this.toId = toId;
    }

    get_url(): string {
        return "/transferItems";
    }

    collect_post_data(): object {
        const data = {
            fromId: this.fromId,
            toId: this.toId
        };

        return data;
    }

}
