
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Emit, Prop } from "vue-property-decorator";

    const CONVERSATION_FLOWCHART = {
        "START": {
            question: "ARE_YOU_NEW",
            options: ["YES_I_AM_NEW", "NO_I_AM_A_CUTIENEER"] as const,
        } as const,
        "NO_I_AM_A_CUTIENEER": {
            question: "WELCOME_BACK",
            options: ["IT_IS_GOOD_TO_BE_BACK"],
        } as const,
        "IT_IS_GOOD_TO_BE_BACK": {
            question: "WELCOME_BACK",
            options: ["IT_IS_GOOD_TO_BE_BACK"],
        } as const,
        "YES_I_AM_NEW": {
            question: "WILL_YOU_BE_MY_FRIEND",
            options: ["SURE", "ABSOLUTELY_SURE"] as const,
        } as const,
        "SURE": {
            question: "NOW_LET_US_HAVE_FUN_IN_FIGHT_CLUB",
            options: ["LET_US_GO_TO_FIGHT_CLUB"],
        } as const,
        "ABSOLUTELY_SURE": {
            question: "NOW_LET_US_HAVE_FUN_IN_FIGHT_CLUB",
            options: ["LET_US_GO_TO_FIGHT_CLUB"],
        } as const,
        "LET_US_GO_TO_FIGHT_CLUB": {
            question: "NOW_LET_US_HAVE_FUN_IN_FIGHT_CLUB",
            options: ["LET_US_GO_TO_FIGHT_CLUB"],
        } as const,
        "FIGHT_ENDED": {
            question: "WHAT_A_FIERCE_BATTLE_BUT_I_GOTTA_GO",
            options: ["SEE_YA"],
        } as const,
        "SEE_YA": {
            question: "WHAT_A_FIERCE_BATTLE_BUT_I_GOTTA_GO",
            options: ["SEE_YA"],
        } as const,
    } as const;

    export type ConversationState = keyof typeof CONVERSATION_FLOWCHART;

    @Component
    export default class InteractiveNewUserReception extends Vue {
        @Prop({ required: true }) state: ConversationState;
        showSpeechBubble = false;

        mounted() {
            this.showSpeechBubble = true;
        }

        get currentQuestion() {
            return CONVERSATION_FLOWCHART[this.state].question;
        }

        get replyOptions() {
            return CONVERSATION_FLOWCHART[this.state].options;
        }

        chooseReply(newState: ConversationState) {
            this.newState(newState);
        }

        @Emit("newState")
        newState(newState: ConversationState) {
            return newState;
        }
    }
