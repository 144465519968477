import DataHelpers from "@/cuties/engine/DataHelpers";
import mad from "./engine/mad";
import Money from "./engine/Money";
import { AuctionModel } from "./model/pet/AuctionModel";
import { Blockchain } from "./model/pet/BlockchainId";
import { BigNumber } from "bignumber.js";
import { ConfigInstance } from "@/cuties/engine/Config";
import type { AuctionInterface } from "@/cuties/pet/PetService";

export default class AuctionUpdater {
    public static auctions = null;

    static init(obj): void {
        AuctionUpdater.auctions = [];
        $(obj).find("[data-auction]").each(function() {
            const obj = $(this);

            const timeStartStr = obj.attr("data-time-start");
            if (!timeStartStr) {
                DataHelpers.error("AuctionUpdater: we have uninitialized data-auction here", obj);
                return;
            }

            const time_start = DataHelpers.toInt(timeStartStr);
            const time_end = DataHelpers.toInt(obj.attr("data-time-end"));
            if (time_end - time_start == 0) {
                AuctionUpdater.update_auction_price(obj);
                return;
            }

            AuctionUpdater.auctions.push(obj);
            AuctionUpdater.update_auction_price(obj);
        });

        const update_each_ms = 10000;

        mad.storage.set_interval_func("_template_auction", function() {
            if (!AuctionUpdater.auctions)
                return;

            for (let i = AuctionUpdater.auctions.length - 1; i >= 0; --i) {
                AuctionUpdater.update_auction_price(AuctionUpdater.auctions[i]);
            }
        }, update_each_ms);
    }

    /**
     * calculates the price on the moment of call given that it
     * scales linearly over time from priceStart to priceEnd
     */
    static actualPrice(price_start: BigNumber, price_end: BigNumber, time_start: number, time_end: number): Money {
        const time_now = DataHelpers.get_time_seconds();
        return this.actualPriceAt(price_start, price_end, time_start, time_end, time_now);
    }

    static actualPriceAtMs(
        auction: AuctionInterface,
        timeNowMs: number
    ): Money {
        const time_now = Math.ceil(timeNowMs / 1000);
        return this.actualPriceAt(
            new BigNumber(auction.priceStart),
            new BigNumber(auction.priceEnd),
            auction.timeStart,
            auction.timeStart + auction.duration,
            time_now
        );
    }

    static actualPriceAt(
        price_start: BigNumber,
        price_end: BigNumber,
        time_start: number,
        time_end: number,
        time_now: number,
    ): Money {
        if (price_end.gt(price_start)) {
            time_now += 300; // if price goes up - make 5 min price save amount
            if (time_now > time_end) {
                time_now = time_end;
            }
        } else if (price_end.lt(price_start)) {
            time_now -= 300; // if price goes down - make 5 min price save amount
            if (time_now > time_end) {
                time_now = time_end;
            }
            if (time_now < time_start) {
                time_now = time_start;
            }
        }

        return AuctionModel.interpolate_price(time_start, time_end, new Money(price_start), new Money(price_end), time_now);
    }

    static formatBlockChainPrice(priceNowRaw: Money, blockchain: Blockchain): string {
        return mad.getWalletCompanion(blockchain).toBaseCurrency(priceNowRaw).toFixed();
    }

    static readablePrice(priceNowRaw: Money, blockchain: Blockchain): string {
        return mad.getWalletCompanion(blockchain).toBaseCurrencyString(priceNowRaw);
    }

    static update_auction_price(obj): void {
        const time_start = DataHelpers.toInt(obj.attr("data-time-start"));
        const time_end = DataHelpers.toInt(obj.attr("data-time-end"));

        const price_start = new BigNumber(obj.attr("data-price-start") as string);
        const price_end = new BigNumber(obj.attr("data-price-end") as string);


        const blockchain = obj.data("blockchain") ? obj.data("blockchain") : Blockchain.Ethereum;

        const priceNowRaw = this.actualPrice(price_start, price_end, time_start, time_end);

        const blockChainPrice = this.formatBlockChainPrice(priceNowRaw, blockchain);

        obj.attr("data-blockchain_price", blockChainPrice);

        const readablePrice = this.readablePrice(priceNowRaw, blockchain);
        obj.html(readablePrice);
        $(".price_icon > span").toggle(true);

        const ethereum = ConfigInstance.dynamic.ethereums[blockchain];

        const token_wrap = obj.data("tokens_wrap");

        if( $("." + token_wrap).length && ethereum != undefined) {
            const tokens = ethereum.tokens;
            for (const token in tokens) {

                const priceEth = priceNowRaw.get_eth_raw();
                const ethRaw = 1.00001 * priceEth;

                if (tokens[token].price != null) {
                    const priceEthFormatted = mad.format(ethRaw * Number(tokens[token].price));
                    const token_price_count = (Number(tokens[token].price) / (10 ** tokens[token].decimals)) * priceEth;
                    $(".token_" + token).html(DataHelpers.formatMoney(token_price_count));
                    $(".token_" + token).parents().data("token_price", priceEthFormatted);
                }
            }
        }
    }
}



