import I18nHelpers from "@/cuties/engine/I18nHelpers";
import RequestFormEmailSubscribe from "../request/form/RequestFormEmailSubscribe";
import analytics from "../analytics";
import RequestAdminSetPetAdditionalDetails from "../request/admin/RequestAdminSetPetAdditionalDetails";
import mad from "@/cuties/engine/mad";

// handlers for forms

mad.handler.register("subscribe_email", function() {


    const request = new RequestFormEmailSubscribe(this);
    request.send(function(response) {

        analytics.gtag_log_event("confirmed", "subscription", "");

        $("#subscribe").html("<div class=\"email_subscribe_succ\">" + I18nHelpers.translate("email_succ_subscribe") + "</div>");
    });
});

mad.handler.register("pet_additional_details_save", function() {

    const request = new RequestAdminSetPetAdditionalDetails("#PetDetailsPopup");
    request.send(function() {
        mad.popup.hide();
        window.location.reload();
    });
});
