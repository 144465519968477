
    import { Component, Emit, Prop, Vue } from "vue-property-decorator";
    import http from "@/http";
    import type { OnAuthorizeSuccess, OnProviderReadiness } from "@/cuties/LoginManager";
    import EmailLoginForm from "@/app/components/LoginManager/forms/EmailLoginForm.vue";
    import type { UserTemplate } from "@/cuties/model/player/UserTemplate";
    import type { Player } from "@/cuties/model/player/Player";
    import { Getter } from "vuex-class";
    import EmailSignupForm from "@/app/components/LoginManager/forms/EmailSignupForm.vue";
    import LoginService from "@/cuties/model/player/LoginService";
    import { defineAsyncComponent } from "vue";
    import PleaseWaitLoadingData from "@/app/components/app-components/PleaseWaitLoadingData.vue";

    const PromotedWalletButtons = defineAsyncComponent({
        loader: () => import(/* webpackChunkName: "PromotedWalletButtons" */ "@/components/LoginManager/misc/PromotedWalletButtons.vue"),
        loadingComponent: PleaseWaitLoadingData,
    });

    @Component({
        components: { PromotedWalletButtons, EmailSignupForm, EmailLoginForm },
    })
    export default class LoginManagerEmail extends Vue {
        @Prop() bus;
        @Prop() token: string;

        /**
         * @deprecated
         * Call when BlockchainService is unlocked and ready to action.
         * Mostly used for legacy blockchain services without adapters (eos).
         */
        @Prop() callback: OnAuthorizeSuccess;

        /**
         * Call when blockchain wallet provider is unlocked
         * and can be used for blockchain service internal adapter
         */
        @Prop({ required: true }) okCallback!: OnProviderReadiness;
        @Getter("getPlayer") user!: Player;

        dataLoaded   = false;
        mobile       = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        login_notice = "";

        forgot_email = "";
        password   = "";
        password_repeat = "";
        email = "";

        state: "email_signup_form" | "email_login_form" | "email_done_form" | "forgot_pass_form" | "change_password" | null = null;
        error = "";
        pwdChanged: boolean = false;

        mounted() {
            this.bus.$on("email_tab_login_popup_show", this.email_tab_login_popup_show);
            this.bus.$on("email_tab_signup_form_show", this.email_signup_form_show);
        }

        created() {
            this.loaded();
        }

        loaded() {
            this.dataLoaded = true;
            if( !this.state ) this.state = "email_login_form";
            if( this.token ) this.state = "change_password";
        }

        back() {
            this.password = "";
            this.password_repeat = "";
            this.cleanError();
            this.state = "email_login_form";
        }

        get highlightGoogleSignInButton() {
            const domain = this.email.split("@")[1] ?? "";
            return this.state === "email_signup_form"
                && domain.length > 0
                && "gmail.com".startsWith(domain);
        }

        email_forgot_email_send() {
            this.cleanError();

            if(!this.forgot_email) {
                this.error = this.$tc("email_is_empty_error");
                return false;
            }

            return http.post("/forgot_password_request", {
                email: this.forgot_email
            })
                .then((res: any) => {

                    const response = res.data;
                    this.login_notice = this.$tc("emailSentNote");
                    this.state = "email_login_form";
                })
                .catch((e)=>{
                    console.log(e);
                    const { data, status } = (e || {}).response || {};
                    if (status && data && typeof data === "string") {
                        e = new Error(data + " - " + status);
                    }
                    this.error = this.$tc(data);
                });
        }

        pwd_change() {
            this.cleanError();

            if (!this.password) {
                this.error = this.$tc("EthereumPrivateKey_MissingPassword");
                return false;
            }
        }

        emailChangePassword() {
            this.cleanError();

            if( !this.password ) {
                this.error = this.$tc("EthereumPrivateKey_MissingPassword");
                return false;
            }

            if( this.password != this.password_repeat ) {
                this.error =  this.$tc("loginManagerErrorPasswordNotMatch");
                return false;
            }

            return http.post("/set_password", {
                password : this.password,
                confirmationCode : this.token
            })
                .then((res: any) => {

                    const response = res.data;
                    if( response.error ) {
                        this.error = this.$tc("error " + response.error);
                    } else {
                        this.pwdChanged = true;
                    }
                })
                .catch((e) => {
                    const error = e.response?.data?.error;
                    if( error ) {
                        this.error = this.$tc(error);
                    } else {
                        this.error = this.$tc("errorInvalidToken");
                    }
                });
        }

        email_signup_form_show() {
            this.cleanError();
            this.state = "email_signup_form";
            this.email = "";
            this.password = "";
            this.password_repeat = "";
            this.login_notice = "";
        }

        email_tab_login_popup_show() {
            this.cleanError();
            this.state = "email_login_form";
            this.login_notice = "";
        }

        forgot_pass_form_show() {
            this.cleanError();
            this.state = "forgot_pass_form";
        }

        cleanError() {
            this.error = "";
        }

        call_callback() {
            if (this.callback) this.callback(null);
        }

        handleSuccessfulAuthentication(user: UserTemplate) {
            LoginService.initializeAuthorizedUser(user);
            if (this.callback) {
                this.callback(null);
            } else {
                this.$router.push({ path: "/pets_my" });
                if (window.location.href.indexOf("email_confirm") > 1) {
                    this.$router.push({ path: "/pets_my" });
                }
            }
        }

        @Emit("close")
        close() {}
    }
