import Request from "../Request";
import LocationPath from "@/cuties/engine/LocationPath";
import mad from "@/cuties/engine/mad";

/**
 * Base class for all project-specific forms.
 */
export default abstract class RequestForm extends Request {
    private location:LocationPath;
    public form_data:object;

    constructor(form_selector:HTMLElement|string, uri:LocationPath|string) {
        super();

        this.location = LocationPath.create(uri);

        this.form_data = mad.collect_form_values(form_selector);

    }

    get_url(): string {
        return this.location.get_relative();
    }

    collect_post_data(): object {
        return this.form_data;
    }

}

