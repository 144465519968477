

    import type LootCrateRewardModel from "@/cuties/model/item/LootCrateRewardModel";
    import { Component, Prop, Vue } from "vue-property-decorator";
    import VueUtils from "@/cuties/VueUtils";
    import PossessionsLayer from "@/app/components/crafting/PossessionsLayer";

    const getSortValue = (reward: LootCrateRewardModel): number => {
        return +reward.dropChance.replace(/%$/, "");
    };

    @Component({})
    export default class ItemLootBoxInfo extends Vue {

        @Prop() rewards: LootCrateRewardModel[];

        readonly goToLink = VueUtils.goToLink;
        readonly getModelBaseName = PossessionsLayer.getModelBaseName;

        getItemLink(reward: LootCrateRewardModel) {
            return `/${this.$i18n.locale}/item/${reward.item.instance.schema.info.type}/0`;
        }

        private getSortedRewards() {
            return [...this.rewards]
                .sort((a,b) => getSortValue(a) - getSortValue(b));
        }
    }

