import { Blockchain } from "@/cuties/model/pet/BlockchainId";
import store from "@/store";
import analytics from "@/cuties/analytics";

export type IntroStepId = (typeof STEPS_DATA_RECORDS)[number]["id"];

interface IntoStepDataRecord {
    id: IntroStepId;
    /** see headerMenu.json */
    headerMenuEntryName?: string;
    isReadOnly?: boolean;
}

export type IntroStep = IntoStepDataRecord;

interface IntroModeState {
    /** whether the tutorial progression was delayed for completing later */
    isActive: boolean;
    completedSteps: IntroStepId[];
}

const STEPS_DATA_RECORDS = [
    { id: "WELCOME_TO_CUTIELAND"         , headerMenuEntryName: "myPets", isReadOnly: true },
    { id: "ACQUIRE_INTRO_CUTIE"          , headerMenuEntryName: "myPets" },
    { id: "CHECK_OUT_CUTIE_PAGE"         , headerMenuEntryName: "myPets", isReadOnly: true },
    { id: "SEND_CUTIE_TO_ADVENTURE"      , headerMenuEntryName: "readyForAdventure" },
    { id: "CHECK_OUT_DROP_RATES"         , headerMenuEntryName: "adventureList", isReadOnly: true },
    { id: "CHECK_OUT_BATTLE_REPORT" },
    { id: "BATTLE_REPORT_EXPLANATION"    , isReadOnly: true },
    { id: "EQUIP_AN_ITEM"                , headerMenuEntryName: "inventory" },
    { id: "VISIT_A_DUNGEON"              , headerMenuEntryName: "dungeons" },
    { id: "ATTACH_BLOCKCHAIN_ACCOUNT" },
    { id: "CHECK_OUT_WALLET_ADDRESS"     , isReadOnly: true },
    { id: "PARTICIPATE_IN_LOTTERY" },
    { id: "ACQUIRE_CUTIES_FOR_BREEDING"  , headerMenuEntryName: "petSale" },
    { id: "BREED"                        , headerMenuEntryName: "myPets" },
    { id: "READ_ABOUT_ADVENTURE_COOLDOWN", isReadOnly: true },
    { id: "READ_ABOUT_ADVENTURE_POTION"  , isReadOnly: true },
    { id: "BUY_ADVENTURE_POTION"         , headerMenuEntryName: "itemMarket" },
    { id: "USE_ADVENTURE_POTION"         , headerMenuEntryName: "inventory" },
    { id: "GRADUATE"                     , isReadOnly: true },
    // GET_ENOUGH_CUTIES_FOR_MIDGAME
] as const;

const INTRODUCTION_MODE_STATE = "INTRODUCTION_MODE_STATE";

/**
 * provides means to show introduction messages to the new user and
 * highlight the buttons he should press to get acquainted with the game
 */
export default class IntroductionSystem {
    /**
     * Matic's SEO is a homie, he posts about us on Twitter if we ask
     */
    public static readonly RECOMMENDED_BLOCKCHAIN = Blockchain.Matic;
    private static userId: number | null = null;
    private static steps: IntroStep[] | null = null;

    private static getStorageKey() {
        if (this.userId) {
            return INTRODUCTION_MODE_STATE + "_" + this.userId;
        } else {
            throw new Error("Tried to access Intro Mode storage without userId being set!");
        }
    }

    private static collectSteps(): IntroStep[] {
        return STEPS_DATA_RECORDS.map((record) => record);
    }

    private static getState(): IntroModeState | null {
        if (!this.userId) {
            return null;
        }
        const stateStr = window.localStorage.getItem(this.getStorageKey());
        if (!stateStr) {
            return null;
        }
        try {
            return JSON.parse(stateStr);
        } catch (exc) {
            return null;
        }
    }

    private static setState(state: IntroModeState): void {
        window.localStorage.setItem(this.getStorageKey(), JSON.stringify(state));
        this.initializePageHints();
        // should probably send state to vue to update quests...
    }

    /** supposed to be called when user registered an account, could also add a button in Profile to start it manually */
    public static launch(userId: number) {
        this.userId = userId;
        this.setState({
            isActive: true,
            completedSteps: [],
        });
    }

    public static pause() {
        const state = this.getState();
        if (state) {
            state.isActive = false;
            this.setState(state);
            const step = this.getActiveStep();
            analytics.gtag_log_event("pause", "tutorial", step?.id);
        }
    }

    public static wasCompleted() {
        const state = this.getState();
        return state && state.completedSteps.includes("GRADUATE");
    }

    public static isAfter(currentStep: IntroStepId, previousStep: IntroStepId): boolean {
        const currentIndex = STEPS_DATA_RECORDS.findIndex(s => s.id === currentStep);
        const previousIndex = STEPS_DATA_RECORDS.findIndex(s => s.id === previousStep);
        return currentIndex > previousIndex;
    }

    public static resume(userId: number) {
        const state = this.getState();

        if (state && !state.completedSteps.includes("GRADUATE")) {
            state.isActive = true;
            this.setState(state);
            const step = this.getActiveStep();
            analytics.gtag_log_event("resume", "tutorial", step?.id);
        } else {
            this.launch(userId);
        }
    }

    public static initializeUser(userId: number) {
        this.userId = userId;
        this.initializePageHints();
    }

    private static initializePageHints() {
        const activeStep = this.getActiveStep();
        store.dispatch("setActiveIntroStep", activeStep);
        if (activeStep) {
            document.body.setAttribute("data-active-intro-step", activeStep.id);
        } else {
            document.body.removeAttribute("data-active-intro-step");
        }
    }

    private static isCompleted(step: IntroStep, state: IntroModeState) {
        return state.completedSteps.includes(step.id);
    }

    private static getSteps() {
        return this.steps
            || (this.steps = this.collectSteps());
    }

    public static getActiveStep(): IntroStep | null {
        const state = this.getState();
        if (!state || !state.isActive) {
            return null;
        }
        if (state.completedSteps.includes("GRADUATE")) {
            // when we update tutorial, new steps appear which makes tutorial meaninglessly pop up
            // for old players, that's why we additionally check if the last step was finished
            return null;
        }
        return this.getSteps().find(step => !this.isCompleted(step, state)) || null;
    }

    public static completeStep(stepId: IntroStepId, skip = false) {
        const state = this.getState() || { isActive: false, completedSteps: [] };
        if (!state.completedSteps.includes(stepId)) {
            state.completedSteps.push(stepId);
            if (this.userId) {
                this.setState(state);
            } else {
                console.error("Tried complete Intro Mode stage " + stepId + " without userId being set!");
            }
            const step = this.getSteps().find(st => st.id === stepId);
            if (state.isActive && step) {
                if (skip) {
                    analytics.gtag_log_event("skip", "tutorial", stepId);
                } else {
                    analytics.gtag_log_event("complete", "tutorial", step.id);
                }
            }
        }
    }

    /** same as complete step, but we need to know if user pressed skip button */
    public static skipStep(stepId: IntroStepId) {
        this.completeStep(stepId, true);
    }
}
