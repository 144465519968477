import { render, staticRenderFns } from "./ItemTooltipStatsTable.vue?vue&type=template&id=d94fd4aa&scoped=true&"
import script from "./ItemTooltipStatsTable.vue?vue&type=script&lang=ts&"
export * from "./ItemTooltipStatsTable.vue?vue&type=script&lang=ts&"
import style0 from "./ItemTooltipStatsTable.vue?vue&type=style&index=0&id=d94fd4aa&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d94fd4aa",
  null
  
)

export default component.exports