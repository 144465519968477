import DataHelpers from "@/cuties/engine/DataHelpers";
import type { Blockchain } from "../pet/BlockchainId";
import type { UserTemplate } from "@/cuties/model/player/UserTemplate";
import type { WalletAddress } from "@/components/LoginManager/TypeDefs";
import PlayerBase, { PlayerRole } from "@/cuties/model/player/PlayerBase";
import I18nHelpers from "@/cuties/engine/I18nHelpers";

export interface BlockchainRecord{
    blockchain_val: Blockchain;
    checked: boolean;
    currency: string;
}

/**
 * Player represents state of the current (authorized) player
 */
export class Player extends PlayerBase {
    public fillFromTemplate(data: UserTemplate) {
        this.authorizationData = data;
        this.agreeEmails = DataHelpers.toBoolean(data.agreeEmails);
        this.user_id = DataHelpers.toInt(data.id);

        this.role = DataHelpers.to_enum_value(PlayerRole, data.role, PlayerRole.Default);

        this.name = DataHelpers.isString(data.name) ? data.name : I18nHelpers.translate("player") + " " + this.user_id;

        this.email = DataHelpers.isString(data.email) ? data.email : null;

        this.tickets = DataHelpers.toInt(data.tickets);

        if( data.blockchains ) {
            this.set_blockchains(data.blockchains);
        }
    }

    public update(data: UserTemplate): void {
        this.authorizationData = {
            ...this.authorizationData ?? {},
            ...data,
        };
        this.tickets = DataHelpers.toInt(data.tickets);
        if( data.blockchains ) this.set_blockchains(data.blockchains);
    }

    pawCoinsDeducted(delta: number) {
        this.tickets -= delta;
    }

    set_email(newEmail: string) {
        this.email = newEmail;
        return this;
    }

    /** domain is local value returned from UD (unstoppable domains) */
    set domain(value: string) {
        this._domain = value;
    }

    set_new_activity_count(count:number): void {
        this.newActivityCount = count;
    }

    /** to be used on successful attach */
    public setAddedBlockchainAddress(blockchain: Blockchain, address: WalletAddress) {
        this.blockchains.set(blockchain, address);
    }

    private set_blockchains(blockchains: Record<Blockchain, WalletAddress>) {
        this.blockchains = new Map<Blockchain, string>();
        for (const [k, v] of Object.entries(blockchains)) {
            const blockchain = k as Blockchain;
            this.blockchains.set(blockchain, v);
        }
    }
}

/**
 * @deprecated - please use the reactive `@Getter("getPlayer") user!: Player;` in Vue instead to get the
 * latest updated player state from store: after a new blockchain attach for example, or PAW balance update
 */
export const PLAYER_SINGLETON = new Player();
