import { Blockchain } from "@/cuties/model/pet/BlockchainId";
import type BlockchainAccountManager from "./BlockchainAttachManager";
import VueUtils from "@/cuties/VueUtils";
import type { AuthProvider } from "@/cuties/blockchain/types";
import type { NeoProvider } from "./blockchain/neo/NeoProvider";
import LoginService from "@/cuties/model/player/LoginService";
import { ConfigInstance } from "@/cuties/engine/Config";

const LoginManagerNeo = () => import(/* webpackChunkName: "LoginManagerNeo" */ "@/components/LoginManager/LoginManagerNeo.vue");

export default class AttachNeoAccountManager implements BlockchainAccountManager {
    private async tryAttachAccount(provider: AuthProvider) {
        const termsText = ConfigInstance.get("terms");
        await LoginService.tryAttachAccount({
            blockchain: Blockchain.Neo3,
            account: await provider.getWalletAddress(),
            code: await provider.signPersonalMessage(termsText),
        });
    }

    async OpenPopupAttachAccount() {
        const loginManager = LoginManagerNeo;

        const provider = await VueUtils.vuePopupCustomId<NeoProvider>("popup_wallet_vue", loginManager, { unlock: false });
        return this.tryAttachAccount(provider);
    }
}
