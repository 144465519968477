import type { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import type { RootState } from "@/store/RootState";
import type { Blockchain } from "@/cuties/model/pet/BlockchainId";
import type { HeaderMenuEntry } from "cuties-client-components/types/api/Config";
import ReactiveSet from "@/app/cuties/utils/ReactiveSet";
import type { PetForAdventureModel } from "@/cuties/model/activity/PetForAdventureModel";

export type NotificationModel = NotificationBattleModel | NotificationTextModel;

export interface NotificationTextModel {
    type: "notificationText";
    isError: boolean;
    removeLink: boolean;
    text: string;
}

export interface NotificationBattleModel {
    type: "notificationBattle";
    participants: NotificationBattleModelParticipants[];
    item: string;
    ticketCount: number;
    text: string;
}

interface NotificationBattleModelParticipants {
    isWinner: boolean;
    model: PetForAdventureModel;
}

export interface AppState {
    shopPopupShow: boolean;
    shopPopupGroup: string;
    lotteryShow: boolean;
    refreshBlockchain: Blockchain | null;
    headerMenuEntries: HeaderMenuEntry[];
    everySecondTickTimeMs: number;
    tick200ms: number;
    notifications: ReactiveSet<NotificationModel>;
    configLoaded: boolean;
    translationsLoaded: boolean;
    navigationIteration: number;
    isMobile: boolean;
}

export const storeState: AppState = {
    shopPopupShow: false,
    shopPopupGroup: "",
    lotteryShow: false,
    refreshBlockchain: null,
    headerMenuEntries: [],
    everySecondTickTimeMs: Date.now(),
    tick200ms: Date.now(),
    notifications: new ReactiveSet<NotificationModel>(),
    configLoaded: false,
    translationsLoaded: false,
    navigationIteration: 0,
    isMobile: window.innerWidth < 800,
};

export enum AppStoreGetters {
    SHOP_POPUP_SHOW = "SHOW_SHOP_POPUP",
    SHOP_POPUP_CATEGORY = "SHOP_POPUP_CATEGORY",
    LOTTERY_POPUP_SHOW = "LOTTERY_POPUP_SHOW",
    REFRESH_BLOCKCHAIN = "REFRESH_BLOCKCHAIN",
    GET_HEADER_MENU_ENTRIES = "GET_HEADER_MENU_ENTRIES",
    /** use this in components like timers whose state depends on time */
    GET_EVERY_SECOND_TICK_TIME_MS = "GET_EVERY_SECOND_TICK_TIME_MS",
    GET_200_MS_TICK = "GET_200_MS_TICK",
    GET_NOTIFICATIONS = "GET_NOTIFICATIONS",
    GET_CONFIG_LOADED = "GET_CONFIG_LOADED",
    GET_TRANSLATIONS_LOADED = "GET_TRANSLATIONS_LOADED",
    GET_NAVIGATION_ITERATION = "GET_NAVIGATION_ITERATION",
}

export const getters: GetterTree<AppState, RootState> = {
    [AppStoreGetters.SHOP_POPUP_SHOW]: (state: AppState) => state.shopPopupShow,
    [AppStoreGetters.SHOP_POPUP_CATEGORY]: (state: AppState) => state.shopPopupGroup,
    [AppStoreGetters.LOTTERY_POPUP_SHOW]: (state: AppState) => state.lotteryShow,
    [AppStoreGetters.REFRESH_BLOCKCHAIN]: (state: AppState) => state.refreshBlockchain,
    [AppStoreGetters.GET_HEADER_MENU_ENTRIES]: (state: AppState) => state.headerMenuEntries,
    [AppStoreGetters.GET_EVERY_SECOND_TICK_TIME_MS]: (state: AppState) => state.everySecondTickTimeMs,
    [AppStoreGetters.GET_200_MS_TICK]: (state: AppState) => state.tick200ms,
    [AppStoreGetters.GET_NOTIFICATIONS]: (state: AppState) => state.notifications,
    [AppStoreGetters.GET_CONFIG_LOADED]: (state: AppState) => state.configLoaded,
    [AppStoreGetters.GET_TRANSLATIONS_LOADED]: (state: AppState) => state.translationsLoaded,
    [AppStoreGetters.GET_NAVIGATION_ITERATION]: (state: AppState) => state.navigationIteration,
};

export enum AppStoreMutations {
    SHOP_POPUP_CLOSE = "SHOP_POPUP_CLOSE",
    SHOP_POPUP_OPEN = "SHOP_POPUP_OPEN",
    LOTTERY_POPUP_OPEN = "LOTTERY_POPUP_OPEN",
    LOTTERY_POPUP_CLOSE = "LOTTERY_POPUP_CLOSE",
    REFRESH_BLOCKCHAIN = "REFRESH_BLOCKCHAIN",
    SET_HEADER_MENU_ENTRIES = "SET_HEADER_MENU_ENTRIES",
    SET_EVERY_SECOND_TICK_TIME_MS = "SET_EVERY_SECOND_TICK_TIME_MS",
    SET_200_MS_TICK = "SET_200_MS_TICK",
    SET_IS_MOBILE = "SET_IS_MOBILE",
    ADD_NOTIFICATION = "ADD_NOTIFICATION",
    REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION",
    SET_CONFIG_LOADED = "SET_CONFIG_LOADED",
    SET_TRANSLATIONS_LOADED = "SET_TRANSLATIONS_LOADED",
    /**
     * @see https://github.com/vuejs/vue-router/issues/974#issuecomment-541127408
     * @see https://github.com/vuejs/router/issues/1257#issuecomment-1220460450
     *
     * vue-router does not reload pages when you try to navigate to same url, which would be fine if we
     * managed all state in our app through url, but the fact is that we don't, so this behaviour
     * (or should I say lack of thereof) causes only frustration for users who click links and nothing
     * happens because their current view happens to be on same url
     *
     * to avoid ux regression, we stick to the original behaviour of our links:
     * every navigation always remounts the page component, even if url is same
     *
     * that is achieved by listening for click events of all router-link components on the page
     */
    INCREMENT_NAVIGATION_ITERATION = "INCREMENT_NAVIGATION_ITERATION",
}

export const mutations: MutationTree<AppState> = {
    [AppStoreMutations.SHOP_POPUP_CLOSE]: (state: AppState) => {
        state.shopPopupShow = false;
        state.shopPopupGroup = "Shop";
    },

    [AppStoreMutations.SHOP_POPUP_OPEN]: (state: AppState, group: string) => {
        state.shopPopupShow = true;
        if (group.length) {
            state.shopPopupGroup = group;
        }
    },

    [AppStoreMutations.LOTTERY_POPUP_CLOSE]: (state: AppState) => {
        state.lotteryShow = false;
    },

    [AppStoreMutations.LOTTERY_POPUP_OPEN]: (state: AppState) => {
        state.lotteryShow = true;
    },

    [AppStoreMutations.REFRESH_BLOCKCHAIN]: (state: AppState, blockchain: Blockchain) => {
        state.refreshBlockchain = blockchain;
    },

    [AppStoreMutations.SET_HEADER_MENU_ENTRIES]: (state: AppState, entries: HeaderMenuEntry[]) => {
        state.headerMenuEntries = entries;
    },
    [AppStoreMutations.SET_EVERY_SECOND_TICK_TIME_MS]: (state: AppState, timeMs: number) => {
        state.everySecondTickTimeMs = timeMs;
    },
    [AppStoreMutations.SET_200_MS_TICK]: (state: AppState, timeMs: number) => {
        state.tick200ms = timeMs;
    },
    [AppStoreMutations.SET_IS_MOBILE]: (state: AppState, isMobile: boolean) => {
        state.isMobile = isMobile;
    },

    [AppStoreMutations.ADD_NOTIFICATION]: (state: AppState, notification: NotificationModel) => {
        state.notifications = state.notifications.add(notification);
    },
    [AppStoreMutations.REMOVE_NOTIFICATION]: (state: AppState, notification: NotificationModel) => {
        state.notifications = state.notifications.delete(notification);
    },
    [AppStoreMutations.SET_CONFIG_LOADED]: (state: AppState, configLoaded: boolean) => {
        state.configLoaded = configLoaded;
    },
    [AppStoreMutations.SET_TRANSLATIONS_LOADED]: (state: AppState, value: boolean) => {
        state.translationsLoaded = value;
    },
    [AppStoreMutations.INCREMENT_NAVIGATION_ITERATION]: (state: AppState) => {
        state.navigationIteration = state.navigationIteration + 1;
    },
};

export enum AppStoreActions {
    SHOP_POPUP_CLOSE = "SHOP_POPUP_CLOSE",
    SHOP_POPUP_OPEN = "SHOP_POPUP_OPEN",
    LOTTERY_POPUP_CLOSE = "LOTTERY_POPUP_CLOSE",
    LOTTERY_POPUP_OPEN = "LOTTERY_POPUP_OPEN",
    REFRESH_BLOCKCHAIN = "REFRESH_BLOCKCHAIN",
}

export const actions: ActionTree<AppState, RootState> = {
    [AppStoreActions.SHOP_POPUP_CLOSE]: async ({ commit }) => {
        commit(AppStoreMutations.SHOP_POPUP_CLOSE);
        return true;
    },

    [AppStoreActions.SHOP_POPUP_OPEN]: async ({ commit }, category: string) => {
        commit(AppStoreMutations.SHOP_POPUP_OPEN, category);
        return true;
    },

    [AppStoreActions.LOTTERY_POPUP_OPEN]: async ({ commit }) => {
        commit(AppStoreMutations.LOTTERY_POPUP_OPEN);
        return true;
    },

    [AppStoreActions.LOTTERY_POPUP_CLOSE]: async ({ commit }) => {
        commit(AppStoreMutations.LOTTERY_POPUP_CLOSE);
        return true;
    },

    [AppStoreActions.REFRESH_BLOCKCHAIN]: async ({ commit }, blockchain: Blockchain) => {
        commit(AppStoreMutations.REFRESH_BLOCKCHAIN, blockchain);
        return true;
    },
};

class AppStore implements Module<AppState, RootState> {
    public state: AppState = storeState;
    public getters: GetterTree<AppState, RootState> = getters;
    public mutations: MutationTree<AppState> = mutations;
    public actions: ActionTree<AppState, RootState> = actions;
}

// return singleton
export const AppStoreSingleton = new AppStore();
