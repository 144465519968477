import { getStateless, makeHttp } from "@/http";

const http = makeHttp({ baseUrl: "/rest" });

/** mapping to InteractiveFightsController.java */
export default class InteractiveFightsApiService {
    private static whenRules: Promise<InteractiveFightRulesResponse> | null = null;

    public static getRules(): Promise<InteractiveFightRulesResponse> {
        if (this.whenRules === null) {
            this.whenRules = getStateless("/public/interactive/fights/rules").then(rs => rs.data);
        }
        return this.whenRules;
    }

    public static getBotDefinitions(): Promise<InteractiveFightsBotDefinitionView[]> {
        return getStateless("/public/interactive/fights/bot/definitions").then(rs => rs.data);
    }

    public static getActiveFighterId(): Promise<string> {
        return http.get("/interactive/fights/active/fighter/id").then(rs => rs.data);
    }

    public static getFightersStats(params: { fightId: number }): Promise<InteractiveFighterStats[]> {
        return getStateless("/public/interactive/fights/fighters/stats", { params }).then(rs => rs.data);
    }

    public static getPersonalBotStats(): Promise<InteractiveFightsPersonalBotStats[]> {
        return http.get("/interactive/fights/bot/personal/stats").then(rs => rs.data);
    }

    public static getHighscores(): Promise<InteractiveFightsHighscoreView[]> {
        return getStateless("/public/interactive/fights/highscores").then(rs => rs.data);
    }

    public static host(): Promise<InteractiveFightsHostResponse> {
        return http.post("/public/interactive/fights/host").then(rs => rs.data);
    }

    public static hostIntroductionFight(): Promise<InteractiveFightsHostResponse> {
        return http.post("/public/interactive/fights/host/introduction/fight").then(rs => rs.data);
    }

    public static listMatchmaking(): Promise<InteractiveFightMatchmakingView[]> {
        return getStateless("/public/interactive/fights/matchmaking/list").then(rs => rs.data);
    }

    public static join(params: { fightId: number }): Promise<{ fighterId: string }> {
        return http.post("/public/interactive/fights/join", params).then(rs => rs.data);
    }

    public static takeOnBot(params: { fighterId: string }): Promise<void> {
        return http.post("/public/interactive/fights/take/on/bot", params).then(rs => rs.data);
    }

    public static getState(params: { fighterId: string }): Promise<InteractiveFightsStateResponse> {
        return getStateless("/public/interactive/fights/state", { params }).then(rs => rs.data);
    }

    public static getTurnsHistory(params: { fighterId: string; minTurnNumber: number }): Promise<InteractiveFightTurnView[]> {
        return getStateless("/public/interactive/fights/turns/history", { params }).then(rs => rs.data);
    }

    public static chooseNextAction(params: InteractiveFightsChooseNextActionRequest): Promise<{ opponentAction: Action | null }> {
        return http.post("/public/interactive/fights/choose/next/action", params).then(rs => rs.data);
    }

    public static quit(params: { fighterId: string }): Promise<void> {
        return http.post("/public/interactive/fights/quit", params).then(rs => rs.data);
    }

    public static kickOpponent(params: { fighterId: string }): Promise<void> {
        return http.post("/public/interactive/fights/kick/opponent", params).then(rs => rs.data);
    }
}

export type Action = "ATTACK" | "REFLECT" | "SCHEME";

type ConnectionStatus = "ACTIVE" | "LEFT" | "KICKED";

interface InteractiveFightsHostResponse {
    fightId: number;
    /** UUID */
    fighterId: string;
}

export interface InteractiveFightMatchmakingView {
    fightId: number;
    /** ISO string */
    startedTime: string;
}

export interface InteractiveFighterStateView {
    hitPointsLeft: number;
    schemesGained: number;
    hasNextAction: boolean;
    isRequester: boolean;
    /** defined only when isRequester=true and hasNextAction=true */
    nextAction?: Action | null;
    connectionStatus: ConnectionStatus;
    /** ISO string */
    lastPingTime: string;
}

interface InteractiveFightsChooseNextActionRequest {
    /** UUID */
    fighterId: string;
    action: Action;
}

export type GameOverState = "NOT_OVER" | "REQUESTER_WON" | "OPPONENT_WON" | "DRAW";

type GameMode = "FIXED_STATS_PLAYER_VS_PLAYER" | "FIXED_STATS_PLAYER_VS_BOT";

export interface InteractiveFightsStateResponse {
    turnNumber: number;
    fightId: number;
    gameMode: GameMode;
    gameOverState: GameOverState;
    isKickingPossible: boolean;
    fighters: InteractiveFighterStateView[];
}

export interface InteractiveFightRulesResponse {
    initialHitPoints: number; // like 100
    defaultAttack: number; // like 10
    defaultDefence: number; // like 10
    schemesToWin: number; // like 5
}

export interface InteractiveFightsBotDefinitionView {
    id: string;
    fancyName: string;
}

export interface InteractiveFightTurnView {
    turnNumber: number;
    effects: InteractiveFighterTurnEffectView[];
}

type Countering = "STRONGER" | "WEAKER" | "EVEN";

export interface InteractiveFighterTurnEffectView {
    action: Action;
    hitPointsLeftChange: number;
    schemesGainedChange: number;
    countering: Countering;
}

export interface InteractiveFighterStats {
    userId?: number;
    userName?: string;
    imageUrl?: string;
    matchesFinished: number;
    matchesWon: number;
    elo: number;
}

export interface InteractiveFightsPersonalBotStats {
    botId: string;
    effectiveWeight: number;
    totalWins: number;
    totalAttempts: number;
}

interface InteractiveFighterUserStats {
    userId: number;
    eloRating: number;
    /** ISO */
    eloRatingUpdateTime: string;
    totalWinsVsPlayer: number;
    totalLosesVsPlayer: number;
    totalWinsVsGuest: number;
    totalLosesVsGuest: number;
    totalWinsVsBot: number;
    totalLosesVsBot: number;
}

export interface InteractiveFightsHighscoreView {
    stats: InteractiveFighterUserStats;
    userName?: string;
}
