import DataHelpers from "@/cuties/engine/DataHelpers";
import Request from "../Request";
import utils from "@/cuties/utils";

export default class RequestAdminCreateAuction extends Request {

    private readonly fancy: string = null;
    private readonly nobility: string;
    private readonly duration: number = 5;
    private readonly blockchain: string;
    private readonly pet_kind: string;
    private readonly price: string;

    constructor(blockchain: string, fancy:string, duration: number, nobility: string, pet_kind:string, price: string) {
        super();
        this.blockchain = blockchain;
        if( fancy ) this.fancy = fancy;
        this.duration = duration;
        this.nobility = nobility;
        this.pet_kind = pet_kind;
        this.price = price;
    }

    get_url(): string {
        return "/auction/gen0";
    }

    collect_post_data(): object {
        return {
            fancy: this.fancy,
            nobilityKind: this.nobility,
            duration: this.duration,
            blockchain: this.blockchain,
            kind: this.pet_kind,
            price: this.price
        };
    }

    on_success(response: any): void {
        super.on_success(response);
        utils.show_notification("Queued command to create Gen0 cutie auction");
    }

    on_error(response: any): void {
        try {
            const status = response.errorDetails.jqXHR.status;
            if (status == 401) {
                utils.propose_auth();
            } else{
                utils.show_notification("error status = " + status);
            }
        } catch (e) {
            DataHelpers.error("Request.on_error", e);
            utils.show_notification(response.error);
        }
    }

    get_handler_prefix(): string {
        return null;
    }
}
