
    import { HANDLER_MANAGER_SINGLETON } from "@/cuties/engine/HandlerManager";
    import type { Player } from "@/cuties/model/player/Player";
    import PlayerSettingsService from "@/cuties/player/PlayerSettingsService";
    import utils from "@/cuties/utils";
    import VueUtils from "@/cuties/VueUtils";
    import type { HeaderProfileMenuEntry } from "@/app/cuties/header/HeaderProfileMenu";
    import { Component, Emit, Prop, Vue } from "vue-property-decorator";
    import FeaturedCutieSelectPopup from "@/app/components/cutie/list/FeaturedCutieSelectPopup.vue";
    import UserProfileIcon from "@/app/components/player/UserProfileIcon.vue";
    import { Getter } from "vuex-class";
    import LoginService from "@/cuties/model/player/LoginService";

    @Component({
        components: { UserProfileIcon, FeaturedCutieSelectPopup },
    })
    export default class HeaderProfileMenu extends Vue {
        @Prop({ required: true }) name!: string;
        @Prop({ required: true }) profileMenu!: HeaderProfileMenuEntry[];
        @Getter("getPlayer") user!: Player;

        readonly goToLink = VueUtils.goToLink;

        showPopup = true;
        editName = false;
        playerName = this.name;
        featuredCutieSelectPopupVisible = false;
        profileImageUpdateVersion = 0;

        mounted() {
            const profile = document.getElementById("profile-info");
            if (profile) {
                const popup = document.getElementsByClassName("header-profile-popup");
                if(popup.length) {
                    const elem = popup[0] as HTMLElement;
                    elem.style.marginLeft = profile.offsetLeft - 60 + "px";
                }
            }
        }

        customGoToLink(event: MouseEvent): void {
            this.onClosed();
        }

        userId() {
            return this.user.get_id();
        }

        isAdmin(): boolean {
            return this.user.is_role_admin();
        }

        isRoleRevsharePartner(): boolean {
            return this.user.isRoleRevsharePartner();
        }

        email(): string | null {
            return this.user.authorizationData?.email ?? null;
        }

        agreeEmails(): boolean {
            return this.user.agree_emails();
        }

        playerId(): number {
            return this.user.get_id();
        }

        openDonatePopup() {
            HANDLER_MANAGER_SINGLETON.run("donate_popup");
        }

        onClosed(): void {
            this.close();
        }

        editMode(): void {
            this.playerName = this.name;
            if (this.user.get_email() != null && this.user.get_email() != undefined && this.user.get_email() != "") {
                this.editName = true;
            } else {
                utils.show_notification(this.$t("tooltipSetEmail"));
            }
        }

        changeName(): void {
            PlayerSettingsService.playerInfoEdit({
                name: this.playerName,
                email: this.email(),
                agreeEmails: this.agreeEmails(),
            })
                .then((rs) => rs.data)
                .then((response) => {
                    if ("result" in response) {
                        utils.show_notification(this.$t("error" + response.result));
                    } else {
                        LoginService.initializeAuthorizedUser(response);
                        this.editName = false;
                    }
                })
                .catch((exc) => VueUtils.handleError(exc));
        }

        cancelEdit(): void {
            this.playerName = this.name;
            this.editName = false;
        }

        @Emit()
        logout(): void {
            this.onClosed();
        }

        @Emit()
        close() {}
    }
