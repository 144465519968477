import { toBigNumber } from "@/common/utils/toBigNumber";
import { encodeFuncCall } from "@/cuties/blockchain/tron/helpers";
import TronContractRegistry from "@/cuties/blockchain/tron/TronContractRegistry";
import type { CallParams, TransactionObject } from "@/cuties/blockchain/tron/TronProvider";
import BigNumber from "bignumber.js";
import type {
    TronAccountResources,
    TronChainParameter,
    TronChainParameterKey,
    TronConstantTransactionResponse
} from "tronweb";
import type TronWeb from "tronweb";
import type BlockchainReader from "../BlockchainReader";
import type { TronConfig } from "./TronConfig";
import TranslatedClientError from "@/app/cuties/utils/TranslatedClientError";

export class TronReader implements BlockchainReader {
    private registry: TronContractRegistry | null = null;

    constructor(private config: TronConfig, private tronWeb: TronWeb) {
    }

    toSun(value: number | BigNumber): string {
        return this.tronWeb.toSun(value);
    }

    fromSun(value: number | BigNumber): string {
        return this.tronWeb.fromSun(value);
    }

    async ownerOf(cutieId: number): Promise<string> {
        const address = this.config.contracts.token;
        const contract = await this.tronWeb.contract().at(address);
        const owner = await contract.ownerOfCutie(cutieId).call();
        return String(this.tronWeb.address.fromHex(owner));
    }

    /**
     * invoke a constant (read-only) method
     *
     * @return - method-specific data format
     */
    async callContractMethod<T>(callParams: CallParams): Promise<T> {
        const transactionObj = await this.getMethodObj(callParams);

        return transactionObj.call();
    }

    async getBreedingFee(matronId: number, sireId: number): Promise<BigNumber> {
        const breedingFee: string = await this.callContractMethod({
            contractName: "core",
            methodName: "getBreedingFee",
            methodArguments: [matronId, sireId],
        });
        return new BigNumber(this.tronWeb.fromSun(breedingFee));
    }

    async getOwner(): Promise<string> {
        const address: string = await this.callContractMethod({
            contractName: "core",
            methodName: "getOwner",
        });
        return address;
    }

    private async getMethodObj(callParams: CallParams): Promise<TransactionObject> {
        if (!this.registry) {
            this.registry = await this.getRegistry();
        }
        return this.registry.getMethodObj(callParams);
    }

    getRegistry(): TronContractRegistry {
        return new TronContractRegistry(this.tronWeb);
    }

    async getBalance(address: string): Promise<BigNumber> {
        const balance = await this.tronWeb.trx.getBalance(address);

        return toBigNumber(this.tronWeb.fromSun(balance));
    }

    async getResources(address: string): Promise<TronAccountResources> {
        return this.tronWeb.trx.getAccountResources(address);
    }

    async getBandwidth(address: string): Promise<number> {
        return this.tronWeb.trx.getBandwidth(address);
    }

    async getChainParameterByName(key: TronChainParameterKey): Promise<number> {
        const param = await this.getChainParametersByName([key]);
        if (!param) {
            throw new Error("Get chain parameter failed.");
        }
        return param[key];
    }

    async getChainParametersByName(keys: TronChainParameterKey[]): Promise<Record<string, number>> {
        const params: TronChainParameter[] = await this.tronWeb.trx.getChainParameters();
        const object: Record<string, number> = {};

        for (let i = 0; i < keys.length; i++) {
            const proposal = params.find(({ key }: TronChainParameter) => key === keys[i]);

            if (proposal) {
                object[keys[i]] = proposal["value"];
            }
        }

        return object;
    }

    burnedEnergyToTrx(energy: number, energyFee: number, options?: { unit: string }): BigNumber {
        let amount = energy * Number(this.tronWeb.fromSun(energyFee));

        if (options?.unit === "sun") {
            amount = Number(this.tronWeb.toSun(amount));
        }

        return toBigNumber(amount);
    }
    /**
     * returns sun cost of bandwidth per byte
     */
    async bandwidthCost(): Promise<number> {
        return this.getChainParameterByName("getTransactionFee");
    }

    async energyCost(): Promise<number> {
        return this.getChainParameterByName("getEnergyFee");
    }

    async getEstimatedEnergy(
        contractAddress: string,
        senderAddress: string,
        methodName: string,
        args: readonly any[],
        price: number
    ): Promise<number> {
        const contract = await this.getRegistry().getContactObj(contractAddress);
        if (!contract) {
            throw new TranslatedClientError("ErrorTronNoContract");
        }

        const { functionSelector, functionParameterHex } = await encodeFuncCall(contract.methodInstances, methodName, args);

        const request = {
            contract_address: this.tronWeb.address.toHex(contract.address),
            owner_address: this.tronWeb.address.toHex(senderAddress),
            call_value: price,
            function_selector: functionSelector,
            parameter: functionParameterHex,
        };

        let transaction;
        try {
            transaction = (await this.tronWeb.fullNode.request(
                "wallet/triggerconstantcontract",
                request,
                "post"
            )) as TronConstantTransactionResponse;
        } catch (error) {
            const message = error.message || error + "";
            throw new TranslatedClientError("ERR_CANNOT_ESTIMATE_TRANSACTION_FEE", [message]);
        }

        if (transaction.Error) {
            const message = transaction.Error;
            throw new TranslatedClientError("ERR_CANNOT_ESTIMATE_TRANSACTION_FEE", [message]);
        }
        if (transaction.result && transaction.result.message) {
            const message = this.tronWeb.toUtf8(transaction.result.message);
            throw new TranslatedClientError("ERR_CANNOT_ESTIMATE_TRANSACTION_FEE", [message]);
        }

        // multiply to be safe gas estimation (same as in Web3), to be safe
        const estimatedEnergy = toBigNumber(transaction.energy_used).times(1.2).toFixed(0);

        return Number(estimatedEnergy);
    }
}
