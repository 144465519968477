import { getStateless, makeHttp } from "@/http";
import type { ItemKind } from "@/cuties/model/item/ItemKind";
import type { ItemGroupKind } from "@/cuties/model/item/ItemGroupKind";
import type ItemInstanceModel from "@/cuties/model/item/ItemInstanceModel";
import type { QuickSlotItem } from "@/cuties/pet/PetService";
import type { ResponseItemUse } from "@/cuties/model/inventory/ResponseItemUse";
import type { ItemEffect } from "@/cuties/model/item/ItemEffectModel";
import type { ItemUnit } from "cuties-client-components/types/general/UnitIdentity";

const http = makeHttp({ baseUrl: "/rest" });

/** a mapping to some ItemController.java routes */
export default class ItemApiService {
    private static whenItemDefinitionList: Promise<ItemDefinitionModel[]> | null = null;
    private static whenItemDefinitionMap: Promise<Map<string, ItemDefinitionModel>> | null = null;

    public static async getQuickSlotItems(): Promise<QuickSlotItem[]> {
        return makeHttp({ baseUrl: "/rest" })
            .get("/item/quick/use/list")
            .then((response) => response.data);
    }

    public static async itemDefinitionList(): Promise<ItemDefinitionModel[]> {
        if (!this.whenItemDefinitionList) {
            this.whenItemDefinitionList = getStateless("/public/item/definition/list")
                .then(response => response.data);
        }
        return this.whenItemDefinitionList;
    }

    public static async itemDefinitionMap(): Promise<Map<string, ItemDefinitionModel>> {
        if (!this.whenItemDefinitionMap) {
            this.whenItemDefinitionMap = this.itemDefinitionList()
                .then(list => new Map(list.map(i => [i.name, i])));
        }
        return this.whenItemDefinitionMap;
    }

    public static async itemModelByIdentity(params: ItemModelIdentity): Promise<ItemInstanceModel> {
        return getStateless("/public/item/model/by/identity", { params: params }).then((response) => response.data);
    }

    public static async useItemOnUserByIdentity(item: ItemUnit): Promise<ResponseItemUse> {
        return http.post<ResponseItemUse>("/item/use/user/by/identity", item).then(response => {
            return response.data;
        });
    }
}

export type ItemModelIdentity = {
    item: keyof typeof ItemKind | string;
    enchantLevel?: number;
    /** mandatory in data coming from server, optional in data sent to server */
    personality?: "ANY" | "SELLABLE" | "PERSONAL" | "FROM_KIND";
};

export type ItemTag =
    | "INACTIVE" | "INGREDIENT" | "CURRENCY" | "ACTION_CONSUMABLE"
    | "DUNGEON_DISPOSABLE" | "ACCESS" | "REGULAR_CUTIE_MINT_TOKEN"
    | "SPECIAL_CUTIE_MINT_TOKEN" | "FUEL_FOR_ENCHANT"
    | string;

/** @see ItemDefinitionModel.java */
export interface ItemDefinitionModel {
    name: keyof typeof ItemKind | string;
    group: keyof typeof ItemGroupKind;
    rarity: ItemRarityKind;
    /**
     * tags are a set of certain keywords that describe traits of this item
     * a tag may either serve a purely informational purpose, like "season_7_exclusive",
     * or it may have some circumstantial logic attached by systems like battle mechanics
     *
     * absent = empty set
     */
    tags: ItemTag[];
    maxEnchantLevel: number;
    /**
     * the item can not be equipped on cuties
     * whose level is below this requirement
     */
    reqLevel: number;
    sellable: boolean;
    /**
     * if this item is a personal version derived from a sellable item, the `baseName` will hold
     * the name of the sellable version - use it for common stuff like images, translations, etc...
     */
    baseName?: string;
    /**
     * undefined means empty array
     * It's nullable because empty arrays are omitted to reduce size of json.
     */
    applicationEffects?: ItemEffect[];
}

export type ItemRarityKind =
    | "Common"
    | "Uncommon"
    | "Rare"
    | "Epic"
    | "Legendary"
    | "Unique";
