
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";
    import PossessionsLayer from "@/app/components/crafting/PossessionsLayer";
    import type { PossessionIdentityCutieGeneral } from "@/cuties/request/crafting/CraftingApiService";

    @Component
    export default class PossessionIdentityBlockCutieGeneral extends Vue {
        @Prop({ required: true }) identity!: PossessionIdentityCutieGeneral;

        get titleText(): string | null {
            return PossessionsLayer.getTitleText(this.identity, this.$i18n);
        }

        get iconPath(): string {
            return this.identity.race
                ? "/static/filters/cutie_types/" + this.identity.race + ".png"
                : "/static/icons/icon_achReward.png";
        }
    }
