
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";
    import SvgApiService from "@/cuties/request/pet/SvgApiService";
    import { makeHttp } from "@/http";
    import type { CutieAssetsResponse, CutieAssetView, CutieListEntryMinimalView } from "cuties-client-components/types/api/Cutie";
    import { throws } from "@/app/cuties/utils/utils";
    import { stringifyPlainObjectError } from "@/cuties/VueUtils";

    type ImageStatus = "LOADING" | "LOADED" | "ERROR";

    const STANDARD_VIEW_BOX_WIDTH = 29000;
    const STANDARD_VIEW_BOX_HEIGHT = 29000;

    /**
     * it looks like on some user machines browser is reluctant to cache
     * automatically, hope doing that explicitly will work for those people
     */
    const whenAssetsCache: Promise<Cache> = new Promise(resolve => resolve(caches.open("BCU_CUTIE_IMAGE_ASSETS")));
    let assetsCacheError = null;

    function getViewBox(doc: Document) {
        const svgDom = doc.documentElement;
        const viewBoxValues = svgDom.attributes
            .getNamedItem("viewBox").nodeValue.split(" ");
        const [x, y, width, height] = viewBoxValues;
        return { x, y, width, height };
    }

    function getAncestors(element: Element) {
        const ancestors = [];
        while (element.parentElement) {
            ancestors.push(element.parentElement);
            element = element.parentElement;
        }
        return ancestors;
    }

    /** some old fancies have eyes closed in the first keyframe, which does not look too good */
    function areClosedEyes(element: Element, firstValue: string) {
        const isInsideEyes = getAncestors(element).some(ancestor => {
            const id = ancestor.getAttribute("id") ?? "";
            return id.match(/^(Copy_.*_|^)Eyes(-|$)/i);
        });
        return isInsideEyes
            && element.tagName === "animate"
            && element.getAttribute("attributeName") === "opacity"
            && firstValue === "0";
    }

    function disableAnimation(element: Element) {
        // <animate> or <animateTransform>
        if (element.tagName.startsWith("animate")) {
            if (element.hasAttribute("dur")) {
                element.setAttribute("dur", "0");
            }
            if (element.hasAttribute("repeatCount")) {
                element.setAttribute("repeatCount", "1");
            }
            if (element.hasAttribute("values")) {
                const firstValue = element.getAttribute("values").split(";")[0];
                const value = areClosedEyes(element, firstValue) ? "1" : firstValue;
                element.setAttribute("values", value);
            }
        }
        for (const child of element.children) {
            disableAnimation(child);
        }
    }

    function compileSvg(svgParts: string[], keepAnimation: boolean): string {
        const assetDocs = svgParts.map(svg => new DOMParser()
            .parseFromString(svg, "application/xml"));

        const viewBoxes = assetDocs.map(getViewBox);
        const maxWidth = viewBoxes
            .map(viewBox => +viewBox.width)
            .reduce((a,b) => Math.max(a, b), 0) || STANDARD_VIEW_BOX_WIDTH;
        const maxHeight = viewBoxes
            .map(viewBox => +viewBox.height)
            .reduce((a,b) => Math.max(a, b), 0) || STANDARD_VIEW_BOX_HEIGHT;

        const elements: Element[] = [];
        for (const assetDoc of assetDocs) {
            const viewBox = getViewBox(assetDoc);
            const scale = maxHeight / +viewBox.height;
            const width = +viewBox.width * scale;
            for (const element of assetDoc.documentElement.children) {
                if (element.tagName === "G" || element.tagName === "g") {
                    if (width < maxWidth || scale > 1) {
                        const offsetX = Math.round((maxWidth - width) / 2);
                        element.setAttribute("transform", "translate(" + offsetX + " 0) scale(" + scale + " " + scale + ")");
                    }
                    if (!keepAnimation) {
                        disableAnimation(element);
                    }
                }
                elements.push(element);
            }
        }

        return "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" style=\"shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd\" version=\"1.1\" viewBox=\"0 0 " + maxWidth + " " + maxHeight + "\" xml:space=\"preserve\">" +
            elements.map(el => el.outerHTML).join("\n") +
            "</svg>";
    }

    interface SrcSvg {
        asset: CutieAssetView;
        svg: string;
    }

    function getAssetSrcSvgUrl(asset: CutieAssetView) {
        if (asset.type === "FANCY") {
            return `/rest/public/cutie/fancy/${asset.fancy}.svg`;
        } else if (asset.type === "UNBORN_EGG") {
            return "/static/pet/breeding/egg.svg";
        } else if (asset.type === "ID_PART") {
            return `/rest/public/asset/${asset.id}.svg?` +
                new URLSearchParams({ animated: "true" });
        } else {
            const never: { type: never } = asset;
            throw new Error("Unsupported asset type: " + never.type);
        }
    }

    function getSourceSvgWithoutCache(asset: CutieAssetView): Promise<SrcSvg> {
        return makeHttp({ baseUrl: "/" })
            .get<string>(getAssetSrcSvgUrl(asset))
            .then(rs => rs.data)
            .then((svg: string) => ({ asset, svg }));
    }

    async function getSourceSvgs(assets: CutieAssetView[]): Promise<SrcSvg[]> {
        if (assetsCacheError) {
            return Promise.all(assets.map(getSourceSvgWithoutCache));
        }
        let assetsCache: Cache;
        try {
            assetsCache = await whenAssetsCache;
        } catch (error) {
            // one of possible causes may be lack of https
            assetsCacheError = error;
            return Promise.all(assets.map(getSourceSvgWithoutCache));
        }
        const svgUrls = assets.map(getAssetSrcSvgUrl);
        const responses: (Response | undefined)[] = await Promise.all(
            svgUrls.map(url => assetsCache.match(url))
        );
        const missing = svgUrls.filter((url, i) => responses[i] === undefined);
        await assetsCache.addAll(missing);
        return Promise.all(assets.map(async (asset, i) => {
            const url = svgUrls[i];
            const response: Response = responses[i]
                ?? await assetsCache.match(url)
                ?? throws("Failed to cache asset " + url);
            if (response.status !== 200) {
                throw new Error(
                    "Unsuccessful status code: " + response.status +
                        " on attempt to cache asset: " + url
                );
            }
            const svg = await response.text();
            return { asset, svg };
        }));
    }

    @Component
    export default class CutieCardImageFromAssets extends Vue {
        @Prop({ required: true }) cutie!: CutieListEntryMinimalView | {
            id: number; // 414413
            renderData?: CutieAssetsResponse | null;
        };
        @Prop({ default: false }) animated!: boolean;

        imageStatus: ImageStatus = "LOADING";
        renderData: CutieAssetsResponse | null = null;
        compiledSvgLink: null | string = null;
        pngLink: null | string = null;

        timeStarted: number = null!;
        timeAssetsLoaded: null | number = null;
        timeCompiled: null | number = null;

        wasDestroyed = false;

        async created() {
            // use png link in 100% cases, as many users have issues due to too many requests
            if (!this.animated && "imagePngPersonal" in this.cutie && Math.random() < 1.00) {
                this.pngLink = this.cutie.imagePngPersonal;
                this.imageStatus = "LOADED";
                return;
            }
            this.timeStarted = Date.now();

            this.imageStatus = "LOADING";
            let renderData: CutieAssetsResponse;
            if (this.cutie.renderData) {
                renderData = this.cutie.renderData;
            } else {
                try {
                    renderData = await SvgApiService
                        .getCutieAssets({ cutieDbId: this.cutie.id });
                } catch (error) {
                    this.imageStatus = "ERROR";
                    throw error;
                }
            }
            this.renderData = renderData;
            let srcSvgs;
            try {
                srcSvgs = await getSourceSvgs(this.renderData.assets);
            } catch (error) {
                this.imageStatus = "ERROR";
                throw error;
            }
            let svgParts: string[];
            try {
                svgParts = srcSvgs.map(srcSvg => this.colorizeSvg(srcSvg));
            } catch (error) {
                this.imageStatus = "ERROR";
                throw error;
            }

            this.timeAssetsLoaded = Date.now();

            const compiledSvg = compileSvg(svgParts, this.animated);
            const blob = new Blob([compiledSvg], { type: "image/svg+xml" });
            if (this.wasDestroyed) {
                return;
            }
            this.compiledSvgLink = URL.createObjectURL(blob);

            this.timeCompiled = Date.now();
        }

        destroyed() {
            if (this.compiledSvgLink) {
                URL.revokeObjectURL(this.compiledSvgLink);
            } else {
                this.wasDestroyed = true;
            }
        }

        private colorizeSvg(srcSvg: SrcSvg): string {
            let { svg, asset } = srcSvg;
            if (asset.type !== "FANCY") {
                for (const replacement of this.renderData.colorReplacements) {
                    svg = svg.replaceAll(
                        "#" + replacement.sourceColor,
                        "#" + replacement.resultColor
                    );
                }
            }
            return svg;
        }

        finalSvgLoaded() {
            this.imageStatus = "LOADED";
            const timeFinalSvgLoaded = Date.now();
            // only report 1 case out of 20 to not overspam the server
            if (Math.random() < 0.05) {
                SvgApiService.reportLongCutieFromAssetsRender({
                    cutieId: this.cutie.id,
                    assetsLoadTime: this.timeAssetsLoaded - this.timeStarted,
                    compileTime: this.timeCompiled - this.timeAssetsLoaded,
                    finalSvgLoadTime: timeFinalSvgLoaded - this.timeCompiled,
                    assetsCacheError: !assetsCacheError ? undefined : stringifyPlainObjectError(assetsCacheError),
                });
            }
        }
    }
