import type { WalletProviderKind } from "@/components/LoginManager/TypeDefs";
import type { NeoProvider } from "@/cuties/blockchain/neo/NeoProvider";
import NeoO3Provider from "@/cuties/blockchain/neo/NeoO3Provider";
import { ConfigInstance } from "@/cuties/engine/Config";
import ProviderError from "@/cuties/blockchain/ProviderError";
import { NeoPrivateKeyProvider } from "@/cuties/blockchain/neo/NeoPrivateKeyProvider";

export const NEO_PROVIDER_SESSION_KEY = "NEO_WALLET_PROVIDER";

export default class NeoProviderBuilder {
    public static async build(wallet: WalletProviderKind, privateKey = ""): Promise<NeoProvider> {
        let provider;
        switch (wallet) {
            case "private_key":
                provider = await NeoPrivateKeyProvider.create(ConfigInstance.neo, privateKey);
                break;
            case "o3_wallet":
            case "neoline":
                provider = await NeoO3Provider.create(ConfigInstance.neo, wallet);
                break;
            default:
                throw new ProviderError(`Unsupported wallet provider: ${wallet}`);
        }

        localStorage.setItem(NEO_PROVIDER_SESSION_KEY, wallet);

        return provider;
    }

    public static sessionProvider(): WalletProviderKind | null {
        return localStorage.getItem(NEO_PROVIDER_SESSION_KEY) ?? null;
    }

    public static sessionClear(): void {
        localStorage.removeItem(NEO_PROVIDER_SESSION_KEY);
    }
}
