import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";
import http from "@/http";
import langMetaDataNarrow from "./generated/langMetaData.json";
import { anyCaseToHuman } from "cuties-client-components/src/TextUtils";
import { ConfigInstance } from "./cuties/engine/Config";
import { AppStoreMutations } from "@/store/AppStore";
import store from "@/store";
import Cookies from "js-cookie";

export const langMetaData: {
    langToMd5: Record<string, string>;
} = langMetaDataNarrow;

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: "en",
    fallbackLocale: "en",
    missing(locale, key) {
        if (!loadedLangs.has(locale)) {
            // show ui elements without captions when translations
            // are still loading, similar to how youtube handles it
            return "…";
        } else if (ConfigInstance.isTestingEnvironment()) {
            return key;
        } else {
            return anyCaseToHuman(key);
        }
    },
});

function setI18nLanguage(lang: string) {
    i18n.locale = lang;
    axios.defaults.headers.common["Accept-Language"] = lang;
    document.querySelector("html")!.setAttribute("lang", lang);
}

async function fetchLocaleMessages(lang: string): Promise<Record<string, string>> {
    let hash = langMetaData.langToMd5[lang];
    if (!hash) {
        console.error("No md5 for lang " + lang);
        // last resort, since nginx would cache file permanently without a hash
        hash = process.env.VUE_APP_VERSION!;
    }
    return http.get(`static/language/lang-${lang}.json?ver=${hash}`, { baseURL: "/" })
        .then(response => response.data);
}

const requestedLangs = new Set<string>();
const loadedLangs = new Set<string>();

export async function setLanguage(requestedLang: string) {
    Cookies.set("lang", requestedLang, { expires: 30 });
    setI18nLanguage(requestedLang);
    if (!requestedLangs.has(requestedLang)) {
        requestedLangs.add(requestedLang);
        const messages = await fetchLocaleMessages(requestedLang);
        // 4000+ keys, causes safari on iOS 13.4.1+ to die on setLocaleMessage()
        // because i18n makes these keys a reactive property, so we freeze the
        // values to tell vue that they are not going to change
        // see https://github.com/kazupon/vue-i18n/issues/827
        i18n.setLocaleMessage(requestedLang, Object.freeze(messages));
        loadedLangs.add(requestedLang);
        store.commit(AppStoreMutations.SET_TRANSLATIONS_LOADED, true);
    }
}

export default i18n;
