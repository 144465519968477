import type { Stat } from "@/cuties/BattleHpService";
import type { BonusKind } from "cuties-client-components/types/api/Cutie";

export function getStatMapping() {
    const mapping: Record<Stat, BonusKind> = {
        ATTACK: "attack",
        MIN_ATTACK: "minAttack",
        DEFENCE: "defence",
        MIN_DEFENCE: "minDefence",
        EVASION: "evasion",
        LUCK: "criticalHit",
        POWER: "power",
        HEALTH: "health",
        EXPERIENCE: "exp",
        REWARD_DROP: "rewardDropChance",
    };
    return mapping;
}

export function isPercentStat(stat: string): boolean {
    return ["evasion", "criticalHit", "rewardDropChance", "exp"].includes(stat);
}

export function formatStat(value: number, stat: string, round = true): string {
    let res = value.toString();
    if (isPercentStat(stat)) {
        res = round ? Math.round(value * 100).toString() + "%" : (value * 100).toString() + "%";
    }

    return res;
}
