import type { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import type { RootState } from "@/store/RootState";

export interface HeaderMenuState {
    url: string;
}

export const state: HeaderMenuState = {
    url: ""
};

export const getters: GetterTree<HeaderMenuState, RootState> = {
    getUrl: state => state.url
};

export const mutations: MutationTree<HeaderMenuState> = {
    setUrl: (state, payload) => state.url = payload
};

export const actions: ActionTree<HeaderMenuState, RootState> = {
    loadUrl: async ({ commit }) => {
        commit("setUrl", window.location.pathname);
        return true;
    }
};

class HeaderMenuStore implements Module<HeaderMenuState, RootState> {
    public namespaced: boolean = false;
    public state: HeaderMenuState = state;
    public getters: GetterTree<HeaderMenuState, RootState> = getters;
    public mutations: MutationTree<HeaderMenuState> = mutations;
    public actions: ActionTree<HeaderMenuState, RootState> = actions;
}

// return singleton
export const HeaderMenuStoreSingleton = new HeaderMenuStore();
