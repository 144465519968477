
    import { Component, Emit, Prop, Vue } from "vue-property-decorator";
    import LotteryWinner from "@/components/lottery/LotteryWinner.vue";
    import type { CurrentLotteryResponse, RegularLottery } from "@/cuties/lottery/LotteryService";
    import LotteryService from "@/cuties/lottery/LotteryService";
    import utils from "@/cuties/utils";
    import VueUtils from "@/cuties/VueUtils";
    import { Getter } from "vuex-class";
    import { AppStoreGetters } from "@/store/AppStore";
    import type { Player } from "@/cuties/model/player/Player";
    import type { LotteryTemplate } from "@/cuties/model/lottery/LotteryModel";
    import PossessionIdentityBlock from "@/app/components/identity/PossessionIdentityBlock.vue";
    import type { OffchainCurrency } from "@/cuties/offchaincurrency/OffchainCurrency";
    import { fromFixedPointBigNumber, toBlockchain } from "@/cuties/offchaincurrency/OffchainCurrency";
    import PossessionsLayer from "@/app/components/crafting/PossessionsLayer";
    import type { PossessionIdentityOffchainCurrency } from "@/cuties/request/crafting/CraftingApiService";
    import { toBigNumber } from "@/common/utils/toBigNumber";
    import AppSelect from "@/components/app-components/AppSelect.vue";
    import { currencyToBlockchainMap } from "@/cuties/model/pet/BlockchainId";
    import { VueSlideToggle } from "vue-slide-toggle";
    import type { PossessionIdentity } from "cuties-client-components/types/general/UnitIdentity";

    @Component({
        components: { PossessionIdentityBlock, LotteryWinner, AppSelect, "vue-slide-toggle": VueSlideToggle },
    })
    export default class Lottery extends Vue {
        @Prop({ required: true }) definition!: RegularLottery;

        @Getter(AppStoreGetters.GET_EVERY_SECOND_TICK_TIME_MS) everySecondTickTimeMs: number;
        @Getter("getPlayer") user!: Player;

        lotteryResponse: null | CurrentLotteryResponse = null;

        isDataLoaded = false;
        isDescOpen = false;
        isFailed = false;

        selectedCurrency: OffchainCurrency = "TRX";

        async created(): Promise<void> {
            try {
                this.lotteryResponse = await LotteryService.getLottery({ definitionId: this.definition.id });
                this.isDataLoaded = true;
                const bestOption = this.entryFeeOptions[0];
                if (bestOption) {
                    this.selectedCurrency = bestOption.offchainCurrency;
                } else {
                    this.noParticipationOptions();
                }
            } catch (error) {
                this.isFailed = true;
                VueUtils.handleError(error);
            }
        }

        get optionSelected() {
            return this.entryFeeOptions.find(rec => rec.offchainCurrency === this.selectedCurrency);
        }

        blockchainFromCurrency(currency: keyof typeof OffchainCurrency) {
            return currencyToBlockchainMap.get(currency);
        }

        compareFees(aFee: PossessionIdentityOffchainCurrency, bFee: PossessionIdentityOffchainCurrency) {
            if (!this.lotteryResponse) {
                return 0;
            }
            const a = toBlockchain(aFee.offchainCurrency);
            const b = toBlockchain(bFee.offchainCurrency);
            const rank1A = this.lotteryResponse.lottery.allowed.includes(a) ? 0 : 1;
            const rank1B = this.lotteryResponse.lottery.allowed.includes(b) ? 0 : 1;
            if (rank1A - rank1B !== 0) {
                return rank1A - rank1B;
            }

            const rank2A = this.user.get_blockchains().has(a) ? 0 : 1;
            const rank2B = this.user.get_blockchains().has(b) ? 0 : 1;

            return rank2A - rank2B;
        }

        get entryFeeOptions(): PossessionIdentityOffchainCurrency[] {

            return !this.lotteryResponse ? [] : this.definition.entryFeeOptions
                .filter(fee => this.lotteryResponse!.lottery.allowed.includes(toBlockchain(fee.offchainCurrency)))
                .map(fee => {
                    // add a 0-10% extra to the amount to better identify the deposit transaction in case of speedup
                    const identifiablePrice = BigInt(fee.count) + BigInt(Math.floor(Math.random() * +fee.count * 0.1));
                    return Object.assign({}, fee, { count: identifiablePrice });
                })
                .sort((a,b) => this.compareFees(a, b));
        }

        get endsNow() {
            if (!this.lotteryResponse) {
                return false;
            }
            return this.everySecondTickTimeMs >= this.lotteryResponse.lottery.tillTime * 1000;
        }

        get hours() {
            if (!this.lotteryResponse) {
                return "??";
            }
            const msLeft = this.lotteryResponse.lottery.tillTime * 1000 - this.everySecondTickTimeMs;
            return String(Math.floor(msLeft / 1000 / 60 / 60)).padStart(2, "0");
        }

        get minutes() {
            if (!this.lotteryResponse) {
                return "??";
            }
            const msLeft = this.lotteryResponse.lottery.tillTime * 1000 - this.everySecondTickTimeMs;
            return String(Math.floor(msLeft / 1000 / 60 % 60)).padStart(2, "0");
        }

        get seconds() {
            if (!this.lotteryResponse) {
                return "??";
            }
            const msLeft = this.lotteryResponse.lottery.tillTime * 1000 - this.everySecondTickTimeMs;
            return String(Math.floor(msLeft / 1000 % 60)).padStart(2, "0");
        }

        formatEntryFee(option: PossessionIdentityOffchainCurrency) {
            return this.formatFancyPrice(fromFixedPointBigNumber(option.count, option.offchainCurrency));
        }

        formatFancyPrice(fullPrice: string): string {
            const price = toBigNumber(fullPrice);

            const maxDecimalPlaces = 8;
            const minDisplayedValue = Math.pow(0.1, maxDecimalPlaces);
            if (price.lt(minDisplayedValue)) {
                return `${minDisplayedValue.toFixed(maxDecimalPlaces)}...`;
            } else if (price.decimalPlaces() > maxDecimalPlaces) {
                return `${price.decimalPlaces(maxDecimalPlaces).toString()}...`;
            }

            return fullPrice;
        }

        public participateLottery(): void {
            const feeOption = this.entryFeeOptions.find(fee => {
                return fee.offchainCurrency === this.selectedCurrency;
            });
            if (!feeOption) {
                utils.show_notification(this.$tc("lottery_error_no_blockchain"));
                return;
            }
            const lotteryResponse = this.lotteryResponse;
            if (!lotteryResponse) {
                utils.show_notification(this.$tc("please_wait_loading_data"));
                return;
            }

            LotteryService.signInLottery(lotteryResponse.lottery.id, feeOption).then(() => {
                this.$emit("close");
            }).catch((error) => {
                VueUtils.handleError(error);
            });
        }

        getPossessionTitle(identity: PossessionIdentity) {
            return PossessionsLayer.getTitleText(identity, this.$i18n);
        }

        get descriptionSign(): string {
            return this.isDescOpen ? "minus" : "plus";
        }

        get archive(): LotteryTemplate[] {
            return this.lotteryResponse?.archive.slice(0, 3) ?? [];
        }

        @Emit("noParticipationOptions")
        noParticipationOptions() {}
    }
