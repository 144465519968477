/**
 * copied as is from mad.ts, there were no attempts to improve code quality
 * supposed to be zero dependency
 */
export default class DataHelpers {
    /** @deprecated - use typeof directly, it provides type-safe assertions in typescript */
    static isString(obj:any): boolean { return(typeof obj === "string"); }

    static isNumeric(obj:any): boolean { return(typeof obj === "number" && !isNaN(obj));}

    /** @deprecated - use typeof directly, it provides type-safe assertions in typescript */
    static isFunction(obj:any): boolean { return(typeof obj === "function"); }

    static isObject(obj:any): boolean { return(typeof obj === "object" && obj !== null); }

    static isInteger(obj:any): boolean { return obj === parseInt(obj); }

    static toInt(value:any, default_value:number = 0):number {
        try {
            const value_int = parseInt(value);
            if (isNaN(value_int))
                return default_value;
            return value_int;
        } catch (e) {
            console.error("mad.toNumeric: '" + value + "'");
            return default_value;
        }
    }

    static toFloat(value:any, default_value:number = 0):number {
        try {
            if (typeof value == "string" && value.search(/,/g) >= 0 && value.search(/./g) >= 0) {
                value = value.replace(/,/g, "");
            }
            const value_float = parseFloat(value);
            if (isNaN(value_float))
                return default_value;
            return value_float;
        } catch (e) {
            console.error("DataHelpers.toFloat: '" + value + "'");
            return default_value;
        }
    }

    static toBoolean(value:any, default_value:boolean = false):boolean {
        try {
            if (typeof value === "boolean")
                return value;
            if (DataHelpers.isNumeric(value))
                return value != 0;
            if (typeof value === "string")
                return value === "true";
            if (value === undefined || value === null)
                return false;
            return !!value;
        } catch (e) {
            console.error("DataHelpers.toBoolean: '" + value + "'");
            return default_value;
        }
    }

    /**
     * get current timestamp
     * @deprecated - plaese use AppStoreSingleton.state.everySecondTickTimeMs instead for reactivity
     */
    static get_time_seconds(): number {
        return Math.ceil(new Date().getTime() / 1000);
    }

    /**
     * get corresponding enum's value
     * @param {enum | {[key:string]:number}} enum_data - {enumKey:enumValue, ...}
     * @param {enum_key} key - enum's key (or enum's value)
     * @param {number} default_value - enum's default value
     */
    static to_enum_value(enum_data: any, key:any, default_value:any):any {
        if (key === null || key == undefined)
            return default_value;

        const keyInt = DataHelpers.toInt(key, null);
        key = keyInt === null ? key : keyInt;

        for (const enum_key in enum_data) {
            const enum_value = enum_data[enum_key];

            if (DataHelpers.isNumeric(enum_value)) {
                if (typeof key === "string") {
                    if (key === enum_key) {
                        return enum_value;
                    }
                } else if (DataHelpers.isNumeric(key)) {
                    if (key === enum_value) {
                        return enum_value;
                    }
                } else {
                    console.error("DataHelpers.to_enum_value: unsupported key type", key, typeof key, enum_data);
                    return default_value;
                }
            }
        }

        return default_value;
    }

    static to_enum_key(enum_data: any, key:string|number):string {
        const keyInt = DataHelpers.toInt(key, null);
        key = keyInt === null ? key : keyInt;

        for (const enum_key in enum_data) {
            const enum_value = enum_data[enum_key];

            if (DataHelpers.isNumeric(enum_value)) {
                if (typeof key === "string") {
                    if (key === enum_key) {
                        return enum_key;
                    }
                } else if (DataHelpers.isNumeric(key)) {
                    if (key === enum_value) {
                        return enum_key;
                    }
                } else {
                    console.error("DataHelpers.to_enum_key: unsupported key type", key, typeof key, enum_data);
                    return "";
                }
            }
        }

        return null;
    }

    static to_enum_key_array(enum_data: any, keys:any[]):string[] {
        if (keys == null)
            return null;
        const result:string[] = [];

        for (const key in keys) {
            result.push(DataHelpers.to_enum_key(enum_data, keys[key]));
        }

        return result;
    }

    static get_enum_map(enum_data: any): any {
        const map:any = {};

        for (const enum_key in enum_data) {
            const enum_value = DataHelpers.toInt(enum_data[enum_key], -1);
            if (enum_value > 0) {
                map[enum_key] = enum_value;
            }
        }

        return map;
    }

    /**
     * @param enum_data - enum class
     * @param value - some value in this enum, usually numeric
     * @return - this value or default_value if not present in enum
     */
    static to_enum(enum_data: any, value: any, default_value: any): any {
        const result = Object.keys(enum_data)
            .filter(key => enum_data[key] == value)
            .map(key => enum_data[key]);

        if (result.length > 0) {
            return result[0];
        }
        return default_value;
    }

    // n - number
    // c - digit count
    static formatMoney(n, c = 4, d = ".", t = ","): string {
        c = isNaN(c = Math.abs(c)) ? 2 : c;

        let test = Math.abs(Math.floor(n));
        let digitsBeforeDot = 0;
        while (test > 1) {
            test /= 10;
            digitsBeforeDot++;
        }

        let maxc = 5 - digitsBeforeDot;
        if (maxc < 0) maxc = 0;
        if (c > maxc) c = maxc;

        let zeroesAfterDot = 0;

        test = Math.abs(n);
        if (test > 0) {
            while (test < 1) {
                test *= 10;
                zeroesAfterDot++;
            }
            if (c < zeroesAfterDot) c = zeroesAfterDot + 1;
        }

        if (n == 0) c = 0;

        d = d == undefined ? "." : d;
        t = t == undefined ? "," : t;
        const s = n < 0 ? "-" : "";
        const i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c)));
        var j = (j = i.length) > 3 ? j % 3 : 0;

        return s +
            (j ? i.substr(0, j) + t : "") +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
            (c ? d + Math.abs(n - DataHelpers.toInt(i)).toFixed(c).slice(2) : "");
    };

    static get_time_string(timestamp:number, format?:string): string {
        const today = new Date(timestamp * 1000);

        const date_yyyy = today.getFullYear();
        const date_mm = today.getMonth() + 1;    // January is 0
        const date_dd = today.getDate();

        const time_hh = today.getHours();
        const time_mm = today.getMinutes();
        const time_ss = today.getSeconds();

        format = format || "Y-m-d H:i:s";

        format = DataHelpers.replace_all(format, "Y", date_yyyy);
        format = DataHelpers.replace_all(format, "m", date_mm < 10 ? "0" + date_mm : date_mm);
        format = DataHelpers.replace_all(format, "d", date_dd < 10 ? "0" + date_dd : date_dd);

        format = DataHelpers.replace_all(format, "H", time_hh < 10 ? "0" + time_hh : time_hh);
        format = DataHelpers.replace_all(format, "i", time_mm < 10 ? "0" + time_mm : time_mm);
        format = DataHelpers.replace_all(format, "s", time_ss < 10 ? "0" + time_ss : time_ss);

        return format;
    }

    static get_time_duration_digits(seconds:number): string {

        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        hours = hours - days * 24;

        minutes = minutes - ( days * 1440 + hours * 60);



        const seconds_left = seconds - ((days * 86400) + (hours * 3600) + (minutes * 60) );

        let res = "";

        if( !seconds ) return "";

        if(days == 0) {
            res += "0:";
        } else {
            res += days + ":";
        }

        if( hours == 0 ) {
            res += "00:";
        } else{
            if( hours < 10 && hours > 0 ) {
                res += "0" + hours + ":";
            }

            if( hours > 9 ) res += hours + ":";
        }


        if( minutes == 0 ) {
            res += "00:";
        } else{
            if( minutes < 10 && minutes > 0 ) {
                res += "0" + minutes + ":";
            }

            if( minutes > 9 ) res += minutes + ":";
        }


        if( seconds_left <= 0 ) {
            res += "00";
        } else{
            if( seconds_left < 10 && seconds_left > 0 ) {
                res += "0" + seconds_left;
            }

            if( seconds_left > 9) res += seconds_left;
        }
        return res;

    }

    static replace_all(str:string, search: any, replace: any): string {
        return str.split(search).join(replace);
    }

    static error(...args: any[]) {
        console.error(...args);
        // following does not include stack trace of the actual exception, not sure it is needed at all
        console.error(args.length == 1 ? args[0] : Array.prototype.slice.call(args), new Error().stack);
    }
}
