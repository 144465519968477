
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Emit, Prop, Watch } from "vue-property-decorator";
    import type {
        Action,
        InteractiveFighterStats,
        InteractiveFightsStateResponse, InteractiveFightTurnView

    } from "@/app/cuties/interactive/InteractiveFightsApiService";
    import InteractiveFightsApiService from "@/app/cuties/interactive/InteractiveFightsApiService";
    import VueUtils from "@/cuties/VueUtils";
    import type { SideData } from "@/app/components/interactive/InteractiveFighterSide.vue";
    import InteractiveFighterSide from "@/app/components/interactive/InteractiveFighterSide.vue";
    import socket from "@/socket";
    import type { SubscriptionHolder } from "@/subscription/WebSocketClient";
    import InteractiveFightActionsPanel from "./InteractiveFightActionsPanel.vue";
    import { AppStoreGetters } from "@/store/AppStore";
    import { Getter } from "vuex-class";
    import {
        EVIL_FANCIES,
        GOOD_FANCIES,
        isNoDefinitionError,
        LAST_INTERACTIVE_FIGHTER_ID,
        PING_INTERVAL_MS
    } from "@/app/cuties/interactive/InteractiveFightsLayer";
    import {
        getAudioPlayer,
        shouldPlaySound
    } from "@/app/cuties/interactive/InteractiveFightsSoundLayer";
    import { ShowLoader } from "@/common/decorators/ShowLoader";
    import InteractiveFightsBotsList from "@/app/components/interactive/InteractiveFightsBotsList.vue";
    import type { WallpaperBackground, WallpaperPattern } from "@/app/cuties/engine/ConfigApiService";
    import ConfigApiService from "@/app/cuties/engine/ConfigApiService";
    import { sha256 } from "js-sha256";

    @Component({
        components: { InteractiveFightsBotsList, InteractiveFighter: InteractiveFighterSide, InteractiveFightActionsPanel },
    })
    export default class InteractiveFight extends Vue {
        @Prop({ required: true }) fighterId!: string;
        @Prop({ default: false }) introductionMode!: boolean;

        @Getter(AppStoreGetters.GET_EVERY_SECOND_TICK_TIME_MS) everySecondTickTimeMs!: number;

        fightState: InteractiveFightsStateResponse | null = null;
        whenSubscription: Promise<SubscriptionHolder | null> = Promise.resolve(null);
        turnsHistory: InteractiveFightTurnView[] = [];
        fightersStatsRequested = false;
        fightersStats: InteractiveFighterStats[] | null = null;
        lastUpdateRequestedMs = Date.now();
        onBeforeUnload = (event: BeforeUnloadEvent) => {};
        audioPlayer: null | ReturnType<typeof getAudioPlayer> = null;
        wallpaperPatterns: Record<string, WallpaperPattern> | null;
        wallpaperBackgrounds: Record<string, WallpaperBackground> | null;

        readonly isMobile = VueUtils.isMobile;

        created() {
            this.audioPlayer = getAudioPlayer();
            this.whenSubscription = this.fetchLatestState().then(state => {
                if (state && !this.isBotMatch) {
                    const source = "/interactive_fight_state_updated_" + state.fightId;
                    return socket.subscribe(source, async (message) => {
                        await this.fetchLatestState();
                    });
                } else {
                    return null;
                }
            });
            this.onBeforeUnload = (event: BeforeUnloadEvent) => this.destroyedImpl();
            window.addEventListener("beforeunload", this.onBeforeUnload);

            ConfigApiService.getWallpaperPatterns().then(patterns => this.wallpaperPatterns = patterns);
            ConfigApiService.getWallpaperBackgrounds().then(backgrounds => this.wallpaperBackgrounds = backgrounds);
        }

        destroyed() {
            this.destroyedImpl();
            window.removeEventListener("beforeunload", this.onBeforeUnload);
        }

        // beforeunload context seems to be lack access to destroyed() method
        destroyedImpl() {
            this.whenSubscription.then(sub => {
                if (sub) {
                    sub.unsubscribe();
                }
            });
            if (!this.isBotMatch) {
                this.quit().catch(exc => {});
                window.localStorage.removeItem(LAST_INTERACTIVE_FIGHTER_ID);
            }
        }

        get isBotMatch(): boolean {
            return this.fightState?.gameMode === "FIXED_STATS_PLAYER_VS_BOT";
        }

        /**
         * serves two purposes: polls for updates in case websocket does not work for some
         * reason and tells server that user still has the tab opened, i.e. he did not quit
         */
        @Watch("everySecondTickTimeMs")
        async onSecondTick(now: number, old: number) {
            if (!document.hidden &&
                Date.now() - this.lastUpdateRequestedMs > PING_INTERVAL_MS &&
                !this.isBotMatch
            ) {
                await this.fetchLatestState();
            }
        }

        private getNextTurnNumber() {
            return this.turnsHistory.slice(0, 1).map(t => t.turnNumber + 1)[0] ?? 1;
        }

        private async fetchTurnsHistory(currentTurnNumber: number) {
            if (currentTurnNumber > this.getNextTurnNumber()) {
                const newTurns = await InteractiveFightsApiService.getTurnsHistory({
                    fighterId: this.fighterId,
                    minTurnNumber: this.getNextTurnNumber(),
                });
                // filter to handle race condition
                const min = this.getNextTurnNumber();
                this.turnsHistory = [
                    ...newTurns.filter(t => t.turnNumber >= min),
                    ...this.turnsHistory,
                ];
            }
        }

        private async fetchLatestState(): Promise<InteractiveFightsStateResponse | null> {
            this.lastUpdateRequestedMs = Date.now();
            const oldState = this.fightState;
            const oldGameOverState = oldState?.gameOverState ?? "NOT_OVER";
            try {
                this.fightState = await InteractiveFightsApiService
                    .getState({ fighterId: this.fighterId });
            } catch (error) {
                if (isNoDefinitionError(error)) {
                    this.ended();
                } else {
                    VueUtils.handleError(error);
                }
                return null;
            }
            const newState = this.fightState;
            if (this.fightState.fighters.length === 2 &&
                !this.fightersStatsRequested
            ) {
                this.fightersStatsRequested = true;
                InteractiveFightsApiService.getFightersStats({ fightId: this.fightState.fightId })
                    .then(stats => this.fightersStats = stats);
            }
            if (oldGameOverState === "NOT_OVER" &&
                newState.gameOverState !== "NOT_OVER"
            ) {
                this.matchOver();
            }

            this.fetchTurnsHistory(this.fightState.turnNumber).then(() => {
                const soundEvent = oldState && shouldPlaySound(oldState, newState, this.turnsHistory);
                if (soundEvent) {
                    this.audioPlayer!.playSoundEvent(soundEvent).catch(error => console.error(error));
                }
            });

            return this.fightState;
        }

        async actionChosen(action: Action) {
            if (this.fightState?.gameMode === "FIXED_STATS_PLAYER_VS_BOT") {
                await this.fetchLatestState();
            }
        }

        @ShowLoader
        async takeOnBot() {
            await InteractiveFightsApiService.takeOnBot({ fighterId: this.fighterId });
            await this.fetchLatestState();
        }

        async quit() {
            try {
                if (!this.fightState || this.fightState.fighters.every(f => f.connectionStatus === "ACTIVE")) {
                    await InteractiveFightsApiService
                        .quit({ fighterId: this.fighterId });
                }
            } catch (error) {
                // other player could have already wiped the fight
                if (!isNoDefinitionError(error)) {
                    throw error;
                }
            }
            this.ended();
        }

        async kickOpponent() {
            await InteractiveFightsApiService.kickOpponent({ fighterId: this.fighterId });
            await this.fetchLatestState();
        }

        getFighterFancies(fightId: number) {
            return [
                GOOD_FANCIES[fightId % GOOD_FANCIES.length],
                EVIL_FANCIES[fightId % EVIL_FANCIES.length],
            ];
        }

        getOption(options: string[], salt: string): string {
            if (!this.fightState) {
                return "";
            } else {
                const hashSource = salt + this.fightState.fightId;
                const idHash = BigInt("0x" + sha256.create().update(hashSource).hex());
                const index = Number(idHash % BigInt(options.length));
                return options[index];
            }
        }

        get patternId() {
            if (this.introductionMode) {
                return "custom-bg";
            } else if (!this.wallpaperPatterns) {
                return "";
            } else {
                return this.getOption(Object.keys(this.wallpaperPatterns), "pattern_");
            }
        }

        get backgroundId() {
            const options = Object.keys(this.wallpaperBackgrounds)
                // filtered backgrounds are too bright, you can't see pattern on them
                .filter(k => !k.match(/^s\d+$/) && !k.match(/^bg\d+_v2$/));
            return this.getOption(options, "background_");
        }

        getFighterSideData(fightState: InteractiveFightsStateResponse, fighterIndex: number): SideData {
            return {
                index: fighterIndex,
                fighter: fightState.fighters[fighterIndex],
                imageUrl: this.getFighterFancy(fightState, fighterIndex),
                gameOverState: fightState.gameOverState,
                stats: this.fightersStats ? this.fightersStats[fighterIndex] : null,
                lastTurnNumber: this.turnsHistory[0]?.turnNumber ?? 0,
                lastTurnEffect: this.turnsHistory[0]?.effects[fighterIndex] ?? null,
            };
        }

        getFighterFancy(fightState: InteractiveFightsStateResponse, fighterIndex: number) {
            if (this.introductionMode && fightState.fighters[fighterIndex].isRequester) {
                return "/static/jotun1.svg";
            }
            const ownImageUrl = this.fightersStats?.[fighterIndex].imageUrl;
            if (ownImageUrl) {
                return ownImageUrl;
            } else if (this.isBotMatch && !fightState.fighters[fighterIndex].isRequester) {
                return "/rest/public/cutie/fancy/Mutant.svg";
            } else {
                const fancyName = this.getFighterFancies(fightState.fightId)[fighterIndex];
                return "/rest/public/cutie/fancy/" + fancyName + ".svg";
            }
        }

        @Emit("matchOver")
        matchOver() {}

        @Emit("ended")
        ended() {}
    }
