import type { Blockchain } from "../model/pet/BlockchainId";
import mad from "../engine/mad";
import AccountMergeService from "@/cuties/player/AccountMergeService";

mad.handler.register("mergeBlockchainAccounts", async function() {
    const btn = <HTMLButtonElement>(<unknown>this);
    const blockchain = <Blockchain>btn.getAttribute("data-blockchain");
    const walletProviderName = btn.getAttribute("data-wallet-provider-name");
    return AccountMergeService.mergeBlockchainAccounts(blockchain, walletProviderName);
});
