import InteractiveFightsApiService from "./InteractiveFightsApiService";
import type { AxiosError } from "axios";
import VueUtils from "@/cuties/VueUtils";

export const PING_INTERVAL_MS = 6000;

export const LAST_INTERACTIVE_FIGHTER_ID = "LAST_INTERACTIVE_FIGHTER_ID";

export async function joinOrHost(): Promise<string> {
    const latestFights = await InteractiveFightsApiService.listMatchmaking();
    let response;
    if (latestFights.length > 0) {
        response = await InteractiveFightsApiService
            .join({ fightId: latestFights[0].fightId });
    } else {
        response = await InteractiveFightsApiService.host();
    }
    return response.fighterId;
}

export function isNoDefinitionError(axiosError: AxiosError): boolean {
    const { error } = VueUtils.extractErrorData(axiosError) ?? {};
    return error === "ERR_DEFINITION_NOT_FOUND";
}

export const GOOD_FANCIES = [
    "Adventure1st",
    "Ethor",
    "Wolverine",
    "Hexer",
    "Singh",
    "KeeperOfTheNorth",
    "BitcoinMan",
    "CaptainRipple",
    "SeasonGinger",
    "SeasonArachna",
    "DemonSlayer",
    "Ostara",
    "MoonGuard",
    "MoonGuardCaptain",
    "WarriorAngel",
    "Gena",
    "DemRightNowCustomCutie",
    "Dante",
    "Luna",
    "Grynchy",
    "Bongman",
    "RedHedgehog",
    "DuckWithGun",
    "JoshCustomCutie",
    "Executor",
    "OfficerJumps",
    "Heracles",
    "Wookie",
    "TrueKing",
    "Bhairava",
    "TheOne",
    "Treen",
    "DragonQueen",
    "HwarangArcher",
    "Crusader",
    "QueenOfSilla",
    "Kitsune",
    "GattoThePhilosopher",
    "DeveloperGirl",
];

// number of good and evil options must be different,
// as otherwise modulus will always pick same pairs
export const EVIL_FANCIES = [
    "PaleHorseman",
    "KnightOfOblivion",
    "Bloodshed",
    "DarkReaper",
    "MadScientistBoar",
    "EvilResearcherRabbit",
    "RottenNutritionistDog",
    "InsaneNeurosurgeonCat",
    "Death",
    "ChiYou",
    "Isra",
    "MorsNoctis",
    "GhostDragon",
    "Warboss",
    "Wraith",
    "IceDemon",
    "DarkAngel",
    "Fury",
    "AmilclarCustomCutie",
    "Faceless",
    "GzLeeVampire",
    "SeasonPhantasmo",
    "Ichoridan",
    "SilentBobbie",
    "VampireHog",
    "DireVampireHog",
    "DireVampireBear",
    "WarlordKaizer",
    "Silvian",
    "Aurora1",
    "Aurora2",
    "Kumiho",
    "KingSlayer",
    "CGCGiveaway",
    "Hackerdog",
    "Zoanoid",
    "BakeNeko",
    "GuideOfTheDead",
];
