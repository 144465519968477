import type { Client, Frame } from "webstomp-client";
import Stomp from "webstomp-client";
import { sleep } from "@/app/cuties/utils/utils";
import socket from "@/socket";
import SockJS from "sockjs-client";

const socketConnection = () => new SockJS("/rest/ws");

const stompOverSocket = () =>
    Stomp.over(socketConnection(), {
        protocols: ["v10.stomp", "v11.stomp", "v12.stomp"],
        debug: false,
    });

export default class StompClient {
    private session: number | null = null;
    private stomp: Promise<Client> | null = null;

    async getClient(): Promise<Client> {
        return this.stomp ?? this.connect();
    }

    hasUserSession(): boolean {
        return (this.session ?? 0) > 0;
    }

    /**
     * Connect method has global error callback.
     * Error callback is triggered every time when error appears
     * or connection was closed unexpectedly (e.g. poor internet connection)
     */
    async connect(): Promise<Client> {
        this.stomp = new Promise((resolve) => {
            const stompClient = stompOverSocket();
            stompClient.connect(
                {},
                (frame) => {
                    console.log("Websocket initialization complete");
                    this.session = Number(frame?.headers["user-name"] ?? 0);
                    resolve(stompClient);
                },
                (error: CloseEvent | Frame) => {
                    console.error("Websocket closed! Trying to reconnect!");
                    stompClient.disconnect();
                    this.stomp = null;
                    resolve(this.reconnect(5000));
                }
            );
        });
        return this.stomp;
    }

    async reconnect(delayMs: number): Promise<Client> {
        await sleep(delayMs);
        await this.disconnect();
        this.stomp = this.connect();
        await socket.resubscribe();
        return this.stomp;
    }

    async disconnect(): Promise<void> {
        if (this.stomp) {
            const stomp = await this.stomp;
            stomp.disconnect();
            this.stomp = null;
        }
    }
}
