import type { Getter, Module, Mutation } from "vuex";
import type { RootState } from "@/store/RootState";
import type {
    GlobalChatAnnounceMessageEvent,
    GlobalChatDeleteMessageEvent,
    GlobalChatNewMessageEvent
} from "@/app/subscription/GlobalChatSubscriptions";
import type { GlobalChatSpamDeletedEvent } from "@/app/subscription/GlobalChatSubscriptions";

/**
 * takes a narrow type and returns a wider one
 * example: null -> null | string
 * similar to `as` operator, but vice-versa (type safe)
 */
const is = <TWider>(input: TWider): TWider => input;
/** ensures that each key name is same as string value */
const constMapping = <T extends { [K in keyof T]: K }>(mapping: T) => mapping;

const state = {
    globalChatNewMessage: is<GlobalChatNewMessageEvent | null>(null),
    globalChatDeleteMessage: is<GlobalChatDeleteMessageEvent | null>(null),
    globalLastChatSpamDeletion: is<GlobalChatSpamDeletedEvent | null>(null),
    globalChatAnnounceMessage: is<GlobalChatAnnounceMessageEvent | null>(null),
};

type GlobalChatState = typeof state;

export const Getters = constMapping({
    GET_GLOBAL_CHAT_NEW_MESSAGE: "GET_GLOBAL_CHAT_NEW_MESSAGE",
    GET_GLOBAL_CHAT_DELETE_MESSAGE: "GET_GLOBAL_CHAT_DELETE_MESSAGE",
    GET_GLOBAL_CHAT_LAST_SPAM_DELETION: "GET_GLOBAL_CHAT_LAST_SPAM_DELETION",
    GET_GLOBAL_CHAT_ANNOUNCE_MESSAGE: "GET_GLOBAL_CHAT_ANNOUNCE_MESSAGE",
});

const getters: { [K in keyof typeof Getters]: Getter<GlobalChatState, RootState> } = {
    GET_GLOBAL_CHAT_NEW_MESSAGE: state => state.globalChatNewMessage,
    GET_GLOBAL_CHAT_DELETE_MESSAGE: state => state.globalChatDeleteMessage,
    GET_GLOBAL_CHAT_LAST_SPAM_DELETION: state => state.globalLastChatSpamDeletion,
    GET_GLOBAL_CHAT_ANNOUNCE_MESSAGE: state => state.globalChatAnnounceMessage,
};

export const Mutations = constMapping({
    SET_GLOBAL_CHAT_NEW_MESSAGE: "SET_GLOBAL_CHAT_NEW_MESSAGE",
    SET_GLOBAL_CHAT_DELETE_MESSAGE: "SET_GLOBAL_CHAT_DELETE_MESSAGE",
    SET_GLOBAL_CHAT_LAST_SPAM_DELETION: "SET_GLOBAL_CHAT_LAST_SPAM_DELETION",
    SET_GLOBAL_CHAT_ANNOUNCE_MESSAGE: "SET_GLOBAL_CHAT_ANNOUNCE_MESSAGE",
});

const mutations: { [K in keyof typeof Mutations]: Mutation<GlobalChatState> } = {
    SET_GLOBAL_CHAT_NEW_MESSAGE: (state, payload) => state.globalChatNewMessage = payload,
    SET_GLOBAL_CHAT_DELETE_MESSAGE: (state, payload) => state.globalChatDeleteMessage = payload,
    SET_GLOBAL_CHAT_LAST_SPAM_DELETION: (state, payload) => state.globalLastChatSpamDeletion = payload,
    SET_GLOBAL_CHAT_ANNOUNCE_MESSAGE: (state, payload) => state.globalChatAnnounceMessage = payload,
};

class GlobalChatStore implements Module<GlobalChatState, RootState> {
    public namespaced = false;
    public state: GlobalChatState = state;
    public getters = getters;
    public mutations = mutations;
    public actions = {};
}

// return singleton
export const GlobalChatStoreSingleton = new GlobalChatStore();
