import type { AnyMap } from "@/cuties/engine/mad";

export default class EosConfig {
    public readonly contractAccount: string;
    public readonly vaultAccount: string;
    public readonly txInfoUrl: string;
    public readonly accountInfoUrl: string;
    public readonly chainId: string;
    public readonly nodeUrl: string;

    public constructor(data: AnyMap) {
        this.contractAccount = data.contractAccount;
        this.vaultAccount = data.vaultAccount;
        this.txInfoUrl = data.txInfoUrl;
        this.accountInfoUrl = data.accountInfoUrl;
        this.chainId = data.chainId;
        this.nodeUrl = data.nodeUrl;
    }
}
