import type { rpc } from "@cityofzion/neon-core";
import type { CallParams } from "@/cuties/blockchain/neo/NeoProvider";
import type { WalletAddress } from "@/components/LoginManager/TypeDefs";
import type { NeoConfig, NeoContracts } from "@/cuties/blockchain/neo/NeoConfig";
import { ConfigInstance } from "@/cuties/engine/Config";
import type { StackItemJson } from "@cityofzion/neon-core/lib/sc";
import CutiesApiFaucet from "@/app/cuties/blockchain/CutiesApiFaucet";

export default class NeoContractApi {
    constructor(private readonly neo: rpc.RPCClient) {}

    public async call(call: CallParams): Promise<StackItemJson[]> {
        const contractAddress = this.getContractAddress(call.contractName);
        const { wallet } = await CutiesApiFaucet.getNeonCore();
        const scriptHash = "0x" + wallet.getScriptHashFromAddress(contractAddress);

        const response = await this.neo.invokeFunction(scriptHash, call.methodName, call.methodArguments);

        if (response.state === "FAULT") {
            throw new Error(`Error on contract read [contract=${call.contractName}, ${response.exception}`);
        }

        return response.stack;
    }

    public getContractAddress(name: keyof NeoContracts): WalletAddress {
        const contracts = this.getConfig().contracts || null;
        if (!contracts || Object.keys(contracts).length === 0) {
            throw new Error("Contract addresses were not supplied in config");
        }
        const contractAddress = contracts[name] || null;
        if (!contractAddress) {
            throw new Error(`Contract address for ${name} was not supplied in config`);
        } else {
            return contractAddress;
        }
    }

    private getConfig(): NeoConfig {
        const config = ConfigInstance.neo || null;
        if (!config || Object.keys(config).length === 0) {
            throw new Error("NEO config is not available");
        } else {
            return config;
        }
    }
}
