
    import Component from "vue-class-component";
    import Vue from "vue";
    import { Emit, Prop, Watch } from "vue-property-decorator";
    import ItemApiService from "@/app/cuties/items/ItemApiService";
    import VueUtils from "@/cuties/VueUtils";
    import type { ItemInstanceModelBase } from "@/cuties/model/item/ItemInstanceModel";
    import type ItemInstanceModel from "@/cuties/model/item/ItemInstanceModel";
    import ItemTooltip from "@/components/items/ItemTooltip.vue";
    import PossessionsLayer from "@/app/components/crafting/PossessionsLayer";

    /**
     * use this component where you need to show heavy item details,
     * while only having the basic data and the data has to be loaded
     * only when this particular component is focused by the user
     */
    @Component({
        components: { ItemTooltip },
    })
    export default class ItemTooltipLazyLoad extends Vue {
        @Prop({ required: true }) partialData: ItemInstanceModel | ItemInstanceModelBase;
        @Prop({ required: true }) visible: boolean;

        private loadedData: ItemInstanceModel | null = null;
        private extraDataLoading = false;

        mounted() {
            this.onVisibleChange(this.visible);
        }

        get fullData(): ItemInstanceModel | null {
            if (this.loadedData) {
                return this.loadedData;
            } else if ("stats" in this.partialData) {
                return this.partialData;
            } else {
                return null;
            }
        }

        get baseName(): string {
            return PossessionsLayer.getModelBaseName(this.partialData);
        }

        @Watch("visible")
        onVisibleChange(value: boolean): void {
            if (!value) {
                return;
            }
            if (this.extraDataLoading) {
                return; // already started loading
            }
            if (this.fullData) {
                return; // data already available
            }
            this.extraDataLoading = true;
            ItemApiService.itemModelByIdentity({
                item: this.partialData.schema.info.type,
                enchantLevel: this.partialData.level,
            }).then(fullData => this.loadedData = fullData)
                .catch(exc => VueUtils.handleError(exc))
                .finally(() => this.extraDataLoading = false);
        }

        @Emit()
        close() {}
    }
