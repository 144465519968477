
/**
 * @deprecated - use Vue router
 *
 * PageManager knows how to send get/post pages to server.
 * Also PageManager knows all page classes (subclasses of page).
 * pages are bound to DOM elements via special html attribute data-page="page_id" (it can be a form or a link or anything else).
 * You can manually create instance of page via PageManager.create_page(page_id).
 *
 * When page is sent, runs handler 'on_page_begin'.
 * When response is received, runs handler 'on_page_end'.
 */
export class PageManager {

    private current_page_id: string | null = null;

    set_body_class(page_id:string): void {
        $("body").attr("class", "page_" + page_id);
    }

    /**
     * get id of the current version
     */
    get_current_page_id(): string | null {
        return this.current_page_id;
    }
}

/** used for fast navigation from one SPA page to another, the legacy way */
export const PAGE_MANAGER_SINGLETON = new PageManager();
