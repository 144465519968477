
    import Component from "vue-class-component";
    import { Select } from "element-ui";
    import { Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {},
        extends: Select /* @see https://element.eleme.io/#/en-US/component/select */,
    })
    export default class AppSelect extends Vue {
        @Prop({
            required: false,
            default: function () {
                return this.$tc("search_loading");
            },
        })
        loadingText: string;

        @Prop({
            required: false,
            default: function () {
                return this.$tc("search_no_matched_data");
            },
        })
        noMatchText: string;

        @Prop({
            required: false,
            default: function () {
                return this.$tc("search_no_data_found");
            },
        })
        noDataText: string;

        @Prop({
            required: false,
            default: function () {
                return this.$tc("search_please_choose");
            },
        })
        placeholder: string;

        @Prop({ required: false, default: null })
        lazyLoadFunction: () => void;

        wrapElement: Element | null = null;

        mounted(): void {
            if (this.$props.filterable) {
                this.removeReadonly();
            }
            if (this.lazyLoadFunction) {
                this.wrapElement = this.$el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");

                if ("addEventListener" in this.wrapElement) {
                    this.wrapElement.addEventListener("scroll", this.handleLazyLoad, { passive: true });
                }
            }
        }

        handleLazyLoad(event: Event): void {
            const element = event.target as Element;
            const isScrolledToBottom = element.scrollHeight - element.scrollTop <= element.clientHeight;

            if (isScrolledToBottom) {
                this.lazyLoadFunction();
            }
        }

        beforeUpdate(): void {
            if (this.$props.filterable) {
                this.$nextTick(() => {
                    this.removeReadonly();
                });
            }
        }

        removeReadonly(): void {
            this.$el.querySelector(".el-input__inner").removeAttribute("readonly");
        }

        beforeDestroy(): void {
            if (this.lazyLoadFunction && "removeEventListener" in this.wrapElement) {
                this.wrapElement.removeEventListener("scroll", this.handleLazyLoad);
            }
        }
    }
