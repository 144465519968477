import type BlockchainAccountManager from "@/cuties/BlockchainAttachManager";
import { Blockchain } from "@/cuties/model/pet/BlockchainId";
import VueUtils from "@/cuties/VueUtils";
import type { Web3Provider } from "@/cuties/blockchain/matic/Web3Provider";
import utils from "@/cuties/utils";
import type { AuthProvider } from "@/cuties/blockchain/types";
import LoginService from "@/cuties/model/player/LoginService";
import type { AsyncComponent } from "vue/types/options";
import { ConfigInstance } from "@/cuties/engine/Config";

const LoginManagerEth = () => import(/* webpackChunkName: "LoginManagerEth" */ "@/components/LoginManager/LoginManagerEth.vue");
const LoginManagerWeb3 = () => import(/* webpackChunkName: "LoginManagerWeb3" */ "@/components/LoginManager/LoginManagerWeb3.vue");

/** maybe this could be generalized for all blockchains even... */
export default class AttachWeb3AccountManager implements BlockchainAccountManager {
    private readonly blockchain: Blockchain;

    constructor(blockchain: Blockchain) {
        this.blockchain = blockchain;
    }

    private async tryAttachAccount(provider: AuthProvider) {
        const termsText = ConfigInstance.get("terms");
        await LoginService.tryAttachAccount({
            blockchain: this.blockchain,
            // lower case needed because same normalization is done on server, and we update local user state with
            // this value, and ui in many places expects lower case when checking whether logged user account does
            // not match the one in wallet provider
            account: (await provider.getWalletAddress()).toLowerCase(),
            code: await provider.signPersonalMessage(termsText),
        });
    }

    async OpenPopupAttachAccount(): Promise<void> {
        let loginManager: AsyncComponent;

        switch (this.blockchain) {
            case Blockchain.Ethereum:
                loginManager = LoginManagerEth;
                break;
            case Blockchain.Matic:
            case Blockchain.Emerald:
            case Blockchain.Heco:
            case Blockchain.Csc:
            case Blockchain.Okc:
                loginManager = LoginManagerWeb3;
                break;
            default:
                utils.show_notification(this.blockchain + " blockchain has no support in AttachWeb3AccountManager");
                throw Error(this.blockchain + " blockchain has no support in AttachWeb3AccountManager");
        }

        const provider = await VueUtils.vuePopupCustomId<Web3Provider>("popup_wallet_vue", loginManager, {
            blockchain: this.blockchain,
            unlock: false,
        });
        return this.tryAttachAccount(provider);
    }
}
