import { BlockchainServiceCompanion } from "@/cuties/blockchain/BlockchainService";
import BigNumber from "bignumber.js";

export default class NeoWalletCompanion extends BlockchainServiceCompanion {
    private static readonly unitFraction = 8;

    public readonly unit_factor = NeoWalletCompanion.unitFraction;

    public readonly icon16Class = "neo_icon_16";
    public readonly icon28Class = "neo_icon_28";

    public readonly auctionStartStep = "0.1";
    public readonly auctionStartMin = "0";
    public readonly auctionStartDefault = "1.5";

    public readonly auctionEndStep = "0.1";
    public readonly auctionEndMin = "0";
    public readonly auctionEndDefault = "0.5";

    public static toGasInt(value: BigNumber): BigNumber {
        return value.times(new BigNumber(10).pow(NeoWalletCompanion.unitFraction));
    }

    public static toGasDecimal(value: BigNumber): BigNumber {
        return value.dividedBy(new BigNumber(10).pow(NeoWalletCompanion.unitFraction));
    }

    isAddressSame(firstAddress: string, secondAddress: string): boolean {
        return firstAddress === secondAddress;
    }
}
