import WebsocketSubscription from "@/subscription/WebSocketSubscription";
import socket from "@/socket";
import store from "@/store";
import { Mutations } from "@/app/store/GlobalChatStore";
import GlobalChatApiService from "@/app/cuties/chat/GlobalChatApiService";
import utils from "@/cuties/utils";
import i18n from "@/i18n";
import { PLAYER_SINGLETON } from "@/cuties/model/player/Player";

export default [
    new WebsocketSubscription({
        subscribe: handler => socket.subscribe("/global_chat_new_message", handler),
        onMessage: (message: GlobalChatNewMessageEvent) => {
            store.commit(Mutations.SET_GLOBAL_CHAT_NEW_MESSAGE, message);
            if (message.replyToUserId && message.replyToUserId === PLAYER_SINGLETON.get_id()) {
                GlobalChatApiService.getExactMessage({
                    messageId: message.messageId,
                }).then(message => {
                    const suffix = i18n.t("chat_reply_notification_user_suffix");
                    utils.show_notification(message.userName + suffix + ":\n" + "> " + message.messageBody.trim());
                });
            }
        },
    }),
    new WebsocketSubscription({
        subscribe: handler => socket.subscribe("/global_chat_announce_message", handler),
        onMessage: (message: GlobalChatAnnounceMessageEvent) => {
            const header = i18n.t("chat_announcement_notification_header");
            utils.show_notification(header + ":\n" + message.messageBody);
            store.commit(Mutations.SET_GLOBAL_CHAT_ANNOUNCE_MESSAGE, message);
        },
    }),
    new WebsocketSubscription({
        subscribe: handler => socket.subscribe("/global_chat_delete_message", handler),
        onMessage: (message: GlobalChatDeleteMessageEvent) => {
            store.commit(Mutations.SET_GLOBAL_CHAT_DELETE_MESSAGE, message);
        },
    }),
    new WebsocketSubscription({
        subscribe: handler => socket.subscribe("/global_chat_spam_deleted", handler),
        onMessage: (event: GlobalChatSpamDeletedEvent) => {
            store.commit(Mutations.SET_GLOBAL_CHAT_LAST_SPAM_DELETION, event);
        },
    }),
];

export interface GlobalChatNewMessageEvent {
    room: string;
    messageId: number;
    replyToUserId: number;
}

export interface GlobalChatAnnounceMessageEvent {
    messageId: number;
    messageBody: string;
}

export interface GlobalChatDeleteMessageEvent {
    messageId: number;
}

export interface GlobalChatSpamDeletedEvent {
    room: string;
    telegramUserId: number;
}
