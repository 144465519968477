import { ConfigInstance } from "@/cuties/engine/Config";
import DataHelpers from "@/cuties/engine/DataHelpers";
import $ from "jquery";
import type { Player } from "@/cuties/model/player/Player";

let analyticsReady: boolean = false;

export default class analytics {
    static guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }

        let result = "";

        for(let i = 0; i < 8; i++)
            result += s4();

        return result;
    }

    static gtag_log_event(action: any, category: any, label: any, value?: any, transaction_id?: string) {

        const fn = window["gtag"];

        const data = {
            "event_category": category,
            "event_label": label,
        };

        if (value) {
            data["value"] = value * 1000;
        }

        if (transaction_id) {
            data["transaction_id"] = transaction_id;
        }

        if (typeof fn === "function" && !ConfigInstance.are_dev_features_allowed()) {
            fn("event", action, data);
        }

        if (ConfigInstance.are_dev_features_allowed()) {
            console.info("ga event fire", { category: category, action: action, label: label });
        }
    }

    static gtag_log_page_navigation(player: Player) {
        const gtag = window["gtag"];

        if (DataHelpers.isFunction(gtag) && !analyticsReady) {
            analyticsReady = true;

            gtag("js", new Date());
            gtag("set", { "sessionId": analytics.guid() });

            if (player.get_id()) {
                gtag("set", { "user_id": player.get_id() });
            }

            gtag("config", "UA-198129654-1", {
                "custom_map": {
                    "dimension3": "user_id",
                    "dimension4": "clientId",
                    "dimension5": "sessionId"
                }
            });

            if (window["fbq"]) {
                window["fbq"]("track", "PageView"); // log fb
            }
            if (window["VK"]) {
                window["VK"].Retargeting.Hit();
            }

            // RTB script

            $("body").append("<img src=\"//pixel.sitescout.com/iap/60894b12fbf8da9f\" width=\"1\" height=\"1\" style=\"position: absolute; left: -150px;\" onload=\"console.log(\"rbt ok!\"); javascript: this.remove()\"/>");
        }
    }
}
