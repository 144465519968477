
    import { Component, Prop, Vue } from "vue-property-decorator";
    import type { LotteryTemplate } from "@/cuties/model/lottery/LotteryModel";
    import DataHelpers from "@/cuties/engine/DataHelpers";
    import PossessionIdentityBlock from "@/app/components/identity/PossessionIdentityBlock.vue";
    import UserProfileIcon from "@/app/components/player/UserProfileIcon.vue";

    @Component({
        components: { UserProfileIcon, PossessionIdentityBlock }
    })
    export default class LotteryWinner extends Vue {
        @Prop({ required: true }) archive!: LotteryTemplate;

        hasWinner: boolean = false;

        get winner() {
            const winner = this.archive.winner;
            if (winner) {
                this.hasWinner = true;
                return winner.name;
            } else {
                this.hasWinner = false;
                return "No winner";
            }
        }

        get lotteryDate() {
            return DataHelpers.get_time_string(this.archive.tillTime, "d.m.Y");
        }
    }

