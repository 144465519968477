
    import { Component, Vue } from "vue-property-decorator";
    import { Action, Getter } from "vuex-class";
    import VueUtils from "@/cuties/VueUtils";
    import { AppStoreActions, AppStoreGetters } from "@/store/AppStore";

    const Shop = () => import(/* webpackChunkName: "Shop" */ "@/components/shop/Shop.vue");

    @Component({
        components: { Shop },
    })
    export default class ShopPopup extends Vue {
        @Getter(AppStoreGetters.SHOP_POPUP_SHOW) showPopup!: boolean;
        @Getter(AppStoreGetters.SHOP_POPUP_CATEGORY) category!: string;
        @Action(AppStoreActions.SHOP_POPUP_CLOSE) closePopup!: () => boolean;

        showPopupInfo = true;
        /**
         * due to LoginManager using a separate Vue instance, the z-indexes of modals get
         * conflicts - as a workaround I'm hiding the parent popup when child popup is active
         */
        isFocused = true;

        isMobile(): boolean {
            return VueUtils.isMobile();
        }

        showSeasonPass(): boolean {
            return ["SeasonPass", "SeasonPoints"].includes(this.category);
        }

        close(): void {
            this.closePopup();
        }

        closeInfo(): void {
            this.showPopupInfo = false;
        }
    }
