export enum ActivityType {
    None,
    PetBuy,
    PetBreed,
    AuctionCreateBuy,
    AuctionCreateBreed,
    AuctionCancelSell,
    AuctionCancelSire,
    PurchaseCoins,
    AdView,
    AdventureBattleWin,
    AdventureBattleFail,
    AdventureBattleDraw,
    DropPoolRewardReceived,
    TransferPending,
    Transfer,
    Approve,
    ApproveERC20,
    AuctionSiringBid,
    AuctionSiringBidTutorial,
    PresaleBid,
    LandPresaleBid,

    ExternalContract,
    ItemBuy,
    MakeItemBuyOrder,
    MakeItemSellOrder,
    CancelItemBuyOrder,
    CancelItemSellOrder,
    TakeItemBuyOrder,
    TakeItemSellOrder,
    HotShopPurchase,
    OffchainSaleUniquePurchase,
    DungeonWorldShopPurchase,
    DungeonStageCleared,
    ItemUse,
    StartItemAuction,
    CancelItemAuction,
    EndItemAuction,
    StoreFeaturing,
    TutorialPetGone,
    TutorialPetAdventuresOver,
    SetApprovalForAll,

    AuctionExpired,
    CreatePromoCutie,
    CreatePromoItem,
    CreateTickets,
    UserIcon,
    ItemGift,
    Donate,
    FancyBid,
    UniqueBid,
    FancyWithdraw,
    FancyBidWin,
    UniqueBidWin,

    EnergyGain,
    TokenErc20Gain,
    TokenErc20Withdraw,
    TokenErc20Purchase,
    CuteCoinShopBuy,
    CreateEosAccount,
    Custody,
    Token1155Transfer,
    TokenNftTransfer,

    TournamentCancelled,
    TournamentFinished,
    SeasonEventLevelGain,

    // EOS

    EosAccountAttachedToAnotherAccount,
    EosAccountAttachedAlreadyAnother,
    EosAccountNotCreatedAlreadyExists,
    EosAccountNotCreatedInvalidName,

    BlockchainAccountAttachedToUser,

    // offchain


    // NEO
    NeoRefund,
    NeoPurchase,


    AdventureFootballWin,
    AdventureFootballFail,
    Achievement,

    LotteryBid,
    LotteryWin,

    FeatureClosed,

    EthSend,

    ShopProductGain,

    PromoCodeUse,

    ItemGain,

    ItemReplacedOnPet,
    ItemRemovedByRemover,

    AdminUniqueAuctionAdd,

    RaidSessionWin,
    RaidSessionFail,

    AdminCustomAuctionAdd,
    AdminUniqueAuctionFinish,
    AdminUniqueAuctionAbort,
    AdminCustomAuctionFinish,
    AdminCustomAuctionAbort,

    ItemRecycleStarted,
    ItemRecycleCompleted,
    ItemRecycleCancelled,
    ItemUpgradeSuccessful,
    ItemUpgradeFailed,
    ItemRepairStarted,
    ItemRepairCompleted,
    ItemRepairCancelled,

    ItemBreak,

    ActivityTypeEosStake,
    ActivityTypeEosUnstake,

    EnergyFreeGain,
    FiatTransactionBlockchainProcessing,

    AdventureNoPvpEnemyFound,

    OffchainCurrencyDeposit,
    OffchainCurrencyWithdraw,
    PossessionProducedWithMemo,
    PossessionConsumedWithMemo,
    MultiplePossessionsProducedWithMemo,
    MultiplePossessionsConsumedWithMemo,
    BCUGShopBuy,
    CutieRespawn,
}
