import { BlockchainServiceCompanion } from "@/cuties/blockchain/BlockchainService";

export default class HecoWalletCompanion extends BlockchainServiceCompanion {
    public readonly unit_factor = 18;

    public readonly icon16Class = "heco_icon_16";
    public readonly icon28Class = "heco_icon_28";

    public readonly auctionStartStep = "0.001";
    public readonly auctionStartMin = "0";
    public readonly auctionStartDefault = "0.005";

    public readonly auctionEndStep = "0.001";
    public readonly auctionEndMin = "0";
    public readonly auctionEndDefault = "0.001";

    isAddressSame(firstAddress: string, secondAddress: string): boolean {
        return firstAddress.toLowerCase() === secondAddress.toLowerCase();
    }
}
