import type { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import type { RootState } from "@/store/RootState";
import AdminEqupmentService from "@/cuties/admin/adminEquipmentService";
import type ResponseAdminEquipment from "@/cuties/admin/ResponseAdminEquipment";


export const getters: GetterTree<ResponseAdminEquipment, RootState> = {

};

export const mutations: MutationTree<ResponseAdminEquipment> = {

};

export const actions: ActionTree<ResponseAdminEquipment, RootState> = {

    createItem: async ({ commit }, payload) => {
        const response = await AdminEqupmentService.createItem(payload.type, payload.count, payload.chain, payload.level, payload.blockchain);
        if( !response.error ) {
            console.log("response", response);
            // commit('setMercenaryHired', new MercenaryModel(response.item));
        }

        return true;
    },
    createSet: async ({ commit }, payload) => {
        const response = await AdminEqupmentService.createSet(payload.type, payload.count);
        if( !response.error ) {
            console.log("response", response);
            // commit('setMercenaryHired', new MercenaryModel(response.mercenary));
        }

        return true;
    },

};

class AdminEquipmentStore implements Module<ResponseAdminEquipment, RootState> {
    public namespaced: boolean = false;
    //public state: ResponseAdminEquipment = state;
    public getters: GetterTree<ResponseAdminEquipment, RootState> = getters;
    public mutations: MutationTree<ResponseAdminEquipment> = mutations;
    public actions: ActionTree<ResponseAdminEquipment, RootState> = actions;
}

// return singleton
export const AdminEquipmentStoreSingleton = new AdminEquipmentStore();
