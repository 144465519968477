import I18nHelpers from "@/cuties/engine/I18nHelpers";
import type { BonusesMapping, PetModelData } from "@/cuties/model/pet/PetModelData";
import { ALL_ELEMENTS } from "@/cuties/pet/PetService";
import type { BonusKind } from "cuties-client-components/types/api/Cutie";

export enum PetNobility
{
    None = 0,
    Normal = 1,
    Noble = 2,
}

export enum PetPurposeKind
{
    None,
    Standard,
    Tutorial,
}

export enum PetKind
{
    None = -1,
    Cat = 0,
    Dog = 1,
    Bear = 2,
    Lizard = 3,
    Fox = 4,
    Owl = 5,
    Alien = 6,
    Hedgehog = 7,
    Mythic = 8,
    Mutant = 9,
    Pig = 10,
    Rabbit = 11,
    Deer = 12
}

export enum PetRarityKind
{
    None = 0,
    Normal = 1,
    Fancy = 2,
    Unique = 3
}

export function collectElements(bonuses: Partial<BonusesMapping>) {
    let max_element_bonus = 0;
    /** a name of an SVG image file in /adventures/elements/ */
    let bestElementsImageName = "icon_elements";

    const elements = {};
    // mind that they are in alphabetical order!
    const elementNames: readonly BonusKind[] = ALL_ELEMENTS;
    for (const name of elementNames) {
        elements[name] = bonuses[name]?.bonus ?? 0;
    }

    for (const key of elementNames) {
        if (elements[key] > max_element_bonus) max_element_bonus = elements[key];
    }

    for (const key of elementNames) {
        if (elements[key] == max_element_bonus) bestElementsImageName += "_" + key;
    }
    return { bestElementsImageName, elements };
}

/**
 * PetModel represents a pet
 * @deprecated - use PetModelData directly, without class wrappers
 */
export default class PetModel {
    public readonly data: PetModelData;
    public readonly name: string;

    /**
     * PlayerModel's constructor
     * @param {object} data - JSON from server {id:123, name:"John"}
     */
    constructor(data: PetModelData) {
        this.data = data;
        this.name = data.name ?? I18nHelpers.translate("pet") + " " + data.uid;
    }

    get_name(): string {
        return this.name;
    }
}
