import { getStateless, makeHttp } from "@/http";
import type {
    PossessionIdentityItem
} from "@/cuties/request/crafting/CraftingApiService";
import type {
    CutieListFilterRequestBase,
    CutieRequirementsModel,
    FilteredCutiesListResponse
} from "@/app/cuties/tournament/TournamentService";
import type { AttackAction, DefenceAction, Element, RoundAction, StatValue } from "@/cuties/BattleHpService";
import type EnergyModel from "@/cuties/model/adventure/EnergyModel";
import type { AttributeStatsModel } from "@/cuties/model/pet/PetModelData";
import type {
    GateGroup,
    GatePart,
    HostileNpc,
    IndestructibleObstacle,
    PickupLoot,
    SingleTileDestructible, TileAttribute
} from "@/app/cuties/dungeons/Tilemap";
import ActionsQueue from "@/app/cuties/utils/ActionsQueue";
import type { AxiosResponse } from "axios";
import type { PossessionIdentity } from "cuties-client-components/types/general/UnitIdentity";

const http = makeHttp({ baseUrl: "/rest" });

const POST_QUEUE = ActionsQueue();

/**
 * most of the operations in dungeons can't be executed in parallel: there is an optimistic lock on instance
 * version for each update, therefore client should ensure that requests are sent one by one to avoid hitting locks
 */
function postQueued(apiPath: string, params: object): Promise<AxiosResponse> {
    return POST_QUEUE.enqueue(() => http.post(apiPath, params));
}

export default class DungeonsApiService {
    private static whenNpcFancyTemplates: Promise<NpcFancyTemplateView[]> | null = null;
    private static whenPossiblePartyItems: Promise<PossessionIdentityItem[]> | null = null;
    private static whenConsumableDefinitions: Promise<Consumable[]> | null = null;

    public static getWorldDefinitions(): Promise<WorldBaseDefinitionView[]> {
        return getStateless("/public/dungeons/world/definitions").then((response) => response.data);
    }

    public static getStageDefinition(stageId: string): Promise<StageDefinition> {
        return getStateless(`/public/dungeons/stage/definition/${stageId}`).then((response) => response.data);
    }

    public static getWorldDefinition(worldId: string): Promise<WorldFullDefinitionResponse> {
        return getStateless(`/public/dungeons/world/definition/${worldId}`).then((response) => response.data);
    }

    public static getWorldState(params: { worldId: string }): Promise<WorldStateResponse> {
        return http.get("/dungeons/world/state", { params }).then((response) => response.data);
    }

    public static async getInstanceDefinition(params: { instanceId: number }): Promise<DungeonInstanceDefinitionResponse> {
        return getStateless("/public/instance/definition", { params }).then((response) => response.data);
    }

    public static async getStageHighscores(stageId: string): Promise<StageHighscoreProjection[]> {
        return getStateless(`/public/dungeons/stage/highscores/${stageId}`).then((response) => response.data);
    }

    public static async getStagePossibleRewardsGroups(stageId: string): Promise<RetainedGoodsGroup[]> {
        return getStateless(`/public/dungeons/stage/possible/rewards/groups/${stageId}`).then((response) => response.data);
    }

    public static async startDungeonInstance(params: DungeonInstanceStartRequest): Promise<DungeonInstanceStartResponse> {
        return postQueued("/dungeons/instance/start", params).then(response => response.data);
    }

    public static async pauseDungeonInstance(params: { instanceId: number }): Promise<void> {
        return postQueued("/dungeons/instance/pause", params).then(response => response.data);
    }

    public static async unpauseDungeonInstance(params: { instanceId: number }): Promise<void> {
        return postQueued("/dungeons/instance/unpause", params).then(response => response.data);
    }

    public static async endDungeonInstance(params: { instanceId: number }): Promise<DungeonEndInstanceResponse> {
        return postQueued("/dungeons/instance/end", params).then(response => response.data);
    }

    public static async getUserDungeonInstances(): Promise<DungeonInstanceListByUserResponse> {
        return http.get("/dungeons/instance/list/by/user").then(response => response.data);
    }

    public static async getApplicableCuties(params: CutieListFilterRequestBase): Promise<FilteredCutiesListResponse> {
        return http.post("/dungeons/cutie/applicable/list", params).then(response => response.data);
    }

    public static async spawnCutie(params: DungeonCutieSpawnRequest): Promise<void> {
        return postQueued("/dungeons/cutie/spawn", params).then(response => response.data);
    }

    public static async moveCutie(params: DungeonCutieMoveRequest): Promise<DungeonCutieMoveResponse> {
        return postQueued("/dungeons/cutie/move", params).then(response => response.data);
    }

    public static async attackMultiPartDestructible(params: DungeonCutieAttackRequest): Promise<DungeonAttackDestructibleResponse> {
        return postQueued("/dungeons/cutie/attack/multi/part/destructible", params).then(response => response.data);
    }

    public static async attackSingleTileDestructible(params: DungeonCutieAttackRequest): Promise<DungeonAttackDestructibleResponse> {
        return postQueued("/dungeons/cutie/attack/single/tile/destructible", params).then(response => response.data);
    }

    public static async attackHostileNpc(params: DungeonCutieAttackRequest): Promise<DungeonAttackNpcResponse> {
        return postQueued("/dungeons/cutie/attack/hostile/npc", params).then(response => response.data);
    }

    public static async attackHostileNpcElemental(params: DungeonCutieAttackElementalRequest): Promise<DungeonAttackNpcElementalResponse> {
        return postQueued("/dungeons/cutie/attack/hostile/npc/elemental", params).then(response => response.data);
    }

    public static async applyItemToTarget(params: DungeonApplyItemToTargetRequest): Promise<{ stateVersion: number }> {
        return postQueued("/dungeons/party/item/apply/to/target", params).then(response => response.data);
    }

    public static async applyItemNoTarget(params: { partyItemId: number }): Promise<void> {
        return postQueued("/dungeons/party/item/apply/no/target", params).then(response => response.data);
    }

    public static async pickupLoot(params: { actorId: number }): Promise<{ draftedItems: DungeonPartyItemView[], stateVersion: number }> {
        return postQueued("/dungeons/cutie/pickup/loot", params).then(response => response.data);
    }

    public static async discoverNextInstance(params: { actorId: number }): Promise<DiscoverNextInstanceResponse> {
        return postQueued("/dungeons/cutie/next/instance/discover", params).then(response => response.data);
    }

    public static async transferCutieToNextInstance(params: { actorId: number }): Promise<void> {
        return postQueued("/dungeons/cutie/next/instance/transfer", params).then(response => response.data);
    }

    public static async getNextInstances(params: { instanceId: number }): Promise<NextInstanceView[]> {
        return getStateless("/public/instance/next/list", { params }).then(response => response.data);
    }

    public static async getDungeonTilemapState(params: { instanceId: number }): Promise<DungeonTilemapStateResponse> {
        return getStateless("/public/dungeons/tilemap/state", { params }).then(response => response.data);
    }

    public static async getPartyItems(params: { instanceId: number }): Promise<DungeonPartyItemView[]> {
        return getStateless("/public/dungeons/party/item/list", { params }).then(response => response.data);
    }

    public static async getSpawnedCuties(params: { instanceId: number }): Promise<DungeonSpawnedCutiesResponse> {
        return getStateless("/public/dungeons/cutie/spawned/list", { params }).then(response => response.data);
    }

    public static async getGatesState(params: { instanceId: number }): Promise<GatesState> {
        return getStateless("/public/dungeons/gates/state", { params }).then(response => response.data);
    }

    public static async purchasePartyItem(params: DungeonPartyItemPurchaseRequest): Promise<void> {
        return postQueued("/dungeons/party/item/purchase", params).then(response => response.data);
    }

    public static async topupTime(params: DungeonTopupRequest): Promise<void> {
        return postQueued("/dungeons/topup/time", params).then(response => response.data);
    }

    public static async topupPartyItems(params: DungeonTopupRequest): Promise<void> {
        return postQueued("/dungeons/topup/party/items", params).then(response => response.data);
    }

    public static async buyWorldShopItem(params: DungeonWorldBuyItemRequest): Promise<void> {
        return http.post("/dungeons/world/purchase", params).then(response => response.data);
    }

    public static async getPossiblePartyItems(): Promise<PossessionIdentityItem[]> {
        if (!this.whenPossiblePartyItems) {
            this.whenPossiblePartyItems = getStateless("/public/dungeons/party/item/possible/list").then(response => response.data);
        }
        return this.whenPossiblePartyItems;
    }

    public static async getLootOnTilemap(params: { instanceId: number }): Promise<DungeonTilemapLootResponse> {
        return http.get("/public/dungeons/tilemap/loot", { params }).then(response => response.data);
    }

    public static async getNpcFancyTemplates(): Promise<NpcFancyTemplateView[]> {
        if (!this.whenNpcFancyTemplates) {
            this.whenNpcFancyTemplates = getStateless("/public/dungeons/npc/fancy/template/list")
                .then(response => response.data);
        }
        return this.whenNpcFancyTemplates;
    }

    public static async getConsumableDefinitions(): Promise<Consumable[]> {
        if (!this.whenConsumableDefinitions) {
            this.whenConsumableDefinitions = getStateless("/public/dungeons/consumable/definition/list")
                .then(response => response.data);
        }
        return this.whenConsumableDefinitions;
    }

    public static async getAssetConnectionDomains(): Promise<AssetConnectionDomain[]> {
        return getStateless("/public/dungeons/asset/connection/domain/list")
            .then(response => response.data);
    }
}

/** @see WorldDefinition.java */
interface WorldDefinition {
    id: string;
    backgroundId: string;
    stages: Stage[];
    rulesSetting: RulesSetting;
    offers: ShopOffer[];
}

/** @see DungeonCutieMoveResponse.java */
export interface DungeonCutieMoveResponse {
    /**
     * unique identifier of the performed action, can be used in conjunction
     * with Instance ID as random seed for battle report critical strike texts
     */
    stateVersion: number;
    pursuitStrikes: StrikeResult[];
}

export interface StrikeResult {
    stateVersion: number;
    attack: AttackAction;
    defence: DefenceAction;
}

export interface DungeonTilemapLootResponse {
    pickupLoots: PickupLoot[];
}

interface RulesSetting {
    /** can be extended by buffs that you purchase in Gold Shop, to be implemented */
    startingPartyItemsSlots: number;
    partyItemRules: ItemRule[];
}

interface ItemRule {
    /** item base name, example: "CollectibleDungeonSilver" */
    id: string;
    /**
     * How many units of this item counts as a single slot when deciding
     * whether the quantity user selected fits in the limit or not.
     * For example, if `unitsPerSlot` for silver is 50 and
     * `startingPartyItemsSlots` is 7 then you can either take 350 units
     * of silver or 7 regular items or 150 units of silver and 4 regular items.
     */
    unitsPerSlot: number;
}

export interface WorldBaseDefinitionView {
    definition: WorldDefinition;
    startRequirements: PossessionIdentity[];
}

export interface WorldFullDefinitionResponse extends WorldBaseDefinitionView {
    stages: Stage[];
}

export interface WorldStateResponse {
    energy: EnergyModel;
    extraStartingPartyItemsSlots: number;
}

export interface StageDefinition {
    id: string;
    baseClearTimeLimitSeconds: number;
    totalExperienceForClear: number;
    worldId: string;
    requirements: PossessionIdentity[];
    /** on the launch most stages will be [PossessionIdentityUndefined] */
    entryFeeOptions: [PossessionIdentity, ...PossessionIdentity[]];
    enabledItemTagModifiers: string[];
    mapX: number;
    mapY: number;
    cutieRequirements: CutieRequirementsModel;
    clearCondition: ClearCondition;
    continuationOptions: string[];
}

export type Stage = StageDefinition;

export interface ClearCondition {
    gateId: string;
    type: "GateDestroyed";
}

export interface StageHighscoreProjection {
    bestClearTimeMs: number;
    timesCleared: number;
    userId: number;
    userName: string;
}

export type SingleTileDestructibleState = Healthy & {
    type: "SingleTileDestructibleState";
    definition: SingleTileDestructible;
};

interface Healthy {
    hitPointsLeft: number;
    definition: {
        type: string;
        initialHitPoints: number;
    };
}

export type HostileNpcState = Healthy & { type: "HostileNpcState"; definition: HostileNpc };

export type MultiPartDestructible = { type: "MultiPartDestructible"; hitPointsLeft: number; definition: GateGroup };

export type PartOfDestructible = { type: "PartOfDestructible"; parentId: number; definition: GatePart };

type BuffId = "BASIC_ACTION_COOLDOWN_SPEEDUP" | "GODSPEED_ACTION_COOLDOWN_SPEEDUP";

type BuffState = {
    id: BuffId;
    /** ISO datetime */
    endTime: string;
};

export type SpawnedCutie = Healthy & {
    type: "SpawnedCutie";
    buffs: BuffState[];
};

/** at runtime, see GameObjectState.java */
export type GameObjectState =
    | SpawnedCutie
    | HostileNpcState
    | SingleTileDestructibleState
    | MultiPartDestructible
    | PartOfDestructible
    | IndestructibleObstacle;

export type HealthyState = GameObjectState & Healthy;

export interface DungeonInstanceDefinitionResponse {
    /** ISO date-time string like "2022-03-25T15:36:32.666Z" */
    clearTimeLimitEnd: string;
    /** ISO date-time string like "2022-03-25T15:36:32.666Z" */
    pausedTime?: string;
    /** ISO date-time string like "2022-03-25T15:36:32.666Z" */
    clearedTime?: string;
    /** including modifier */
    maxCutiesToBeSpawned: number;
    tilesPerRow: number;
    totalRows: number;
    gateGroups: GateGroup[];
    stageDefinition: StageDefinition;
    partyShop: PartyShop;
    timeTopupOffers: TimeTopupOfferView[];
    partyItemTopupOffers: PartyItemTopupOfferView[];
}

/** @see PartyShop.java */
export interface PartyShop {
    id: string;
    offers: ShopOffer[];
}

export interface TimeTopupOfferView {
    id: string;
    extraTimeSeconds: number;
    effectivePrices: PossessionIdentity[];
}

export interface PartyItemTopupOfferView {
    id: string;
    produces: PossessionIdentity[];
    effectivePrices: PossessionIdentity[];
}

export interface ShopOffer {
    id: string;
    requires: PossessionIdentity[];
    consumes: PossessionIdentity[];
    produces: PossessionIdentity[];
}

export type EligibilityRule =
    /** applicable to SpawnedCutie game objects */
    | "SPAWNED_CUTIE"
    /** applicable to MultiPartDestructible and SingleTileDestructibleState game objects */
    | "DESTRUCTIBLE_NEAR_CUTIE";

/** @see ChangeHitPoints.java */
type ChangeHitPoints = {
    type: "ChangeHitPoints";
    /**
     * positive for healing effects, negative for damaging effects
     * defines how many hit points should be added/subtracted from the unit
     */
    value: number;
};

type ChangeBuffRemainingSeconds = {
    type: "ChangeBuffRemainingSeconds";
    /** number of seconds */
    value: number;
    buffId: string;
};

/** @see ChangeLimitEndTimeSeconds.java */
type ChangeLimitEndTimeSeconds = {
    type: "ChangeLimitEndTimeSeconds";
    value: number;
};

type HealthyTargetEffect = ChangeHitPoints | ChangeBuffRemainingSeconds;

type NoTargetEffect = ChangeLimitEndTimeSeconds;

/** @see HealthyTarget.java */
export interface HealthyTarget {
    type: "HealthyTarget";
    /** an object is applicable if it matches at least one of the following rules */
    eligibility: EligibilityRule[];
    effects: HealthyTargetEffect[];
}

/** @see NoTarget.java */
interface NoTarget {
    type: "NoTarget";
    effects: NoTargetEffect[];
}

export type ConsumableApplication = HealthyTarget | NoTarget;

export type Consumable = {
    /** item base name */
    id: string;
    application: ConsumableApplication;
};

type Persistence = "STAYS_AFTER_CLEAR" | "LOST_ON_LEAVE";

export interface DungeonPartyItemView {
    id: number;
    persistence: Persistence;
    identity: PossessionIdentity;
    /** present only on consumable items, like HP potions or Dynamite */
    application?: ConsumableApplication;
}

interface DungeonInstanceStartRequest {
    dungeonId: string;
    /** @see StageDefinition.entryFeeOptions */
    acceptedEntryFee: PossessionIdentity;
    partyItems: PossessionIdentity[];
}

interface DungeonInstanceStartResponse {
    instanceId: number;
}

export interface DungeonInstanceModel {
    id: number;
    userId: number;
    dungeonId: string;
    startTime: string;
}

interface DungeonInstanceListByUserResponse {
    instances: DungeonInstanceModel[];
}

export interface DungeonApplicableCutiesRequest extends CutieListFilterRequestBase {
    dungeonInstanceId: number;
    page: number;
}

interface Point {
    y: number;
    x: number;
}

interface DungeonCutieSpawnRequest {
    instanceId: number;
    cutieId: number;
    targetPoint: Point;
}

interface DungeonCutieMoveRequest {
    actorId: number;
    targetPoint: Point;
}

interface DungeonCutieAttackRequest {
    actorId: number;
    targetId: number;
}

interface DungeonCutieAttackElementalRequest extends DungeonCutieAttackRequest {
    actorId: number;
    targetId: number;
    element: Element;
}

interface DungeonApplyItemToTargetRequest {
    partyItemId: number;
    targetId: number;
}

interface DungeonPartyItemPurchaseRequest {
    instanceId: number;
    offerId: string;
}

interface DungeonTopupRequest {
    instanceId: number;
    offerId: string;
    acceptedPrice: PossessionIdentity;
}

interface DungeonWorldBuyItemRequest {
    worldId: string;
    offerId: string;
}

interface CutieMetadata {
    id: number;
    name: string;
    imageUrl: string;
    background?: string;
}

export interface DungeonCutieSpawnView {
    gameObjectId: number | null;
    cooldownEndTime: string;
    cooldownDurationSeconds: number;
    cutieMetadata: CutieMetadata;
    /** ISO datetime string */
    lastElementalAttackTime?: string;
    elementalRestoreTimeSeconds: number;
    bonusesV2: AttributeStatsModel[];
}

export interface DungeonGameObject<T extends GameObjectState = GameObjectState> {
    id: number;
    type: T["type"];
    state: T;
}

export interface TileState {
    attributes?: TileAttribute[];
    gameObject?: DungeonGameObject | null;
    wasExplored: boolean;
}

export interface DungeonTileState extends TileState {
    y: number;
    x: number;
}

export interface DungeonTilemapStateResponse {
    tiles: DungeonTileState[];
}

export interface DungeonSpawnedCutiesResponse {
    spawns: DungeonCutieSpawnView[];
}

export interface DungeonAttackDestructibleResponse {
    stateVersion: number;
    attack: AttackAction;
    targetHitPointsLeft: number;
}

export interface DungeonAttackNpcResponse {
    stateVersion: number;
    logEntries: RoundAction[];
}

interface DungeonAttackNpcElementalResponse {
    stateVersion: number;
    actorElementValue: number;
    targetVulnerability: number;
    actorHitPointsLeft: number;
    targetHitPointsLeft: number;
    damageDealt: number;
}

/** elements info deliberately not exposed */
export interface NpcFancyTemplateView {
    id: string;
    fancy: string;
    baseStats: StatValue[];
}

export interface GatesState {
    /** @see DropRollView.id */
    earnedDropRolls: string[];
    remainingGates: MultiPartDestructible[];
}

export interface RetainedGoodsGroup {
    type: "INVENTORY" | "INVENTRY_FIRST_CLEAR" | "DROP_ROLL";
    goods: PossessionIdentity[];
}

interface DungeonEndInstanceResponse {
    dropChanceMultiplier: number;
    retainedGoodsGroups: RetainedGoodsGroup[];
}

export type ConnectionDirection = "Left" | "TopLeft" | "Top" | "TopRight" | "Right" | "BottomRight" | "Bottom" | "BottomLeft";

export interface AssetConnectionDomain {
    id: string;
    groups: ConnectionGroup[];
}

interface ConnectionGroup {
    origins: string[];
    connectionDirections: ConnectionDirection[];
}

export type DiscoverNextInstanceResponse = {
    nextInstanceId: number;
    tilesDiscovered: number;
};

export type NextInstanceView = {
    id: number;
};
