import DataHelpers from "@/cuties/engine/DataHelpers";
import Request from "../Request";
import utils from "@/cuties/utils";

export default class RequestAdminSetCustomDescription extends Request {

    private pet_uid:string;

    constructor(pet_uid:string) {
        super();

        this.pet_uid = pet_uid;

    }

    get_url(): string {
        return "/set_custom_description?pet=" + this.pet_uid;
    }

    on_success(response: any): void {
        super.on_success(response);
        utils.show_notification("Custom generated " + response["custom_description"]);
    }

    on_error(response: any): void {
        try {
            const status = response.errorDetails.jqXHR.status;
            if (status == 401) {
                utils.propose_auth();
            } else{
                utils.show_notification("error status = " + status);
            }
        } catch (e) {
            DataHelpers.error("Request.on_error", e);
            utils.show_notification(response.error);
        }
    }

    get_handler_prefix(): string {
        return null;
    }
}
