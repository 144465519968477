import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";
import Cookies from "js-cookie";
import BcuGetApi from "cuties-client-components/src/BcuGetApi";

const makeHttp = ({ baseUrl = "/rest/1" } = {}) => {
    const http = axios.create({
        timeout: 120000, // 2 minutes
        baseURL: baseUrl,
    });

    http.interceptors.request.use(
        function (config: AxiosRequestConfig) {
            const csrfToken = Cookies.get("XSRF-TOKEN");
            if (csrfToken) {
                config.headers["X-XSRF-TOKEN"] = csrfToken;
            }
            return config;
        },
        function (error: any) {
            return Promise.reject(error);
        }
    );

    // Add a response interceptor
    http.interceptors.response.use(
        function (response: AxiosResponse) {
            // Do something with response data
            return response;
        },
        function (error: any) {
            // Do something with response error
            return Promise.reject(error);
        }
    );

    return http;
};

/**
 * for better compatibility with existing code we try to mimic the data format of axios
 */
export class AxiosLikeError extends Error implements AxiosError {
    readonly isAxiosError = false;
    readonly isAxiosLikeError = true;
    readonly config = {};
    readonly response;

    constructor(path: string, response: Response, data: unknown) {
        const message = "Failed with status " + response.status + " while executing " + path;
        super(message);
        this.response = {
            data: data,
            status: response.status,
            statusText: response.statusText,
            headers: response.headers,
            config: {},
        };
    }

    toJSON(): object {
        return this;
    }
}

async function getStatelessBySearch(
    path: string,
    params?: URLSearchParams
) {
    const uri = "/rest" + path + (!params ? "" : "?" + params);
    const response = await fetch(uri, {
        // omit does not work with cloudflare ddos protection on
        // credentials: "omit",
        headers: {
            "content-type": "application/json",
        }
    });
    let data;
    let noJsonError;
    try {
        data = await response.json();
        noJsonError = null;
    } catch (error) {
        data = {};
        noJsonError = await response.text().catch(error => "(body not available)");
    }
    if (!noJsonError) {
        if (response.status !== 200) {
            throw new AxiosLikeError(path, response, data);
        } else {
            return { data };
        }
    } else {
        throw new Error("No JSON, status " + response.status + " while executing " + path + " - " + noJsonError.slice(0, 100));
    }
}

/**
 * axios uses XHR, and therefore it does not support credentials:omit
 * @see https://github.com/axios/axios/issues/2455
 *
 * credentials:omit is important to let server know that session is not needed
 * for this request, so it won't do meaningless queries to db for session
 */
async function getStateless(path: string, config?: {
    params?: Record<string, string | number | boolean | null>;
}) {
    const params: Record<string, string> | null = !config?.params ? null : Object.fromEntries(
        Object.entries(config?.params)
            .filter(([k,v]) => v !== undefined)
            .map(([k,v]) => [k,v + ""])
    );
    return getStatelessBySearch(path, !params ? undefined : new URLSearchParams(params));
}

export type ValidJson = string | number | boolean | null | ValidJson[] | { [key in string]: ValidJson };

export type JsonGetClient = {
    get: <T extends ValidJson>(path: string, params?: URLSearchParams) => Promise<T>;
};

const bcuGetApi = BcuGetApi({
    async get<T extends ValidJson>(path: string, params?: URLSearchParams): Promise<T> {
        const response = await getStatelessBySearch(path, params);
        return response.data;
    },
});

const http = makeHttp();

export default http;

export {
    makeHttp,
    getStateless,
    bcuGetApi,
};
