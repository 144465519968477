import { hideLoader, showLoader } from "@/cuties/engine/LoadSpinner";

/**
 * Show loader for async methods
 *
 * @example
 * ```typescript
 *  @ShowLoader // add a decorator for an async method
 *  sellRam(value: string): Promise<string> { // method should return promise
 *      return this.wallet.sellRam(DataHelpers.toFloat(value));
 *  }
 * ```
 * DON'T PUT IT ON METHODS THAT MAY REQUIRE INPUT FROM USER, LIKE LoginManager.unlock() !!!
 * */
export function ShowLoader(target: unknown, propertyKey: string, descriptor: PropertyDescriptor): void {
    const classMethod = descriptor.value;

    descriptor.value = function () {
        showLoader();
        return classMethod
            .apply(this, arguments)
            .then((value: unknown) => {
                hideLoader();
                return value;
            })
            .catch((error: Error | unknown) => {
                hideLoader();
                return Promise.reject(error);
            });
    };
}
