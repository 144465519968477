import http from "@/http";

export default class EosService {
    public static async getTransferTokenFee(): Promise<number> {

        return http.get("/token1155/eos_transfer_fee", {})
            .then(response => response.data.fee);
    }

    public static async getCutieBreedingFee(mom: string, dad: string, network: string = "EOS"): Promise<string> {
        return http.get(`/get_breeding_fee?mom=${mom}&dad=${dad}&blockchain=${network}`)
            .then(response => response.data.fee);
    }


}
