import type { AccountInfo } from "@/cuties/blockchain/eos/EosJsClient";
import { EosJsClient } from "@/cuties/blockchain/eos/EosJsClient";
import type BlockchainReader from "../BlockchainReader";
import BigNumber from "bignumber.js";
import EosService from "./EosService";

export class EosReader implements BlockchainReader {
    public async getBalance(account: string): Promise<BigNumber> {
        const accountInfo = await EosJsClient.getAccountInfo(account);
        let eos = "0";
        if (accountInfo.core_liquid_balance) {
            eos = accountInfo.core_liquid_balance.substring(0, accountInfo.core_liquid_balance.length - 4);
        }
        return new BigNumber(eos);
    }

    async getBreedingFee(matronId: number, sireId: number): Promise<BigNumber> {
        const fee = await EosService.getCutieBreedingFee("e" + matronId, "e" + sireId);
        return new BigNumber(fee);
    }

    getResources(account: string): Promise<AccountInfo> {
        return EosJsClient.getAccountInfo(account);
    }
}
