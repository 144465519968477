
    import BaseForm from "@/app/components/form/BaseForm.vue";
    import type { PopupProps } from "@/cuties/VueUtils";
    import { Component, Prop } from "vue-property-decorator";
    import type { ConfirmedFee } from "@/cuties/blockchain/neo/NeoProvider";
    import type { NeoReader } from "@/cuties/blockchain/neo/NeoReader";
    import NeoWalletCompanion from "@/cuties/blockchain/neo/NeoWalletCompanion";
    import BigNumber from "bignumber.js";
    import type { Player } from "@/cuties/model/player/Player";
    import { ConfigInstance } from "@/cuties/engine/Config";
    import type { Blockchain } from "@/cuties/model/pet/BlockchainId";
    import utils from "@/cuties/utils";
    import mad from "@/cuties/engine/mad";
    import { Getter } from "vuex-class";

    type TransactionConfirm = (data: ConfirmedFee) => Promise<void>;
    type TransactionCancel = (error: Error) => void;

    @Component({
        components: {},
    })
    export default class NeoConfirmTransaction extends BaseForm implements PopupProps<ConfirmedFee, TransactionConfirm> {
        @Prop() okCallback: TransactionConfirm;
        @Prop() cancelCallback: TransactionCancel;
        @Prop({ required: true }) from!: string;
        @Prop({ required: true }) networkFee!: string;
        @Prop({ required: true }) systemFee!: string;
        @Prop({ required: true }) reader!: NeoReader;
        @Prop({ required: true }) blockchain!: Blockchain;
        @Prop({ default: "0" }) value!: string;
        @Getter("getPlayer") user!: Player;

        etherscanUrl = "";
        contractName = "";
        playerName = "";
        playerToName = "";
        blockchainIcon = "";
        visible = true;
        balance = new BigNumber(0);
        amount = "0";
        transactionTotal = "0";

        get blockchainBalance(): string {
            return this.balance.toFixed(4);
        }

        get isMobile(): boolean {
            return window.innerWidth < 800;
        }

        get networkFeeGas(): string {
            return NeoWalletCompanion.toGasDecimal(new BigNumber(this.networkFee)).toString();
        }

        get systemFeeGas(): string {
            return NeoWalletCompanion.toGasDecimal(new BigNumber(this.systemFee)).toString();
        }

        async mounted(): Promise<void> {
            this.etherscanUrl = ConfigInstance["neo"].scan.replace(/\/*$/, "");

            const staticWallet = mad.getWalletCompanion(this.blockchain);
            if (staticWallet) {
                this.blockchainIcon = staticWallet.icon16Class;
            }

            const address = this.user.getBlockchainAccount(this.blockchain);
            if (!address) {
                utils.show_notification("no support for blockchain " + this.blockchain);
                return;
            }

            this.balance = await this.reader.getBalance(address);

            if (this.value) {
                this.amount = NeoWalletCompanion.toGasDecimal(new BigNumber(this.value)).toString();
            }

            this.transactionTotal = new BigNumber(this.amount)
                .plus(new BigNumber(this.networkFeeGas))
                .plus(new BigNumber(this.systemFeeGas))
                .toString();
        }

        toUsd(value: BigNumber | string): string {
            const usdPrice = ConfigInstance.dynamic.usdPrices[this.blockchain];
            return new BigNumber(value).multipliedBy(usdPrice).toFixed(2);
        }

        reject(): void {
            this.visible = false;
            this.cancelCallback(new Error("Transaction declined"));
        }

        confirm(): void {
            this.okCallback({
                networkFee: Number(this.networkFee),
                systemFee: Number(this.systemFee),
            });
        }
    }
