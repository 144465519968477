import type ResponseRaids from "@/cuties/model/raid/ResponseRaids";
import http from "@/http";
import type { SortOrderType } from "../pet/PetService";
import type { PetFilter, PetFilterOptions } from "@/app/cuties/list/CutieListApiService";
import type RangeModel from "../model/misc/RangeModel";
import type BossModel from "../model/boss/BossModel";
import type LocationModel from "../model/location/LocationModel";
import type OldRaidEnergyModel from "../model/raid/OldRaidEnergyModel";
import type { PetKind } from "../model/pet/PetModel";
import type { ElementKind, PetModelData, PlayerModelData } from "../model/pet/PetModelData";
import type { RewardModelData } from "../model/adventure/RewardModel";
import type EnergyModel from "@/cuties/model/adventure/EnergyModel";
import type PetEquipmentSlotModel from "@/cuties/model/pet/PetEquipmentSlotModel";
import type Raid2LevelRewardSectionModel from "../model/raid/Raid2LevelRewardSectionModel";
import type { ItemKind } from "../model/item/ItemKind";
import type { ActivityModelData } from "@/cuties/model/query/ActivityModelData";
import type { StatModel } from "cuties-client-components/types/api/Cutie";
import type { Raid2BattleHistoryModel } from "cuties-client-components/types/api/Raid";

export enum SortBy
{
    Price,
    Age,
    Like,
    ExpLevel,
    Exp,
    Tier,
    Featured,
    Random,
    Latest,
    Gen,
    Adventure,
    Football,
    UniqueSetIndex,
    Cool,
    Air,
    Water,
    Fire,
    Earth,
    Energy,
    BreedingCooldown,
    AdventureCooldown,
}

/** maps to routes of RaidController.java */
export default class RaidApiService {
    public static async getRaidsPage(): Promise<ResponseRaids> {
        return http.post("/raids", {}).then((response) => response.data);
    }

    public static async attackV2(cutieId: number, raidHashId: string): Promise<RaidAttackResponse> {
        return http
            .post("/raid/v2/attack", {
                petId: cutieId,
                raidId: raidHashId,
            })
            .then((rs) => rs.data);
    }

    public static async attackV1(cutieId: number, raidHashId: string): Promise<RaidAttackResponse> {
        return http
            .post("/raid/v1/attack", {
                petId: cutieId,
                raidId: raidHashId,
            })
            .then((rs) => rs.data);
    }

    public static async getRaid(raidRequest: RaidCurrentRequest): Promise<RaidCurrentResponse> {
        return http.post("/raid", raidRequest).then((response) => response.data);
    }

    public static async getOldRaidEnegy(): Promise<void> {
        return http.post("/user/collect/energy", {}).then((response) => response.data);
    }
}

export interface RaidCurrentRequest {
    filter: PetFilter;
    id: string;
    page: number;
    raidGroup: string;
    size: number;
    sortBy: keyof typeof SortBy;
    sortOrder: SortOrderType;
    version: number;
}

export type RaidVersionType = 1 | 2;
interface RaidVersion {
    version: RaidVersionType;
}
type ResponseRaidVersion = ResponseRaidVersion1Data | ResponseRaidVersion2Data;
export type RaidCurrentResponse = RaidVersion & ResponseRaidVersion;

export interface ResponseRaidVersion1Data {
    raid: Raid1Model;
    rewards: RewardModelData[];
    reservedTokens: number;
    unclaimedTokens: number;
    pets: PetModelData[];
    petCount: number;
    disabledIds: number[];
    filter: PetFilter;
    filterOptions: PetFilterOptions;
}

export interface ItemCountProjection {
    item: string | keyof typeof ItemKind;
    quantity: number;
}

export interface ResponseRaidVersion2Data {
    raid: Raid2Model;
    requirementsMet: boolean;
    battleHistory: Raid2BattleHistoryModel[];
    availableRaidEnergyElixirs?: ItemCountProjection[];
}

/** @see Raid2Model.java */
export interface Raid2Model {
    boss: BossModel;
    energy1?: OldRaidEnergyModel;
    energy2?: EnergyModel;
    id: string;
    isActive: boolean;
    isAlive: boolean; // check
    level: number;
    levels: Raid2LevelModel[];
    location: LocationModel;
    enabledItemTagModifiers: string[];
    requiredPetGenerations: number[];
    requiredPetLevel: RangeModel;
    /** @deprecated - use timeStart */
    spawnIn?: number;
    version: string;
    timeStart: string;
}

export interface Raid2LevelModel {
    alive: boolean;
    energyCost: number;
    equipment: PetEquipmentSlotModel[] | null;
    hitpoints: RaidLevelHitpoints;
    leaderboard: Raid2LeaderboardModel[] | null;
    leaderboardMe: Raid2LeaderboardMeModel | null;
    level: number;
    rewardSections: Raid2LevelRewardSectionModel[];
    statisticsPersonal: Raid2BattleStatisticsModel;
    statisticsTotal: Raid2BattleStatisticsModel;
    stats: StatModel[];
    strength: StatModel[];
    /** @deprecated - use timeEnd */
    timeLeft: RangeModel | null;
    weakness: StatModel[];
    timeEnd: string;
}

export interface Raid2LeaderboardMeModel {
    damage: number | null;
    possibleRewards: Raid2LevelRewardSectionModel[] | null;
    rank: number | null;
    rankPerecentage: number | null;
    rewardsDefeat: RaidLeaderboardEntryItem[];
    rewardsWin: RaidLeaderboardEntryItem[];
    sessionId: null;
    tokensLost: number | null;
    tokensWin: number | null;
    userIcon: string;
    userId: number;
    userName: string;
}

export interface Raid2BattleStatisticsModel {
    draw: number;
    lost: number;
    total: number;
    win: number;
}

export interface Raid2LeaderboardModel {
    damage: number;
    possibleRewards: Raid2LevelRewardSectionModel[] | null;
    rank: number;
    rankPerecentage: number;
    rewardsDefeat: RaidLeaderboardEntryItem[];
    rewardsWin: RaidLeaderboardEntryItem[];
    sessionId: number | null;
    tokensLost: number | null;
    tokensWin: number | null;
    userIcon: string;
    userId: number;
    userName: string;
}

export interface RaidLeaderboardEntryItem {
    amount: number;
    description: string;
    icon: string;
    name: string;
    type: string;
}

export interface RaidLevelHitpoints {
    cur: number;
    max: number;
}

export interface Raid1Model {
    id: string;
    requiredPetLevel: RangeModel;
    requiredPetGenerations: number[];
    timeStart: number;
    timeEnd: number;
    power: number /** it seems this field is depricated and insted used raidPowerIncrement */;
    users?: Record<number, RaidUserModel>; //map [user id => user stats]
    boss: BossModel;
    location: LocationModel;
    configId: string;
    groupId: string;
    winExp: number;
    loseExp: number;
    elementWeakness: ElementKind[];
    elementStrength: ElementKind[];
    petKindWeakness: PetKind[];
    petKindStrength: PetKind[];
    raidPowerIncrement: number;
    duration: number;
    generationLimit: Record<number, number>;
    winTokenErc20: number; // BigDecimal
    lostTokenErc20: number; // BigDecimal
    energy1: OldRaidEnergyModel;
    energyCost: number;
}

export interface RaidUserModel {
    user: PlayerModelData;
    winCount: number;
    failCount: number;
}

export interface RaidAttackResponse {
    reportActivity: ActivityModelData;
}
