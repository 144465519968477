import type Web3CutiesApi from "@/cuties/blockchain/matic/Web3CutiesApi";
import type BlockchainBase from "@/cuties/blockchain/BlockchainBase";
import type { TronWebCutiesApi } from "@/cuties/blockchain/tron/TronWebCutiesApi";
import type Web3 from "web3";
import type BlockchainService from "@/cuties/blockchain/BlockchainService";
import { Blockchain } from "@/cuties/model/pet/BlockchainId";
import { ConfigInstance } from "@/cuties/engine/Config";
import type { EosImplementation } from "@/cuties/blockchain/eos/EosImplementation";
import type * as NeonCore from "@cityofzion/neon-core";
import type eosjs_ecc from "eosjs-ecc";
import type NeoCutiesApi from "@/cuties/blockchain/neo/NeoCutiesApi";
import type TronWeb from "tronweb";
import TronHelper from "@/cuties/blockchain/tron/TronHelper";
import { ShowLoader } from "@/common/decorators/ShowLoader";

type NeonCore = typeof NeonCore;

type eosjs_ecc_local_typing = {
    randomKey: () => Promise<string>;
    privateToPublic: (pk: string) => string;
};

export default class CutiesApiFaucet {
    private static EosWalletApi: (EosImplementation) | null = null;
    private static whenWeb3: Promise<typeof Web3> | null = null;
    private static whenNeonCore: Promise<NeonCore> | null = null;
    private static whenEosJsEcc: Promise<eosjs_ecc> | null = null;
    private static whenTronWeb: Promise<typeof TronWeb> | null = null;
    private static whenWeb3CutiesApi: Promise<typeof Web3CutiesApi> | null = null;

    private static whenTron: Promise<TronWebCutiesApi> | null = null;
    private static whenEthereum: Promise<Web3CutiesApi> | null = null;
    private static whenMatic: Promise<Web3CutiesApi> | null = null;
    private static whenHeco: Promise<Web3CutiesApi> | null = null;
    private static whenNeo: Promise<NeoCutiesApi> | null = null;
    private static whenEmerald: Promise<Web3CutiesApi> | null = null;
    private static whenCsc: Promise<Web3CutiesApi> | null = null;
    private static whenOkc: Promise<Web3CutiesApi> | null = null;
    private static whenBsc: Promise<Web3CutiesApi> | null = null;

    static blockchain_impls: Record<string, () => (BlockchainService & BlockchainBase) | Promise<BlockchainService & BlockchainBase>> = {
        [Blockchain.Ethereum]: () => CutiesApiFaucet.getEthereum(),
        [Blockchain.Matic]: () => CutiesApiFaucet.getMatic(),
        [Blockchain.Eos]: () => CutiesApiFaucet.getEos(),
        [Blockchain.Tron]: () => CutiesApiFaucet.getTron(),
        [Blockchain.Neo]: () => CutiesApiFaucet.getNeo(),
        [Blockchain.Neo3]: () => CutiesApiFaucet.getNeo(),
        [Blockchain.Heco]: () => CutiesApiFaucet.getHeco(),
        [Blockchain.Emerald]: () => CutiesApiFaucet.getEmerald(),
        [Blockchain.Csc]: () => CutiesApiFaucet.getCsc(),
        [Blockchain.Okc]: () => CutiesApiFaucet.getOkc(),
        [Blockchain.Bsc]: () => CutiesApiFaucet.getBsc(),
    };

    public static isEosWalletUnlocked(): boolean {
        return CutiesApiFaucet.EosWalletApi !== null;
    }

    @ShowLoader
    public static async getTron(): Promise<TronWebCutiesApi> {
        if (!CutiesApiFaucet.whenTron) {
            CutiesApiFaucet.whenTron = Promise.all([
                import(/* webpackChunkName: "TronWebCutiesApi" */ "@/cuties/blockchain/tron/TronWebCutiesApi"),
                TronHelper.initClient(ConfigInstance.tron)
            ]).then(([module, tronWeb]) => new module.TronWebCutiesApi(tronWeb));
        }
        return CutiesApiFaucet.whenTron;
    }

    @ShowLoader
    public static async getNeo(): Promise<NeoCutiesApi> {
        if (!CutiesApiFaucet.whenNeo) {
            CutiesApiFaucet.whenNeo = import(
                /* webpackChunkName: "NeoCutiesApi" */
                "@/cuties/blockchain/neo/NeoCutiesApi"
            ).then(module => new module.default(ConfigInstance.neo));
        }
        return CutiesApiFaucet.whenNeo;
    }

    @ShowLoader
    public static async getEos(): Promise<EosImplementation> {
        if (CutiesApiFaucet.EosWalletApi === null) {
            const { EosImplementation } = await import(
                /* webpackChunkName: "eos-chunk" */
                "@/cuties/blockchain/eos/EosImplementation"
            );

            CutiesApiFaucet.EosWalletApi = new EosImplementation(ConfigInstance.eos);
        }
        return CutiesApiFaucet.EosWalletApi;
    }

    @ShowLoader
    private static async getWeb3CutiesApi(): Promise<typeof Web3CutiesApi> {
        if (!CutiesApiFaucet.whenWeb3CutiesApi) {
            CutiesApiFaucet.whenWeb3CutiesApi = import(
                /* webpackChunkName: "Web3CutiesApi" */
                "@/cuties/blockchain/matic/Web3CutiesApi"
            ).then(module => module.default);
        }
        return CutiesApiFaucet.whenWeb3CutiesApi;
    }

    public static async getEthereum(): Promise<Web3CutiesApi> {
        if (!CutiesApiFaucet.whenEthereum) {
            CutiesApiFaucet.whenEthereum = this.getWeb3CutiesApi()
                .then(Web3CutiesApi => new Web3CutiesApi(Blockchain.Ethereum));
        }
        return CutiesApiFaucet.whenEthereum;
    }

    public static async getMatic(): Promise<Web3CutiesApi> {
        if (!CutiesApiFaucet.whenMatic) {
            CutiesApiFaucet.whenMatic = this.getWeb3CutiesApi()
                .then(Web3CutiesApi => new Web3CutiesApi(Blockchain.Matic));
        }
        return CutiesApiFaucet.whenMatic;
    }

    public static async getHeco(): Promise<Web3CutiesApi> {
        if (!CutiesApiFaucet.whenHeco) {
            CutiesApiFaucet.whenHeco = this.getWeb3CutiesApi()
                .then(Web3CutiesApi => new Web3CutiesApi(Blockchain.Heco));
        }
        return CutiesApiFaucet.whenHeco;
    }

    public static async getEmerald(): Promise<Web3CutiesApi> {
        if (!CutiesApiFaucet.whenEmerald) {
            CutiesApiFaucet.whenEmerald = this.getWeb3CutiesApi()
                .then(Web3CutiesApi => new Web3CutiesApi(Blockchain.Emerald));
        }
        return CutiesApiFaucet.whenEmerald;
    }

    public static async getCsc(): Promise<Web3CutiesApi> {
        if (!CutiesApiFaucet.whenCsc) {
            CutiesApiFaucet.whenCsc = this.getWeb3CutiesApi()
                .then(Web3CutiesApi => new Web3CutiesApi(Blockchain.Csc));
        }
        return CutiesApiFaucet.whenCsc;
    }

    public static async getOkc(): Promise<Web3CutiesApi> {
        if (!CutiesApiFaucet.whenOkc) {
            CutiesApiFaucet.whenOkc = this.getWeb3CutiesApi()
                .then(Web3CutiesApi => new Web3CutiesApi(Blockchain.Okc));
        }
        return CutiesApiFaucet.whenOkc;
    }

    public static async getBsc(): Promise<Web3CutiesApi> {
        if (!CutiesApiFaucet.whenBsc) {
            CutiesApiFaucet.whenBsc = this.getWeb3CutiesApi()
                .then(Web3CutiesApi => new Web3CutiesApi(Blockchain.Bsc));
        }
        return CutiesApiFaucet.whenBsc;
    }

    /**
     * please, use this function instead of directly accessing Web3 from a static import, since this lib weighs
     * ~2 MiB and we definitely do not want it to appear in the main webpack bundle. Importing types is fine though
     */
    public static async getWeb3(): Promise<typeof Web3> {
        if (!CutiesApiFaucet.whenWeb3) {
            CutiesApiFaucet.whenWeb3 = import(
                /* webpackChunkName: "web3-chunk" */
                "web3"
            ).then((web3) => web3.default);
        }
        return CutiesApiFaucet.whenWeb3;
    }

    /**
     * please, use this function instead of directly accessing neon-core from a static import, since this lib weighs
     * ~0.5 MiB and we definitely do not want it to appear in the main webpack bundle. Importing types is fine though
     */
    public static getNeonCore() {
        if (!CutiesApiFaucet.whenNeonCore) {
            CutiesApiFaucet.whenNeonCore = import(
                /* webpackChunkName: "neon-core-chunk" */
                "@cityofzion/neon-core"
            ).then((neonCore) => neonCore);
        }
        return CutiesApiFaucet.whenNeonCore;
    }

    public static async getEosJsEcc(): Promise<eosjs_ecc & eosjs_ecc_local_typing> {
        if (!CutiesApiFaucet.whenEosJsEcc) {
            CutiesApiFaucet.whenEosJsEcc = import(
                /* webpackChunkName: "eosjc_ecc" */
                "eosjs-ecc"
            ).then((module) => module.default);
        }
        return CutiesApiFaucet.whenEosJsEcc;
    }

    /**
     * please, use this function instead of directly accessing tronweb from a static import, since this lib weighs
     * ~1 MiB and we definitely do not want it to appear in the main webpack bundle. Importing types is fine though
     */
    public static async getTronWeb(): Promise<typeof TronWeb> {
        if (!CutiesApiFaucet.whenTronWeb) {
            CutiesApiFaucet.whenTronWeb = import(
                /* webpackChunkName: "tronweb-chunk" */
                "tronweb"
            ).then((tronweb) => tronweb.default);
        }
        return CutiesApiFaucet.whenTronWeb;
    }

    @ShowLoader
    public static async getApi(blockchain: Blockchain): Promise<BlockchainService & BlockchainBase> {
        return Promise.resolve(CutiesApiFaucet.blockchain_impls[blockchain]());
    }
}
