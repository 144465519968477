
    import { Component, Prop, Vue } from "vue-property-decorator";
    import type { PopupProps } from "@/cuties/VueUtils";
    import type { AuthMethod } from "@/components/LoginManager/LoginManagerEosView.vue";
    import VueUtils from "@/cuties/VueUtils";

    const LoginManagerEosView = () => import(/* webpackChunkName: "LoginManagerEosView" */ "@/components/LoginManager/LoginManagerEosView.vue");

    @Component({
        components: { LoginManagerEosView },
    })
    export default class LoginManagerEosViewDialog extends Vue implements PopupProps<AuthMethod> {
        @Prop({ required: true }) okCallback!: (authMethod: AuthMethod) => void | Promise<void>;
        @Prop({ required: true }) cancelCallback!: () => Promise<void>;

        readonly isMobile = VueUtils.isMobile;
    }
