import { button } from "@/directives/button";
import { svgIcon } from "@/directives/svgIcon";
import type { VueConstructor } from "vue";

export default {
    install(Vue: VueConstructor): void {
        Vue.directive("svg-icon", svgIcon);
        Vue.directive("button", button);
    },
};
