import type { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import type { RootState } from "@/store/RootState";
import PlayerSettingsService from "@/cuties/player/PlayerSettingsService";
import PlayerBase from "@/cuties/model/player/PlayerBase";

export type PlayerOrGuest = PlayerBase;

export default interface PlayerState {
    player: PlayerOrGuest;
    isLogined: boolean;
}

export const state: PlayerState = {
    player: new PlayerBase(),
    isLogined: false,
};

export const getters: GetterTree<PlayerState, RootState> = {
    getIsLogined: (state: PlayerState) => state.isLogined,
    getPlayer: (state: PlayerState) => state.player,
};

export const mutations: MutationTree<PlayerState> = {
    setPlayer: (state, payload) => state.player = payload,
    setIsLogined: (state, payload) => state.isLogined = payload
};

export const actions: ActionTree<PlayerState, RootState> = {
    updatePlayer: async ({ commit }, payload) => {
        return PlayerSettingsService.updatePlayerSettings(payload);
    },
    sendConfirmationEmail: ({ commit }, payload) => {
        return PlayerSettingsService.sendConfirmationEmail();
    }
};

class PlayerStore implements Module<PlayerState, RootState> {
    public namespaced: boolean = false;
    public state: PlayerState = state;
    public getters: GetterTree<PlayerState, RootState> = getters;
    public mutations: MutationTree<PlayerState> = mutations;
    public actions: ActionTree<PlayerState, RootState> = actions;
}

export const PlayerStoreSingleton = new PlayerStore();
