import type { Api } from "eosjs";
import { ConfigInstance } from "@/cuties/engine/Config";

type FullApi = Api & {
    authorityProvider: {
        get_account: (account: string) => Promise<AccountInfo>;
    };
};

export class EosJsClient {
    private static whenApi: Promise<FullApi> | null = null;

    public static async sendTransaction(api, payload: InvokeTransaction): Promise<any> {
        const data = {
            actions: [
                {
                    account: payload.account,
                    name: payload.method,
                    authorization: [
                        {
                            actor: payload.actor,
                            permission: payload.permission,
                        },
                    ],
                    data: payload.data,
                },
            ],
        };

        if (api.transact) {
            return api.transact(data, {
                blocksBehind: 3,
                expireSeconds: 30,
            });
        }

        if (api.invokeTransaction) {
            return api
                .invokeTransaction(data, {
                    blocksBehind: 3,
                    expireSeconds: 30,
                })
                .then((res) => {
                    console.log("invokeTransaction", res);
                    return res.data;
                });
        }

        throw Error("Unsupported api for EOS");
    }

    public static async getAccountInfo(account: string): Promise<AccountInfo> {
        const api = await this.getApi();

        if (api) {
            return api.authorityProvider.get_account(account);
        } else {
            throw Error("Cannot get EOS account info");
        }
    }

    private static async getApi() {
        if (this.whenApi == null) {
            this.whenApi = import(/* webpackChunkName: "eosjs" */ "eosjs").then(({ Api, JsonRpc }) => {
                const rpc = new JsonRpc(ConfigInstance.eos.nodeUrl);
                // const signatureProvider = new JsSignatureProvider(['5K8vvQEY5qt66dnxaFKZmiKPVPUkQktnr4NHrcswRvyft8cLGzu']);
                const signatureProvider = null;
                return new Api({ rpc, signatureProvider }) as FullApi;
            });
        }
        return this.whenApi;
    }
}

export interface InvokeTransaction {
    account: string;
    method: string;
    actor: string;
    permission: string;
    data: object;
}

export interface AccountInfo {
    permissions: Permission[];
    core_liquid_balance: string;
    total_resources: TotalResources;
    cpu_limit: ResourceLimit;
    net_limit: ResourceLimit;
    ram_quota: number;
    ram_usage: number;
    ram: number;
}

export interface TotalResources {
    net_weight: string;
    cpu_weight: string;
    ram_bytes: number;
}

export interface ResourceLimit {
    used: number;
    available: number;
    max: number;
}

export interface Permission {
    perm_name: string;
    required_auth: RequiredAuth;
}

export interface RequiredAuth {
    keys: RequiredAuthKey[];
}

export interface RequiredAuthKey {
    key: string;
}
