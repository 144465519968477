import { PLAYER_SINGLETON } from "@/cuties/model/player/Player";
import DataHelpers from "@/cuties/engine/DataHelpers";
import mad from "@/cuties/engine/mad";
import RequestAdminCreatePet from "../request/admin/RequestAdminCreatePet";
import RequestAdminDeletePet from "../request/admin/RequestAdminDeletePet";
import RequestAdminCreateAuction from "../request/admin/RequestAdminCreateAuction";
import RequestPromoCodeCreate from "../request/promo_code/RequestPromoCodeCreate";
import RequestAdminTransferItems from "../request/admin/RequestAdminTransferItems";
import RequestAdminSetCustomDescription from "../request/admin/RequestAdminSetCustomDescription";
import RequestCutieEvents from "../request/RequestCutieEvents";
import { Blockchain } from "../model/pet/BlockchainId";
import type { OnResponseFunc } from "../engine/RequestBase";
import RequestAdminTokenPawCoins from "../request/admin/RequestAdminTokenPawCoins";
import $ from "jquery";
import AdminService from "@/cuties/admin/AdminService";
import utils from "@/cuties/utils";
import { TEMPLATE_MANAGER_SINGLETON } from "@/cuties/engine/TemplateManager";
import PetService from "../pet/PetService";

mad.handler.register("admin_transfer_items", function () {
    const fromId = DataHelpers.toInt($("#transferFromId").val());
    const toId = DataHelpers.toInt($("#transferToId").val());

    const request = new RequestAdminTransferItems(fromId, toId);
    request.send();
});

mad.handler.register("admin_create_pet", function () {
    const fancy =  $("#fancy_select").find(":selected").text();
    const nobility =  $("#nobility").find(":selected").text();
    const generation =  $("#generation").val() as number;
    const pet_kind =  $("#pet_kind").find(":selected").text();
    const blockchain =  $("#blockchain").find(":selected").text();
    const owner =  $("#owner").val() as string;
    const count =  $("#count").val() as number;
    const request = new RequestAdminCreatePet(blockchain, fancy, pet_kind, nobility, generation, owner, count);

    request.send();
});

mad.handler.register("admin_create_auction", function () {
    const fancy =  $("#fancy_select_auction").find(":selected").text();
    const nobility =  $("#nobility_auction").find(":selected").text();
    const duration =  $("#duration_auction").val() as number;
    const blockchain =  $("#blockchain_auction").find(":selected").text();
    const pet_kind =  $("#pet_kind_auction").find(":selected").text();
    const price =  $("#price_auction").val() as string;
    const request = new RequestAdminCreateAuction(blockchain, fancy, duration, nobility, pet_kind, price);

    request.send();
});

mad.handler.register("admin_delete_pet", function () {
    const uid_pet_id =  $("#deletePetUid").val() as string;
    const request = new RequestAdminDeletePet(uid_pet_id);
    request.send();
});

mad.handler.register("admin_update_pet", function () {
    const petId = $("#update_pet_id").val() as string;
    PetService.recover(petId).then(() => {
        utils.show_notification("Pet " + petId + " updated");
    });
});

mad.handler.register("admin_show_promo_reward", function () {

    $(".admin_show_promo_reward_block").fadeOut();
    if( $(this).val() == "item" ) {
        $(".admin_show_promo_reward_item").fadeIn();
        this.checked(true);
    }

    if( $(this).val() == "pet" ) {
        $(".admin_show_promo_reward_pet").fadeIn();
        this.checked(true);
    }
});

mad.handler.register("set_custom_description", function() {
    const obj = $(this);
    const pet_id = obj.data("pet");

    const request = new RequestAdminSetCustomDescription(pet_id);
    request.send();
});

mad.handler.register("givePawcoins", function() {
    const obj = $(this);
    const amount = DataHelpers.toInt($("#coinAmount").val());
    const toWalletId = $("#toWalletIdcoins").val() as string;

    const request = new RequestAdminTokenPawCoins(amount, toWalletId);
    request.send();
});

function get_blockchain_events(blockchain: Blockchain, filter: any, renderFunction: OnResponseFunc) {

    let cutieIdInput = filter.cutieIdInput;
    const readFailed = DataHelpers.toBoolean(filter.readFailedInput, false);

    if (cutieIdInput.startsWith("e") || cutieIdInput.startsWith("t")) {
        cutieIdInput = cutieIdInput.substring(1, cutieIdInput.length);
    }

    if (DataHelpers.toInt(cutieIdInput) !== 0) {
        const cutieId = DataHelpers.toInt(cutieIdInput);

        const request = new RequestCutieEvents(blockchain, cutieId, readFailed);
        request.send(renderFunction);

    } else if (cutieIdInput === "") {

        const request = new RequestCutieEvents(blockchain, null, readFailed);
        request.send(renderFunction);

    } else {
        utils.show_notification("Cutie id is not a number");
        return false;
    }
}

mad.handler.register("get_eos_events", function () {
    const cutieIdInput = $("#cutieId").val();
    const readFailedInput = $("#readFailed").prop("checked");

    const filter = {
        cutieIdInput: cutieIdInput,
        readFailedInput: readFailedInput
    };

    const onResponse = function(response) {
        const data = {
            items: response.events
        };

        if (PLAYER_SINGLETON.is_role_admin() || PLAYER_SINGLETON.is_role_master()) {
            TEMPLATE_MANAGER_SINGLETON.show("admin_eos_events", data);
        }
    };

    get_blockchain_events(Blockchain.Eos, filter, onResponse);
});

mad.handler.register("get_tron_events", function () {
    const cutieIdInput = $("#cutieId").val();
    const readFailedInput = $("#readFailed").prop("checked");

    const filter = {
        cutieIdInput: cutieIdInput,
        readFailedInput: readFailedInput
    };

    const onResponse = function(response) {
        const data = {
            items: response.events
        };

        if (PLAYER_SINGLETON.is_role_admin() || PLAYER_SINGLETON.is_role_master()) {
            TEMPLATE_MANAGER_SINGLETON.show("admin_tron_events", data);
        }
    };

    get_blockchain_events(Blockchain.Tron, filter, onResponse);
});

mad.handler.register("admin_create_promo_codes", function () {

    const promo_chars =  $("#promo_chars").val() as string;
    const promo_count =  $("#promo_count").val() as number;
    const promo_length =  $("#promo_length").val() as number;
    const promo_use_times =  $("#promo_use_times").val() as number;
    let timeAvailableFrom = $("#promo_timeAvailableFrom").val() as any;
    let timeAvailableTill = $("#promo_timeAvailableTill").val() as any;
    console.log(timeAvailableFrom);
    if( timeAvailableFrom ) {
        timeAvailableFrom = Date.parse(timeAvailableFrom) / 1000;
    }

    if( timeAvailableTill ) {
        timeAvailableTill = Date.parse(timeAvailableTill) / 1000;
    }

    const item = {};
    const pet = {};

    if (!promo_count) {
        utils.show_notification("Please set promo code count");
        return false;
    }

    console.log("cods gen", promo_chars, promo_count, promo_length, promo_use_times, timeAvailableFrom, timeAvailableTill);

    const request = new RequestPromoCodeCreate(promo_chars, promo_count, promo_length, promo_use_times);

    if( $(".select_reward_reward:checked").val() == "pet" ) {

        if( $("#promo_fancy_select").val() )
            pet["petFancy"] = $("#promo_fancy_select").val();

        if( $("#promo_pet_kind").val() )
            pet["petKind"] = $("#promo_pet_kind").val();

        if( $("#promo_nobility").val() )
            pet["petNoble"] = $("#promo_nobility").val();

        if( $("#promo_generation").val() )
            pet["petGeneration"] = $("#promo_generation").val();

        if( $("#promo_bg").val() )
            pet["overrideBackgroundId"] = $("#promo_bg").val();

        if( !$("#promo_fancy_select").val() && !$("#promo_pet_kind").val() ) {
            utils.show_notification("Please select fancy or pet kind");
            return false;
        }

        if( !$.isEmptyObject(pet) ) request.set_pet(pet);
    }

    if( $(".select_reward_reward:checked").val() == "item" ) {

        if( $("#promo_code_item_kind").val() && $("#promo_code_item_kind").val() != "None" )
            item["itemKind"] = $("#promo_code_item_kind").val();

        if( $("#promo_code_item_grp_kind").val() )
            item["itemGroupKind"] = $("#promo_code_item_grp_kind").val();

        if( $("#promo_code_item_rarity").val() )
            item["itemRarityKind"] = $("#promo_code_item_rarity").val();

        if( !$.isEmptyObject(item) ) {
            if( $("#promo_code_item_count").val() ) {
                item["itemCount"] = $("#promo_code_item_count").val();
            }

            request.set_item(item);
        }
    }

    console.log("ITEM", item);
    console.log("PET", pet);
    request.send(function(response) {
        if (response.errors && response.errors.length) {
            utils.show_notification(response.errors.join(", "));
            return false;
        }

        if (response.promoCodes) {
            $("#res_promo_codes").text(response.promoCodes.join("\n"));
            utils.show_notification("Promo codes Genereated!");
        }
    });
});

mad.handler.register("admin_set_my_pet_levels", function() {
    const minPetLevel: number =  $("#minPetLevel").val() as number;
    const maxPetLevel: number =  $("#maxPetLevel").val() as number;

    utils.addAdminNotification("Updating all pet levels, please wait...");
    AdminService.setAllMyPetLevels(minPetLevel, maxPetLevel).then(data=>{
        utils.addAdminNotification("All pet levels updated!");
    });
});

mad.handler.register("admin_set_my_pet_experiences", function() {
    const minPetExperience: number =  $("#minPetExperience").val() as number;
    const maxPetExperience: number =  $("#maxPetExperience").val() as number;

    utils.addAdminNotification("Updating all pet experiences, please wait...");
    AdminService.setAllMyPetExperience(minPetExperience, maxPetExperience).then(data=>{
        utils.addAdminNotification("All pet experiences updated!");
    });
});
