import http, { getStateless } from "@/http";
import type { Blockchain } from "@/cuties/model/pet/BlockchainId";
import type { PersonalMessageSignature, WalletAddress, WalletProviderKind } from "@/components/LoginManager/TypeDefs";
import type { UserTemplate } from "@/cuties/model/player/UserTemplate";
import type { AxiosResponse } from "axios";
import IntroductionSystem from "@/cuties/player/introduction/IntroductionSystem";
import i18n from "@/i18n";
import analytics from "../analytics";
import { ShowLoader } from "@/common/decorators/ShowLoader";

export interface AuthResult {
    user: UserTemplate;
}

export type AuthTokenResult = AuthResult;

export interface AuthError {
    message: string;
    stack: string;
    response: AxiosResponse<{
        error?: "errorGameAccountNotExist" | string;
    } | null>;
}

/** a mapping to the UserController.java API routes */
export default class UserApiService {
    private static whenSubscriptionDefinitionList: Promise<SubscriptionDefinition[]> | null = null;
    private static whenSubscriptionDefinitionMap: Promise<Map<string, SubscriptionDefinition>> | null = null;

    public static getCampaignUrlParams() {
        const entries = [];
        if (document.referrer) {
            // for cases when user gets redirected to login
            // page from our main landing, which is static
            entries.push(...new URL(document.referrer).searchParams.entries());
        }
        // for cases when user instantly gets to a real page, for example /maticpresalestore
        entries.push(...new URLSearchParams(window.location.search).entries());
        return Object.fromEntries(entries);
    }

    /** returns user data if supplied token session is still alive, otherwise returns rejected promise */
    public static async authorizeTokenSession(): Promise<AuthTokenResult> {
        return http.get("/auth_token").then(rs => rs.data);
    }

    /** note, there are these RequestAuth*.ts classes, they are legacy, don't use them */
    public static async authorizeBlockchainAccount(params: AuthenticationRequest): Promise<AuthResult> {
        return http.post<AuthResult>("/blockchain/auth", params)
            .then(rs => rs.data)
            .catch((exc: AuthError | null) => {
                const error = exc?.response?.data?.error || null;
                if (error) {
                    exc.message = i18n.t(error) + " - " + exc.message;
                }
                return Promise.reject(exc);
            });
    }

    /**
     * creating same request definition as RequestBlockchainRegistration.ts, but without circular dependencies to
     * mad.ts, as they cause constructor to randomly fail depending on which place in project you use this class from
     */
    public static async registerBlockchainAccount(params: {
        blockchain: Blockchain;
        account: WalletAddress;
        code: PersonalMessageSignature;
        walletProvider: WalletProviderKind;

        /** referrer page url, used for revshare profit distribution */
        referrer?: string;
        /** id of the player that invited new user to the game with a referral link */
        inviterId?: string;
        campaignUrlParams: {};
    }): Promise<AuthResult> {
        const url = "/" + params.blockchain.toString().toLowerCase() + "/registration";
        return http.post<AuthResult>(url, params)
            .then(rs => {
                IntroductionSystem.launch(rs.data.user.id);
                analytics.gtag_log_event(params.blockchain.toString().toLowerCase(), "registration", params.walletProvider);
                return rs.data;
            })
            .catch((exc: AuthError | null) => {
                const error = exc?.response?.data?.error || null;
                if (error) {
                    exc.message = i18n.t(error) + " - " + exc.message;
                }
                return Promise.reject(exc);
            });
    }

    public static async registerOffchainAccount(params: OffchainRegistration): Promise<EmailRegisterResponse> {
        return http.post("/register", params).then((rs) => {
            const data: EmailRegisterResponse = rs.data;
            analytics.gtag_log_event("email", "registration", params.email);
            IntroductionSystem.launch(data.userId);
            return data;
        });
    }

    public static async resendEmailConfirmationCode(params: { email: string }): Promise<unknown> {
        return http.post("/resend_confirmation_code", params).then((rs) => rs.data);
    }

    @ShowLoader
    public static async loginUsingPassword(params: UserPasswordRequest): Promise<AuthResult> {
        return http.post<AuthResult>("/login", params).then((rs) => rs.data);
    }

    @ShowLoader
    public static async loginGoogleJwt(params: GoogleJwtLoginRequest) {
        return http.post("/login/google/jwt", params)
            .then((rs) => rs.data)
            .then((authResult: AuthResult) => {
                const ageMs = Date.now() - new Date(authResult.user.registrationTime).getTime();
                const justCreated = ageMs < 60 * 1000;
                if (justCreated) {
                    IntroductionSystem.launch(authResult.user.id);
                }
                return authResult;
            });
    }

    public static async loginUnstoppableDomains(params: UDLoginRequest): Promise<AuthResult> {
        return http.post("/login/ud", params).then((rs) => rs.data);
    }

    /** checks whether such account exists, returns rejected promise if it does not */
    public static async checkAccount(blockchain: Blockchain, account: string): Promise<void> {
        return http.get(`/user/${blockchain}/${account}`).then((rs) => rs.data);
    }

    public static async logout(): Promise<void> {
        return http.get("/logout").then((rs) => rs.data);
    }

    public static async attachBlockchainAccountToUser(request: AuthenticationRequest): Promise<{ user: UserTemplate }> {
        return http.post("/blockchain/attach", request).then((rs) => rs.data);
    }

    public static async mergeAccounts(request: AuthenticationRequest): Promise<void> {
        return http.post("/account/merge", request).then((rs) => rs.data);
    }

    public static async setFeaturedCutie(params: { cutieDbId: number }): Promise<void> {
        return http.post("/user/featured/cutie/set", params).then((rs) => rs.data);
    }

    private static async subscriptionDefinitionList(): Promise<SubscriptionDefinition[]> {
        if (!this.whenSubscriptionDefinitionList) {
            this.whenSubscriptionDefinitionList = getStateless("/public/user/subscription/definition/list")
                .then((response) => response.data);
        }
        return this.whenSubscriptionDefinitionList;
    }

    public static async subscriptionDefinitionMap(): Promise<Map<string, SubscriptionDefinition>> {
        if (!this.whenSubscriptionDefinitionMap) {
            this.whenSubscriptionDefinitionMap = this.subscriptionDefinitionList().then((list) => new Map(list.map((i) => [i.id, i])));
        }
        return this.whenSubscriptionDefinitionMap;
    }
}

export interface OffchainRegistration {
    email: string;
    password: string;
    language: string;
    referrer: string;
    inviterId: string;
    campaignUrlParams: any;
}

/** @see UserPasswordRequest.java */
interface UserPasswordRequest {
    username: string;
    password: string;
    /** required on following attempts after a failure */
    captchaResponse?: string | null;
}

/** @see SubscriptionDefinition.java */
export interface SubscriptionDefinition {
    id: string;
    effects: SubscriptionEffect[];
}

interface SubscriptionEffect {
    type: "DungeonWorldExtraStartingPartyItemsSlots";
    value: number;
    stacking: "CUMULATIVE" | "MULTIPLICATIVE" | "ONLY_GREATEST";
}

/** @see AuthenticationRequest.java */
export interface AuthenticationRequest {
    blockchain: Blockchain;
    account: WalletAddress;
    code: PersonalMessageSignature;
    walletProvider?: WalletProviderKind;
    /** examples: 'en', 'es' */
    language?: string;
    /** not sure server uses it */
    salt?: string;
}

export interface EmailRegisterResponse {
    userId: number;
}

/**
 * Login through UnstoppableDomains
 * @see UDLoginRequest.java
 */
export interface UDLoginRequest {
    accessToken: string;
    account: WalletAddress;
    domain: string;
}


interface GoogleJwtLoginRequest {
    jwtToken: string;
    inviterId?: number | string;
}
