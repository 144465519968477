// throw exception in functional way
import BigNumber from "bignumber.js";

export function throws(error: string | Error): never {
    if (error instanceof Error) {
        throw error;
    }
    throw new Error(error);
}

export const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(() => resolve(), ms));

export const MINUTES_IN_10_YEARS = 5259600;

export function assert(predicate: boolean, message: string): void {
    if (!predicate) throws(message);
}

export function removeEmptyValues<T extends {}>(struct: T): T {
    if (!struct || typeof struct !== "object") {
        return struct;
    }
    for (const key in struct) {
        if (struct[key] === null ||
            struct[key] === undefined
        ) {
            delete struct[key];
        } else {
            const value = struct[key];
            if (Array.isArray(value)) {
                value.forEach(removeEmptyValues);
            } else if (typeof value === "object") {
                removeEmptyValues(value);
                if (Object.keys(value).length === 0) {
                    delete struct[key];
                }
            }
        }
    }
    return struct;
}

export function isSameSet<T>(a: Set<T>, b: Set<T>): boolean {
    if (a.size !== b.size) {
        return false;
    }
    for (const item of b) {
        if (!a.has(item)) {
            return false;
        }
    }
    return true;
}

export function equalByValue<T>(a: T, b: T) {
    return JSON.stringify(a) === JSON.stringify(b);
}

/** compares elements by value */
export function removeDupes<T>(elements: T[]): T[] {
    const entries = elements.map(e => [JSON.stringify(e), e] as const);
    return [...new Map(entries).values()];
}

export function symmetricDifference<T>(a: Set<T>, b: Set<T>) {
    const result = new Set<T>();
    for (const item of a) {
        if (!b.has(item)) {
            result.add(item);
        }
    }
    for (const item of b) {
        if (!a.has(item)) {
            result.add(item);
        }
    }
    return result;
}

export function intersection<T>(a: Set<T>, b: Set<T>) {
    const result = new Set<T>();
    for (const item of a) {
        if (b.has(item)) {
            result.add(item);
        }
    }
    return result;
}

export function minBigInt(a: string | bigint, b: string | bigint): bigint {
    a = BigInt(a);
    b = BigInt(b);
    return a < b ? a : b;
}

export function maxBigInt(a: string | bigint, b: string | bigint): bigint {
    a = BigInt(a);
    b = BigInt(b);
    return a > b ? a : b;
}

/** manual implementation to avoid static imports of the giant web3-utils lib */
export function weiToGwei(wei: number | string | bigint): string {
    return new BigNumber(typeof wei === "bigint" ? wei.toString() : wei)
        .div("1000000000").toString();
}

export function weiToEther(wei: number | string | bigint): string {
    return new BigNumber(typeof wei === "bigint" ? wei.toString() : wei)
        .div(new BigNumber(10).pow(18)).toString();
}

export function gweiToWei(gwei: number | string | bigint): string {
    return new BigNumber(typeof gwei === "bigint" ? gwei.toString() : gwei).times(1000000000).toString();
}
