import RequestForm from "../form/RequestForm";


export default class RequestAdminSetPetAdditionalDetails extends RequestForm {

        constructor(form_selector:HTMLElement|string) {
            super(form_selector, "/set_pet_additional_details");
        }

        on_success(response) {

        }

        on_error(response) {
            //PLAYER_SINGLETON.clear();
        }

}
