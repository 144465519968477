
    import Vue from "vue";
    import Component from "vue-class-component";
    import type {
        InteractiveFightsBotDefinitionView,
        InteractiveFightsPersonalBotStats
    } from "@/app/cuties/interactive/InteractiveFightsApiService";
    import InteractiveFightsApiService from "@/app/cuties/interactive/InteractiveFightsApiService";
    import { camelToHuman } from "cuties-client-components/src/TextUtils";

    @Component
    export default class InteractiveFightsBotsList extends Vue {
        private botDefinitions: null | InteractiveFightsBotDefinitionView[] = null;
        private personalStats: null | Record<string, InteractiveFightsPersonalBotStats> = null;

        created() {
            InteractiveFightsApiService.getBotDefinitions().then(d => this.botDefinitions = d);
            InteractiveFightsApiService.getPersonalBotStats()
                .then(bots => this.personalStats = Object.fromEntries(
                    bots.map(b => [b.botId, b] as const)
                ));
        }

        isUnlocked(botDefinition: InteractiveFightsBotDefinitionView) {
            return this.personalStats
                && this.personalStats[botDefinition.id]
                && this.personalStats[botDefinition.id].totalWins > 0;
        }

        normalizeBotName(botDefinition: InteractiveFightsBotDefinitionView): string {
            return camelToHuman(botDefinition.id);
        }

        getImageUrl(botDefinition: InteractiveFightsBotDefinitionView) {
            if (this.isUnlocked(botDefinition)) {
                return `/rest/pet/fancy/${botDefinition.fancyName}.png`;
            } else {
                return `/rest/public/cutie/fancy/silhouette/${botDefinition.fancyName}.png`;
            }
        }

        getWeightPercent(botDefinition: InteractiveFightsBotDefinitionView) {
            const stats = this.personalStats?.[botDefinition.id];
            if (!stats) {
                return "?%";
            } else {
                const totalWeight = Object.values(this.personalStats!)
                    .map(bot => bot.effectiveWeight)
                    .reduce((a,b) => a + b, 0);
                return (100 * stats.effectiveWeight / totalWeight).toFixed(2) + "%";
            }
        }

        getWins(botDefinition: InteractiveFightsBotDefinitionView) {
            const stats = this.personalStats?.[botDefinition.id];
            return stats?.totalWins ?? 0;
        }

        getLoses(botDefinition: InteractiveFightsBotDefinitionView) {
            const stats = this.personalStats?.[botDefinition.id];
            return (stats?.totalAttempts ?? 0) - this.getWins(botDefinition);
        }
    }
