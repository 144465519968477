
    import Vue from "vue";
    import Component from "vue-class-component";
    import CutieListApiService from "@/app/cuties/list/CutieListApiService";
    import { Emit, Prop } from "vue-property-decorator";
    import AppSelect from "@/components/app-components/AppSelect.vue";
    import type { AttributeModelData } from "cuties-client-components/types/api/Cutie";
    import CutieAttributeIcon from "cuties-client-components/components/CutieAttributeIcon.vue";

    /**
     * @param source = [1,2,3,4,5,6,7,8,9,10]
     * @param count = 3
     * @return = [2,5,10]
     *
     * note, it could have been implemented without splitting into random groups: could just start the normal
     * shuffle, but stop at 15th element, that way we'll get 15 purely random elements without repetitions
     */
    function getRandomValues<T>(count: number, source: T[]): T[] {
        let leftToTake = Math.min(source.length, count);
        const result: T[] = [];
        let optionsLeft = source.length;
        while (leftToTake > 0) {
            const rangeSize = Math.floor(optionsLeft / leftToTake);
            const rangeOffset = Math.floor(Math.random() * rangeSize);
            const selectedIndex = source.length - optionsLeft + rangeOffset;
            const selectedValue = source[selectedIndex];
            result.push(selectedValue);
            optionsLeft -= rangeSize;
            --leftToTake;
        }
        return result;
    }

    @Component({
        components: { AppSelect, CutieAttributeIcon }
    })
    export default class CutieAttributesAutocomplete extends Vue {
        @Prop({ required: true }) value: string[];
        inputStr: string = "";

        loading = true;
        allOptions: AttributeModelData[] = [];
        filteredOptions: AttributeModelData[] = [];

        created() {
            CutieListApiService.cutieAttributeList()
                .then(allOptions => {
                    this.allOptions = allOptions;
                    this.remoteMethod();
                })
                .finally(() => this.loading = false);
        }

        remoteMethod(inputStr = ""): void {
            const currentInputLc = inputStr.toLowerCase();
            if (!inputStr) {
                this.filteredOptions = getRandomValues(15, this.allOptions!);
            } else {
                const matching: AttributeModelData[] = [];
                for (const attribute of this.allOptions) {
                    if (this.sanitizeName(attribute.srcName).includes(currentInputLc)) {
                        matching.push(attribute);
                        if (matching.length >= 15) {
                            // the less options we display, the better the responsiveness
                            break;
                        }
                    }
                }
                this.filteredOptions = matching;
            }
        }

        sanitizeName(srcName: string) {
            return srcName.toLowerCase().replace(" ", "_");
        }

        @Emit("update:value")
        updateValue(newValue: string[]) {
            return newValue;
        }

        get valueAccessor() {
            return this.value;
        }

        set valueAccessor(newValue: string[]) {
            if (JSON.stringify(this.value) !== JSON.stringify(newValue)) {
                this.updateValue(newValue);
            }
        }
    }
