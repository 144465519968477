import I18nHelpers from "@/cuties/engine/I18nHelpers";
import type { Blockchain } from "@/cuties/model/pet/BlockchainId";
import CutiesApiFaucet from "@/app/cuties/blockchain/CutiesApiFaucet";
import PopupManager from "@/cuties/engine/PopupManager";
import VueUtils from "@/cuties/VueUtils";
import UserApiService from "@/cuties/player/UserApiService";
import LoginService from "@/cuties/model/player/LoginService";

export default class AccountMergeService {
    public static async mergeBlockchainAccounts(
        blockchain: Blockchain, walletProviderName: string
    ) {
        this.merge_account(blockchain, walletProviderName)
            .then(() => new PopupManager().show("account_attach_done_popup", {
                title: I18nHelpers.translate("yourAccountIsCreated"),
                blockchain: blockchain,
            }))
            .catch(e => {
                const error = VueUtils.extractError(e);
                $(".error_div").text(error);
            });
    }

    private static async merge_account(blockchain: Blockchain, provider_name: string): Promise<any> {
        const impl = await CutiesApiFaucet.getApi(blockchain);
        try {
            let account, signature, salt;
            [account, signature, salt] = await impl.signTerms(provider_name);

            await UserApiService.mergeAccounts({
                blockchain: impl.get_blockchain(),
                account: account,
                code: signature,
                salt: salt,
            });
            LoginService.setAddedBlockchainAddress(impl.get_blockchain(), account);
        } catch (error) {
            impl.clean_provider();
            throw error;
        }
    }
}
