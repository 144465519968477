import I18nHelpers from "@/cuties/engine/I18nHelpers";
import type { SaleEntryRewardType } from "@/cuties/model/sale/SaleEntryRewardType";
import type PetDataModel from "../pet/PetDataModel";

export default class SaleEntryRewardModel {

    private _type : keyof typeof SaleEntryRewardType = null;
    private _data : any;
    private _name: string = "";
    private stats_translation: string[] = [];

    constructor(data) {
        this._type = data.type;
        this._data = data.data;
        if (this.data.fancy.name) this._name = I18nHelpers.translate(this.data.fancy.name);
        this.set_stat_translation(this.data.stats, this.data.elements);
    }

    get type(): keyof typeof SaleEntryRewardType {
        return this._type;
    }

    get data(): PetDataModel {
        return this._data;
    }

    set_stat_translation(stats, elements): void {
        const translation_lines: string[] = [];
            for (let i = 0; i < stats.length; i++) {
                const cur = stats[i];
                switch (cur.type) {
                    default:
                        if (cur.value !== "0" && cur.value !== "0%" ) {
                            translation_lines.push(I18nHelpers.translate("stat_tr_" + cur.type) + "  " + cur.value );
                        }
                        break;
                }
            }

            for (let i = 0; i < elements.length; i++) {
                const cur = elements[i];
                switch (cur.type) {
                    default:
                        if (cur.value !== "0" ) {
                            if (cur.value > 0) {
                                translation_lines.push((I18nHelpers.translate("element_tr_" + cur.type)) + "  " + cur.value);
                            } else{
                                translation_lines.push((I18nHelpers.translate("element_weak_tr_" + cur.type)) + "  " + cur.value);
                            }

                        }
                        break;
                }
            }

        this.stats_translation = translation_lines;
    }
}
