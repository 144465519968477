
    import { Component, Emit, Prop, Vue } from "vue-property-decorator";
    import type ItemInstanceModel from "../../cuties/model/item/ItemInstanceModel";
    import ItemLootBoxInfo from "@/components/items/ItemLootBoxInfo.vue";
    import ItemSupplySourceLine from "@/components/items/ItemSupplySourceLine.vue";
    import VueUtils from "@/cuties/VueUtils";
    import type { ItemEffect } from "@/cuties/model/item/ItemEffectModel";
    import type ItemEffectModel from "@/cuties/model/item/ItemEffectModel";
    import ItemTooltipEffectRow from "@/components/items/ItemTooltipEffectRow.vue";
    import ItemTooltipStatsTable from "@/components/items/ItemTooltipStatsTable.vue";
    import type { Consumable, ConsumableApplication } from "@/app/cuties/dungeons/DungeonsApiService";
    import DungeonsApiService from "@/app/cuties/dungeons/DungeonsApiService";
    import ItemTooltipDungeonApplication from "@/components/items/ItemTooltipDungeonApplication.vue";
    import ItemTagList from "@/components/items/ItemTagList.vue";

    const EFFECT_ORDER_RANKS: ItemEffect["type"][] = [
        "ItemEffectAdventureCooldownChange",
        "ItemEffectAdventureCooldownReset",
        "ItemEffectAdventureCooldownRemainingSecondsChange",
        "ItemEffectBreedingCooldownChange",
        "ItemEffectBreedingCooldownReset",
        "ItemEffectCannotUse",
        "ItemEffectClothes",
        "ItemEffectEquipmentRemover",
        "ItemEffectBackgroundChangeColor",
        "ItemEffectBackgroundPatternChange",
        "ItemEffectGrantNobility",
        "ItemEffectGenerationChange",
        "ItemEffectTemporarelyTimeBonusKind",
        "ItemEffectTemporarelyCountBonusKind",
        "UserItemEffectAddCurrentEnergy",
        "UserItemEffectEnergyMaxChange",
    ];

    function getEffectOrderRank(effect: ItemEffectModel) {
        return EFFECT_ORDER_RANKS.indexOf(effect.source.type);
    }

    @Component({
        components: { ItemTagList, ItemTooltipDungeonApplication, ItemTooltipStatsTable, ItemTooltipEffectRow, ItemSupplySourceLine, ItemLootBoxInfo },
    })
    export default class ItemTooltip extends Vue {

        @Prop({ required: true }) item!: ItemInstanceModel;
        @Prop({ default: true }) showHeader!: boolean;
        @Prop({ default: true }) show!: boolean;

        private dungeonConsumableDefinitions: Consumable[] = [];

        created() {
            DungeonsApiService.getConsumableDefinitions().then(defs => {
                this.dungeonConsumableDefinitions = defs;
            });
        }

        get columnCount(): number {
            return this.item.statsTable.columns.length;
        }

        get effects() {
            return [...this.item.effects].sort((a,b) => {
                return getEffectOrderRank(a) - getEffectOrderRank(b);
            });
        }

        get dungeonApplication(): ConsumableApplication | null {
            const baseName = this.item.schema.baseName ?? this.item.schema.info.type;
            return this.dungeonConsumableDefinitions.find(c => c.id === baseName)?.application ?? null;
        }

        getItemLink(itemKind: string) {
            return `/${this.$i18n.locale}/item/${itemKind}/0`;
        }

        goToLink(event: MouseEvent) {
            this.close();
            VueUtils.goToLink(event);
        }

        /**
         * fired when user clicks a link - any active popups
         * in parent component should be closed in such case
         */
        @Emit()
        close(): void {}

        getSupplySources() {
            const sources = [...this.item.schema.supplySources]
                .filter(rec => rec.status === "OBTAINABLE");
            const baseName = this.item.schema.baseName ?? this.item.schema.info.type;
            if (baseName === "ItemDust") {
                sources.unshift({
                    type: "ForgeRecycle",
                    status: "OBTAINABLE",
                });
            }
            return sources;
        }
    }
