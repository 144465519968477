import type { Blockchain } from "./pet/BlockchainId";

export enum TokenType
{
    Fungible,
    NFT
}

export default class token1155 {
    private _type: TokenType;
    private _id: number;
    private _blockchain: Blockchain;
    private _contract: string;
    private _token_id: string;
    // fungible
    private _item_kind: string;
    private _quantity: number;
    // nft
    private _nft_kind: string;
    private _token_index: number;


    constructor(data) {
        this._id = data.id;
        this._blockchain = data.blockchain;
        this._contract = data.contract;
        this._token_id = data.tokenId;

        this._item_kind = data.itemKind;
        this._quantity = data.quantity;

        this._nft_kind = data.nftKind;
        this._token_index = data.tokenIndex;
    }

    get type(): TokenType {
        return this._type;
    }

    get id(): number {
        return this._id;
    }

    get item_kind(): string {
        return this._item_kind;
    }

    get blockchain(): Blockchain {
        return this._blockchain;
    }

    get quantity(): number {
        return this._quantity;
    }

    get contract(): string {
        return this._contract;
    }

    get token_id(): string {
        return this._token_id;
    }

    get nft_kind(): string {
        return this._nft_kind;
    }

    get token_index(): number {
        return this._token_index;
    }

    get image() : string {
        return this.nft_kind + "-" + this.blockchain;
    }
}
