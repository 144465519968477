import { PLAYER_SINGLETON } from "@/cuties/model/player/Player";
import I18nHelpers from "@/cuties/engine/I18nHelpers";
import type { EosProviderInterface, EosTransactionRequest, WalletAction } from "@/cuties/blockchain/eos/EosWallet";
import type { AccountInfo, InvokeTransaction } from "./EosJsClient";
import { EosJsClient } from "./EosJsClient";
import type EosConfig from "./EosConfig";
import { Blockchain } from "../../model/pet/BlockchainId";
import BlockchainPrivateKey from "../BlockchainPrivateKey";
import type { JsonRpc } from "eosjs";
import type { JsSignatureProvider } from "eosjs/dist/eosjs-jssig";
import type { OnConfirmTransaction, Signature } from "@/cuties/blockchain/BlockchainService";
import type { PrivateKeyImportSyncOrAsyncDelegate } from "@/components/LoginManager/TypeDefs";
import i18n from "@/i18n";
import { ConfigInstance } from "@/cuties/engine/Config";
import TranslatedClientError from "@/app/cuties/utils/TranslatedClientError";
import VueUtils from "@/cuties/VueUtils";
import EosConfirmTransaction from "@/app/components/blockchains/EosConfirmTransaction.vue";
import CutiesApiFaucet from "@/app/cuties/blockchain/CutiesApiFaucet";
import type { Api } from "eosjs";
import createHash from "create-hash";
import utils from "@/cuties/utils";

function sha256(data: string) {
    return createHash("sha256").update(data).digest("hex");
}

const PROVIDER_STORAGE_KEY = "eosjs"; // change to unique
const ACCOUNT_NAME_STORAGE_KEY = "EOSAccountName";

type EosjsKeyAccountsResponse = {
    code: 500;
    message: "Internal Service Error";
    error: {
        what: "No alive blockchain providers. Reason: unexpected internal rpc error [path=/v1/history/get_key_accounts, status=503, response=<html><body><h1>503 Service Unavailable</h1>\nNo server is available to handle this request.\n</body></html>\n\n]";
    };
} | {
    account_names: string[]; // ["k1esunk1esun"];
    permissions?: [["owner", "active"]];
    prev_account_names?: [];
    prev_permissions?: [];
    error?: undefined;
};

function getAccountNameKey(passwordHash: string): string {
    return ACCOUNT_NAME_STORAGE_KEY + "_" + sha256(passwordHash);
}

export class EosPrivateKey extends BlockchainPrivateKey implements EosProviderInterface {

    private readonly config: EosConfig;

    public kind = "eos_private_key";
    protected public_key: string | null = null;
    protected session_pwd_key = "EosAccountPassword";
    protected provider_key = PROVIDER_STORAGE_KEY;

    private actorPermission = "active";
    private privateKey: string | null = null;
    private signatureProvider: JsSignatureProvider | null = null;
    private rpc: JsonRpc | null = null;
    private api: Api | null = null;

    constructor(config: EosConfig) {
        super();
        this.config = config;
        this.init();
    }

    private getAccountNameKey(passwordHash: string): string {
        return getAccountNameKey(passwordHash);
    }

    protected async is_private_key(pk: string) {
        return true;
    }

    public async get_address_from_private_key(pk: string) {
        return "";
    }

    public get_provider_current_account_address(): string {
        return PLAYER_SINGLETON.get_eos_account_name();
    }

    protected async init(): Promise<void> {
        const { Api, JsonRpc } = await import(/* webpackChunkName: "eosjs" */ "eosjs");
        this.rpc = new JsonRpc(this.config.nodeUrl);

        // Auto init if we have pwd in session
        if (!this.privateKey) {
            if (this.get_password_from_session()) {
                const pk = this.getStoredPrivateKey(this.get_password_from_session());
                this.set_private_key(pk);
            }
        }

        // Init EOS + User Auth
        if (this.privateKey) {

            const { JsSignatureProvider } = await import(/* webpackChunkName: "eosjs-jssig" */ "eosjs/dist/eosjs-jssig");
            this.signatureProvider = new JsSignatureProvider([this.privateKey]);
            if (this.signatureProvider) {
                this.api = new Api({ rpc: this.rpc, signatureProvider: this.signatureProvider });
            }
            const eosjs_ecc = await CutiesApiFaucet.getEosJsEcc();
            this.public_key = eosjs_ecc.privateToPublic(this.privateKey);

            let account = localStorage.getItem(this.getAccountNameKey(this.get_password_from_session()));

            if (!account) {
                const signatureProvider = null;
                const eosjs_ecc = await CutiesApiFaucet.getEosJsEcc();
                const publicKey = eosjs_ecc.privateToPublic(this.privateKey);
                const api = new Api({ rpc: this.rpc, signatureProvider });

                try {
                    const response = await api.rpc.history_get_key_accounts(publicKey);

                    if (response.account_names && response.account_names.length) {
                        account = response.account_names[0];
                    }
                } catch (e) {
                    utils.show_notification(e, { remove_link: true });
                }
            }

            if (!account) {
                throw Error(I18nHelpers.translate("eos_account_do_not_exist"));
            }

            const accountInfo = await EosJsClient.getAccountInfo(account);

            if (accountInfo.permissions) {
                for (let i = 0; i < accountInfo.permissions.length; i++) {
                    const cur = accountInfo.permissions[i];

                    if (cur.required_auth && cur.required_auth.keys && cur.required_auth.keys.length) {
                        for (let j = 0; j < cur.required_auth.keys.length; j++) {
                            const elem = cur.required_auth.keys[j];
                            if (elem.key && elem.key == this.public_key) {
                                this.actorPermission = cur.perm_name;
                            }
                        }
                    }
                }
            }
        }

    }

    public async logout(): Promise<void> {
        sessionStorage.removeItem(this.session_pwd_key);
    }

    public get_password_from_session() {
        return sessionStorage.getItem(this.session_pwd_key);
    }

    public async importPrivateKey(privateKey: string, account: string, password: string): Promise<void> {
        const passwordHash = sha256(password);
        localStorage.setItem(this.getAccountNameKey(passwordHash), account);
        await this.importOrFail(privateKey, password, true);
    }

    public import: PrivateKeyImportSyncOrAsyncDelegate = async (privateKey, password, addToStorage) => {
        try {
            await this.importOrFail(privateKey, password, addToStorage);
        } catch (exc) {
            return (exc || {}).message || exc + "";
        }
    };

    private importOrFail = async (privateKey: string, password: string, addToStorage: boolean): Promise<void> => {
        const eosjs_ecc = await CutiesApiFaucet.getEosJsEcc();

        if (!eosjs_ecc.isValidPrivate(privateKey)) {
            throw Error("Invalid EOS private key");
        }

        if (addToStorage && !password) {
            throw Error(I18nHelpers.translate("EthereumPrivateKey_ImportFailed_MissingPassword"));
        }

        if (addToStorage) {
            const passwordHash = sha256(password);
            sessionStorage.setItem(this.session_pwd_key, passwordHash);
            this.storePrivateKey(privateKey, passwordHash);
        }
    };

    public set_private_key(private_key: string) {
        this.privateKey = private_key;
    }

    private static async fetchAccountByPrivateKey(privateKey: string, config: EosConfig): Promise<string> {
        const { Api, JsonRpc } = await import(/* webpackChunkName: "eosjs" */ "eosjs");
        const rpc = new JsonRpc(config.nodeUrl);
        const signatureProvider = null;
        const eosjs_ecc = await CutiesApiFaucet.getEosJsEcc();
        const public_key = eosjs_ecc.privateToPublic(privateKey);
        const api = new Api({ rpc, signatureProvider });
        const response: EosjsKeyAccountsResponse = await api.rpc.history_get_key_accounts(public_key);
        if (!("account_names" in response)) {
            const msg = (response as EosjsKeyAccountsResponse).error?.what || "RPC Failure while attempting to retrieve EOS account name by public key";
            throw new Error(msg);
        } else if ("account_names" in response && response.account_names && response.account_names.length) {
            return response.account_names[0];
        } else {
            throw new TranslatedClientError("eos_account_do_not_exist");
        }
    }

    public static async getStoredAccountData(password: string, config: EosConfig): Promise<{
        account: string; privateKey: string; passwordHash: string;
    }> {
        if (!password) {
            throw Error(I18nHelpers.translate("wallet_ulock_failed_missing_pwd"));
        }

        const eosjs_ecc = await CutiesApiFaucet.getEosJsEcc();
        const passwordHash = sha256(password);
        const privateKey = BlockchainPrivateKey.getStoredPrivateKeyByPasswordHash(PROVIDER_STORAGE_KEY, passwordHash);

        if (!privateKey || !eosjs_ecc.isValidPrivate(privateKey)) {
            if (BlockchainPrivateKey.hasStoredPrivateKeys(PROVIDER_STORAGE_KEY)) {
                throw new TranslatedClientError("wallet_ulock_failed_pwd");
            } else {
                throw new TranslatedClientError("wallet_unlock_failed_no_private_keys_stored");
            }
        }

        const account = localStorage.getItem(getAccountNameKey(passwordHash)) ||
            await EosPrivateKey.fetchAccountByPrivateKey(privateKey, config);

        return { account, privateKey, passwordHash };
    }

    public async private_key_init(_: unknown, password?: string): Promise<void> {
        const { account, privateKey, passwordHash } = await EosPrivateKey.getStoredAccountData(password!, this.config);

        let unlock = false;

        if (!PLAYER_SINGLETON.is_logined()) {
            unlock = true;
        } else {
            // game account have eos account
            if (PLAYER_SINGLETON.get_eos_account_name()) {
                if (account == PLAYER_SINGLETON.get_eos_account_name()) {
                    unlock = true;
                } else {
                    throw Error(I18nHelpers.translate("eos_game_account_error_wrong") + PLAYER_SINGLETON.get_eos_account_name());
                }
            } else if (!PLAYER_SINGLETON.get_eos_account_name() && PLAYER_SINGLETON.get_ethereum_wallet_id()) { // eos account not attached to game account
                throw Error(I18nHelpers.translate("eos_error_attach_eos"));
            } else {
                throw Error(I18nHelpers.translate("eos_account_do_not_exist"));
            }
        }

        if (unlock) {
            sessionStorage.setItem(this.session_pwd_key, passwordHash);
            this.set_private_key(privateKey);
            await this.init();
        } else {
            throw Error(I18nHelpers.translate("eos_account_do_not_exist"));
        }
    }

    public export_private_key(password: string): string {
        const password_hash = sha256(password);

        if (!password) {
            throw Error(I18nHelpers.translate("wallet_ulock_failed_missing_pwd"));
        }

        if (password_hash != this.get_password_from_session()) {
            throw Error(I18nHelpers.translate("wallet_ulock_failed_pwd"));
        }

        return this.getStoredPrivateKey(password_hash);
    }

    public unlock(password?: string): Promise<any> {
        this.migratePasswordHash(password);
        let login = null;
        if (!PLAYER_SINGLETON.is_logined()) login = "login";
        return this.private_key_init(login, password);
    }

    public sendTransaction(account: string, method: string, data: any): Promise<any> {

        if (!this.privateKey) {
            throw Error("No private key or account");
        }

        if (!PLAYER_SINGLETON.get_eos_account_name()) {
            throw Error("No eos account name present!");
        }

        const invoke: InvokeTransaction = {
            account,
            method,
            actor: PLAYER_SINGLETON.get_eos_account_name(),
            permission: this.actorPermission,
            data
        };

        return EosJsClient.sendTransaction(this.api, invoke);
    }

    public async confirmTransaction(request: EosTransactionRequest, onConfirm: OnConfirmTransaction): Promise<string> {
        const accountInfo = await EosJsClient.getAccountInfo(PLAYER_SINGLETON.get_eos_account_name());
        const data = this.prepareConfirmPopupData(request, accountInfo);
        await VueUtils.mountPopup(EosConfirmTransaction, { data });
        return onConfirm();
    }

    private prepareConfirmPopupData(request: EosTransactionRequest, accountInfo: AccountInfo) {

        const data = request.data;

        let quantity = "0 EOS";
        if (data["quantity"]) {
            quantity = data["quantity"];
        }

        if (data["quant"]) {
            quantity = data["quant"];
        }

        if (data["stake_net_quantity"] || data["stake_cpu_quantity"]) {
            delete data["quantity"];
        }

        let eos_usd_balance = null;
        let eos_number = 0;

        const usdPrice = ConfigInstance.dynamic.usdPrices[Blockchain.Eos];

        if (accountInfo.core_liquid_balance) {
            eos_number = Number(accountInfo.core_liquid_balance.substring(0, accountInfo.core_liquid_balance.length - 4));
            eos_usd_balance = (eos_number * usdPrice).toFixed(2);
        } else {
            eos_usd_balance = 0;
        }

        let usd_quantity = quantity.substring(0, quantity.length - 4);
        usd_quantity = (Number(usd_quantity) * usdPrice).toFixed(2);


        const net_max = (accountInfo.net_limit.max / 1024).toFixed(2);
        const net_cur = (accountInfo.net_limit.used / 1024).toFixed(2);
        const net_fill = Math.round(((accountInfo.net_limit.max - accountInfo.net_limit.available) / accountInfo.net_limit.max) * 100);

        const cpu_max = (accountInfo.cpu_limit.max / 1000000).toFixed(4);
        const cpu_cur = (accountInfo.cpu_limit.used / 1000000).toFixed(4);
        const cpu_fill = Math.round(((accountInfo.cpu_limit.max - accountInfo.cpu_limit.available) / accountInfo.cpu_limit.max) * 100);


        const ram_max = (accountInfo.ram_quota / 1024).toFixed(2);
        const ram_cur = (accountInfo.ram_usage / 1024).toFixed(2);
        const ram_use = ((accountInfo.ram_quota - accountInfo.ram_usage) / 1024).toFixed(2);
        const ram_fill = Math.round((accountInfo.ram_usage / accountInfo.ram_quota) * 100);

        const popup_data = {
            eosAccountInfoUrl: ConfigInstance.eos.accountInfoUrl,
            account: accountInfo,
            eos_usd_ballance: eos_usd_balance,
            cpu_max: cpu_max,
            cpu_cur: cpu_cur,
            net_max: net_max,
            net_cur: net_cur,
            ram_max: ram_max,
            ram_cur: ram_cur,
            ram_use: ram_use,
            net_fill: net_fill,
            cpu_fill: cpu_fill,
            ram_fill: ram_fill,
            eos_number: eos_number,
            net_eos: accountInfo.total_resources.net_weight,
            cpu_eos: accountInfo.total_resources.cpu_weight,
            cpu_units: "sec",
            net_units: "KB",
            ram_units: "KB",
            price: quantity,
            price_usd: usd_quantity,
            player_to: "",
            toAddress: "",
        };

        if (data["to"]) {
            popup_data["player_to"] = data["to"];
            popup_data["toAddress"] = request.account + " " + request.method;
        } else {
            popup_data["player_to"] = request.account;
            popup_data["toAddress"] = request.method;
        }

        return popup_data;
    }

    public async sign(text: string): Promise<Signature> {
        const eosjs_ecc = await CutiesApiFaucet.getEosJsEcc();
        const sign = await eosjs_ecc.sign(text, this.privateKey);
        return { code: sign };
    }

    public async getAccount(): Promise<string> {
        const password = this.get_password_from_session();
        if (!password) {
            // a consistency check, just to get a meaningful message instead of "invalid input type"
            // should not happen if code is working correctly, but likely to happen,
            // as session storage initialization is very roundabout and prone to mistakes
            throw new Error(i18n.t("ERR_MISSING_UNLOCK_PASSPHRASE_IN_SESSION_STORAGE").toString());
        }
        const privateKey = this.getStoredPrivateKey(password);

        const account = localStorage.getItem(this.getAccountNameKey(this.get_password_from_session()));

        if (!account) {
            const { Api } = await import(/* webpackChunkName: "eosjs" */ "eosjs");
            const api = new Api({ rpc: this.rpc, signatureProvider: null });
            const eosjs_ecc = await CutiesApiFaucet.getEosJsEcc();
            const publicKey = eosjs_ecc.privateToPublic(privateKey);

            const result = await api.rpc.history_get_key_accounts(publicKey);

            if (result.account_names && result.account_names.length) {
                return result.account_names[0];
            }
        }

        if (!account) {
            throw Error("Cannot get EOS from wallet");
        }

        return account;
    }

    public async runUnauthorized(action: WalletAction): Promise<any> {
        throw Error("Not implemented");
    }

}

export type ConfirmPopupData = ReturnType<(typeof EosPrivateKey.prototype)["prepareConfirmPopupData"]>;
