
    import { Component, Vue } from "vue-property-decorator";
    import Lottery from "@/components/lottery/Lottery.vue";
    import VueUtils from "@/cuties/VueUtils";
    import { Action, Getter } from "vuex-class";
    import { AppStoreActions, AppStoreGetters } from "@/store/AppStore";
    import type { Player } from "@/cuties/model/player/Player";
    import analytics from "@/cuties/analytics";
    import RegularLotteryTabs from "@/components/lottery/RegularLotteryTabs.vue";

    const Shop = () => import(/* webpackChunkName: "Shop" */ "@/components/shop/Shop.vue");

    @Component({
        components: { RegularLotteryTabs, Lottery, Shop },
    })
    export default class FloatingIcons extends Vue {
        @Action(AppStoreActions.SHOP_POPUP_OPEN) openShop: (category) => boolean;
        @Action(AppStoreActions.LOTTERY_POPUP_OPEN) openLottery: () => boolean;
        @Action(AppStoreActions.LOTTERY_POPUP_CLOSE) closeLottery: () => boolean;
        @Getter(AppStoreGetters.LOTTERY_POPUP_SHOW) showLottery: boolean;
        @Getter("getPlayer") player: Player | undefined;
        menuOpen = false;

        openHalloweenLootbox(): void {
            this.openShop("HalloweenLootbox");
        }

        toggleMenu(): void {
            this.menuOpen = !this.menuOpen;
        }

        isMobile(): boolean {
            return VueUtils.isMobile();
        }

        openLotteryPopup(): void {
            this.openLottery();
        }

        closePopup(): void {
            this.closeLottery();
        }

        openSeasonLootbox(): void {
            analytics.gtag_log_event("lootbox icon click", "shop", "");
            this.openShop("SeasonLootbox");
        }

        openLands(): void {
            this.$router.push({
                path: "/" + this.$i18n.locale + "/presalestore",
            });
        }

        get isUserLoggedIn(): boolean {
            return this.player !== undefined;
        }
    }
