import { BlockchainServiceCompanion } from "@/cuties/blockchain/BlockchainService";
import type { BigNumber } from "bignumber.js";

export default class EosWalletCompanion extends BlockchainServiceCompanion {
    private static readonly unitFraction = 4;

    public readonly unit_factor = EosWalletCompanion.unitFraction;

    public readonly icon16Class = "eos_icon_16";
    public readonly icon28Class = "eos_icon_28";

    public readonly auctionStartStep = "0.1";
    public readonly auctionStartMin = "0";
    public readonly auctionStartDefault = "0.1";

    public readonly auctionEndStep = "0.05";
    public readonly auctionEndMin = "0";
    public readonly auctionEndDefault = "0.05";

    public static format(value: BigNumber): string {
        return value.toFixed(EosWalletCompanion.unitFraction);
    }

    isAddressSame(firstAddress: string, secondAddress: string): boolean {
        return firstAddress === secondAddress;
    }
}
