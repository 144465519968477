import type { OnConfirmTransaction, Signature } from "@/cuties/blockchain/BlockchainService";
import type BlockchainService from "@/cuties/blockchain/BlockchainService";
import type { AccountInfo } from "@/cuties/blockchain/eos/EosJsClient";
import type { BigNumber } from "bignumber.js";

export type WalletAction = (provider: EosProviderInterface) => Promise<any>;

export enum EosProviderType {
    PRIVATE_KEY = "eos_private_key",
    WOMBAT_WALLET = "wombat",
    EOS_DAPP_WALLET = "eos_dapp",
    LYNX_WALLET = "eos_lynx",
}

export interface EosWallet extends BlockchainService {
    generateKeys(): Promise<EosGeneratedKeys>;
    importPrivateKey(privateKey: string, account: string, password: string): Promise<void>;
    exportPrivateKey(password: string): string;
    getAccountInfo(account: string): Promise<AccountInfo>;
    getRawProvider(provider: EosProviderType): EosProviderInterface;

    unlock(provider: EosProviderType, password?: string): Promise<any>;
    logout(): void;
    send(to: string, amount: BigNumber): Promise<string>;
    stake(cpu: number, net: number): Promise<string>;
    unstake(cpu: number, net: number): Promise<string>;
    buyRam(eos: number): Promise<string>;
    sellRam(bytes: number): Promise<string>;
}

export interface EosProviderInterface {
    runUnauthorized(action: WalletAction): Promise<any>;
    sendTransaction(account: string, method: string, data: any): Promise<any>;
    confirmTransaction(request: EosTransactionRequest, onConfirm: OnConfirmTransaction): Promise<any>;
    getAccount(): Promise<string>;
    logout(): Promise<void>;
    sign(text: string): Promise<Signature>;
    unlock(password?: string): Promise<void>;
}

export interface EosGeneratedKeys {
    private_key1: string;
    private_key2: string;
    public_key1: string;
    public_key2: string;
}

export interface EosTransactionRequest {
    account: string;
    method: string;
    data: any;
}
