import CutiesApiFaucet from "@/app/cuties/blockchain/CutiesApiFaucet";

export async function encodeFuncCall(methods: Record<string, { abi: any }>, name: string, args: readonly any[]) {
    const TronWeb = await CutiesApiFaucet.getTronWeb();
    const { AbiCoder } = await import(/* webpackChunkName: "ethersprojectAbi" */ "@ethersproject/abi");
    // since in Solidity you can overload functions, there may be multiple different functions
    // with same name - filtering out the ones whose signature does not match passed arguments
    const matchingFuncCalls = Object.values(methods)
        .map((m) => m.abi)
        .filter((m) => m.name === name && (m.inputs || []).length === args.length)
        .map((abi) => {
            const types = (abi.inputs || []).map(i => i.type);
            const values = args.map((v, i) => (types[i] !== "address" ? v : TronWeb.address.toHex(v).replace(/^(41)/, "0x")));
            return {
                functionSelector: name + "(" + types.join(",") + ")",
                functionParameterHex: new AbiCoder().encode(types, values).replace(/^(0x)/, ""),
            };
        });
    const uniqueSigs = new Set(matchingFuncCalls.map(enc => enc.functionSelector));
    if (uniqueSigs.size === 1) {
        return matchingFuncCalls[0];
    } else {
        const msg = "Ambiguous or missing #" + name + " signatures (" +
            uniqueSigs.size + ") in contract";
        const exc = new Error(msg);
        exc["uniqueSigs"] = [...uniqueSigs];
        throw exc;
    }
}
