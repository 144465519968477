import { render, staticRenderFns } from "./PossibleCutieAttributesPopup.vue?vue&type=template&id=a485bf64&scoped=true&"
import script from "./PossibleCutieAttributesPopup.vue?vue&type=script&lang=ts&"
export * from "./PossibleCutieAttributesPopup.vue?vue&type=script&lang=ts&"
import style0 from "./PossibleCutieAttributesPopup.vue?vue&type=style&index=0&id=a485bf64&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a485bf64",
  null
  
)

export default component.exports