import DataHelpers from "@/cuties/engine/DataHelpers";
import type { AnyMap } from "../../engine/mad";

export interface TronContracts {
    readonly token: string;
    readonly core: string;
    readonly sale: string;
    readonly sale2: string;
    readonly saleProxy: string;
    readonly presale: string;
    readonly breeding: string;
    readonly breeding2: string;
    readonly plugins: string;
    readonly itemMarket: string;
    readonly setNobleEffect: string;
    readonly lottery: string;
    readonly generationDecreaseEffect: string;
    readonly buyEnergy: string;
    readonly cooldownDecreaseEffect: string;
    readonly cooldownEndTimeResetEffect: string;
    readonly pawShop: string;
    readonly token1155: string;
    readonly landPresale: string;
    readonly bank: string;
}

export interface TronScan {
    readonly transaction: string;
    readonly account: string;
}

export class TronConfig {
    readonly nodeUrl: string;
    readonly allowedNodes: string[];
    readonly contracts: TronContracts;
    readonly scan: TronScan;
    readonly minFee: number;

    constructor(data: AnyMap) {
        this.nodeUrl = data.nodeUrl;
        this.allowedNodes = data.allowedNodes;
        this.contracts = data.contracts;
        this.scan = data.scan;
        this.minFee = DataHelpers.toInt(data.minFee);
    }
}
