import { Blockchain } from "../model/pet/BlockchainId";
import { ConfigInstance } from "@/cuties/engine/Config";

export enum Feature {
    Login,
    Wallet,
    PetSell,
    PetBuy,
    ItemSell,
    ItemBuy,
    SellForTokens,
    PetTransfer,
    Custody,
    Lottery,
    /** promo codes, legacy referrals */
    PetRewards,
    CancelPetSell,
    CutieCrafting,
}

/** see https://github.com/microsoft/TypeScript/issues/17198#issuecomment-315400819 */
const allFeatures = Object.keys(Feature)
    // enum class consists of string keys pointing to it's
    // values and numeric keys pointing ot it's keys
    .filter((k) => typeof Feature[k] === "number")
    .map((k) => Feature[k]);

export default class BlockchainFeatures {
    private static getFeatureSet(): Record<Blockchain, any[]> {
        if (ConfigInstance.getProjectProfile() === "bic") {
            return {
                [Blockchain.Ethereum]: [],
                [Blockchain.Eos]: [],
                [Blockchain.Tron]: [],
                [Blockchain.Neo]: [],
                [Blockchain.Neo3]: [],
                [Blockchain.Matic]: [],
                [Blockchain.Heco]: [],
                [Blockchain.Emerald]: [],
                [Blockchain.Csc]: [],
                [Blockchain.Okc]: [],
                [Blockchain.Bsc]: allFeatures,
                [Blockchain.Offchain]: [Feature.Login],
            };
        } else {
            return {
                [Blockchain.Ethereum]: allFeatures.filter(f => {
                    // exclude ETH, cutie generation disabled on server due to expensive gas
                    return f !== Feature.PetRewards
                        && f !== Feature.CutieCrafting;
                }),
                [Blockchain.Eos]: allFeatures,
                [Blockchain.Tron]: allFeatures.filter(f => {
                    return f !== Feature.PetRewards;
                }),
                [Blockchain.Neo]: [],
                [Blockchain.Neo3]: allFeatures,
                [Blockchain.Matic]: allFeatures,
                [Blockchain.Heco]: allFeatures,
                [Blockchain.Emerald]: allFeatures,
                [Blockchain.Csc]: allFeatures,
                [Blockchain.Okc]: allFeatures,
                [Blockchain.Bsc]: [],
                [Blockchain.Offchain]: [Feature.Login],
            };
        }
    }

    static is_enabled(feature: Feature, blockchain: Blockchain): boolean {
        return BlockchainFeatures.getFeatureSet()[blockchain].some((elem) => elem.valueOf() === feature.valueOf());
    }

    public static byFeature(feature: Feature): Blockchain[] {
        return Object.entries(this.getFeatureSet())
            .filter(([_, features]) => features.includes(feature))
            .map(([blockchain]) => blockchain as Blockchain);
    }

    static is_custody_enabled(from_blockchain: Blockchain, to_blockchain: Blockchain): boolean {
        return ConfigInstance.are_dev_features_allowed() && from_blockchain == Blockchain.Ethereum && to_blockchain == Blockchain.Eos;
    }
}
