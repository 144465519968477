const $loadSpinner = $(".loading_ani");
const stack: number[] = [];

export function showLoader(): void {
    if (stack.length == 0) {
        $loadSpinner.fadeIn(400);
    }
    stack.push(0);
}

export function hideLoader(): void {
    stack.pop();
    if (stack.length == 0) {
        $loadSpinner.fadeOut(400);
    }
}

export async function withLoader<T>(promise: Promise<T>): Promise<T> {
    showLoader();
    try {
        return await promise;
    } finally {
        hideLoader();
    }
}
