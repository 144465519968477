import DataHelpers from "@/cuties/engine/DataHelpers";
import type Money from "../engine/Money";
import type { ActivityType } from "@/cuties/model/activity/ActivityType";
import BigNumber from "bignumber.js";
import type { WithdrawalSignature } from "@/cuties/blockchain/types";
import type { PetModelData } from "../model/pet/PetModelData";

export type CreateGenericCallbackArgs = {
    petId1?: number; // tokenid
    petId2?: number; // tokenid

    userId2?: string;
    itemKind?: string;
    itemCount?: number;
    itemId?: number;
    value?: any;
    tokenContract?: string;
    tokenCount?: string;
};

export abstract class BlockchainServiceCompanion {
    abstract unit_factor: number;

    auctionStartMin: string;
    auctionStartStep: string;
    auctionStartDefault: string;

    auctionEndMin: string;
    auctionEndStep: string;
    auctionEndDefault: string;

    icon28Class: string;
    icon16Class: string;

    /**
     * @param value - major scale decimal units: ETH/TRX/BTC/etc...
     * @return - minor scale atomic units: WEI/SUN/SATOSHI/etc...
     */
    toFixedPoint(value: BigNumber): BigNumber {
        return new BigNumber(value).times(new BigNumber(10).pow(this.unit_factor));
    }

    /**
     * @param value - minor scale atomic units: WEI/SUN/SATOSHI/etc...
     * @return - major scale decimal units: ETH/TRX/BTC/etc...
     */
    fromFixedPoint(value: BigNumber): BigNumber {
        return new BigNumber(value).div(new BigNumber(10).pow(this.unit_factor));
    }

    /**
     * @param money - integer units: WEI, SUN, etc...
     * @return - decimal base currency: ETH, TRX, etc...
     */
    toBaseCurrency(money: Money): BigNumber {
        return money.getAmountRaw().div(new BigNumber(10).pow(this.unit_factor));
    }

    toBaseCurrencyString(money: Money): string {
        return DataHelpers.formatMoney(this.toBaseCurrency(money));
    }

    abstract isAddressSame(firstAddress: string, secondAddress: string): boolean;
}

export interface Signature {
    code: string;
    salt?: string;
}

export type OnConfirmTransaction = () => Promise<string>;

export class BlockchainNetworkError extends Error {
    constructor(public readonly actual: string, public readonly expected: string) {
        super(`Wrong network selected in wallet. Expected ${expected}, but actual is ${actual}`);
    }
}

export class BlockchainAddressError extends Error {
    constructor(public readonly actual: string, public readonly expected: string) {
        super(`Wrong address selected in wallet. Expected ${expected}, but actual is ${actual}`);
    }
}

/**
 * aka "Cuties Blockchain API" interface
 * describes a generic interface of every Blockchain implementation, there
 * is one implementation of this interface for every blockchain we support
 *
 * it is basically a mapping to smart contracts we have uploaded in every of these blockchains
 */
export default interface BlockchainService {
    /**
     * used mostly to check whether provider is available, (and also to get provider kind in eos)
     * when this function returns a falsy value, Login Manager is triggered to initialize the provider
     */
    get_provider(): any;

    transfer_fungible(contract: string, from:string, to:string, id:string, count:BigNumber, itemKind: string, activity:ActivityType): unknown;

    /** aka "gift" cutie to other player */
    transfer_pet(account: string, token_id: number): Promise<any>;

    /**
     * @param {boolean} matron_tutorial - true if matron cutie is a tutorial cutie...
     *  I believe we do not offer breeding with tutorial cuties anymore
     */
    breed(sire: PetModelData, matron_id: number, matron_tutorial: boolean): Promise<any>;

    /** put cutie on siring auction */
    siring(blockchain_id: number, auction_price_start: BigNumber, auction_price_end: BigNumber, auction_duration: BigNumber): Promise<any>;

    /** remove cutie from siring auction */
    cancel_breed(blockchain_id: number): Promise<any>;

    /**
     * cancel cutie auction
     *
     * @param blockchain_id - cutie id
     * @param contract - contract address
     */
    cancel_sell(blockchain_id: number, contract: string): Promise<any>;

    /**
     * buy the cutie from auction
     *
     * @param blockchain_id - id of the cutie
     * @param price - current price, should be same or slightly higher than
     *                 the number that will be calculated in the smart contract
     * @param account - legacy, used for analytics, account of the cutie owner I believe
     * @param contract - contract address, needed since we have sale, sale2, sale6, etc... contracts for same function
     */
    buy(blockchain_id: number, price: BigNumber, account: string | number, contract: string): Promise<any>;

    /**
     * put cutie on the auction
     *
     * @param blockchain_id - id of the cutie
     * @param auction_price_start - a decimal of ETH/TRON/etc..., the price will dynamically grow or drop from auction_price_start to
     * @param auction_price_end      auction_price_end. Note, this dynamic price may result in different values for buyer and seller
     *                               if transaction mines too long - in such case contract gets the difference
     *
     *                               this dynamic price is also the reason you may see price being multiplied by 0.00000001 in many places - it's done
     *                               for cases when price grew one or two WEIs during mining - to avoid contract failing due to small number error
     * @param duration - seconds, determines how much time will it take for price to rise/drop
     *                    from start value to end value, note: does not end the auction
     * @param feature_price - if provided, will make cutie promoted in the auction page header for either 1 day or 1 week
     */
    sell(blockchain_id: number, auction_price_start: BigNumber, auction_price_end: BigNumber, auction_duration: BigNumber, feature_price: any, allow_tokens: any): Promise<any>;

    /**
     * @return {Promise<string>} - transaction hash
     *
     * note, even though it has a return signature, all implementations
     * redirect user to the activity page themselves currently
     */
    runLottery(): Promise<string>;

    /** buy an asset for a price defined in USD using signed rate from server */
    presale_bid(lotId: number, rate: string, expireAt: number, v: string, r: string, s: string, price: string): Promise<string>;
    /** buy an asset for blockchain's native coins */
    bid_native(lotId: number, price: string): Promise<string>;

    /** used only in legacy code, not relevant for newly added blockchains I believe */
    clean_provider(): void;

    /**
     * Check session provider network and active address
     */
    checkReadyForTransaction(): Promise<void>;

    offchainCurrencyDeposit(amount: BigNumber): Promise<string>;
    offchainCurrencyWithdraw(sign: WithdrawalSignature): Promise<string>;
}
