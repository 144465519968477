export function getSoundLayerAudioPlayer(folder: string, soundEvents: readonly string[]) {
    type SoundEvent = string;
    let eventToAudio: null | Record<SoundEvent, HTMLAudioElement> = null;

    if (!eventToAudio) {
        eventToAudio = Object.fromEntries(
            soundEvents.map(e => {
                const audio = new Audio(folder + e + ".mp3");
                // needed to make it possible to pitch bend
                (audio as unknown as { preservesPitch: boolean }).preservesPitch = false;
                return [e, audio];
            })
        ) as Record<SoundEvent, HTMLAudioElement>;
    }

    return {
        playLoopedSoundEvent(soundEvent: SoundEvent) {
            const audio = eventToAudio![soundEvent];
            audio.playbackRate = 1;
            eventToAudio![soundEvent].loop = true;
            return eventToAudio![soundEvent].play();
        },
        playSoundEvent: (soundEvent: SoundEvent) => {
            const audio = eventToAudio![soundEvent];
            audio.playbackRate = 1;
            return eventToAudio![soundEvent].play();
        },
        playSoundEventRandomPitch: (soundEvent: SoundEvent, maxOffset = 0.15) => {
            const audio = eventToAudio![soundEvent];
            audio.playbackRate = 1 - maxOffset + maxOffset * 2 * Math.random();
            return audio.play();
        },
        stopSoundEvent: (soundEvent: SoundEvent) => {
            return eventToAudio![soundEvent].pause();
        },
        stopAllSounds: () => {
            if (eventToAudio) {
                for (const key in eventToAudio) {
                    eventToAudio[key].pause();
                    eventToAudio[key].currentTime = 0;
                }
            }
        },
    };
}
