
    import Component from "vue-class-component";
    import { Emit, Prop, Vue, Watch } from "vue-property-decorator";
    import type { InteractiveFighterStateView, InteractiveFightRulesResponse, GameOverState ,
                  InteractiveFighterStats
                  ,
                  InteractiveFighterTurnEffectView
    } from "@/app/cuties/interactive/InteractiveFightsApiService";
    import InteractiveFightsApiService from "@/app/cuties/interactive/InteractiveFightsApiService";
    import { AppStoreGetters } from "@/store/AppStore";
    import { Getter } from "vuex-class";
    import { PING_INTERVAL_MS } from "@/app/cuties/interactive/InteractiveFightsLayer";
    import { camelToHuman } from "cuties-client-components/src/TextUtils";

    export interface SideData {
        index: number;
        fighter: InteractiveFighterStateView;
        imageUrl: string;
        gameOverState: GameOverState;
        stats: InteractiveFighterStats | null;
        lastTurnNumber: number;
        lastTurnEffect: InteractiveFighterTurnEffectView | null;
    }

    @Component
    export default class InteractiveFighterSide extends Vue {
        @Prop({ required: true }) data!: SideData;
        @Getter(AppStoreGetters.GET_EVERY_SECOND_TICK_TIME_MS) everySecondTickTimeMs!: number;

        rules: InteractiveFightRulesResponse | null = null;
        finalizingTurnNumber = 0;
        justUpdated = false;

        $refs!: {
            interactiveFightSideRoot: HTMLElement;
        };

        @Watch("data")
        async onDataChange(data: SideData) {
            if (this.finalizingTurnNumber !== data.lastTurnNumber) {
                this.finalizingTurnNumber = data.lastTurnNumber;
                this.justUpdated = true;
                await this.nextTick();
                /**
                 * by taking this computed property we force style recalculation to guarantee
                 * that animation observer will not miss this brief moment that class is set
                 * @see https://stackoverflow.com/a/63561659/2750743
                 */
                this.$refs.interactiveFightSideRoot.offsetWidth;
                this.justUpdated = false;
            }
        }

        private async nextTick(): Promise<void> {
            await new Promise<void>(resolve => this.$nextTick(() => resolve()));
        }

        async created() {
            this.rules = await InteractiveFightsApiService.getRules();
        }

        get fighter() {
            return this.data.fighter;
        }

        get imageUrl() {
            return this.data.imageUrl;
        }

        get gameOverState() {
            return this.data.gameOverState;
        }

        get stats() {
            return this.data.stats;
        }

        formatWinrate(stats: InteractiveFighterStats): string {
            if (stats.matchesFinished === 0) {
                return "50%";
            } else {
                return (100 * stats.matchesWon / stats.matchesFinished).toFixed(1) + "%";
            }
        }

        normalizeBotName(botId: string): string {
            return camelToHuman(botId);
        }

        get isDead() {
            return this.gameOverState === "DRAW"
                || this.gameOverState === "REQUESTER_WON" && !this.fighter.isRequester
                || this.gameOverState === "OPPONENT_WON" && this.fighter.isRequester;
        }

        get afkSeconds() {
            return Math.floor((this.everySecondTickTimeMs - new Date(this.fighter.lastPingTime).getTime()) / 1000);
        }

        get isAfk() {
            return this.afkSeconds * 1000 > PING_INTERVAL_MS * 2;
        }

        @Emit("kick")
        kick() {}
    }
