import type { UserTemplate } from "@/cuties/model/player/UserTemplate";
import { Blockchain } from "@/cuties/model/pet/BlockchainId";
import type { BlockchainRecord } from "@/cuties/model/player/Player";
import type { Feature } from "@/cuties/blockchain/BlockchainFeatures";
import BlockchainFeatures from "@/cuties/blockchain/BlockchainFeatures";
import BlockchainId from "@/cuties/model/pet/BlockchainId";

export enum PlayerRole {
    Default = 0,
    Tester = 1,
    Admin = 2,
    Master = 3,
    RevsharePartner = 4
}

/** the pure data part of the Player.ts - without dependency on store, config singleton, socket, etc... */
export default class PlayerBase {
    /** should be set if player successfully logged in */
    public authorizationData?: UserTemplate;

    /**
     * @property {number} user_id - user id
     */
    protected user_id:number = 0;

    /**
     * @property {string} language - String language id (En, Ru, Lv, etc.)
     */
    protected language:string = "en";

    /**
     * @property {enum} role - EnumGender.Default | EnumGender.Tester | EnumGender.Admin
     */
    protected role:PlayerRole = PlayerRole.Default;

    /**
     * @property {string} name - player's name
     */
    protected name: string | null = null;

    /**
     * @property {string} email - player's e-mail (if set)
     */
    protected email: string | null = null;

    protected newActivityCount: number = 0;
    protected agreeEmails: boolean = false;
    /** aka Paw Coins */
    protected tickets:number = 0;

    protected _domain = "";

    /**
     * Ethereum, EOS and other blockchains wallet id, specified by server
     */
    protected blockchains: Map<Blockchain, string> = new Map<Blockchain, string>();

    has_blockchain(blockchain: Blockchain): boolean {
        return this.blockchains.has(blockchain);
    }

    get_blockchains_data(
        feature: Feature | null = null,
        exclude: Array<Blockchain> = []
    ): BlockchainRecord[] {
        const blockchains_vals = [];
        if (this != null && this.get_blockchains() != null) {

            let first = true;
            for (const key of this.get_blockchains().keys()) {
                if (!exclude.some(el => el === key)) {

                    if (feature == null || BlockchainFeatures.is_enabled(feature, key)) {
                        blockchains_vals.push({ blockchain_val: key, checked: first, currency: new BlockchainId({ blockchain: key }).get_currency() });
                    }
                    first = false;
                }
            }
        }

        return blockchains_vals;
    }

    public get_blockchains(): Map<Blockchain, string> {
        return this.blockchains;
    }

    get_tickets() {
        return this.tickets;
    }

    agree_emails(): boolean {
        return this.agreeEmails;
    }

    get_server_ethereum_wallet_id(): string | undefined {
        return this.blockchains.get(Blockchain.Ethereum);
    }

    get_ethereum_wallet_id(): string | undefined {
        return this.get_server_ethereum_wallet_id();
    }

    getBlockchainAccount(blockchain: Blockchain): string | undefined {
        return this.blockchains.get(blockchain);
    }

    get_tron_address(): string | undefined {
        return this.blockchains.get(Blockchain.Tron);
    }

    is_logined(): boolean {
        return this.user_id != 0;
    }

    isRoleRevsharePartner() {
        return this.is_role_admin() || (this.role === PlayerRole.RevsharePartner);
    }

    is_role_admin():boolean {
        return this.role === PlayerRole.Admin || this.role === PlayerRole.Master;
    }

    is_role_master():boolean {
        return this.role === PlayerRole.Master;
    }

    public addressByBlockchain(blockchain:Blockchain) {
        if (blockchain === this.get_ethereum_wallet_id()) {
            return this.get_ethereum_wallet_id();
        } else {
            return this.blockchains.get(blockchain) || "";
        }
    }

    get_id():number {
        return this.user_id;
    }

    get_eos_account_name(): string | undefined {
        return this.blockchains.get(Blockchain.Eos);
    }

    get_neo_account_name(): string | undefined {
        return this.blockchains.get(Blockchain.Neo3);
    }

    get_name(): string | null {
        return this.name;
    }

    get_email(): string | null {
        return this.email;
    }

    /** domain is local value returned from UD (unstoppable domains) */
    get domain(): string {
        return this._domain;
    }

    getRegisteredForMs(): number {
        if (!this.authorizationData) {
            return 0;
        } else {
            return Date.now() - new Date(this.authorizationData.registrationTime).getTime();
        }
    }
}
