import DataHelpers from "@/cuties/engine/DataHelpers";
import Request from "../Request";
import utils from "@/cuties/utils";

export default class RequestAdminCreatePet extends Request {

    private readonly fancy: string = null;
    private readonly pet_kind: string;
    private readonly nobitity: string;
    private readonly generation: number;
    private readonly count: number;
    private readonly blockchain: string;
    private readonly owner: string;

    constructor(blockchain: string, fancy:string, petKind:string, nobitity: string, generation: number, owner: string, count: number) {
        super();
        this.blockchain = blockchain;
        if( fancy ) this.fancy = fancy;
        this.pet_kind = petKind;
        this.nobitity = nobitity;
        this.generation = generation;
        this.owner = owner;
        this.count = count;
        //  DataHelpers.to_enum_key(PetKind, this.pet_kind),
    }

    get_url(): string {
        return "/create_pet";
    }

    collect_post_data(): object {
        return {
            fancy: this.fancy,
            kind: this.pet_kind,
            nobilityKind: this.nobitity,
            generation: this.generation,
            blockchain: this.blockchain,
            owner: this.owner,
            count: this.count
        };
    }

    on_success(response: any): void {
        super.on_success(response);
        utils.show_notification("Pet generation scheduled!");
    }

    on_error(response: any): void {
        try {
            let message = response.errorMsg;

            if (!message) {
                message = "Internal server error!";
            }
            utils.show_notification(message);
        } catch (e) {
            DataHelpers.error("Request.on_error", e);
            utils.show_notification(response.error);
        }


        // utils.page_error(response.error, response.error);
    }

    get_handler_prefix(): string {
        return null;
    }
}
