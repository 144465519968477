import type { Message } from "webstomp-client";
import type { SubscriptionHolder } from "@/subscription/WebSocketClient";

export type OnRawMessageFunction = (message: Message) => unknown;
export type OnMessageFunction<T> = (message: T) => unknown;
export type SubscriptionFunction = (handler: OnRawMessageFunction) => Promise<SubscriptionHolder>;

export interface WebsocketSubscriptionOptions<T> {
    subscribe: SubscriptionFunction;
    onRawMessage?: OnRawMessageFunction;
    onMessage?: OnMessageFunction<T>;
}

export default class WebsocketSubscription<T> {
    private readonly options: WebsocketSubscriptionOptions<T>;

    private subscription: SubscriptionHolder | null = null;
    private subscribed = false;

    constructor(options: WebsocketSubscriptionOptions<T>) {
        this.options = options;
    }

    public subscribe(): void {
        if (!this.subscription && !this.subscribed) {
            this.subscribed = true;
            this.options
                .subscribe(this.defaultHandler.bind(this))
                .then((s) => (this.subscription = s))
                .catch(() => (this.subscribed = false));
        }
    }

    public unsubscribe(): void {
        this.subscription?.unsubscribe();
    }

    private defaultHandler(message: Message) {
        if (!(this.options.onRawMessage || this.options.onMessage)) {
            throw new Error("No message handler provided");
        }

        if (this.options.onRawMessage) {
            this.options.onRawMessage(message);
        }

        if (this.options.onMessage) {
            this.options.onMessage(JSON.parse(message.body));
        }
    }
}
