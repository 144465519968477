import { PLAYER_SINGLETON } from "@/cuties/model/player/Player";
import DataHelpers from "@/cuties/engine/DataHelpers";
import socket from "@/socket";
import type { OnMessageFunction, OnRawMessageFunction, SubscriptionFunction } from "@/subscription/WebSocketSubscription";
import WebsocketSubscription from "@/subscription/WebSocketSubscription";

interface ActivityMessage {
    newActivityCount: number;
    importantUnseenActivity: unknown;
}

const subscribe: SubscriptionFunction = function (handler: OnRawMessageFunction) {
    return socket.subscribeUserOnly("/notifications", handler);
};

const onMessage: OnMessageFunction<ActivityMessage> = function (message: ActivityMessage) {
    updateActivityCount(DataHelpers.toInt(message.newActivityCount));
};

function updateActivityCount(countNumber: number) {
    let count: number | "99+" | null;
    if (countNumber && countNumber > 0) {
        if (countNumber > 99) {
            count = "99+";
        } else {
            count = countNumber;
        }
    } else {
        count = null;
    }

    PLAYER_SINGLETON.set_new_activity_count(countNumber);

    updateActivityCountHeader(count);

    if (count) {
        $(".new-activity-panel").css("display", "block");
        $(".new-activity-panel").html(count + "");
        $(".bell_icon").css("opacity", "1");
    } else {
        $(".bell_icon").css("opacity", ".3");
        $(".new-activity-panel").css("display", "none");
    }
}

function updateActivityCountHeader(count: number | "99+" | null) {
    const add = count ? "(" + count + ") " : "";
    document.title = document.title.replace(/^\(\d+\+?\)\s*/, "");
    document.title = add + document.title;
}

export default new WebsocketSubscription({
    subscribe,
    onMessage,
});
