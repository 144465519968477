import mad from "@/cuties/engine/mad";

mad.handler.register("tron_confirm_ok", function () {
    const callback = $(this).data("confirm_callback");
    if (callback) callback();
    else {
        console.log("No callback", callback);
    }
    // not sure how it worked before, adding this line to
    // hide popup same way as in "ethereum_private_key_transaction"
    // it will be hidden even in case of error, I think that's acceptable
    mad.handler.run("popup_hide");
});

mad.handler.register("tron_confirm_nok", function () {
    const callback = $(this).data("reject_callback");
    if (callback) callback();
    else {
        console.log("No callback", callback);
    }
    // not sure how it worked before, adding this line to
    // hide popup same way as in "ethereum_private_key_transaction"
    // it will be hidden even in case of error, I think that's acceptable
    mad.handler.run("popup_hide");
});
