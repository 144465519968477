import { Blockchain } from "@/cuties/model/pet/BlockchainId";
import type BlockchainAccountManager from "./BlockchainAttachManager";
import VueUtils from "@/cuties/VueUtils";
import type { AuthMethod } from "@/components/LoginManager/LoginManagerEosView.vue";
import { EosPrivateKey } from "@/cuties/blockchain/eos/EosPrivateKey";
import { ConfigInstance } from "@/cuties/engine/Config";
import { ShowLoader } from "@/common/decorators/ShowLoader";
import LoginManagerEosViewDialog from "@/components/LoginManager/LoginManagerEosViewDialog.vue";
import LoginService from "@/cuties/model/player/LoginService";
import CutiesApiFaucet from "@/app/cuties/blockchain/CutiesApiFaucet";

export default class AttachEosAccountManager implements BlockchainAccountManager {
    @ShowLoader
    private static async signByMethod(authMethod: AuthMethod): Promise<{ account: string; code: string }> {
        const termsText = ConfigInstance.get("terms");
        if (authMethod.type === "EXTERNAL_PROVIDER") {
            const wallet = await CutiesApiFaucet.getEos();
            const provider = wallet.createProvider(authMethod.providerType);
            const [account, signature] = await Promise.all([
                provider.getAccount(),
                provider.sign(termsText),
            ]);
            return { account, code: signature.code };
        } else {
            const { privateKey, account } = await EosPrivateKey
                .getStoredAccountData(authMethod.password, ConfigInstance.eos);
            const eosjs_ecc = await CutiesApiFaucet.getEosJsEcc();
            return {
                account: account,
                code: await eosjs_ecc.sign(termsText, privateKey),
            };
        }
    }

    private async tryAttachAccount(authMethod: AuthMethod) {
        const { account, code } = await AttachEosAccountManager.signByMethod(authMethod);
        await LoginService.tryAttachAccount({
            blockchain: Blockchain.Eos,
            account: account,
            code: code,
        });
    }

    public async OpenPopupAttachAccount(): Promise<void> {
        while (true) {
            const authMethod = await VueUtils.mountPopup<AuthMethod>(
                LoginManagerEosViewDialog, { unlock: false }
            );
            try {
                await this.tryAttachAccount(authMethod);
                return;
            } catch (exc) {
                VueUtils.handleError(exc);
            }
        }
    }
}


