import DataHelpers from "@/cuties/engine/DataHelpers";
import type { EthereumConfig } from "@/cuties/blockchain/ethereum/EthereumContractAddresses";
import EosConfig from "@/cuties/blockchain/eos/EosConfig";
import { TronConfig } from "@/cuties/blockchain/tron/TronConfig";
import type { ILangModel } from "@/cuties/engine/LangModel";
import type { NeoConfig } from "@/cuties/blockchain/neo/NeoConfig";
import type { Blockchain } from "@/cuties/model/pet/BlockchainId";
import type { ConfigModel, ConfigProfileType } from "@/cuties/engine/ConfigModel";
import type DynamicConfiguration from "@/cuties/engine/DynamicConfiguration";
import type { Web3Blockchain } from "@/cuties/model/pet/BlockchainId";
import type { TokenInfo } from "@/cuties/engine/DynamicConfiguration";
import type { AdventureCooldownTierView } from "@/cuties/engine/ConfigModel";
import { langMetaData } from "@/i18n";

export default class Config {
    public ethereums: Record<Blockchain, EthereumConfig>;
    public eos: EosConfig;
    public tron: TronConfig;
    public neo: NeoConfig;

    /** will be null before page initializes */
    private data: ConfigModel | null = null;

    public supportedFiatCurrencies: string[];
    public supportedBlockchains: Blockchain[];

    public dynamic: DynamicConfiguration = null;

    public init(dataConst: ConfigModel, dataDynamic: DynamicConfiguration): void {
        this.data = { ...dataConst };
        this.dynamic = dataDynamic;

        // postprocess some properties
        // no matter if they were received as Raw/Const/Dynamic, in any case they are registered in this.data now

        if (DataHelpers.isObject(this.data.ethereums)) {
            this.ethereums = this.data.ethereums;
            delete this.data.ethereums;
        }

        if (DataHelpers.isObject(this.data.eos)) {
            this.eos = new EosConfig(this.data.eos);
            delete this.data.eos;
        }

        if (DataHelpers.isObject(this.data.tron)) {
            this.tron = new TronConfig(this.data.tron);
            delete this.data.tron;
        }

        if (DataHelpers.isObject(this.data.neo)) {
            this.neo = this.data.neo;
            delete this.data.neo;
        }

        if (Array.isArray(this.data.supportedFiatCurrencies)) {
            this.supportedFiatCurrencies = this.data.supportedFiatCurrencies;
            delete this.data.supportedFiatCurrencies;
        }
        if (Array.isArray(this.data.supportedBlockchains)) {
            this.supportedBlockchains = this.data.supportedBlockchains;
            delete this.data.supportedBlockchains;
        }
    }

    getProjectProfile(): ConfigProfileType {
        return this.get("projectProfile");
    }

    getAdventureCooldowns(): AdventureCooldownTierView[] {
        return this.get("adventureCooldowns");
    }

    getBreedingCooldowns() {
        // TODO: retrieve from cooldownConfig.xml
        return [
            60,
            120,
            300,
            600,
            1800,
            3600,
            7200,
            14400,
            28800,
            57600,
            86400,
            172800,
            345600,
            691200
        ];
    }

    get<TKey extends keyof ConfigModel>(key: TKey): ConfigModel[TKey] {
        if (!this.data) {
            const msg = "Attempted to read property " + key +
                " while config is not yet initialized";
            throw new Error(msg);
        }
        return this.data[key];
    }

    get_supported_languages(): ILangModel[] {
        const langToNativeName: Record<string, string> = {
            "en": "English",
            "zh-CN": "中文",
            "ja": "日本語",
            "ko": "한국어",
            "ru": "Русский",
            "es": "Español",
            "it": "Italiano",
            "pt": "Português",
            "tr": "Türk",
        };
        return Object.keys(langMetaData.langToMd5).map(lang => ({
            lang, text: langToNativeName[lang] ?? lang,
        }));
    }

    getLanguageNativeName(code: string) {
        return this.get_supported_languages().find(l => l.lang === code)?.text ?? code;
    }

    get_language(current_lang:string): ILangModel | null {
        if (!current_lang)
            return null;

            for (const model of this.get_supported_languages()) {
                if (current_lang == model.lang)
                    return model;
            }

        return null;
    }

    get_adventure_cooldown_time(tier:number): number {
        const data = this.getAdventureCooldowns();

        if (tier < 0)
            tier = 0;
        else if (tier > data.length - 1)
            tier = data.length - 1;

        return data[tier].seconds;
    }

    /** seems to be false on karlo =/ */
    are_dev_features_allowed(): boolean {
        return !this.data ? false : this.data.allowDevFeatures;
    }

    isBinance(): boolean {
        return !this.data ? false : this.data.projectProfile === "bic";
    }

    // todo: @nikita implement in Web3Reader
    getErc20TokenInfo(blockchain: Web3Blockchain, tokenName: string): TokenInfo & { address: string } | null {
        const tokens = this.dynamic.ethereums[blockchain]?.tokens ?? [];
        for (const address in tokens) {
            if (tokens.hasOwnProperty(address) && tokens[address].name == tokenName) {
                return {
                    ...tokens[address],
                    address,
                };
            }
        }

        return null;
    }

    isTestingEnvironment() {
        return ["karl.techcuties.com", "pelipo.techcuties.com", "https://pavl.techcuties.com/"]
            .includes(window.location.hostname);
    }
}

// return singleton
export const ConfigInstance = new Config();
