import type { RealBlockchain } from "@/cuties/model/pet/BlockchainId";
import { Blockchain } from "@/cuties/model/pet/BlockchainId";
import BigNumber from "bignumber.js";

export const OffchainCurrency = {
    ETH: "ETH",
    TRX: "TRX",
    MATIC: "MATIC",
    HT: "HT",
    EOS: "EOS",
    NEO3GAS: "NEO3GAS",
    ROSE: "ROSE",
    CET: "CET",
    OKT: "OKT",
    BNB: "BNB",
} as const;

export type OffchainCurrency = keyof typeof OffchainCurrency;

const decimals: { [k in keyof typeof OffchainCurrency]: number } = {
    [OffchainCurrency.ETH]: 18,
    [OffchainCurrency.TRX]: 6,
    [OffchainCurrency.MATIC]: 18,
    [OffchainCurrency.HT]: 18,
    [OffchainCurrency.EOS]: 4,
    [OffchainCurrency.NEO3GAS]: 8,
    [OffchainCurrency.ROSE]: 18,
    [OffchainCurrency.CET]: 18,
    [OffchainCurrency.OKT]: 18,
    [OffchainCurrency.BNB]: 18,
};

/**
 * @param value - integer units: WEI/SUN/SATOSHI/etc...
 * @param currency
 * @return - scaled decimal: ETH/TRX/BTC/etc...
 *
 * btw the name of the function contradicts it's
 * behaviour, a fixed point number is not an integer
 *
 * @deprecated - don't use it, it looses precision at
 *     15th decimal place, use fromFixedPointBigNumber()
 */
export function fromFixedPoint(
    value: number | string | bigint,
    currency: keyof typeof OffchainCurrency
): number {
    return Number(value) / Math.pow(10, decimals[currency]);
}

export function fromFixedPointBigNumber(
    value: number | string | bigint,
    currency: keyof typeof OffchainCurrency
): string {
    return new BigNumber(typeof value === "bigint" ? value.toString() : value)
        .div(new BigNumber(10).pow(decimals[currency])).toFixed().toString();
}

export function toOffchainIfAny(blockchain: Blockchain): OffchainCurrency | null {
    if (blockchain === "ETHEREUM") {
        return OffchainCurrency.ETH;
    } else if (blockchain === "MATIC") {
        return OffchainCurrency.MATIC;
    } else if (blockchain === "HECO") {
        return OffchainCurrency.HT;
    } else if (blockchain === "TRON") {
        return OffchainCurrency.TRX;
    } else if (blockchain === "EMERALD") {
        return OffchainCurrency.ROSE;
    } else if (blockchain === "CSC") {
        return OffchainCurrency.CET;
    } else if (blockchain === "OKC") {
        return OffchainCurrency.OKT;
    } else if (blockchain === "EOS") {
        return OffchainCurrency.EOS;
    } else if (blockchain === "NEO3") {
        return OffchainCurrency.NEO3GAS;
    } else if (blockchain === "BSC") {
        return OffchainCurrency.BNB;
    } else {
        return null;
    }
}

export function toOffchain(blockchain: Blockchain): OffchainCurrency {
    const asOffchain = toOffchainIfAny(blockchain);
    if (asOffchain) {
        return asOffchain;
    } else {
        throw new Error("No offchain currency support for blockchain " + blockchain);
    }
}

export function toBlockchain(offchain: keyof typeof OffchainCurrency): RealBlockchain {
    switch (offchain) {
        case "ETH":
            return Blockchain.Ethereum;
        case "TRX":
            return Blockchain.Tron;
        case "MATIC":
            return Blockchain.Matic;
        case "HT":
            return Blockchain.Heco;
        case "EOS":
            return Blockchain.Eos;
        case "NEO3GAS":
            return Blockchain.Neo3;
        case "ROSE":
            return Blockchain.Emerald;
        case "CET":
            return Blockchain.Csc;
        case "OKT":
            return Blockchain.Okc;
        case "BNB":
            return Blockchain.Bsc;
        default:
            throw new Error("No blockchain support for offchain currency " + offchain);
    }
}
