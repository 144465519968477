
    import type { NotificationModel } from "@/store/AppStore";
    import { AppStoreMutations, AppStoreGetters } from "@/store/AppStore";
    import { Component, Vue } from "vue-property-decorator";
    import { Getter, Mutation } from "vuex-class";
    import Notification from "@/app/components/notification/Notification.vue";

    @Component({
        components: { Notification },
    })
    export default class Notifications extends Vue {
        @Getter(AppStoreGetters.GET_NOTIFICATIONS) notifications!: NotificationModel[];
        @Mutation(AppStoreMutations.ADD_NOTIFICATION) addNotification!: (val: NotificationModel) => void;
    }
