import Cookies from "js-cookie";

export default class SecurityToken {

    public static sessionCookieName = "at";
    public static xsrfCookieName = "XSRF-TOKEN";
    public static xsrfHeaderName = "X-XSRF-TOKEN";

    public static getCSRFCookie(): string {
        return Cookies.get(this.xsrfCookieName);
    }

}

