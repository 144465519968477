
    import Vue from "vue";
    import Component from "vue-class-component";
    import type {
        InteractiveFightRulesResponse, InteractiveFightsHighscoreView
    } from "@/app/cuties/interactive/InteractiveFightsApiService";
    import InteractiveFightsApiService from "@/app/cuties/interactive/InteractiveFightsApiService";
    import VueUtils from "@/cuties/VueUtils";
    import UserProfileIcon from "@/app/components/player/UserProfileIcon.vue";
    @Component({
        components: { UserProfileIcon }
    })
    export default class InteractiveFightsFooter extends Vue {
        private rules: InteractiveFightRulesResponse | null = null;
        private highscores: null | InteractiveFightsHighscoreView[] = null;

        readonly goToLink = VueUtils.goToLink;

        created() {
            InteractiveFightsApiService.getRules().then(r => this.rules = r);
            InteractiveFightsApiService.getHighscores().then(h => this.highscores = h);
        }

        formatWinrate(highscore: InteractiveFightsHighscoreView) {
            const totalMatches = highscore.stats.totalWinsVsPlayer + highscore.stats.totalLosesVsPlayer;
            if (totalMatches > 0) {
                return (100 * highscore.stats.totalWinsVsPlayer / totalMatches).toFixed(1) + "%";
            } else {
                return "50.0%";
            }
        }
    }
