import DataHelpers from "@/cuties/engine/DataHelpers";
import mad from "@/cuties/engine/mad";

mad.handler.register("show_tooltip", function(obj : any) {

    const tool = $("#title-tip").first();
    const text = tool.find("div").first();
    tool.removeClass();

    text.html(obj.data("tooltip"));
    if(obj.attr("data-maxwidth")) {
        tool.css("max-width", DataHelpers.toInt(obj.attr("data-maxwidth")));
    }
    let customClass = null;
    if(obj.data("customclass") != null) {
        customClass = obj.data("customclass");
    }
    if(customClass)
        tool.addClass(customClass);

    tool.toggle(true);

    tool.css("top", -500);
    tool.css("left", -500);

    obj.on("mouseleave ", function() {
        if($("#title-tip:hover").length == 0) {
            tool.toggle(false);
            obj.off("mouseleave click");
            if(customClass)
                tool.removeClass(customClass);
        } else {
            $("#title-tip").on("mouseleave ", function() {
                $("#title-tip").toggle(false);
                $("#title-tip").off("mouseleave click");
                if(customClass)
                    tool.removeClass(customClass);
            });
        }
    });
    $("#title-tip").on("click", function() {
        $("#title-tip").toggle(false);
        $("#title-tip").off("click");
        if(customClass)
            tool.removeClass(customClass);
    });

    window.setTimeout(function() {
        process_tooltip(obj, tool);
    }, 500);


});

/* global JQuery */
mad.handler.register("show_tooltip_ext", function(obj : JQuery, content : string, maxwidth : number) {
    const tool = $("#title-tip").first();
    const text = tool.find("div").first();
    tool.removeClass();

    text.html(content);
        tool.css("max-width", maxwidth);
    tool.toggle(true);

    tool.css("top", -200);
    tool.css("left", -100);

    obj.on("mouseleave ", function() {
        if($("#title-tip:hover").length == 0) {
            tool.toggle(false);
            obj.off("mouseleave  click");
        } else {
            $("#title-tip").on("mouseleave ", function() {
                $("#title-tip").toggle(false);
                $("#title-tip").off("mouseleave  click");
            });
        }
    });
    $("#title-tip").on("click", function() {
        $("#title-tip").toggle(false);
        $("#title-tip").off("click");
    });

    window.setTimeout(function() {
        process_tooltip(obj, tool);
    }, 500);
});

function process_tooltip(obj : JQuery, tool : JQuery, ach:boolean = false) {

    const data = get_tooltip_pref_pos(obj, tool);

    if(data.hclass) {
        tool.addClass(data.hclass);
    }

    if(data.vclass) {
        tool.addClass(data.vclass);
        if(data.vclass == "tip-v-top") {
            data.top -= tool.height();
        }
    }

    if(data.xPer < 10) {
        data.left += (tool.width() / 2 - 20);
    } else if(data.xPer > 90) {
        data.left -= (tool.width() / 2 - 20);
    }
    tool.css("top", data["top"]);
    tool.css("left", data["left"]);
    /*if(!ach){
        $('#title-tip').toggle(true)
    }
    else
    {
        tool.toggle(true);
    }*/

}

$("body").on("click ", "#title-tip", function() {
    $(this).toggle(false);
});


function get_tooltip_pref_pos(obj : JQuery, tool : JQuery) {
    const pos = {
        top : obj.offset().top,
        left : obj.offset().left + obj.width() / 2
    };
    const data = {
        top : pos.top,
        left : pos.left,
        vclass : null,
        hclass : null,
        xPer : 0 ,
    };
    const viewY = $(window).innerHeight();
    const viewX = $(window).innerWidth();
    const offsetFromViewportTop = pos.top - $(document).scrollTop();

    const xPer = pos.left * 100 / viewX;
    data.xPer = xPer;

    if(pos.top  < tool.height() || offsetFromViewportTop < tool.height()) {
        data.vclass = "tip-v-bot";
        data.top += (obj.height() + 10);
    } else {
        data.vclass = "tip-v-top";
        data.top -= 10;
    }


    if(pos.left - (tool.width() / 2) < 0) {
        data.hclass = "tip-h-left";
        data.left += tool.width() / 2 -  obj.width() / 2;
    } else if(pos.left + (tool.width() / 2) > viewX) {
        data.hclass = "tip-h-right";
        data.left -= tool.width() / 2  - obj.width() / 2;
    }

    return data;

}
