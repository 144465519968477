import LoginPage from "@/views/LoginPage.vue";
import type { RouteConfig } from "vue-router";
import type { AsyncComponentLoader } from "vue/types/v3-define-async-component";

const AdventuresList = () => import(/* webpackChunkName: "AdventuresList" */ "./views/AdventuresList.vue");
const Settings = () => import(/* webpackChunkName: "Settings" */ "./views/Settings.vue");
const Forge = () => import(/* webpackChunkName: "Forge" */ "./views/Forge.vue");
const Achievements = () => import(/* webpackChunkName: "Achievements" */ "@/views/Achievements.vue");
const Leaderboard = () => import(/* webpackChunkName: "Leaderboard" */ "@/views/Leaderboard.vue");
const SpotTradeItemView = () => import(/* webpackChunkName: "SpotTradeItemView" */ "@/app/views/market/SpotTradeItemView.vue");
const TradedItemKindsView = () => import(/* webpackChunkName: "TradedItemKindsView" */ "@/app/views/market/TradedItemKindsView.vue");
const ItemPage = () => import(/* webpackChunkName: "ItemPage" */ "@/views/items/ItemPage.vue");
const InventoryPage = () => import(/* webpackChunkName: "InventoryPage" */ "@/views/InventoryPage.vue");
const PetsMy = () => import(/* webpackChunkName: "PetsMy" */ "@/views/PetsMy.vue");
const PetsAdventure = () => import(/* webpackChunkName: "PetsAdventure" */ "@/views/PetsAdventure.vue");
const Staking = () => import(/* webpackChunkName: "Staking" */ "@/views/Staking.vue");
const CutieShopping = () => import(/* webpackChunkName: "CutieShopping" */ "@/app/views/cutie-lists/CutieShopping.vue");
const Tokens = () => import(/* webpackChunkName: "Tokens" */ "@/views/Tokens.vue");
const Page404 = () => import(/* webpackChunkName: "Page404" */ "@/app/components/Page404.vue");
const CutieCraft = () => import(/* webpackChunkName: "CutieCraft" */ "@/views/CutieCraft.vue");
const DungeonWorlds = () => import(/* webpackChunkName: "DungeonWorlds" */ "@/app/views/dungeons/DungeonWorlds.vue");
const DungeonWorld = () => import(/* webpackChunkName: "DungeonWorld" */ "@/app/views/dungeons/DungeonWorld.vue");
const DungeonSession = () => import(/* webpackChunkName: "DungeonSession" */ "@/app/views/dungeons/objects/DungeonSession.vue");
const CutiePage = () => import(/* webpackChunkName: "CutiePage" */ "@/views/CutiePage.vue");
const AdventureSingle = () => import(/* webpackChunkName: "AdventureSingle" */ "@/views/AdventureSingle.vue");
const RaidListPage = () => import(/* webpackChunkName: "RaidListPage" */ "@/app/views/raid/RaidListPage.vue");
const RaidPage = () => import(/* webpackChunkName: "RaidPage" */ "@/app/views/raid/RaidPage.vue");
const Tournament = () => import(/* webpackChunkName: "Tournament" */ "@/views/tournaments/TournamentInstancePage.vue");
const TournamentList = () => import(/* webpackChunkName: "TournamentList" */ "@/views/tournaments/TournamentList.vue");
const InteractiveFights = () => import(/* webpackChunkName: "InteractiveFights" */ "@/app/components/interactive/InteractiveFights.vue");
const ItemShopping = () => import(/* webpackChunkName: "ItemShopping" */ "@/app/views/cutie-lists/ItemShopping.vue");
const ReferralPage = () => import(/* webpackChunkName: "ReferralPage" */ "@/app/views/referral/ReferralPage.vue");
const CutieFancyStats = () => import(/* webpackChunkName: "CutieFancyStats" */ "@/app/views/CutieFancyStats.vue");
const CutieFancyDetails = () => import(/* webpackChunkName: "CutieFancyDetails" */ "@/app/views/CutieFancyDetails.vue");
const CutieListAll = () => import(/* webpackChunkName: "CutieListAll" */ "@/app/views/cutie-lists/CutieListAll.vue");
const Promo = () => import(/* webpackChunkName: "Promo" */ "@/app/views/promo/Promo.vue");
const Player = () => import(/* webpackChunkName: "Player" */ "@/views/PlayerProfilePage.vue");
const BlockchainsPage = () => import(/* webpackChunkName: "BlockchainsPage" */ "@/views/BlockchainsPage.vue");
const OffchainCurrency = () => import(/* webpackChunkName: "OffchainCurrency" */ "@/views/OffchainCurrency.vue");
const SeasonPass = () => import(/* webpackChunkName: "SeasonPass" */ "@/views/SeasonPass.vue");
const SeasonInfoPage = () => import(/* webpackChunkName: "SeasonInfoPage" */ "@/views/SeasonInfoPage.vue");
const SeasonStoryPage = () => import(/* webpackChunkName: "SeasonStoryPage" */ "@/views/SeasonStoryPage.vue");
const Activity = () => import(/* webpackChunkName: "Activity" */ "@/views/Activity.vue");
const OKCUniquesPresalePage = () => import(/* webpackChunkName: "OKCUniquesPresalePage" */ "@/app/views/presale/OKCUniquesPresalePage.vue");
const Mascot = () => import(/* webpackChunkName: "Mascot" */ "@/views/Mascot.vue");
const Press = () => import(/* webpackChunkName: "Quests" */ "@/app/views/Press.vue");
const Presale = () => import(/* webpackChunkName: "Presale" */ "@/views/Presale.vue");
const PresaleStore = () => import(/* webpackChunkName: "PresaleStore" */ "@/views/PresaleStore.vue");
const Admin = () => import(/* webpackChunkName: "Admin" */ "@/app/views/admin/Admin.vue");
const AdminPromoCodes = () => import(/* webpackChunkName: "AdminPromoCodes" */ "@/app/views/admin/AdminPromoCodes.vue");
const AdminEquipmentView = () => import(/* webpackChunkName: "AdminEquipmentView" */ "@/views/admin/AdminEquipmentView.vue");
const LoginAdvertisementSetup = () => import(/* webpackChunkName: "LoginAdvertisementSetup" */ "@/views/admin/LoginAdvertisementSetup.vue");
const BatchLotteryTool = () => import(/* webpackChunkName: "BatchLotteryTool" */ "@/views/admin/BatchLotteryTool.vue");
const OfcIncomeReport = () => import(/* webpackChunkName: "OfcIncomeReport" */ "@/views/admin/OfcIncomeReport.vue");
const BreedParamsPage = () => import(/* webpackChunkName: "BreedParamsPage" */ "@/views/admin/BreedParamsPage.vue");
const AdminBattlePage = () => import(/* webpackChunkName: "AdminBattlePage" */ "@/views/admin/AdminBattlePage.vue");
const EmailConfirm = () => import(/* webpackChunkName: "EmailConfirm" */ "@/app/views/EmailConfirm.vue");
const ResetPassword = () => import(/* webpackChunkName: "ResetPassword" */ "@/app/views/player/ResetPassword.vue");
const OldPage = () => import(/* webpackChunkName: "OldPage" */ "@/app/views/OldPage.vue");
const SingleAchievementPage = () => import(/* webpackChunkName: "SingleAchievementPage" */ "@/app/views/SingleAchievementPage.vue");
const BatchFundAccountsTool = () => import(/* webpackChunkName: "BatchFundAccountsTool" */ "@/views/admin/BatchFundAccountsTool.vue");
const DungeonTilemapEditor = () => import(/* webpackChunkName: "DungeonTilemapEditor" */ "@/app/views/dungeons/DungeonTilemapEditor.vue");
const SharedHpBattleReport = () => import(/* webpackChunkName: "SharedHpBattleReport" */ "@/views/SharedHpBattleReport.vue");
const OasisLaunchUniquesSalePage = () => import(/* webpackChunkName: "OasisLaunchUniquesSalePage" */ "@/app/views/presale/OasisLaunchUniquesSalePage.vue");
const Heco = () => import(/* webpackChunkName: "Heco" */ "@/app/views/presale/Heco.vue");
const CoinexSmartChainUniquesPresalePage = () => import(/* webpackChunkName: "CoinexSmartChainUniquesPresalePage" */ "@/app/views/presale/CoinexSmartChainUniquesPresalePage.vue");
const QuestPage = () => import(/* webpackChunkName: "QuestPage" */ "@/app/views/QuestPage.vue");

export enum OrdersRouteNames {
    MARKET = "marketOrders",
    ITEM = "itemOrders",
    MARKET_TAB = "marketOrders_tab",
    ITEM_TAB = "itemOrders_tab",
}

const asyncRoutes: (RouteConfig & { component: AsyncComponentLoader })[] = [
    {
        path: "/admin_eos_events",
        name: "admin_eos_events",
        component: OldPage,
    },
    {
        path: "/admin_tron_events",
        name: "admin_tron_events",
        component: OldPage,
    },
    {
        path: "/admin_requests",
        name: "admin_requests",
        component: OldPage,
    },
    {
        path: "/email_confirm/:id/:code",
        name: "email_confirm",
        component: EmailConfirm,
    },
    {
        path: "/admin",
        name: "admin",
        component: Admin,
    },
    {
        path: "/admin_equipment",
        name: "admin_equipment",
        component: AdminEquipmentView,
    },
    {
        path: "/admin_promo_codes",
        name: "admin_promo_codes",
        component: AdminPromoCodes,
    },
    {
        path: "/admin_login_advertisements",
        name: "admin_login_advertisements",
        component: LoginAdvertisementSetup,
    },
    {
        path: "/admin_batch_lottery",
        name: "admin_batch_lottery",
        component: BatchLotteryTool,
    },
    {
        path: "/admin_batch_fund_accounts",
        name: "admin_batch_fund_accounts",
        component: BatchFundAccountsTool,
    },
    {
        path: "/admin_ofc_income_report",
        name: "admin_ofc_income_report",
        component: OfcIncomeReport,
    },
    {
        path: "/admin_breed_params",
        name: "admin_breed_params",
        component: BreedParamsPage,
    },
    {
        path: "/admin_battle",
        name: "admin_battle",
        component: AdminBattlePage,
    },
    {
        path: "/index_samsung_tron",
        name: "index_samsung_tron",
        component: OldPage,
    },
    {
        path: "/battle",
        name: "battle",
        component: SharedHpBattleReport,
    },
    {
        path: "/halloweeen",
        name: "halloweeen",
        component: QuestPage,
    },
    {
        path: "/pets_my",
        name: "pets_my",
        component: PetsMy,
    },
    {
        path: "/pet/:uid",
        name: "pet",
        component: CutiePage,
    },
    {
        path: "/cutie",
        name: "cutie",
        component: CutiePage,
    },
    {
        path: "/inventory",
        name: "inventory",
        component: InventoryPage,
    },
    {
        path: "/item/:itemKind",
        name: "item",
        component: ItemPage,
    },
    {
        path: "/item/:itemKind/:level",
        name: "item",
        component: ItemPage,
    },
    {
        path: "/tokens",
        name: "tokens",
        component: Tokens,
    },
    {
        path: "/pets_adventure",
        name: "pets_adventure",
        component: PetsAdventure,
    },
    {
        path: "/forge",
        name: "forge",
        component: Forge,
    },
    {
        path: "/forge/:tab",
        name: "forge",
        component: Forge,
    },
    {
        path: "/cutiesCrafting",
        name: "cutiesCrafting",
        component: CutieCraft,
    },
    {
        path: "/bcug",
        name: "bcug",
        component: Staking,
    },
    {
        path: "/cuties",
        name: "cuties",
        component: CutieShopping,
    },
    {
        path: "/cuties/:tab",
        name: "cuties",
        component: CutieShopping,
    },
    {
        path: "/adventures/:mode",
        name: "adventures",
        component: AdventuresList,
    },
    {
        path: "/adventure/:adventureName",
        name: "adventure",
        component: AdventureSingle,
    },
    {
        path: "/dungeon-editor",
        name: "dungeon-editor",
        component: DungeonTilemapEditor,
    },
    {
        path: "/dungeon-worlds",
        name: "dungeon-worlds",
        component: DungeonWorlds,
    },
    {
        path: "/dungeon-world",
        name: "dungeon-world",
        component: DungeonWorld,
    },
    {
        path: "/dungeon_instance",
        name: "dungeon_instance",
        component: DungeonSession,
    },
    {
        path: "/setPassword/:key",
        name: "setPassword",
        component: ResetPassword,
    },
    {
        path: "/raids",
        name: "raids",
        component: RaidListPage,
    },
    {
        path: "/raid/:version/:id",
        name: "raid",
        component: RaidPage,
    },
    {
        path: "/tournaments/start",
        name: "tournaments",
        component: TournamentList,
    },
    {
        path: "/tournaments",
        name: "tournaments",
        component: TournamentList,
    },
    {
        path: "/tournaments/tournament",
        name: "tournament",
        component: Tournament,
    },
    {
        path: "/interactive-fights",
        name: "interactive-fights",
        component: InteractiveFights,
    },
    {
        path: "/settings",
        name: "settings",
        component: Settings,
    },
    {
        path: "/leaderboard",
        name: "leaderboard",
        component: Leaderboard,
    },
    {
        path: "/achievement/:id",
        name: "achievement",
        component: SingleAchievementPage,
    },
    {
        path: "/achievements",
        name: "achievements",
        component: Achievements,
    },
    {
        path: "/referral",
        name: "referral",
        component: ReferralPage,
    },
    {
        path: "/cutie-tribute-stats",
        name: "cutie-tribute-stats",
        component: CutieFancyStats,
    },
    {
        path: "/cutie-tribute-details/:fancyName",
        name: "cutie-tribute-details",
        component: CutieFancyDetails,
    },
    {
        path: "/cutie-list-all",
        name: "cutie-list-all",
        component: CutieListAll,
    },
    {
        path: "/items/:tab",
        name: "items",
        component: ItemShopping,
        children: [
            {
                path: "item/:itemKind",
                name: OrdersRouteNames.ITEM_TAB,
                props: () => ({ routerToItemName: OrdersRouteNames.ITEM_TAB }),
                component: SpotTradeItemView,
            },
            {
                path: "",
                name: OrdersRouteNames.MARKET_TAB,
                props: () => ({ routerToItemName: OrdersRouteNames.ITEM_TAB }),
                component: TradedItemKindsView,
            },
        ],
    },
    {
        path: "/items",
        name: "items",
        component: ItemShopping,
    },
    {
        path: "/promo",
        name: "promo",
        component: Promo,
    },
    {
        path: "/player/:id",
        name: "player",
        component: Player,
    },
    {
        path: "/blockchain_wallets",
        name: "blockchain_wallets",
        component: BlockchainsPage,
    },
    {
        path: "/offchain",
        name: "offchain",
        component: OffchainCurrency,
    },
    {
        path: "/season_pass",
        name: "season_pass",
        component: SeasonPass,
    },
    {
        path: "/season_info",
        name: "season_info",
        component: SeasonInfoPage,
    },
    {
        path: "/season_story",
        name: "season_story",
        component: SeasonStoryPage,
    },
    {
        path: "/activity",
        name: "activity",
        component: Activity,
    },
    {
        path: "/okc",
        name: "okc",
        component: OKCUniquesPresalePage,
    },
    {
        path: "/oasis",
        name: "oasis",
        component: OasisLaunchUniquesSalePage,
    },
    {
        path: "/heco",
        name: "heco",
        component: Heco,
    },
    {
        path: "/csc",
        name: "csc",
        component: CoinexSmartChainUniquesPresalePage,
    },
    {
        path: "/mascot",
        name: "mascot",
        component: Mascot,
    },
    {
        path: "/press",
        name: "press",
        component: Press,
    },
    {
        path: "/presalestore",
        name: "presalestore",
        component: PresaleStore,
    },
    {
        path: "/presale",
        name: "presale",
        component: Presale,
    },
    {
        path: "/page404",
        name: "page404",
        component: Page404,
    },
];

const syncRoutes: RouteConfig[] = [
    // login page should be part of main bundle as it is the first
    // page new users see so we want to load it without delay
    {
        path: "/login",
        name: "login",
        component: LoginPage,
    },
    {
        path: "/raidlink/:id",
        name: "raidlink",
    },
    {
        path: "/cutie-my-oldest",
        name: "cutie-my-oldest",
    },
    {
        path: "/",
        name: "root",
    },
    {
        path: "/changelog",
        name: "changelog",
    },
    {
        path: "/blog-history",
        name: "blog-history",
    },
];

export const routes: RouteConfig[] = [
    ...asyncRoutes,
    ...syncRoutes,
    {
        path: "*",
        name: "page404",
        component: Page404,
    },
];
