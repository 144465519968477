import Request from "../Request";

export default class RequestAdminTokenPawCoins extends Request {

    private amount:number;
    private toWalletId:string;

    constructor(amount:number, toWalletId:string) {
        super();

        this.amount = amount;
        this.toWalletId = toWalletId;
    }

    get_url(): string {
        return "/paw_coin_give";
    }

    collect_post_data(): object {
        const data = {
            amount: this.amount,
            toWalletId: this.toWalletId
        };

        return data;
    }

    on_success(response: any): void {
        super.on_success(response);
    }
}
