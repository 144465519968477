import I18nHelpers from "@/cuties/engine/I18nHelpers";
import DataHelpers from "@/cuties/engine/DataHelpers";


/**
 * PlayerModel represents any player
 */
export default class PlayerModel {


    private user_id:number = 0;
    private _name : string = null;
    private _icon : string = null;
    private canGiftItems:boolean = null;
    private achievementPoints:number = 0;
    private achievementCount:number = 0;
    private icon_name: string = "";
    private blockchains: any;
    private hideInLeaderboards : boolean;

    /**
     * PlayerModel's constructor
     * @param {object} data - JSON from server {id:123, name:"John"}
     */
    constructor(data) {
        this.user_id = DataHelpers.toInt(data.id);
        this._name = DataHelpers.isString(data.name) ? data.name : I18nHelpers.translate("player") + " " + this.user_id;
        this._icon = data.icon;
        this.icon_name = this.get_icon_name(data.icon);
        this.canGiftItems = typeof data.canGiftItems === "boolean" ? data.canGiftItems : false;
        this.achievementPoints = DataHelpers.toInt(data.achievementPoints);
        this.achievementCount = DataHelpers.toInt(data.achievementCount);
        this.blockchains = data.blockchains;
        this.hideInLeaderboards = data.hideInLeaderboards;
    }

    get icon(): string {
        return this._icon;
    }

    get name(): string {
        return this._name;
    }

    get_icon_name(icon) {
        if(!icon) return null;

        switch(icon) {
            case "referral.svg":
                return I18nHelpers.translate("referal_icon_descr");

            default:
                return "";
        }
    }

    get_id() {
        return this.user_id;
    }
}
