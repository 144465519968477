import { PLAYER_SINGLETON } from "@/cuties/model/player/Player";
import type { TronConfig } from "@/cuties/blockchain/tron/TronConfig";
import type TronWeb from "tronweb";
import CutiesApiFaucet from "@/app/cuties/blockchain/CutiesApiFaucet";

/**
 * reusable functions needed by multiple providers
 */
export default class TronHelper {
    public static async initClient(config: TronConfig): Promise<TronWeb> {
        const fullNode = config.nodeUrl; // https://api.trongrid.io';
        const solidityNode = config.nodeUrl; // 'https://api.trongrid.io';
        const eventServer = config.nodeUrl; // 'https://api.trongrid.io/';
        let acc = "TVGW7sdY6mR53NxYYFDRUyhsV7Kkp6TMTB"; // need some default address

        if (PLAYER_SINGLETON.get_tron_address()) {
            acc = PLAYER_SINGLETON.get_tron_address();
        }

        // This is reader
        const TronWeb = await CutiesApiFaucet.getTronWeb();
        const client = new TronWeb(fullNode, solidityNode, eventServer);
        client.setAddress(acc);
        return client;
    }
}
