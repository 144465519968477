
    import { Component, Prop, Vue } from "vue-property-decorator";
    import type { PossessionIdentityItem } from "@/cuties/request/crafting/CraftingApiService";
    import ItemBlock from "@/components/ItemBlock.vue";
    import type { ItemInstanceModelBase } from "@/cuties/model/item/ItemInstanceModel";
    import VueUtils from "@/cuties/VueUtils";
    import ItemTooltipLazyLoad from "@/components/items/ItemTooltipLazyLoad.vue";
    import PossessionsLayer from "@/app/components/crafting/PossessionsLayer";
    import PossessionIdentityBlockCutieGeneral from "./PossessionIdentityBlockCutieGeneral.vue";
    import PossessionIdentityBlockDungeonWorldCountable from "@/app/components/identity/PossessionIdentityBlockDungeonWorldCountable.vue";
    import PossessionIdentityBlockSubscriptionMinutes from "@/app/components/identity/PossessionIdentityBlockSubscriptionMinutes.vue";
    import AppDialog from "@/components/app-components/AppDialog.vue";
    import PossessionIdentityDescription from "@/app/components/identity/PossessionIdentityDescription.vue";
    import type { PossessionIdentity } from "cuties-client-components/types/general/UnitIdentity";
    import DungeonsApiService from "@/app/cuties/dungeons/DungeonsApiService";
    import type { StageDefinition } from "@/app/cuties/dungeons/DungeonsApiService";

    @Component({
        name: "PossessionIdentityBlock",
        components: {
            PossessionIdentityDescription,
            AppDialog,
            ItemBlock,
            ItemTooltipLazyLoad,
            PossessionIdentityBlock,
            PossessionIdentityBlockCutieGeneral,
            PossessionIdentityBlockDungeonWorldCountable,
            PossessionIdentityBlockSubscriptionMinutes,
        }
    })
    export default class PossessionIdentityBlock extends Vue {
        @Prop({ required: true }) identity: PossessionIdentity;

        @Prop({ default: "craft-item" }) wrapperClass: string;
        @Prop({ default: null }) prefetchedQuantityLeft?: number | bigint;
        @Prop({ default: true }) showQuantity: boolean;
        @Prop({ default: 1 }) minMultiplier: number;
        @Prop({ default: 1 }) maxMultiplier: number;
        @Prop({ default: true }) showTooltip: boolean;
        @Prop({ default: null }) onClick?: () => void;

        private itemInstanceModel: ItemInstanceModelBase | null = null;
        private failedToFetchSchema = false;
        private descriptionDialogVisible = false;
        private stageDefenition: StageDefinition | null = null;

        readonly goToLink = VueUtils.goToLink;

        get titleText(): string | null {
            let effectiveIdentity = this.identity;
            if (this.itemInstanceModel) {
                const baseName = this.itemInstanceModel.schema.baseName
                    ?? this.itemInstanceModel.schema.info.type;
                effectiveIdentity = Object.assign({}, this.asItem, {
                    item: baseName,
                    personality: this.itemInstanceModel.schema.sellable ? "SELLABLE" : "PERSONAL",
                });
            }
            return PossessionsLayer.getTitleText(effectiveIdentity, this.$i18n);
        }

        get asItem(): PossessionIdentityItem | null {
            return PossessionsLayer.asItem(this.identity);
        }

        get quantityLabelText(): string | null {
            if (this.showQuantity) {
                if (!this.isRange()) {
                    const showQuantityLeft = !!this.prefetchedQuantityLeft;
                    if (this.getMin() === 1 && !showQuantityLeft) {
                        return null;
                    } else {
                        const quantityLeft = this.prefetchedQuantityLeft ?? "?";
                        return (showQuantityLeft ? this.formatCount(quantityLeft) + " / " : "") + this.formatCount(this.getMin());
                    }
                } else {
                    return this.formatCount(this.getMin()) +
                        " - " + this.formatCount(this.getMax());
                }
            }
            return null;
        }

        truncateAmount(fullNumber: string) {
            const truncated = fullNumber.slice(0, 6);
            return truncated !== fullNumber ? truncated + "…" : fullNumber;
        }

        get jsonStr(): string {
            return JSON.stringify(this.identity);
        }

        get isInvalid(): boolean {
            if ((this.prefetchedQuantityLeft ?? null) === null) return false;
            return BigInt(this.prefetchedQuantityLeft) - BigInt(this.identity.count ?? 1) < 0;
        }

        async mounted() {
            if (this.asItem) {
                PossessionsLayer.itemBaseModelByIdentity(this.asItem)
                    .then((model) => this.itemInstanceModel = model)
                    .catch(exc => {
                        console.error("Failed to load item model", exc);
                        this.failedToFetchSchema = true;
                    });
            } else if (this.identity.type === "clearedDungeonStage") {
                this.stageDefenition = await DungeonsApiService.getStageDefinition(this.identity.stageId);
            }
        }

        get dungeonWorldName(): string {
            return this.stageDefenition ? this.$t("dungeon_world_name_" + this.stageDefenition.worldId).toString() : "...";
        }

        /**
         * @param shownNumber = 125268658
         * @return            = '125kk'
         *
         * 12345 -> 12k
         * 2346 -> 2346
         */
        private formatBigNumber(shownNumber: number): string {
            return VueUtils.formatBigNumber(shownNumber);
        }

        private isRange(): boolean {
            return this.minMultiplier != this.maxMultiplier;
        }

        private getMin(): number {
            return this.minMultiplier * Number(this.identity.count ?? 1);
        }

        private getMax(): number {
            return this.maxMultiplier * Number(this.identity.count ?? 1);
        }

        private goTo(evt: Event, path: string) {
            // left-click should be handled by router to make SPA load
            // faster, but middle click should get to the normal <a/>
            evt.preventDefault();
            this.$router.push({ path: path });
            this.$emit("close");
            return false;
        }

        handleClick(event: MouseEvent) {
            if (this.onClick) {
                this.onClick();
            } else {
                event.preventDefault();
                event.stopPropagation();
                this.descriptionDialogVisible = true;
            }
        }

        close(): void {
            this.$emit("close");
        }

        private formatCount(count: number | string | bigint): string {
            return PossessionsLayer.formatDisplayAmount(Object.assign(
                {}, this.identity, { count: count }
            ));
        }
    }
