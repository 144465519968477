import type BlockchainReader from "@/cuties/blockchain/BlockchainReader";
import CutiesApiFaucet from "@/app/cuties/blockchain/CutiesApiFaucet";
import type { rpc } from "@cityofzion/neon-core";
import BigNumber from "bignumber.js";
import type { NeoConfig } from "@/cuties/blockchain/neo/NeoConfig";
import NeoContractApi from "@/cuties/blockchain/neo/NeoContractApi";
import { assert } from "@/app/cuties/utils/utils";
import NeoWalletCompanion from "@/cuties/blockchain/neo/NeoWalletCompanion";

export class NeoReader implements BlockchainReader {
    private readonly whenNeoRpc: Promise<rpc.RPCClient>;
    private contractApi: NeoContractApi | null = null;

    constructor(private readonly config: NeoConfig) {
        this.whenNeoRpc = NeoReader.getNeo3(config);
    }

    public static async getNeo3(config: NeoConfig): Promise<rpc.RPCClient> {
        const { rpc } = await CutiesApiFaucet.getNeonCore();
        return new rpc.RPCClient(config.gateway);
    }

    public async getBalances(address: string): Promise<{ neo: BigNumber; gas: BigNumber }> {
        const rpc = await this.whenNeoRpc;
        const { balance } = await rpc.getNep17Balances(address);

        const { CONST } = await CutiesApiFaucet.getNeonCore();
        const NEO_TOKEN_SCRIPT_HASH = "0x" + CONST.NATIVE_CONTRACT_HASH.NeoToken;
        const GAS_TOKEN_SCRIPT_HASH = "0x" + CONST.NATIVE_CONTRACT_HASH.GasToken;

        const neo = balance.find((a) => a.assethash == NEO_TOKEN_SCRIPT_HASH)?.amount ?? 0;
        const gas = balance.find((a) => a.assethash == GAS_TOKEN_SCRIPT_HASH)?.amount ?? 0;
        return {
            neo: new BigNumber(neo),
            gas: NeoWalletCompanion.toGasDecimal(new BigNumber(gas)),
        };
    }

    public async getBalance(address: string): Promise<BigNumber> {
        const balances = await this.getBalances(address);
        return balances.gas;
    }

    public async getBreedingFee(matronId: number, sireId: number): Promise<BigNumber> {
        const contractApi = await this.getContractApi();

        const { sc } = await CutiesApiFaucet.getNeonCore();
        const response = await contractApi.call({
            contractName: "core",
            methodName: "get_breeding_fee",
            methodArguments: [sc.ContractParam.integer(sireId), sc.ContractParam.integer(matronId)],
        });

        assert(response[0].type === "Integer", `Unexpected response [contract=core, method=get_breeding_fee, args=${matronId}, ${sireId}]`);

        return NeoWalletCompanion.toGasDecimal(new BigNumber(response[0].value as number));
    }

    public async getContractApi(): Promise<NeoContractApi> {
        const rpc = await this.whenNeoRpc;

        if (!this.contractApi) {
            this.contractApi = new NeoContractApi(rpc);
        }

        return this.contractApi;
    }
}
