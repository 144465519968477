
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Emit, Prop } from "vue-property-decorator";
    import VueUtils from "@/cuties/VueUtils";

    type ImageStatus = "LOADING" | "LOADED" | "ERROR";

    @Component
    export default class UserProfileIcon extends Vue {
        @Prop({ required: true }) userId!: number;
        @Prop({ required: true }) userName!: string;
        /** you can use this to force image update after featured cutie was changed */
        @Prop({ required: false, default: 0 }) updateVersion!: number;
        @Prop({ required: false }) onClick?: () => void;
        @Prop({ default: false }) animated: boolean;
        @Prop({ default: 432 }) height: number;

        imageStatus: ImageStatus = "LOADING";

        handleClick(event: MouseEvent) {
            if (this.onClick) {
                event.preventDefault();
                this.onClick();
            } else {
                VueUtils.goToLink(event);
            }
        }

        @Emit("click")
        click(event: MouseEvent) {
            return event;
        }
    }
