
    import Component from "vue-class-component";
    import { Dialog } from "element-ui";
    import Vue from "vue";

    /**
     * a wrapper that fixes the default behaviour of popup getting
     * closed on mouse release outside (very nagging when selecting text)
     */
    @Component({
        components: {},
        extends: Dialog /* @see https://element.eleme.io/#/en-US/component/dialog */,
    })
    export default class AppDialog extends Vue {
        closed: false;

        targetDown: string;
        targetUp: string;

        handleMouseDown(event: MouseEvent): void {
            this.targetDown = [...(event.target as Element).classList].join(",");
        }

        handleMouseUp(event: MouseEvent): void {
            this.targetUp = [...(event.target as Element).classList].join(",");
        }

        mounted(): void {
            this.$el.addEventListener("mousedown", this.handleMouseDown);
            this.$el.addEventListener("mouseup", this.handleMouseUp);
        }

        /*
         * Rewriting extended Dialog component method
         * to resolve issue when Dialog is closing unexpectedly
         * when you select text inside and release mouse outside
         * @see: node_modules/element-ui/lib/dialog.js
         * @see: https://image.liubing.me/2021/05/30/20813bc7e4aa5.gif
         * */
        handleWrapperClick(): void {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (!this.closeOnClickModal) return;

            //* added code -- start */
            if (this.targetDown !== this.targetUp) {
                return;
            }
            //* added code -- end */

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.handleClose();
        }

        destroyed(): void {
            this.$el.removeEventListener("mousedown", this.handleMouseDown);
            this.$el.removeEventListener("mouseup", this.handleMouseUp);
        }
    }
