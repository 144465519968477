import SaleEntryGroupModel from "@/cuties/model/sale/SaleEntryGroupModel";
import type { SaleModel as SaleModelData } from "@/app/cuties/presale/PresaleCutie";

export default class SaleModel {
    private readonly _groups: SaleEntryGroupModel[] = [];

    constructor(data: SaleModelData) {
        for (let i = 0; i < data.groups.length; i++) {
            this._groups.push(new SaleEntryGroupModel(data.groups[i]));
        }
    }

    get groups(): SaleEntryGroupModel[] {
        return this._groups;
    }
}
