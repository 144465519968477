
    import type { Player } from "@/cuties/model/player/Player";
    import { Component, Vue } from "vue-property-decorator";
    import HeaderMenu from "cuties-client-components/components/header/HeaderMenu.vue";
    import HeaderLangs from "@/app/components/header/HeaderLangs.vue";
    import HeaderPromoLine from "@/app/components/header/HeaderPromoLine.vue";
    import HeaderMobilePart from "@/app/components/header/HeaderMobilePart.vue";
    import HeaderProfileMenu from "@/app/components/header/HeaderProfileMenu.vue";
    import { Getter } from "vuex-class";
    import { HANDLER_MANAGER_SINGLETON } from "@/cuties/engine/HandlerManager";
    import { AppStoreGetters, AppStoreMutations } from "@/store/AppStore";
    import VueUtils from "@/cuties/VueUtils";
    import store from "@/store";
    import UserApiService from "@/cuties/player/UserApiService";
    import mad from "@/cuties/engine/mad";
    import socket from "@/socket";
    import type { HeaderProfileMenuEntry } from "@/app/cuties/header/HeaderProfileMenu";
    import NeoProviderBuilder from "@/cuties/blockchain/neo/NeoProviderBuilder";
    import UserProfileIcon from "@/app/components/player/UserProfileIcon.vue";
    import type { HeaderMenuEntry } from "cuties-client-components/types/api/Config";
    import { getAllBlockchains } from "@/cuties/model/pet/BlockchainId";
    import BlockchainFeatures, { Feature } from "@/cuties/blockchain/BlockchainFeatures";
    import type BlockchainService from "@/cuties/blockchain/BlockchainService";
    import CutiesApiFaucet from "@/app/cuties/blockchain/CutiesApiFaucet";
    import { ConfigInstance } from "@/cuties/engine/Config";
    import type PlayerBase from "@/cuties/model/player/PlayerBase";
    import { Dialog, Popover } from "element-ui";
    import ConfigApiService from "@/app/cuties/engine/ConfigApiService";
    import type { IntroStep } from "@/cuties/player/introduction/IntroductionSystem";
    import i18n from "@/i18n";

    const LoginManagerVue = () => import(/* webpackChunkName: "LoginManagerVue" */ "@/views/LoginManagerVue.vue");

    /** a recursive function that removes subentries that require login */
    function filterSubEntriesForGuest(subentries: HeaderMenuEntry[]): HeaderMenuEntry[] {
        return subentries
            .filter(e => !e.requiresLogin)
            .map(e => ({
                ...e,
                dropDownMenu: filterSubEntriesForGuest(e.dropDownMenu),
            }));
    }

    function addLangPrefix(subentries: HeaderMenuEntry[], lang: string): HeaderMenuEntry[] {
        return subentries.map(e => ({
            ...e,
            url: e.url.startsWith("https://") ? e.url : `${lang}/${e.url}`,
            dropDownMenu: addLangPrefix(e.dropDownMenu, lang),
        }));
    }

    @Component({
        components: {
            LoginManagerVue, UserProfileIcon, HeaderMenu,
            HeaderLangs, HeaderPromoLine, HeaderMobilePart,
            HeaderProfileMenu,
            "el-dialog": Dialog,
            "el-popover": Popover,
        },
    })
    export default class AppHeader extends Vue {
        @Getter("getIsLogined") isLogined: boolean | undefined;
        @Getter("getPlayer") player: PlayerBase | undefined;
        @Getter("getUrl") urlCurrent!: string;
        @Getter("getActiveIntroStep") activeIntroStep?: IntroStep | null;
        @Getter(AppStoreGetters.GET_HEADER_MENU_ENTRIES) headerMenu!: HeaderMenuEntry[];
        @Getter("getPlayer") user!: Player;

        readonly goToLink = VueUtils.goToLink;
        readonly isMobile = VueUtils.isMobile;

        showMobileMenu = false;
        showProfileMenu = false;
        loginFormVisible = false;
        langsDialogVisible = false;

        profileMenu: HeaderProfileMenuEntry[] = [
            {
                type: "internal",
                url: "offchain",
                key: "player_menu_depositTitle",
                icon: "blockchains",
            },
            {
                type: "internal",
                url: "settings",
                key: "Settings",
                icon: "settings",
            },
            {
                type: "internal",
                url: "blockchain_wallets",
                key: "My_wallet_link_blockchains",
                icon: "wallet",
            },
            {
                type: "donatePopup",
                url: "",
                key: "eth_donate",
                icon: "donate",
            },
        ];

        get playerId(): number {
            if (this.player) {
                return this.player.get_id();
            } else {
                return 0;
            }
        }

        get name(): string | undefined {
            return this.player?.get_name();
        }

        get pawCoins(): string | undefined {
            return this.player?.get_tickets().toString();
        }

        get lang(): string {
            return i18n.locale;
        }

        get showHeader(): boolean {
            return (
                this.urlCurrent !== "mascot" &&
                this.urlCurrent !== "presale" &&
                this.urlCurrent !== "presalestore" &&
                this.urlCurrent !== "heco"
            );
        }

        get headerMenuFiltered(): HeaderMenuEntry[] {
            let result = this.headerMenuFilteredWithoutLang;
            result = addLangPrefix(result, this.lang);
            return result;
        }

        // mobile header menu uses router-link - it will add lang
        get headerMenuFilteredWithoutLang() {
            let result: HeaderMenuEntry[];
            if (this.isLogined) {
                const isNewUser = this.user.getRegisteredForMs() < 7 * 24 * 60 * 60 * 100;
                result = this.headerMenu.map(e => {
                    if (e.name === "raidBoss" && isNewUser) {
                        // show worm boss to new players
                        return { ...e, dropDownMenu: [{
                            name: "boss_name_JuniorBoss1",
                            dropDownMenu: [],
                            requiresLogin: false,
                            url: "/raidlink/JuniorBoss1",
                        }, ...e.dropDownMenu] };
                    } else {
                        return e;
                    }
                });
            } else {
                result = filterSubEntriesForGuest(this.headerMenu);
            }
            return result;
        }

        created(): void {
            ConfigApiService.getHeaderMenu().then((headerMenu) => {
                store.commit(AppStoreMutations.SET_HEADER_MENU_ENTRIES, headerMenu);
            });
        }

        langLong(): string {
            return ConfigInstance.getLanguageNativeName(this.$i18n.locale);
        }

        openMenu(): void {
            this.showProfileMenu = !this.showProfileMenu;
        }

        async auth() {
            this.closeMobileMenu();
            this.loginFormVisible = true;
        }

        get allowedBlockchains() {
            return getAllBlockchains().filter((bc) => {
                return BlockchainFeatures.is_enabled(Feature.Login, bc);
            });
        }

        async loggedIn(wallet: BlockchainService) {
            this.loginFormVisible = false;
            if (this.$route.name === "login" || this.$route.name === "login-localized") {
                await this.$router.replace({ path: "/" + this.$i18n.locale + "/pets_my" }).catch(() => {});
            }
        }

        toggleMenu(): void {
            if (this.showMobileMenu) {
                this.closeMobileMenu();
            } else {
                this.openMobileMenu();
            }
        }

        closeMobileMenu(): void {
            this.showMobileMenu = false;
            document.body.classList.remove("bodyOverflow");
        }

        openMobileMenu(): void {
            this.showMobileMenu = true;
            document.body.classList.add("bodyOverflow");
        }

        openDonatePopup() {
            HANDLER_MANAGER_SINGLETON.run("donate_popup");
        }

        async logout() {
            await UserApiService.logout();
            mad.request.clear_auth_token();
            // after logout we need clean seasion if exists
            NeoProviderBuilder.sessionClear();

            if (CutiesApiFaucet.isEosWalletUnlocked()) {
                CutiesApiFaucet.getEos().then(wallet => wallet.logout());
            }

            this.closeMobileMenu();

            socket.disconnect();
            store.commit("setIsLogined", false);

            window.location.assign("/" + this.$i18n.locale + "/login");
        }

        get bus() {
            return this;
        }
    }
