import DataHelpers from "@/cuties/engine/DataHelpers";
import { parseUri } from "@/cuties/engine/parseuri";

/**
 * protocol://host:port/part1/part2/?key1=value1#anchor_part1/anchor_part2
 */
export default class LocationPath {
    static first_path_index:number = 0;


    static create(uri:any): LocationPath {
        if (uri instanceof LocationPath)
            return uri;

        if (uri == window.location)
            return new LocationPath(window.location.href);

        if (DataHelpers.isString(uri))
            return new LocationPath(uri);

        if (typeof uri == "undefined")
            return new LocationPath();

        DataHelpers.error("LocationPath.create: unsupported location type: " + uri);
        return new LocationPath();
    }

    private uri_str:string = null;
    private uri: ReturnType<typeof parseUri> | null = null;

    constructor(uri_str?:string) {
        this.uri_str = uri_str;
        if (!this.uri_str)
            return;

        try {
            this.uri = parseUri(uri_str);
        } catch (e) {
            DataHelpers.error("LocationPath.init: " + uri_str, e);
        }

        if (DataHelpers.isObject(this.uri)) {
            if (this.uri.directory) {
                this.uri.parts = this.uri.directory.split("/");
            }

            if (this.uri.anchor) {
                this.uri.parts_anchor = this.uri.anchor.split("/");
            }
        }

        //d(this.uri);
    }

    get_base(): string {
        return !this.uri ? "" : this.uri.protocol + "://" + this.uri.authority;
    }

    /**
     * Get relative URI
     */
    get_relative(): string {
        return !this.uri ? "" : this.uri.relative;
    }

    part(i:number, default_value?:any): any {
        if (!DataHelpers.isObject(this.uri))
            return default_value;

        if (!Array.isArray(this.uri.parts))
            return default_value;

        i += LocationPath.first_path_index;

        if (i < 0 || i >= this.uri.parts.length)
            return default_value;

        return this.uri.parts[i];
    }

    part_number(i:number, default_value:number = 0): number {
        return DataHelpers.toInt(this.part(i, default_value), default_value);
    }

    part_id(i:number, default_value?:any): any {
        return this.part(i, default_value);
    }

    arg(key:string, default_value?:any):any {
        if (!DataHelpers.isObject(this.uri))
            return default_value;

        if (!DataHelpers.isObject(this.uri.queryKey))
            return default_value;

        const value = this.uri.queryKey[key];
        if (typeof value == "undefined")
            return default_value;

        return value;
    }
}
