
    import Vue from "vue";
    import Component from "vue-class-component";
    import type { RegularLottery } from "@/cuties/lottery/LotteryService";
    import LotteryService from "@/cuties/lottery/LotteryService";
    import { Emit } from "vue-property-decorator";
    import Lottery from "@/components/lottery/Lottery.vue";
    @Component({
        components: { Lottery }
    })
    export default class RegularLotteryTabs extends Vue {
        definitions: null | RegularLottery[] = null;
        focusedTab: string | null = null;

        async created() {
            this.definitions = await LotteryService.getDefinitions();
            this.focusedTab = this.definitions.map(d => d.id)[0];
        }

        switchToNextTabIfAny() {
            const definitions = this.definitions ?? [];
            const previousIndex = definitions.findIndex(d => d.id === this.focusedTab);
            if (previousIndex >= 0 && previousIndex < definitions.length - 1) {
                this.focusedTab = definitions[previousIndex + 1].id;
            }
        }

        @Emit("close")
        close() {}
    }
