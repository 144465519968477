import DataHelpers from "@/cuties/engine/DataHelpers";

type Handler = Function;

/**
 * HandlerManager stores set of functions (handlers), available by their id.
 * Handlers are bound to DOM elements via special html attributes:
 * 1. data-click="handler_id"
 *                 =>    function() { on click handler, "this" equals to the DOM element, which triggered click event }
 * 2. href="..." data-response="handler_id"
 *                 =>    function(response) { custom handler for response, "this" equals to the DOM element, which triggered click event }
 */
export interface Interface
{

    /**
     * register handlers
     * @param {object} handlers - map {handler_id1:handler1, ...}
     */
    register_batch(handlers:object): void;

    /**
     * register a handler
     * @param {string} handler_id - handler id
     * @param {function} handler - handler function
     */
    register(handler_id:string, handler:Handler): void;

    get(handler_id:string): Handler;

    exist(handler_id:string):boolean;

    /**
     * run handler (if no such handler, log error)
     * @param {string} handler_id - handler id
     * @param {any} context - (optional) context of the function call ('this' in the function body), - for example, DOM element
     * @param {array} args_array - (optional) array of arguments
     */
    run(handler_id:string, context?:any, args_array?:any[]): any;

    /**
     * run handler, if such exists
     * @param {string} handler_id - handler id
     * @param {any} context - (optional) context of the function call ('this' in the function body), - for example, DOM element
     * @param {array} args_array - (optional) array of arguments
     */
    run_if_exist(handler_id:string, context?:any, args_array?:any[]): any;

    /**
     * register default handler for clicking/submiting in templates
     * @param {function} default_handler - default handler
     */
    register_default_handler(default_handler:Handler): void;

    get_default_handler(): Handler;

    get_log(): any;
}






/**
 * HandlerManager stores set of functions (handlers), available by their id.
 * Handlers are bound to DOM elements via special html attributes:
 * 1. data-click="handler_id"
 *                 =>    function() { on click handler, "this" equals to the DOM element, which triggered click event }
 * 2. href="..." data-response="handler_id"
 *                 =>    function(response) { custom handler for response, "this" equals to the DOM element, which triggered click event }
 */
export class HandlerManager implements Interface {

    private default_handler: Handler;

    private handlers: object;

    constructor() {
        this.handlers = {};
    };

    /**
     * register handlers
     * @param {object} handlers - map {handler_id1:handler1, ...}
     */
    register_batch(handlers:object): void {
        for (const handler_id in handlers) {
            const handler = handlers[handler_id];
            this.register(handler_id, handler);
        }
    }

    /**
     * register a handler
     * @param {string} handler_id - handler id
     * @param {function} handler - handler function
     */
    register(handler_id:string, handler:Handler): void {
        if (this.handlers[handler_id])
            console.warn("HandlerManager: rewriting handler " + handler_id);

        this.handlers[handler_id] = handler;
    }

    get(handler_id:string): Handler {
        return this.handlers[handler_id];
    }

    exist(handler_id:string):boolean {
        return DataHelpers.isFunction(this.handlers[handler_id]);
    }

    /**
     * run handler (if no such handler, log error)
     * @param {string} handler_id - handler id
     * @param {any} context - (optional) context of the function call ('this' in the function body), - for example, DOM element
     * @param {array} args_array - (optional) array of arguments
     */
    run(handler_id:string, context?:any, args_array?:any[]): any {
        const handler = this.handlers[handler_id];
        if (!handler) {
            DataHelpers.error("HandlerManager.handle: unknown handler " + handler_id);
            return;
        }

        return handler.apply(context, args_array);
    }

    /**
     * run handler, if such exists
     * @param {string} handler_id - handler id
     * @param {any} context - (optional) context of the function call ('this' in the function body), - for example, DOM element
     * @param {array} args_array - (optional) array of arguments
     */
    run_if_exist(handler_id:string, context?:any, args_array?:any[]): any {
        const handler = this.handlers[handler_id];
        if (!handler) {
            console.log(handler + " - handler do not exists");
            return;
        }

        return handler.apply(context, args_array);
    }

    /**
     * register default handler for clicking/submiting in templates
     * @param {function} default_handler - default handler
     */
    register_default_handler(default_handler:Handler): void {
        this.default_handler = default_handler;
    }

    get_default_handler(): Handler {
        return this.default_handler;
    }

    get_log(): any {
        return this.handlers;
    }
}

/** used to define named callbacks for buttons in our legacy templater */
export const HANDLER_MANAGER_SINGLETON = new HandlerManager();
