
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";
    import type ItemEffectModel from "@/cuties/model/item/ItemEffectModel";
    import type { ItemEffect, ItemEffectBackgroundChangeColor } from "@/cuties/model/item/ItemEffectModel";
    import { EffectGoodness } from "@/cuties/model/StatValueModel";
    import { isPercentStat } from "@/cuties/model/adventure/BonusUseInfo";

    const EFFECT_ICON_NAMES: Partial<Record<ItemEffect["type"], string>> = {
        ItemEffectAdventureCooldownChange: "adv-cld-change",
        ItemEffectAdventureCooldownReset: "adv-cld-reset",
        ItemEffectAdventureCooldownRemainingSecondsChange: "adv-cld-reset",
        ItemEffectOccupationCooldownReset: "adv-cld-reset",
        ItemEffectBackgroundChangeColor: "bck-col",
        ItemEffectBackgroundPatternChange: "bck-col-royal",
        ItemEffectDynamicPatternChange: "bck-col-royal",
        ItemEffectBreedingCooldownChange: "bred-cld-change",
        ItemEffectBreedingCooldownReset: "bred-cld-reset",
        ItemEffectCannotUse: "unusable",
        ItemEffectClothes: "clothes",
        ItemEffectGenerationChange: "gen-change",
        ItemEffectEquipmentRemover: "item-remover",
        ItemEffectGrantNobility: "grant-nobility",
        UserItemEffectAddCurrentEnergy: "energy-cur-change",
        UserItemEffectEnergyMaxChange: "energy-max-change",
        ItemEffectTemporarelyCountBonusKind: "stats-boost",
        ItemEffectExperience: "stats-boost",
    };

    @Component
    export default class ItemTooltipEffectRow extends Vue {
        @Prop({ required: true }) effect!: ItemEffectModel;

        getEffectIconPath(effect: ItemEffectModel): string {
            const name = EFFECT_ICON_NAMES[effect.source.type] ?? "missing";
            return "/static/items/effects/icon-effect-" + name + ".png";
        }

        getBgClasses(source: ItemEffectBackgroundChangeColor): string[] {
            const bgClasses = [];
            for (let i = source.minIndex; i <= source.maxIndex; ++i) {
                bgClasses.push(source.colorPrefix + String(i).padStart(2, "0"));
            }
            return bgClasses;
        }

        get goodnessClass() {
            if (this.effect.source.type === "ItemEffectTemporarelyCountBonusKind") {
                if (this.effect.source.bonusAmount > 0) {
                    return "val_inc";
                } else if (this.effect.source.bonusAmount < 0) {
                    return "val_dec";
                }
            }
            if (!this.effect.value) {
                return "";
            }

            return this.effect.value.goodness == EffectGoodness.POSITIVE ? "val_inc"
                : this.effect.value.goodness == EffectGoodness.NEGATIVE ? "val_dec"
                    : "";
        }

        get valueString() {
            if (this.effect.source.type === "ItemEffectTemporarelyCountBonusKind") {
                if (this.effect.source.immunityKind) {
                    return this.effect.source.bonusAmount * 100 + "%";
                } else if (this.effect.source.bonusKind === "maxAdventureCooldown") {
                    return this.$t("adventure_cooldown" + this.effect.source.bonusAmount);
                } else {
                    const sign = this.effect.source.bonusAmount >= 0 ? "+" : "";
                    const amount = isPercentStat(this.effect.source.bonusKind)
                        ? this.effect.source.bonusAmount * 100 + "%"
                        : this.effect.source.bonusAmount;
                    return sign + amount;
                }
            }

            if (this.effect.source.type === "ItemEffectExperience") {
                const sign = this.effect.source.quantity >= 0 ? "+" : "";
                return sign + this.effect.source.quantity;
            }

            return this.effect.value?.valueString ?? "";
        }

        get name() {
            const { source } = this.effect;
            if (this.effect.source.type === "ItemEffectTemporarelyCountBonusKind") {
                const bonusName = this.effect.source.immunityKind
                    ? this.$t("stat_tr_immunity_title") + ": " + this.$t("cutie_occupation_cooldown_title_" + this.effect.source.immunityKind)
                    : this.$t("stat_name_" + this.effect.source.bonusKind);
                return bonusName + " " + this.$t("for_n_battles", [this.effect.source.battleCount]);
            } else if (this.effect.source.type === "ItemEffectOccupationCooldownReset") {
                return this.$t("cutie_occupation_cooldown_title_" + this.effect.source.cooldownName) + " " + this.$t("occupation_cooldown_reset_name_postfix");
            } else if (source.type === "UserItemEffectAddCurrentEnergy") {
                return this.$t("energy_name_" + source.energyName) + " " + this.$t("add_current_energy_effect_charges_restored");
            } else if (source.type === "UserItemEffectEnergyMaxChange") {
                return this.$t("energy_name_" + source.energyName) + " " + this.$t("add_current_energy_effect_capacity_increased");
            } else {
                return this.$t("item_effect_type_name_" + this.effect.source.type);
            }
        }
    }
