
    import Component from "vue-class-component";
    import Vue from "vue";
    import { Prop } from "vue-property-decorator";
    import type { ItemStatsModel, StatTable } from "@/cuties/model/item/ItemInstanceModel";
    import FormatStat from "@/app/components/stat/FormatStat.vue";
    import CodeUtils from "@/cuties/CodeUtils";
    import type { BonusKind } from "cuties-client-components/types/api/Cutie";

    @Component({
        components: { FormatStat }
    })
    export default class ItemTooltipStatsTable extends Vue {
        @Prop({ required: true }) statsTable!: StatTable;
        @Prop({ required: true }) statsAll!: Record<BonusKind, ItemStatsModel>;

        get statsRows(): BonusKind[] {
            let res = this.statsTable.rows;

            if (this.statsTable.rows.includes("minDefence") && !this.statsTable.rows.includes("defence")) {
                res = ["defence", ...res];
            }

            if (this.statsTable.rows.includes("minAttack") && !this.statsTable.rows.includes("attack")) {
                res = ["attack", ...res];
            }

            return res;
        }

        locationEventBuffIcon(modifierTag: string | null): string {
            if (modifierTag === "Farming") {
                return "/static/icons/wreath.png";
            } else {
                return CodeUtils.eventLocationIconPath;
            }
        }

        getCallStatSetValue(statKind: BonusKind, key: string, defaultValue = "-"): string {
            // if (["minAttack", "minDefence"].includes(statKind)) {
            //     return this.item.statsAll[statKind]?.sets?.[key]?.valueString ?? "+0";
            // }
            return this.statsAll[statKind]?.sets?.[key]?.valueString ?? defaultValue;
        }

        getCallStatBaseValue(statKind: BonusKind, defaultValue = "-"): string {
            return this.statsAll[statKind]?.base?.valueString ?? defaultValue;
        }

        getCallStatBuffValue(statKind: BonusKind, defaultValue = "-"): string {
            return this.statsAll[statKind]?.buff?.valueString ?? defaultValue;
        }
    }
