
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop, PropSync } from "vue-property-decorator";
    import type { PetKind } from "@/cuties/model/pet/PetModel";

    @Component
    export default class CutieRaceSelect extends Vue {
        @Prop({ required: true }) options!: (keyof typeof PetKind)[];
        @PropSync("value") syncedValue!: (keyof typeof PetKind) | null;

        get kinds() {
            return this.syncedValue ? [this.syncedValue] : [];
        }

        set kinds(input: (keyof typeof PetKind)[]) {
            this.syncedValue = input.slice(-1)[0] || null;
        }
    }
