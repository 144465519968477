import Vue from "vue";
import i18n from "@/i18n";
import router from "@/router";
import store from "@/store";
import type { Filter } from "@/Filter";
import mad from "./engine/mad";
import utils from "./utils";
import type { AxiosError } from "axios";
import { isAmountValid, isNumberValid } from "@/common/formValidator";
import { BigNumber } from "bignumber.js";
import type { Blockchain } from "./model/pet/BlockchainId";
import TranslatedClientError from "@/app/cuties/utils/TranslatedClientError";
import { throws } from "@/app/cuties/utils/utils";
import type { AxiosLikeError } from "@/http";
import type { Component } from "vue/types/options";
import type { AsyncComponent } from "vue/types/options";
import { AppStoreSingleton } from "@/store/AppStore";

type PopupDefaultOkCallback<Tresult> = (result: Tresult, ...args: any[]) => void | Promise<void>;

export interface PopupProps<Tresult, OkCallback = PopupDefaultOkCallback<Tresult>> {
    okCallback: OkCallback;
    cancelCallback?: (exc?: Error | unknown) => void | Promise<void>;
}

export type ErrorType =
    | "errorGameSessionUnknown"
    | "errorUnknownError"
    | "ERR_CUTIE_LIKED_ALREADY"
    | "errorGameAccountAlreadyExist"
    | "ERR_API_SERVER_IS_DOWN"
    | "errorUnconfirmedMail"
    | "errorEnergyNotEnough"
    | "ERR_NO_MORE_CUTIES_IN_THAT_DIRECTION"
    | "ERR_PLAYER_LACKS_REQUIRED_POSSESSIONS"
    | "ERR_NOT_ENOUGH_UNITS_LEFT_IN_STOCK"
    | "ERR_DEFINITION_NOT_FOUND"
    | "ERR_PAW_SHOP_LIMIT"
    | "ERR_CLEAR_TIME_LIMIT_REACHED"
    | "errorAuctionNotFound"
    | string;

/** @see com.madsword.cat.core.response.Response */
export type ServerErrorResponse = {
    error: ErrorType;
    tplVars: string[];
    message?: string;
};

/**
 * to not show "API server is down" error more often than once in N seconds, as it's
 * same message for every asynchronous API call, so it verbosely duplicates many times
 */
let lastServerIsDownErrorTime = new Date(0).getTime();

function shouldIgnoreError(error: AxiosError | Error | any): boolean {
    const errorData = VueUtils.extractErrorData(error);
    if (errorData?.error === "ERR_API_SERVER_IS_DOWN") {
        const now = Date.now();
        if (now - lastServerIsDownErrorTime < 1000) {
            return true;
        } else {
            lastServerIsDownErrorTime = now;
        }
    }
    return false;
}

/**
 * many libraries throw objects that do not extend Error, this function attempts
 * to extract the message from any kind of error object using popular conventions
 * like having `toString()` implementation or `message` property
 */
export function stringifyPlainObjectError(error: unknown): string {
    if (!error) {
        return "(empty error)";
    } else if (error + "" !== "[object Object]") {
        return error + "";
    } else if (error && typeof error === "object"
            && "message" in error
            && typeof (error as { message: unknown }).message === "string"
    ) {
        return (error as { message: string }).message;
    } else if (error instanceof Event) {
        return "Cancelled by user";
    } else {
        return "Unknown format error: " + JSON.stringify(error);
    }
}

export default class VueUtils {

    static tooltipOpenDelay = 500;
    static tooltipCloseDelay = 20;

    static vueComponent(
        component: Component | AsyncComponent | (() => Component),
        mount: Element | string,
        props: Record<string, any>
    ): Vue {
        return new Vue({
            i18n,
            router,
            store,
            render: (h) =>
                h(component, {
                    props: props,
                }),
        }).$mount(mount);
    }

    static isMobile(): boolean {
        return AppStoreSingleton.state.isMobile;
    }

    /**
     * @deprecated - pls try to use mountPopup() instead, as it does
     *     not depend on the legacy templater unlike this function
     *
     * this should only be used in legacy templates: on Vue-powered
     * pages <el-dialog/> is the proper way to display popups
     */
    static vuePopupCustomId<Tresult>(
        popupId: string,
        component: (typeof Vue) & { new(): PopupProps<Tresult> } | AsyncComponent,
        props: Record<string, any> = {}
    ): Promise<Tresult> {
        return new Promise<Tresult>((resolve, reject) => {
            mad.popup.show(popupId, null, false, () => {
                const propsWithCallbacks: PopupProps<Tresult> = {
                    ...props,
                    okCallback: (result) => {
                        mad.popup.hide();
                        resolve(result);
                    },
                    cancelCallback: exc => {
                        mad.popup.hide();
                        if (exc instanceof Error) {
                            reject(exc);
                        } else if (typeof exc === "string") {
                            reject(new Error(exc));
                        } else {
                            // usually the argument passed to `cancelCallback` is a
                            // PointerEvent as it's passed as the callback for the "Close" button
                            reject(new TranslatedClientError("ERR_ACTION_WAS_CANCELLED_BY_USER"));
                        }
                    },
                };
                const cmp = VueUtils.vueComponent(
                    component, "#" + popupId, propsWithCallbacks
                );
            });
        });
    }

    /**
     * NOTE: the mounted component must us el-dialog, otherwise it won't be visible!
     *
     * Mount independent Vue popup instance into 'popup-container' tag.
     * Component will be destroyed from DOM and memory on promise resolving or rejection.
     * @param component vue component to mount
     * @param props vue component props
     */
    static mountPopup<Tresult, OkCallback = PopupDefaultOkCallback<Tresult>>(
        component: typeof Vue & { new (): PopupProps<Tresult, OkCallback> },
        props: Record<string, unknown> = {}
    ): Promise<Tresult> {
        const container = document.getElementById("popup-container") ?? throws("Cannot mount confirmation popup");
        let popup: Vue | null = null;

        const destroy = () => {
            if (popup) {
                popup.$destroy();
                container.removeChild(popup.$el);
            }
        };

        return new Promise<Tresult>((resolve, reject) => {
            const propsWithCallbacks: PopupProps<Tresult> = {
                ...props,
                okCallback: (result) => {
                    resolve(result);
                    destroy();
                },
                cancelCallback: (exc) => {
                    if (exc instanceof Error) {
                        reject(exc);
                    } else if (typeof exc === "string") {
                        reject(new Error(exc));
                    } else {
                        // usually the argument passed to `cancelCallback` is a
                        // PointerEvent as it's passed as the callback for the "Close" button
                        reject(new TranslatedClientError("ERR_ACTION_WAS_CANCELLED_BY_USER"));
                    }
                    destroy();
                },
            };

            popup = VueUtils.vueComponent(component, "", propsWithCallbacks);
            container.appendChild(popup.$el);
        });
    }

    static parseQueryParam(param: string | string[]) {
        if (Array.isArray(param)) {
            return param;
        } else {
            return [param];
        }
    }

    /**
    * @param shownNumber = 125268658
    * @return            = '125kk'
    *
    * 12345 -> 12k
    * 2346 -> 2346
    */
    static formatBigNumber(shownNumber: number): string {
        if (Math.log10(shownNumber) < 5) {
            return shownNumber + "";
        } else {
            let shortened = "";
            while (Math.log10(shownNumber) >= 3) {
                shortened += "K";
                shownNumber /= Math.floor(Math.pow(10, 3));
            }
            return Math.floor(shownNumber) + shortened;
        }
    }

    static toggleFilter(val: string[]) {
        if (val.length) {
            return [val[val.length - 1]];
        } else {
            return [];
        }
    }

    static toggleFilterString<T extends string>(val: T[]): T | null {
        if (val.length) {
            return val[val.length - 1];
        } else {
            return null;
        }
    }

    static petsGenomeWithoutShadow(): string[] {
        return [
            "00001106047225010010013d0a303271214020000c80801054132022300c9da8", // space pet
            "0004110004a5060000b005135212190563450010fb90952214a343212201111d" // wizard pet
        ];
    }


    static cutieName(name: string, uid: string):string {
        return name ? name : "Cutie " + uid;
    }


    static findSearchKindFilter(optionList : Filter<string>, kind: string) {
        let key = "";
        for (let index = 0; index < optionList .values.length; index++) {
            if (optionList .values[index].value === kind) {
                key = optionList .values[index].name;
                break;
            }
        }

        return key;
    }

    static timeStampSeconds() {
        return Math.ceil(new Date().getTime() / 1000);
    }

    static fixSearchClearFocus(object: any) {
        // bug on component - this is temp solution - https://github.com/ElemeFE/element/issues/17568
        object.$children
            .find((c: Vue) => c.$el.className.includes("el-input"))
            .blur();

        return object;
    }

    static itemRarityClass(rarity: string): string {
        return "item-rarity item-rarity--" + rarity;
    }

    // format method for countdown component
    static countdownFormatTime(props: CountdownProps, days = false) {

        if (!days) {
            props["hours"] = props["hours"] + props["days"] * 24;
        }

        Object.entries(props).forEach(([key, value]) => {
            // Adds leading zero
            const digits = value < 10 ? `0${value}` : value;
            props[key] = `${digits}`;
        });

        return props;
    }

    static handleRequestError(exc: Error | any): void {
        const error = this.extractError(exc);
        utils.show_notification(error);
        throw exc;
    }

    /**
     * be careful, now that this function uses `this`, it can not be used as
     * `.catch(VueUtils.handleError)`, must use
     * `.catch((exc) => VueUtils.handleError(exc))`
     */
    static handleError(exc: Error | any): void {
        if (shouldIgnoreError(exc)) {
            return;
        }
        console.error("ERROR:", exc);
        const error = this.extractError(exc);
        utils.show_notification(error, { is_error_message: true });
    }

    /**
     * @param exc - an axios exception you normally receive
     *     when server responds with an error status code
     * @return - an error codename if present in the
     *     response, it's often a string starting with "ERR_"
     * @see ResultType.java
     * @see BCU_lang_error in translations spreadsheet
     */
    static extractErrorData(exc: AxiosError | AxiosLikeError | TranslatedClientError | string | null | undefined | { isAxiosError: never; isAxiosLikeError: never }): ServerErrorResponse | undefined {
        if (!exc) {
            return undefined;
        }
        if (typeof exc === "string") {
            // some of our older APIs return error code name as exception
            return this.getErrorTranslation(exc, [])
                ? { error: exc, tplVars: [] } : undefined;
        }
        if (exc instanceof TranslatedClientError) {
            return {
                error: exc.translationKey,
                tplVars: exc.tplVars,
            };
        }
        if (!exc.isAxiosError && !("isAxiosLikeError" in exc && exc.isAxiosLikeError)) {
            return undefined;
        }
        let { error, message = undefined, tplVars = [] } = exc.response?.data || exc?.message || {};
        if (error) {
            message = message !== "No message available" ? message : undefined;
            return { error, message, tplVars };
        } else if (exc.response?.headers["content-type"] !== "application/json" &&
            (exc.response?.data + "").includes("ECONNREFUSED")
        ) {
            return { error: "ERR_API_SERVER_IS_DOWN", tplVars: [] };
        } else {
            return undefined;
        }
    }

    public static getErrorTranslation(error: string, tplVars: string[] = []) {
        // some APIs do add "error" prefix to ResultType, while some do not, and ResultType-s that start
        // with "ERR_" do not require the "error" prefix. It's really simple once you get to know it ^_^
        if (i18n.te(error)) {
            // new standardized ResultType-s with explicit ERR_ prefix
            return i18n.t(error, tplVars).toString();
        } else if (i18n.te("error" + error)) {
            // older ResultType-s that had "error" prefix automatically added to them
            return i18n.t("error" + error, tplVars).toString();
        } else {
            return null;
        }
    }

    static translateError(error: ErrorType | string, tplVars: string[] = []) {
        const translation = this.getErrorTranslation(error, tplVars);
        if (translation) {
            return translation;
        } else if (error === "ERR_API_SERVER_IS_DOWN") {
            // may happen if translations are not loaded yet: when you only open the page during a downtime
            return "API server is down. Please, wait for a few minutes, then try again.";
        } else {
            // likely a Spring-handled exception with human-readable "error" field
            return error;
        }
    }

    static extractError(exc: Error | any): string {
        const errorData = this.extractErrorData(exc);

        if (!errorData) {
            return typeof exc === "string"
                ? this.translateError(exc, [])
                : stringifyPlainObjectError(exc);
        }

        // some external resources may respond with similar structure as we do from api (e.g. tron node)
        // we should handle it otherwise we will show [Object object] in notification
        if (typeof errorData.error === "object") {
            return stringifyPlainObjectError(exc);
        }

        const { error, message, tplVars } = errorData;
        return this.translateError(error, tplVars) + (message ? " - " + message : "");
    }

    /** transforms supplied link to a path if the domain BCU */
    static asLocalLink(link: string | null | undefined): string | null {
        if (!link) {
            return null;
        } else if (link.startsWith("/")) {
            return link;
        } else if (link.startsWith("https://blockchaincuties.com/")) {
            const url = new URL(link);
            return url.pathname + url.search + url.hash;
        } else {
            return null;
        }
    }

    static goToLink(event: MouseEvent) {
        event.preventDefault();
        const anchor = event.currentTarget as HTMLAnchorElement;
        const link = anchor.getAttribute("href");
        // may be undefined for convenience
        if (link) {
            if (link.startsWith("/")) {
                router.push({ path: link });
            } else {
                window.open(link, "_blank")!.focus();
            }
        }
    }

    static cutieSortOptions(additional: string[] = []): string[] {
        return [
            ...additional,
            "Age",
            "Gen",
            "Like",
            "Exp",
            "Latest",
            "BreedingCooldown",
            "AdventureCooldown",
            "Air",
            "Fire",
            "Water",
            "Earth",
            "Energy",
        ];
    }
}

export function globalVueErrorHandler(
    err: AxiosError | AxiosLikeError | Error & { isAxiosError: never; isAxiosLikeError: never },
    vm: Vue | undefined,
    info: string
): void {
    if (shouldIgnoreError(err)) {
        return;
    }
    const componentSuffix = vm?.$options.name
        ? " (at " + vm.$options.name + ")"
        : "";
    console.error("Got an error in Vue" + componentSuffix, { vm, info });
    console.error(err);

    const errorData = VueUtils.extractErrorData(err);
    if (errorData && errorData.error !== "errorUnknownError") {
        const { error, message, tplVars } = errorData;
        const translation = VueUtils.getErrorTranslation(error, tplVars);
        if (translation) {
            // expected error, no need to show component name
            utils.show_notification(translation);
            return;
        }
    }
    const msg = `${VueUtils.extractError(err) + componentSuffix}`;
    utils.show_notification(msg);
}

export const globalJsErrorHandler = (event: ErrorEvent): void => {
    const { message, filename, lineno, colno, error } = event;
    // Reason to ignore this error: https://stackoverflow.com/a/50387233
    if (message !== "ResizeObserver loop limit exceeded" && message !== "ResizeObserver loop completed with undelivered notifications.") {
        console.error("global js error", { message, filename, lineno, colno, error });
        const formattedMessage = message + " at " + filename.replace(/.*\//, "");
        utils.show_notification(formattedMessage);
    }
};

export function globalUnhandledRejectionHandler(event: PromiseRejectionEvent): void {
    event.preventDefault();
    // hopefully that will make sure event won't implicitly
    // come to sentry despite of us already handling it here
    event.stopImmediatePropagation();
    const { promise, reason } = event;
    console.info("Got an unhandled promise rejection somewhere in code", reason);
    let message;
    if (reason?.constructor?.name === "CloseEvent") {
        // seems to be fired somewhere inside axios when server can't return config... or is it jQuery?
        message = reason.reason;
    } else if (reason?.stack?.includes("@scatterjs/core/dist/wallets/RelaySocket") || // locally
        reason?.stack?.includes("/js/eos-chunk.") // on pelipo/prod
    ) {
        // scatterjs guys leave hanging promises inside their lib =/
        console.warn("Could not connect to any EOS relay");
        console.warn(reason);
        return;
    } else if (reason?.message?.includes("Avoided redundant navigation to current location")) {
        // see https://stackoverflow.com/a/62465003/2750743
        // it gets reported if requested page was opened at least once
        // since last refresh, not just if it's same page we are currently on,
        // it possibly happens this way because of our legacy templater hacks
        console.warn(reason);
        return;
    } else {
        if (shouldIgnoreError(event.reason)) {
            return;
        }
        message = VueUtils.extractError(event.reason) + " (UPR)";
    }
    console.error(reason);
    utils.show_notification(message);
}

// VueCoountown prop typings
export interface CountdownProps {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    milliseconds: number;
    totalDays: number;
    totalHours: number;
    totalMinutes: number;
    totalSeconds: number;
    totalMilliseconds: number;
}

export function getDecimalsError(amount: string, blockchain: Blockchain, currencyName: string): string | undefined {
    if (!(isNumberValid(amount) && isAmountValid(new BigNumber(amount).toString(), blockchain))) {
        return i18n.t("errorTooManyDecimals", {
            0: currencyName,
            1: amount,
            2: mad.getWalletCompanion(blockchain).unit_factor,
        }).toString();
    } else {
        return undefined;
    }
}

export function translateBlockchain(blockchain: Blockchain): string {
    if (i18n.te("blockchain_" + blockchain)) {
        return i18n.t("blockchain_" + blockchain) + "";
    } else {
        return blockchain;
    }
}

export function makeCountdownProps(totalMs: number) {
    const totalSeconds = Math.floor(totalMs / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const totalDays = Math.floor(totalHours / 24);
    const result = {
        days: totalDays,
        hours: formatWithLeadingZero(totalHours % 24),
        minutes: formatWithLeadingZero(totalMinutes % 60),
        seconds: formatWithLeadingZero(totalSeconds % 60),
    };
    return result;
}

export function formatWithLeadingZero(value: number) {
    return value < 10 ? "0" + value : value;
}
