import { BlockchainServiceCompanion } from "@/cuties/blockchain/BlockchainService";

export default class TronWalletCompanion extends BlockchainServiceCompanion {
    public readonly unit_factor = 6;

    public readonly icon16Class = "tron_icon_16";
    public readonly icon28Class = "tron_icon_28";

    public readonly auctionStartStep = "10";
    public readonly auctionStartMin = "0";
    public readonly auctionStartDefault = "100";

    public readonly auctionEndStep = "10";
    public readonly auctionEndMin = "0";
    public readonly auctionEndDefault = "10";

    isAddressSame(firstAddress: string, secondAddress: string): boolean {
        return firstAddress === secondAddress;
    }
}
