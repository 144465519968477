import DataHelpers from "@/cuties/engine/DataHelpers";
import SecurityToken from "./SecurityToken";
import i18n from "@/i18n";
import { HANDLER_MANAGER_SINGLETON } from "@/cuties/engine/HandlerManager";
import Cookies from "js-cookie";


/**
 * RequestManager knows how to send get/post requests to server.
 * Also RequestManager knows all request classes (subclasses of Request).
 * Requests are bound to DOM elements via special html attribute data-request="request_id" (it can be a form or a link or anything else).
 * You can manually create instance of request via RequestManager.create_request(request_id).
 *
 * When response is received, runs handler 'on_request_end'.
 */
export interface Interface
{

    init(): void;

    clear_auth_token(): void;

    /**
     * set prefix for all URL (is used when sending AJAX-requests)
     * @param {string} server_base_url_json - a real prefix for URL
     * @param {string} server_base_url_user - a prefix for browser's history - what user will see
     */
    set_server_base_url(server_base_url_json, server_base_url_user);

    get_server_base_url_json():string;

    get_server_base_url_user():string;

    set_mock(url:string, value:any):void;

    /**
     * make AJAX-GET request
     * @param {string} url - URL
     * @param {function} on_success_func - (optional) function(url, data, response) {}
     * @param {function} on_error_func - (optional) function(url, data, response) {}
     * @param {any} context - (optional) any context for calling functions on_success_func and on_error_func (it can be instance of Request)
     * @param {any} handler_prefix - (optional) handler prefix for begin/end request
     */
    ajax_get(
        url:string,
        on_success_func?:(url, data, response, textStatus, jqXHR) => void,
        on_error_func?:(url, data, response, textStatus, jqXHR) => void,
        context?:any,
        handler_prefix?:string
    );

    /**
     * make AJAX-POST request
     * @param {string} url - URL
     * @param {object} data - map of POST-values {key:value, ...}
     * @param {function} on_success_func - (optional) function(url, data, response) {}
     * @param {function} on_error_func - (optional) function(url, data, response) {}
     * @param {any} context - (optional) any context for calling functions on_success_func and on_error_func (it can be instance of Request)
     * @param {any} handler_prefix - (optional) handler prefix for begin/end request
     */
    ajax_post(url:string,
        data: { [key:string]:any },
        on_success_func?:(url, data, response, textStatus, jqXHR) => void,
        on_error_func?:(url, data, response, textStatus, jqXHR) => void,
        context?:any,
        handler_prefix?:string
    );

    ajax_post_async(
        url:string,
        data: { [key:string]:any },
        on_success_func?:(url, data, response, textStatus, jqXHR) => void,
        on_error_func?:(url, data, response, textStatus, jqXHR) => void,
        context?:any,
        handler_prefix?:string
    );

    ajax_get_async(
        url:string,
        data: { [key:string]:any },
        on_success_func?:(url, data, response, textStatus, jqXHR) => void,
        on_error_func?:(url, data, response, textStatus, jqXHR) => void,
        context?:any,
        handler_prefix?:string
    );

    get_log(): any;
}

/**
 * RequestManager knows how to send get/post requests to server.
 * Also RequestManager knows all request classes (subclasses of Request).
 * Requests are bound to DOM elements via special html attribute data-request="request_id" (it can be a form or a link or anything else).
 * You can manually create instance of request via RequestManager.create_request(request_id).
 *
 * When response is received, runs handler 'on_request_end'.
 */
export class RequestManager implements Interface {

    /**
     * Base URL part - for JSON requests ( http://example.com/rest/player/123 )
     */
    private server_base_url_json:string = "";

    /**
     * Base URL part - that is shown to the user in the browser's history ( http://example.com/player/123 )
     */
    private server_base_url_user:string = "";

    private mock:object = null;

    constructor() {
        this.mock = {};
    }

    init() {
    }

    clear_auth_token(): void {
        Cookies.remove(SecurityToken.sessionCookieName);
        Cookies.remove(SecurityToken.xsrfCookieName);
    }

    /**
     * set prefix for all URL (is used when sending AJAX-requests)
     * @param {string} server_base_url_json - a real prefix for URL
     * @param {string} server_base_url_user - a prefix for browser's history - what user will see
     */
    set_server_base_url(server_base_url_json, server_base_url_user) {
        this.server_base_url_json = server_base_url_json;
        this.server_base_url_user = server_base_url_user;
    }

    get_server_base_url_json():string {
        return this.server_base_url_json;
    }

    get_server_base_url_user():string {
        return this.server_base_url_user;
    }

    injectCSRFHeader(xhr) {
        if (SecurityToken.getCSRFCookie())
            xhr.setRequestHeader(SecurityToken.xsrfHeaderName, SecurityToken.getCSRFCookie());
    }

    /**
     * make AJAX-GET request
     * @param {string} url - URL
     * @param {function} on_success_func - (optional) function(url, data, response, textStatus, jqXHR) {}
     * @param {function} on_error_func - (optional) function(url, data, response) {}
     * @param {any} context - (optional) any context for calling functions on_success_func and on_error_func (it can be instance of Request)
     * @param {any} handler_prefix - (optional) handler prefix for begin/end request
     */
    ajax_get(
        url:string,
        on_success_func?:(url, data, response, textStatus, jqXHR) => void,
        on_error_func?:(url, data, response, textStatus, jqXHR) => void,
        context?:any,
        handler_prefix?:string) {

        if (url === undefined) {
            DataHelpers.error("RequestManager.ajax_get: url is undefined");
            return;
        }

        if (this.try_mock(url, null, on_success_func, context))
            return;

        if (handler_prefix)
            HANDLER_MANAGER_SINGLETON.run_if_exist(handler_prefix + "begin");

        const self = this;
        context = context || this;
        $.ajax({
            type: "GET",
            url: url,
            beforeSend: self.injectCSRFHeader,
            success(response, textStatus, jqXHR) {
                if (handler_prefix)
                    HANDLER_MANAGER_SINGLETON.run_if_exist(handler_prefix + "end");

                if (DataHelpers.isFunction(on_success_func))
                    on_success_func.call(context, url, null, response, textStatus, jqXHR);
            },
            error(jqXHR, textStatus, errorThrown) {
                DataHelpers.error("RequestManager.ajax_get", url, "response:", textStatus, errorThrown, jqXHR);

                if (handler_prefix)
                    HANDLER_MANAGER_SINGLETON.run_if_exist(handler_prefix + "end");

                const response = self.get_default_error_response(jqXHR, textStatus, errorThrown);
                if (DataHelpers.isFunction(on_error_func))
                    on_error_func.call(context, url, null, response, textStatus, jqXHR);
            },
            contentType: "application/json",
            dataType: "json"
        });
    }

    /**
     * make AJAX-POST request
     * @param {string} url - URL
     * @param {object} data - map of POST-values {key:value, ...}
     * @param {function} on_success_func - (optional) function(url, data, response, textStatus, jqXHR) {}
     * @param {function} on_error_func - (optional) function(url, data, response) {}
     * @param {any} context - (optional) any context for calling functions on_success_func and on_error_func (it can be instance of Request)
     * @param {any} handler_prefix - (optional) handler prefix for begin/end request
     */
    ajax_post(
        url:string,
        data: { [key:string]:any },
        on_success_func?: (url, data, response, textStatus, jqXHR) => void,
        on_error_func?:(url, data, response, textStatus, jqXHR) => void,
        context?:any,
        handler_prefix?:string) {

        if (url === undefined) {
            DataHelpers.error("RequestManager.ajax_post: url is undefined");
            return;
        }

        if (this.try_mock(url, data, on_success_func, context))
            return;

        if (handler_prefix)
            HANDLER_MANAGER_SINGLETON.run_if_exist(handler_prefix + "begin");

        const self = this;
        context = context || this;

        $.ajax({
            type: "POST",
            url: url,
            data: JSON.stringify(data),    // send as JSON string
            beforeSend: self.injectCSRFHeader,
            success(response, textStatus, jqXHR) {

                const format = /\/ping/;

                if (handler_prefix)
                    HANDLER_MANAGER_SINGLETON.run_if_exist(handler_prefix + "end");

                //console.log(jqXHR);

                if (DataHelpers.isFunction(on_success_func))
                    on_success_func.call(context, url, data, response, textStatus, jqXHR);
            },
            error(jqXHR, textStatus, errorThrown) {
                DataHelpers.error("RequestManager.ajax_post", url, data, "response:", textStatus, errorThrown, jqXHR);

                if (handler_prefix)
                    HANDLER_MANAGER_SINGLETON.run_if_exist(handler_prefix + "end");

                const response = self.get_default_error_response(jqXHR, textStatus, errorThrown);
                if (DataHelpers.isFunction(on_error_func))
                    on_error_func.call(context, url, data, response, textStatus, jqXHR);
            },
            contentType: "application/json",
            dataType: "json"
        });
    }

    private try_mock(url, data, on_success_func, context) {
        if (DataHelpers.isObject(this.mock[url])) {
            if (DataHelpers.isFunction(on_success_func))
                on_success_func.call(context, this.mock[url]);

            return true;
        }

        if (DataHelpers.isFunction(this.mock[url])) {
            if (DataHelpers.isFunction(on_success_func))
                on_success_func.call(context, this.mock[url](data));

            return true;
        }

        return false;
    }

    private get_default_error_response(jqXHR, textStatus, errorThrown) {
        const error_id = (jqXHR && DataHelpers.isObject(jqXHR.responseJSON) && DataHelpers.isString(jqXHR.responseJSON.error)) ? jqXHR.responseJSON.error : "errorResponse";
        const tplVars = jqXHR?.responseJSON?.tplVars || [];
        return {
            error: i18n.t(error_id, tplVars).toString(),
            error_id: error_id,
            errorDetails:
            {
                jqXHR: jqXHR,
                textStatus: textStatus,
                errorThrown: errorThrown
            }
        };
    }

    get_log(): any {
        return {
            server_base_url_json: this.server_base_url_json,
            server_base_url_user: this.server_base_url_user
        };
    }

    set_mock(url:string, value:any):void {
        this.mock[url] = value;
    }

    ajax_post_async(
        url:string,
        data: { [key:string]:any },
        on_success_func?:(url, data, response, textStatus, jqXHR) => void,
        on_error_func?:(url, data, response, textStatus, jqXHR) => void,
        context?:any,
        handler_prefix?:string) {

        if (url === undefined) {
            DataHelpers.error("RequestManager.ajax_post: url is undefined");
            return;
        }


        if (handler_prefix)
            HANDLER_MANAGER_SINGLETON.run_if_exist(handler_prefix + "begin");

        const self = this;
        return new Promise(function(resolve, reject) {
                $.ajax({
                    type: "POST",
                    url: url,
                    data: JSON.stringify(data),    // send as JSON string
                    beforeSend: self.injectCSRFHeader,
                    contentType: "application/json",
                    dataType: "json"
                }).done(
                    (response, textStatus, jqXHR) => {
                        if (handler_prefix) HANDLER_MANAGER_SINGLETON.run_if_exist(handler_prefix + "end");

                        if (DataHelpers.isFunction(on_success_func))
                            on_success_func.call(context, url, data, response, textStatus, jqXHR);

                        resolve({ response, textStatus, jqXHR });
                    }
                ).fail(
                    (jqXHR, textStatus, errorThrown) => {
                        if (handler_prefix) HANDLER_MANAGER_SINGLETON.run_if_exist(handler_prefix + "end");

                        const response = self.get_default_error_response(jqXHR, textStatus, errorThrown);
                        if (DataHelpers.isFunction(on_error_func))
                            on_error_func.call(context, url, data, response, textStatus, jqXHR);

                        // return Promise.reject(response);
                        console.log(response);
                        let error = response.error;

                        if( response.error_id == "errorGameAccountNotExist" || response.error_id == "errorGameAccountAlreadyExist") {
                            error = response.error_id;
                        }

                        console.log(error);

                        reject(error);
                    }
                );
        });
    }


    ajax_get_async(
        url:string,
        data: { [key:string]:any },
        on_success_func?:(url, data, response, textStatus, jqXHR) => void,
        on_error_func?:(url, data, response, textStatus, jqXHR) => void,
        context?:any,
        handler_prefix?:string) {

        if (url === undefined) {
            DataHelpers.error("RequestManager.ajax_post: url is undefined");
            return;
        }


        if (handler_prefix)
            HANDLER_MANAGER_SINGLETON.run_if_exist(handler_prefix + "begin");

        const self = this;
        return $.ajax({
            type: "GET",
            url: url,
            data: JSON.stringify(data),    // send as JSON string
            beforeSend: self.injectCSRFHeader,
            contentType: "application/json",
            dataType: "json"
        }).done(
            (response, textStatus, jqXHR) => {
                if (handler_prefix) HANDLER_MANAGER_SINGLETON.run_if_exist(handler_prefix + "end");
            }
        ).fail(
            () => {
                if (handler_prefix) HANDLER_MANAGER_SINGLETON.run_if_exist(handler_prefix + "end");
            }

        );
    }
}
