export enum EffectGoodness {
    POSITIVE = "POSITIVE",
    NEGATIVE = "NEGATIVE",
    NEUTRAL = "NEUTRAL"
}

export interface StatValueModel {
    value: number;
    valueString: string;
    increase: number;
    goodness: EffectGoodness;
}
