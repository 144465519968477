
    import { defineComponent } from "vue";
    import GdprNotice from "@/components/GdprNotice.vue";
    import NewsPopup from "@/components/NewsPopup.vue";
    import FloatingIcons from "@/components/FloatingIcons.vue";
    import ShopPopup from "@/components/ShopPopup.vue";
    import AppHeader from "@/app/components/header/AppHeader.vue";
    import Notifications from "@/app/components/notification/Notifications.vue";
    import AppFooter from "cuties-client-components/components/AppFooter.vue";
    import { AppStoreSingleton } from "./store/AppStore";
    import type { PlayerOrGuest } from "@/store/PlayerStore";
    import { PlayerStoreSingleton } from "@/store/PlayerStore";
    import { ConfigInstance } from "@/cuties/engine/Config";

    const Quests = () => import(/* webpackChunkName: "Quests" */ "@/components/Quests.vue");

    export default defineComponent({
        name: "App",
        components: {
            GdprNotice,
            Quests,
            NewsPopup,
            FloatingIcons,
            ShopPopup,
            AppHeader,
            Notifications,
            AppFooter,
        },
        computed: {
            configLoaded(): boolean {
                return AppStoreSingleton.state.configLoaded;
            },
            navigationIteration(): number {
                return AppStoreSingleton.state.navigationIteration;
            },
            player(): PlayerOrGuest | undefined {
                return PlayerStoreSingleton.state.player;
            },
            pageId(): string {
                return this.$route.name ?? "";
            }
        },
        methods: {
            theme(): string {
                const theme = ConfigInstance.isBinance() ? "binance" : "bcu";
                $("body").attr("theme", theme); // so we can set variables on body (body use background variable)
                return theme;
            },
        },
    });
