import http from "@/http";
import type ResponseAdminPanel from "@/cuties/admin/ResponseAdminPanel";
import type { ResponseItemUse } from "@/cuties/model/inventory/ResponseItemUse";
import type { UserTemplate } from "@/cuties/model/player/UserTemplate";


export default class AdminService {
    public static async checkUser(params: { userId: number }): Promise<UserTemplate> {
        return http.get("/admin_check_user", { params }).then(rs => rs.data);
    }

    public static async getPrimaryAdminPage(): Promise<ResponseAdminPanel> {
        return http.get<any>("/admin/all_fancy", {})
            .then(response => response.data);
    }

    public static async setAllMyPetLevels(minPetLevel: number, maxPetLevel: number) {
        return http.post<ResponseItemUse>("/admin_set_my_pet_levels", {
            minPetLevel,
            maxPetLevel,
        })
            .then(response => response.data);
    }

    public static async setAllMyPetExperience(minPetExperience: number, maxPetExperience: number) {
        return http.post<ResponseItemUse>("/admin_set_my_pet_experiences", {
            minPetExperience,
            maxPetExperience,
        })
            .then(response => response.data);
    }

    public static async setPlayerIcon(userId: number, icon: string) {
        return http.post("/set_icon", {
            icon: icon,
            userId: userId
        }).then(response => response.data);
    }

    public static async setCanGift(userId: number, canGiftItems: boolean) {
        return http.post("/set_can_gift_items", {
            canGiftItems: canGiftItems,
            userId: userId
        }).then(response => response.data);
    }

    public static async hideFromLeaderBoard(userId: number) {
        return http.get("/admin_hide_board?walletId=" + userId).then(response => response.data);
    }

}
