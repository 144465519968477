import type { Player } from "@/cuties/model/player/Player";
import { PLAYER_SINGLETON } from "@/cuties/model/player/Player";
import I18nHelpers from "@/cuties/engine/I18nHelpers";
import type { PersonalMessageSignature, WalletAddress, WalletProviderKind } from "@/components/LoginManager/TypeDefs";
import type { UserTemplate } from "@/cuties/model/player/UserTemplate";
import type { Blockchain } from "@/cuties/model/pet/BlockchainId";
import utils from "@/cuties/utils";
import type { AuthenticationRequest, AuthError } from "@/cuties/player/UserApiService";
import UserApiService from "@/cuties/player/UserApiService";
import type { AuthProvider } from "@/cuties/blockchain/types";
import VueUtils from "@/cuties/VueUtils";
import AccountMergePopup from "@/components/wallet/AccountMergePopup.vue";
import PopupManager from "@/cuties/engine/PopupManager";
import store from "@/store";
import socket from "@/socket";
import ActivitySubscription from "@/subscription/ActivitySubscription";
import ImportantActivitySubscription from "@/subscription/ImportantActivitySubscription";
import QuestSubscription from "@/subscription/QuestSubscription";
import GlobalChatSubscriptions from "@/app/subscription/GlobalChatSubscriptions";
import IntroductionSystem from "@/cuties/player/introduction/IntroductionSystem";
import { ConfigInstance } from "@/cuties/engine/Config";
import { ShowLoader } from "@/common/decorators/ShowLoader";

const isNonExistingAccError = (exc: AuthError | null) => {
    return exc?.response?.data?.error === "errorGameAccountNotExist";
};

export interface AuthorizeBySigningTermsParams {
    blockchain: Blockchain;
    walletProvider: WalletProviderKind;
    provider: AuthProvider;
}

type AuthorizeUser = {
    blockchain: Blockchain;
    account: WalletAddress;
    code: PersonalMessageSignature;
    walletProvider: WalletProviderKind;
};

/**
 * this class provides functionality which is currently scattered and copypasted
 * again and again in every blockchain - hopefully we can eventually start using
 * this single class for logged user common UI initialization code
 */
class LoginService {
    /**
     * @param signMessage - provider-specific action that takes a text message
     *  (terms) and returns signature generated using wallet's private key
     */
    static async authorizeBySigningTerms({ blockchain, walletProvider, provider }: AuthorizeBySigningTermsParams): Promise<UserTemplate> {
        const termsText = ConfigInstance.get("terms");
        const account = await provider.getWalletAddress();
        const code = await provider.signPersonalMessage(termsText);
        return LoginService.authorizeUserSignature({
            code,
            walletProvider,
            blockchain,
            account,
        });
    }

    @ShowLoader
    static async authorizeUserSignature({ account, code, blockchain, walletProvider }: AuthorizeUser): Promise<UserTemplate> {
        try {
            const { user } = await UserApiService.authorizeBlockchainAccount({
                blockchain,
                account,
                code,
                walletProvider,
            });

            return user;
        } catch (e) {
            if (isNonExistingAccError(e)) {
                const { user } = await UserApiService.registerBlockchainAccount({
                    blockchain,
                    account,
                    code,
                    walletProvider: walletProvider,
                    referrer: utils.get_referrer(),
                    inviterId: utils.get_inviter_id(),
                    campaignUrlParams: UserApiService.getCampaignUrlParams(),
                });

                return user;
            }

            throw e;
        }
    }

    /**
     * @param {UserTemplate} user - player's data from server
     */
    public static initializeAuthorizedUser(user: UserTemplate) {
        const player = PLAYER_SINGLETON;
        player.fillFromTemplate(user);

        store.commit("setPlayer", player);
        store.commit("setIsLogined", true);

        notificationSubscribe();
        // reconnect ws connection because api is not aware about
        // user session after login with current connection
        socket.userConnection();

        IntroductionSystem.initializeUser(player.get_id());
        if (player.get_blockchains().size > 0) {
            IntroductionSystem.completeStep("ATTACH_BLOCKCHAIN_ACCOUNT");
        }
    }

    private static updateUserData(update: (user: Player) => void) {
        update(PLAYER_SINGLETON);
        // committing Object.create() instead of the instance itself is an inheritance h̶a̶c̶k̶ trick that produces
        // virtually same object, but a different reference, to make vuex aware that value has changed
        store.commit("setPlayer", Object.create(PLAYER_SINGLETON));
    }

    public static setAddedBlockchainAddress(blockchain: Blockchain, address: WalletAddress) {
        this.updateUserData(user => user.setAddedBlockchainAddress(blockchain, address));
    }

    public static pawCoinsDeducted(delta: number) {
        this.updateUserData(user => user.pawCoinsDeducted(delta));
    }

    public static async tryAttachAccount(params: AuthenticationRequest) {
        try {
            await UserApiService.attachBlockchainAccountToUser(params);
            await new PopupManager().show("account_attach_done_popup", {
                title: I18nHelpers.translate("yourAccountIsCreated"),
                blockchain: params.blockchain,
            });
        } catch (exc: any) {
            const errorData = VueUtils.extractErrorData(exc);
            if (errorData && errorData.error === "errorGameAccountAlreadyExist") {
                const translation = VueUtils.getErrorTranslation(errorData.error, errorData.tplVars ?? []);
                utils.show_notification(translation!);

                await VueUtils.mountPopup(AccountMergePopup);
                await UserApiService.mergeAccounts(params);
                await new PopupManager().show("account_attach_done_popup", {
                    title: I18nHelpers.translate("yourAccountIsCreated"),
                    blockchain: params.blockchain,
                });
            } else {
                throw exc;
            }
        }
        this.setAddedBlockchainAddress(params.blockchain, params.account);
    }
}

export default LoginService;

function notificationSubscribe() {
    ActivitySubscription.subscribe();
    ImportantActivitySubscription.subscribe();
    QuestSubscription.subscribe();
    GlobalChatSubscriptions.forEach((s) => s.subscribe());
}
