import type { DirectiveBinding } from "vue/types/options";

// examples
// <button v-svg-icon="'external'>Button text</button>"
// the icons stored in src/assets/icons
export const svgIcon = {
    bind(el: HTMLElement, { value }: DirectiveBinding): void {
        const img: HTMLElement = document.createElement("i");
        img.style.backgroundImage = `url(${require("@/assets/icons/" + value + ".svg")})`;
        img.classList.add("glb-svg-icon");
        el.prepend(img);
    },
};
