import { PLAYER_SINGLETON } from "./cuties/model/player/Player";
import Router from "vue-router";
import type { Route } from "vue-router/types/router";
import RaidApiService from "./cuties/raid/RaidApiService";
import VueUtils from "./cuties/VueUtils";
import PetService from "./cuties/pet/PetService";
import CutieListApiService from "./app/cuties/list/CutieListApiService";
import { withLoader } from "./cuties/engine/LoadSpinner";
import utils from "./cuties/utils";
import store from "@/store";
import { AppStoreMutations, AppStoreSingleton } from "@/store/AppStore";
import { langMetaData, setLanguage } from "@/i18n";
import Cookies from "js-cookie";

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
});

/**
 * vue mutates address bar before INCREMENT_NAVIGATION_ITERATION click handler is executed,
 * so we can only get hold of the actual origin address by storing in beforeEach()
 * oh, and this damn thing does not fire when to is same as from ^_^
 */
export let currentNavigation: {
    time: number;
    to: Route;
    from: Route;
} | null = null;

router.beforeEach(async (to, from, next) => {
    currentNavigation = { time: window.performance.now(), to, from };

    const navigatorLang: string | undefined = navigator.language.split("-")[0];
    let requestedLang = to.params.lang ?? Cookies.get("lang") ?? navigatorLang ?? "en";
    if (!langMetaData.langToMd5[requestedLang]) {
        requestedLang = "en";
    }
    if (to.params.lang) {
        if (!langMetaData.langToMd5[to.params.lang]) {
            return next("/page404");
        }
    } else {
        router.push({ path: "/" + requestedLang + to.fullPath });
        return;
    }

    setLanguage(requestedLang);

    if (to.name === "changelog" || to.name === "changelog-localized" ||
        to.name === "blog-history" || to.name === "blog-history-localized"
    ) {
        window.location.href = to.fullPath; // ssr page - force reload
    }
    if (to.name === "raidlink" || to.name === "raidlink-localized") {
        const raidId = to.params["id"] ?? "";

        try {
            const data = await RaidApiService.getRaidsPage();
            const find = data.raids.find((rec) => rec.boss.type === raidId);

            if (find !== undefined) {
                let link = `/${requestedLang}/raid/${find.version}/${find.id}`;
                if (link === window.location.pathname + window.location.search) {
                    link += "?ver=" + +new Date();
                }

                await router.replace({ path: link });
            } else {
                await router.replace({ path: "/" + requestedLang + "/raids" });
            }
            store.commit(AppStoreMutations.INCREMENT_NAVIGATION_ITERATION);
            return;
        } catch (exc) {
            VueUtils.handleError(exc);
            return router.replace({ name: "page404-localized", params: { lang: requestedLang } });
        }
    }
    next();
});

router.afterEach(async (to, from) => {
    // setTimeout 0 ensures that we do that on the next tick, hopefully that guarantees that
    // INCREMENT_NAVIGATION_ITERATION click handler will always be executed before that
    setTimeout(() => {
        currentNavigation = null;
    }, 0);

    const user_inviter_id = to.query.inviter ?? false;
    if (user_inviter_id) {
        utils.init_inviter_id(user_inviter_id.toString());
    }

    const langPrefix = to.params.lang ? "/" + to.params.lang : "";

    // Set body class
    $("body").attr("class", "page_" + to.name?.replace(/-localized$/, ""));
    if (to.name?.startsWith("admin")) {
        if (!(PLAYER_SINGLETON.is_role_admin() || PLAYER_SINGLETON.is_role_master())) {
            router.replace({ name: "page404", params: { lang: to.params.lang } });
        }
    } else if (to.name === "cutie-my-oldest" || to.name === "cutie-my-oldest-localized") {
        const whenResponse = CutieListApiService.listMy({
            sortBy: "Age",
            sortOrder: "Asc",
            size: 1,
            page: 0,
        });
        const response = await withLoader(whenResponse);
        if (response.cutiesPage.content.length > 0) {
            return router.replace({ path: langPrefix + "/pet/" + response.cutiesPage.content[0].uid });
        } else {
            throw new Error("You have no cuties!");
        }
    } else if (to.name === "cutie" || to.name === "cutie-localized") {
        if (!to.query["cutieDbId"]) {
            throw new Error("Cutie ID not supplied in URL");
        }
        const cutieDbId = Number(to.query["cutieDbId"]);
        const { uid } = await PetService.getCutieByDbId({ cutieDbId });
        router.replace({ path: langPrefix + "/pet/" + uid });
    } else if (to.name === "root-localized" || to.name === "root") {
        if (!PLAYER_SINGLETON.is_logined()) {
            router.replace({ path: langPrefix + "/login" });
        } else {
            router.replace({ path: langPrefix + "/pets_my" });
        }
    }

    const pageId = to.name ? to.name.toString() : "";
    store.commit("setUrl", to.path);
    utils.setPageFavicon("/static/favicon.ico");
    if (AppStoreSingleton.state.translationsLoaded) {
        utils.setPageTitle(to);
    }
});

export default router;
