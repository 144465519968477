import http, { getStateless } from "@/http";
import { makeHttp } from "@/http";
import IntroductionSystem from "@/cuties/player/introduction/IntroductionSystem";
import type { CutieGroupStats, PetGroupRaw } from "@/cuties/CutieGroupService";
import type {
    AttributeStatsModel,
    BonusesMapping,
    PetModelFull,
    PlayerModelData,
    PetModelData,
    ElementKind
} from "@/cuties/model/pet/PetModelData";
import type { Blockchain } from "@/cuties/model/pet/BlockchainId";
import type { PetPurposeKind } from "../model/pet/PetModel";
import type { PetFilter } from "@/app/cuties/list/CutieListApiService";
import type { AuctionType, CutieListFilterRequestBase } from "@/app/cuties/tournament/TournamentService";
import type { SortBy } from "../raid/RaidApiService";
import type { ErrorType } from "@/cuties/VueUtils";
import type { PossessionIdentity } from "cuties-client-components/types/general/UnitIdentity";

const unversionedHttp = makeHttp({ baseUrl: "/rest" });

export default class PetService {
    public static async getPetsMy(payload: PetListRequest): Promise<ResponsePets> {
        const location = (await this.getDefaultLocation())?.defaultLocation;
        if (location) {
            payload["statsForLocation"] = location;
        }
        return this.getLegacyCutieList(payload);
    }

    public static getLegacyCutieList(payload: PetListRequest): Promise<ResponsePets> {
        return http.post("/pets", payload).then((Response) => Response.data);
    }

    public static async getCutieByDbId(params: { cutieDbId: number }): Promise<CutieByDbIdResponse> {
        return unversionedHttp.get("/public/cutie/by/db/id", { params }).then((response) => response.data);
    }

    public static async getPet(petUid: string): Promise<SingleCutiePageResponse> {
        const location = (await this.getDefaultLocation())?.defaultLocation;
        return http.get("/pet", { params: { uid: petUid } }).then((response) => response.data);
    }

    public static async getDefaultLocation(): Promise<{ defaultLocation: string }> {
        return http.get("/static/defaultValues/defaultLocation.json", { baseURL: "" }).then((response) => response.data);
    }

    public static async petRename(id: number, name: string): Promise<PetModelFull> {
        const location = (await this.getDefaultLocation())?.defaultLocation;
        return http.post("/pet_rename", { id, name, location }).then((response) => response.data);
    }

    public static async setBioText(id: number, bioText: string): Promise<void> {
        return makeHttp({ baseUrl: "/rest" })
            .post("/cutie/set/bio", { id, bioText })
            .then((response) => response.data);
    }

    public static recover(uid: string): Promise<{ result: boolean }> {
        return http.get("/recover/" + uid, {}).then((response) => response.data);
    }

    public static setBackground(petId: number, background: string): Promise<void> {
        return http.post("/set_pet_background", { petId: petId, background: background }).then((response) => response.data);
    }

    public static getPetPossibleMaxLevel(uid: string): Promise<{ maxLevel: number }> {
        return http.get(`/level/max/${uid}`).then((response) => response.data);
    }

    public static countTimesBred(cutieId: number): Promise<number> {
        return getStateless("/public/breeding/count/times", { params: { cutieId } })
            .then((response) => response.data);
    }

    public static setPetLevel(uid: string, level: number): Promise<void> {
        return http.post(`/level/${uid}/${level}`).then((response) => response.data);
    }

    public static async likeCutie(cutieId: number): Promise<PetModelData> {
        return http.post("/pet_like", { id: cutieId }).then((response) => response.data);
    }

    public static async useItemQuick(id: number, itemKind: string): Promise<PetModelData> {
        return http.post("/item_use_quick", { petId: id, itemKind: itemKind, returnQuickslots: true }).then((response) => {
            IntroductionSystem.completeStep("EQUIP_AN_ITEM");
            return response.data;
        });
    }

    public static async selectionItemUse(
        itemKind: string,
        level: number,
        groupId: number | null,
        replaceStrategy: string,
        petIds: number[] = []
    ): Promise<PetModelData> {
        return http
            .post("/selection_item_use", {
                itemKind: itemKind,
                groupId: groupId,
                enchantLevel: level,
                replaceStrategy: replaceStrategy,
                petIds: petIds,
            })
            .then((response) => response.data);
    }

    public static async getTutorialCutie(): Promise<void> {
        return http.get("/tutorial_start", {}).then((response) => response.data);
    }

    /** returns false if user already owns a tutorial cutie, true otherwise */
    public static async isTutorialCutieClaimable(): Promise<boolean> {
        return http.get("/tutorial/cutie/is/claimable").then((response) => response.data);
    }
}

export const ALL_ELEMENTS = ["air", "earth", "energy", "fire", "water"] as const;

export function getStatsDict(
    bonusesV2: AttributeStatsModel[]
): Partial<BonusesMapping> {
    const bonuses: Partial<BonusesMapping> = {};
    for (const bonus of bonusesV2) {
        bonuses[bonus.kind] = {
            bonus: bonus.value,
            useInfos: bonus.useInfos ?? [],
        };
    }
    return bonuses;
}

export type SortOrderType = "Asc" | "Desc";

export interface ResponsePets {
    adventureConfigs: any[];
    pets: PetModelData[];
    petCount: number;
    filter: PetFilter;
    filterOptions: any;
    sort: {
        by: keyof typeof SortBy;
        order: SortOrderType;
    };
    sortOptions: string[];
    groupStats?: CutieGroupStats;
    promo_pets?: PetModelData[];
    quickSlotItems: QuickSlotItem[];
    allowGetTutorialCutie: boolean;
    petCountTotal: number;
    petCountForAdventures: number;
    current_player_state: "string"; // 'New'
    owner?: PlayerModelData; // returns only if request have filter by owner
    groups: PetGroupRaw[];
    featured?: PetModelData[];
    disabled?: Record<number, string>;
}

/** @see QuickslotItemTemplate.java */
export interface QuickSlotItem {
    itemId: number;
    /** the base name of the item, the server will implicitly use personal version when applicable */
    itemKind: string;
}

export interface CutieByDbIdResponse {
    uid: string;
}

export interface SingleCutiePageResponse {
    adventureConfigs: AdventureConfigModel[];
    groups: PetGroupRaw[];
    pet_model: PetModelFull;
}

export interface AdventureConfigModel {
    adventureId: string;
    elements: ElementKind[];
    maxExpLevel: number;
    minExpLevel: number;
    allOfPlayerRequirements: Array<PossessionIdentity>;
    allOfConsumes: Array<PossessionIdentity>;
    /** error explaining why cutie can't go thi this adventure if any */
    participationResult?: ErrorType;
    levelDiffDropMultiplier: number;
}

/** @deprecated - better reference from PetModelData.d.ts directly */
export { PetModelData };

/** @see AuctionTemplate.java */
export interface AuctionInterface {
    id: number;
    type: AuctionType;
    priceStart: number;
    priceEnd: number;
    priceNow: number;
    timeStart: number;
    duration: number;
    featured: boolean;
    contract: string;
    allowedTokens: string[] | null;
    blockchain: Blockchain;
}

export interface AdditionalDetailsModel {
    title: string;
    description: string;
    socialFacebook: string;
    socialLinkedin: string;
    socialTwitter: string;
}

export interface AdventureElements {
    name: string;
    power: number;
}

/** @see PetListRequest.java */
export interface PetListRequest extends CutieListFilterRequestBase {
    page?: number | null; // @Positiv
    ownerId?: number | null;
    ownerWalletId?: string | null;
    group?: number | null;
    listBattleState?: boolean | null;
    /** for season buffs to be included in cutie stats data */
    statsForLocation?: string | null;
    /**
     * This ugly workaround used for cutie breed page
     * breedPetId - cutie uid
     */
    breedPetId?: string;
    onlyIds?: number[];
    itemId?: number;
    listItems?: boolean;
    availableForTokens?: boolean;
    adventureConfigId?: string;
    featuredCount?: number;
    purpose?: keyof typeof PetPurposeKind;
    inAdventure?: boolean;
    readyForAdventure?: boolean;
    featured?: boolean;
    specialSale?: boolean;
    /** no definition on server */
    /** @deprecated */
    mymarket?: unknown;
    /** @deprecated */
    blockchain?: null;
}
