
    import type { NotificationModel } from "@/store/AppStore";
    import { AppStoreMutations } from "@/store/AppStore";
    import { Component, Prop, Vue } from "vue-property-decorator";
    import { Mutation } from "vuex-class";

    @Component({
        components: {},
    })
    export default class Notification extends Vue {
        @Mutation(AppStoreMutations.REMOVE_NOTIFICATION) removeNotification!: (val: NotificationModel) => void;
        @Prop({ required: true }) notification!: NotificationModel;
        showBox = false;

        mounted() {
            this.showBox = true;

            setTimeout(() => {
                this.close();
            }, this.timeShow);
        }

        get timeShow(): number {
            const defaultValue = 6000;
            if (this.notification.type === "notificationText") {
                return this.notification.isError ? 15000 : defaultValue;
            } else {
                return defaultValue;
            }
        }

        goToActivity() {
            if (this.notification.type === "notificationText" && this.notification.removeLink) {
                return;
            } else {
                this.$router.push({ path: "/" + this.$i18n.locale + "/activity" });
            }
        }

        close() {
            this.showBox = false;
            this.delete();
        }

        delete() {
            setTimeout(() => {
                this.removeNotification(this.notification);
            }, 1500); // time to play fade out
        }
    }
