import type { AnyItemSupplySource, ItemSupplySource } from "@/cuties/items/ItemSupplySource";
import RaidApiService from "@/cuties/raid/RaidApiService";
import utils from "@/cuties/utils";
import VueUtils from "@/cuties/VueUtils";
import i18n from "@/i18n";
import store from "@/store";
import { AppStoreActions } from "@/store/AppStore";
import { QuestStoreActions } from "@/store/QuestsStore";
import ItemApiService from "@/app/cuties/items/ItemApiService";
import router from "@/router";

export default class ItemSupplySourcesLayer {
    public static getLink(supplySource: AnyItemSupplySource): string | undefined {
        if (supplySource.type === "TimedCrafting") {
            return "/forge/" + supplySource.craftingTableId + "/?" + new URLSearchParams({ recipe: supplySource.recipeId });
        } else if (supplySource.type === "ForgeRecycle") {
            return "/forge/RECYCLE";
        } else if (supplySource.type === "Lootbox") {
            return "/item/" + supplySource.lootboxItemKind + "/0";
        } else if (supplySource.type === "Tournament") {
            return "/tournaments";
        } else if (supplySource.type === "HotShop") {
            return "/items/hotshop?" + new URLSearchParams({
                category: supplySource.category,
                offerId: supplySource.offerId,
            });
        } else if (supplySource.type === "LegacyShop" && supplySource.group === "Shop") {
            return "/items/token?" + new URLSearchParams({ category: supplySource.category! });
        } else if (supplySource.type === "SeasonLevelReward") {
            return "/season_pass";
        } else if (supplySource.type === "Dungeon") {
            return "/dungeon-world?" + new URLSearchParams({
                id: supplySource.worldId,
                stageId: supplySource.stageId,
            });
        } else if (supplySource.type === "Adventure") {
            return "/adventure/" + supplySource.location;
        } else {
            return undefined;
        }
    }

    public static async goToNoLinkSource(itemKind: string, supplySource: AnyItemSupplySource): Promise<void> {
        if (supplySource.type === "PlayersMarket") {
            const map = await ItemApiService.itemDefinitionMap();
            const definition = map.get(itemKind);
            if (!definition) {
                // may happen for hidden items like upcoming season content
                utils.show_notification(i18n.t("ERR_ITEM_NAVIGATION_DISABLED"));
                return;
            }
            const link = this.getOffchainMarketLink(definition.baseName ?? definition.name);
            router.push({ path: link });
        } else if (supplySource.type === "Raid2") {
            const { boss } = supplySource;
            this.goToBoss(boss, "2");
        } else if (supplySource.type === "Raid1") {
            const { boss } = supplySource;
            this.goToBoss(boss, "1");
        } else if (supplySource.type === "Lottery") {
            store.dispatch(AppStoreActions.LOTTERY_POPUP_OPEN);
        } else if (supplySource.type === "Secret") {
            return utils.show_notification(i18n.t("item-source-secret").toString());
        } else if (supplySource.type === "LegacyShop") {
            // Season Pass and Season Lootboxes
            store.dispatch(AppStoreActions.SHOP_POPUP_OPEN, supplySource.group);
        } else if (supplySource.type === "DailyQuest") {
            store.dispatch(QuestStoreActions.OPEN_DAILY_QUESTS_POPUP);
        } else {
            const ever: ItemSupplySource = supplySource;
            return utils.show_notification("TODO: implement navigation for " + ever.type + "!");
        }
    }

    private static async goToBoss(bossName: string, systemVersion: "1" | "2") {
        try {
            const { raids } = await RaidApiService.getRaidsPage();
            const raid = raids.find((raid) => raid.boss.type === bossName);
            if (!raid) {
                return utils.show_notification("Raid Boss is not in the active list");
            }
            router.push({
                path: "/" + i18n.locale + "/raid/" + systemVersion + "/" + raid.id,
            });
        } catch (error) {
            return VueUtils.handleError(error);
        }
    }

    static getOffchainMarketLink(itemKind: string): string {
        return "/items/market/item/" +
            encodeURIComponent(itemKind) + "?" +
            new URLSearchParams({ enchantLevel: 0 + "" });
    }

    static async getShopSources(item: string): Promise<{ hotShopOfferId: string; supplyLink: string }> {
        const itemModel = await ItemApiService.itemModelByIdentity({ item });
        const bestSupplySource = itemModel.schema.supplySources.find((ss) => ss.type === "HotShop");

        const res = {
            hotShopOfferId: "",
            supplyLink: "",
        };

        if (bestSupplySource) {
            if (bestSupplySource.type === "HotShop") {
                res.hotShopOfferId = bestSupplySource.offerId;
            }
        }

        const bestSupplyLink = itemModel.schema.supplySources.find((ss) => ss.type === "LegacyShop");
        if (bestSupplyLink) {
            if (bestSupplyLink.type === "LegacyShop") {
                res.supplyLink = bestSupplyLink.group;
            }
        }

        return res;
    }
}
